<template>
  <portal to="portal_popup">
    <modal-window :modal_open="true" title="Edit Branch Details" @close="$emit('close')">
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Branch</div>
          <input v-if="touch_business_user" v-model="branch.name" class="form-control" />
          <input
            v-if="touch_portal_user"
            v-model="branch.name"
            class="form-control bg-gray-200 text-gray-500"
            readonly
          />
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Email</div>
          <input
            v-if="touch_business_user || loggedInUser.isAdmin"
            v-model="branch.email"
            class="form-control"
          />
          <input
            v-if="touch_portal_user && !loggedInUser.isAdmin"
            v-model="branch.email"
            class="form-control bg-gray-200 text-gray-500"
            readonly
          />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-4 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Contact Number</div>
          <input
            v-if="touch_business_user || loggedInUser.isAdmin"
            v-model="branch.phone"
            class="form-control"
          />
          <input
            v-if="touch_portal_user && !loggedInUser.isAdmin"
            v-model="branch.phone"
            class="form-control bg-gray-200 text-gray-500"
            readonly
          />
        </div>
      </div>
      <!-- <div class="flex flex-wrap" v-if="touch_business_installation">
        <div class="w-full mt-4 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">
            License Status
          </div>
          <div class="flex items-center cursor-pointer mt-2" @click.prevent="toggleCustomerLicenseStatusType()">
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
              <div class="switch-toggle" :class="{'_active': branch.generalLicenseStatus === enums.generalLicenseStatus.ENABLED}"></div>
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
              Branch <span v-text="branch.generalLicenseStatus === enums.generalLicenseStatus.ENABLED ? 'Enabled' : 'Disabled'"></span>
            </div>
          </div>
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">
            Skip Order Acceptance
          </div>
          <div class="flex items-center cursor-pointer mt-2" @click.prevent="branch.skipOrderAcceptance = !branch.skipOrderAcceptance">
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
              <div class="switch-toggle" :class="{'_active': branch.skipOrderAcceptance}"></div>
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
              Order Acceptance <span v-text="branch.skipOrderAcceptance ? 'Skipped' : 'Enabled'"></span>
            </div>
          </div>
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">
            Skip SOP
          </div>
          <div class="flex items-center cursor-pointer mt-2" @click.prevent="branch.skipSopOrderAcceptance = !branch.skipSopOrderAcceptance">
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
              <div class="switch-toggle" :class="{'_active': branch.skipSopOrderAcceptance}"></div>
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
              SOP <span v-text="branch.skipSopOrderAcceptance ? 'Skipped' : 'Enabled'"></span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="$emit('save')">Save Changes</button>
      </div>
    </modal-window>
  </portal>
</template>

<script>
export default {
  props: ['branch', 'staffContacts', 'loggedInUser'],
  data() {
    return {
      orgDetails: {},
      contacts: [],
      customerStatuses: [],
    };
  },
  async mounted() {
    this.orgDetails = await window.touch.commonOrganisationDetails();
    this.contacts = await window.touch.listTicketContacts();
    this.customerStatuses = await window.touch.commonGetCustomerStatuses();
  },
  methods: {
    toggleCustomerLicenseStatusType() {
      if (this.branch.generalLicenseStatus === window.enum.generalLicenseStatus.ENABLED) {
        this.branch.generalLicenseStatus = window.enum.generalLicenseStatus.DISABLED;
      } else {
        this.branch.generalLicenseStatus = window.enum.generalLicenseStatus.ENABLED;
      }
    },
  },
};
</script>
