<template>
  <div class="sm:py-2">
    <div :class="{'p-2': isCreateQuoteEnabled ||isCreateOrderEnabled }">
      <MenuButton
        v-if="isCreateQuoteEnabled"
        icon="fa-shopping-basket"
        label="Create Quote"
        url="/new-quote"
        :active="'designer'"
      ></MenuButton>
      <MenuButton
        v-if="isCreateOrderEnabled"
        icon="fa-calculator"
        label="Create Order"
        url="/new-order"
        :active="'designer'"
      ></MenuButton>
    </div>
    <div v-if="isCreateOrderEnabled || isCreateQuoteEnabled" class="border-solid border-t border-gray-800 my-2"></div>
    <div class="p-2">
      <router-link to="/network-enquiry">
        <div
          class="flex items-center hover:bg-blue-700"
          :class="{ 'bg-blue-700': $route.meta.active === 'enquiries' }"
        >
          <div class="p-1">
            <div class="rounded-full h-5 w-5 border-solid border-2 border-yellow-500 mr-4"></div>
          </div>
          <span class="text-white">View Enquiries</span>
        </div>
      </router-link>
      <router-link v-if="isViewQuotesEnabled" to="/quotes">
        <div
          class="flex items-center hover:bg-blue-700"
          :class="{ 'bg-blue-700': $route.meta.active === 'quotes' }"
        >
          <div class="p-1">
            <div class="rounded-full h-5 w-5 border-solid border-2 border-orange-500 mr-4"></div>
          </div>
          <span class="text-white">View Quotes</span>
        </div>
      </router-link>
      <router-link v-if="isViewOrdersEnabled" to="/orders">
        <div
          class="flex items-center hover:bg-blue-700"
          :class="{ 'bg-blue-700': $route.meta.active === 'orders' }"
        >
          <div class="p-1">
            <div class="rounded-full h-5 w-5 border-solid border-2 border-green-500 mr-4"></div>
          </div>
          <span class="text-white">View Orders</span>
        </div>
      </router-link>
    </div>
    <div class="border-solid border-t border-gray-800 my-2"></div>
    <div class="p-2">
      <MenuButton
        icon="fa-book-open"
        label="Resource Centre"
        url="/marketing"
        :active="'marketing'"
      ></MenuButton>
      <MenuButton icon="fa-megaphone" label="News" url="/news" :active="'news'"></MenuButton>
      <router-link v-if="branded_mode_enabled" to="/setup">
        <div
          class="flex items-center hover:bg-blue-700"
          :class="{ 'bg-blue-700': $route.meta.active === 'branded-mode' }"
        >
          <div class="p-1 mr-4">
            <i class="fa-thin fa fa-desktop text-white"></i>
          </div>
          <span class="text-white mr-2">Branded Mode</span>
          <span class="text-white bg-purple-500 py-0.5 px-1 rounded-sm text-xs">New</span>
        </div>
      </router-link>
    </div>
    <div class="border-solid border-t border-gray-800 my-2"></div>
    <div class="p-2">
      <MenuButton
        v-if="!branded_mode_enabled"
        icon="fa-store"
        label="TOUCH Store"
        url="/touch-store"
        :active="'touch-store'"
      ></MenuButton>
      <MenuButton
        icon="fa-square-question"
        label="Knowledge Base"
        :external_link="true"
        url="https://bmgroupholdingslimited.freshdesk.com/support/solutions/101000252285"
        :active="''"
      ></MenuButton>
      <MenuButton
        icon="fa-window"
        label="Quick Support Pop-up"
        url="?hideIntroPopUp=true"
        :active="''"
      ></MenuButton>
    </div>
    <router-link to="/my-account" class="sm:hidden">
      <div class="text-center p-3">
        <div class="btn-action">My Account</div>
      </div>
    </router-link>
    <SupportPopUp :company-name="$store.state.auth.companyName" />
  </div>
</template>

<script>
import SupportPopUp from '@/components/shared/nav/SupportPopUp.vue';
import MenuButton from '@/components/shared/nav/MenuButton.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'PortalMenuItems',
  components: {
    MenuButton,
    SupportPopUp,
  },
  computed: {
    ...mapGetters({
      isCreateQuoteEnabled: 'feature/getIsCreateQuoteEnabled',
      isCreateOrderEnabled: 'feature/getIsCreateOrderEnabled',
      isViewOrdersEnabled: 'feature/getIsViewOrdersEnabled',
      isViewQuotesEnabled: 'feature/getIsViewQuotesEnabled',
    }),
  },
};
</script>
