<template>
  <div>
    <div v-if="!available_addresses || available_addresses.length === 0">
      No delivery addresses available.
    </div>
    <template v-else>
      <div>
        <div v-for="(row, i) in address_array" :key="i" v-text="row"></div>
      </div>
      <select class="form-control bg-white" @change="setAddress($event.target.value)">
        <option v-if="addressId === 0" :value="0">Please Select</option>
        <option
          v-for="address in available_addresses"
          :key="address.id"
          :selected="address.id == addressId"
          :value="address.id"
          v-text="describe(address)"
        ></option>
      </select>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      available_addresses: 'basket/available_addresses',
      addressId: 'basket/selected_address_id',
    }),
    address() {
      const addressArray = this.available_addresses.filter(
        (address) => address.id === parseInt(this.addressId, 10),
      );

      if (addressArray.length !== 1) {
        return null;
      }

      return addressArray[0];
    },

    address_array() {
      if (this.address === null) {
        return [];
      }

      if (!(typeof this.address === 'object')) {
        return [];
      }

      return [
        [this.address.line1, this.address.line2, this.address.line3].filter(Boolean).join(', '),
        this.address.town,
        this.address.code,
        this.address.iso,
      ].filter(Boolean);
    },
  },
  methods: {
    setAddress(addressId) {
      this.$store.dispatch('basket/setAddress', addressId);
    },
    describe(address) {
      return [address.line1, address.line2, address.line3, address.town, address.code, address.iso]
        .filter(Boolean)
        .join(', ');
    },
  },
};
</script>
