<template>
  <div class="h-full">
    <loading :loading="loading" class="min-h-full">
      <div class="grid grid-cols-3 gap-5 p-5">
        <div>
          <div class="bg-white p-5 border border-gray overflow-y-scroll h-screen">
            <div class="mb-5 flex items-center">
              <span class="text-lg">Groups</span>
              <div class="btn-action ml-auto" @click="isAddGroup = true">Add Group</div>
            </div>
            <div class="flex flex-col gap-2">
              <div
                v-for="group in groups"
                :key="group.id"
                class="flex items-center bg-gray-200 rounded lg p-2 cursor-pointer hover:bg-green-300 hover:text-white"
                :class="{ 'bg-green-600 text-white': groupId === group.id }"
                @drop.prevent="moveItem(group.id)"
                @dragenter.prevent
                @dragover.prevent
                @click="checkForChanges(group.id, true)"
              >
                <span>{{ group.description }}</span>
                <i
                  class="ml-auto cursor-pointer fa fa-trash-can hover:text-red-600 ml-auto"
                  @click.prevent="deleteGroup(group.id)"
                />
              </div>
            </div>
          </div>
          <portal v-if="isAddGroup" to="portal_popup">
            <modal-window :modal_open="true" title="Add Group" @close="isAddGroup = false">
              <div class="flex flex-col container">
                <div class="flex flex-col flex-wrap">
                  <div class="w-full xs:w-1/2 xs:pr-5">
                    <div class="font-medium">Name</div>
                    <input v-model="groupName" class="form-control" />
                  </div>
                </div>
                <div class="text-right mt-10 flex flex-col 2xs:block">
                  <button
                    class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
                    @click.prevent="isAddGroup = false"
                  >
                    Cancel
                  </button>
                  <button class="btn-action btn-lg" @click.prevent.stop="addGroup">Save Group</button>
                </div>
              </div>
            </modal-window>
          </portal>
        </div>
        <div>
          <div class="bg-white p-5 border border-gray overflow-y-scroll h-screen">
            <div class="mb-5 flex items-center">
              <span class="text-lg">Extras</span>
              <div
                v-if="groups.length > 0"
                class="btn-action ml-auto"
                @click="(isAddExtra = true), (extra = undefined)"
              >
                Add Item
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div
                v-for="item in items"
                :key="item.id"
                draggable="true"
                class="flex items-center bg-gray-200 rounded lg p-2 cursor-pointer hover:bg-green-300 hover:text-white"
                :class="{ 'bg-green-600 text-white': extra && item.id === extra.id }"
                @dragstart="() => (itemIdBeingMoved = item.id)"
                @click="checkForChanges(item, false)"
              >
                <span>{{ item.description }}</span>
                <i
                  class="ml-auto cursor-pointer fa fa-trash-can hover:text-red-600 ml-auto"
                  @click.prevent="deleteItem(item.id)"
                />
              </div>
            </div>
          </div>
          <portal v-if="isAddExtra" to="portal_popup">
            <modal-window
              :modal_open="true"
              title="Add Extra"
              @close="(extra = undefined), (isAddExtra = undefined)"
            >
              <updateExtra
                :groups="groups"
                :group-id="groupId"
                :extra="extra"
                :currency="currency"
                @close="(isAddExtra = undefined), loadSettings($event)"
              >
              </updateExtra>
            </modal-window>
          </portal>
        </div>
        <div>
          <div class="bg-white p-5 border border-gray overflow-y-scroll h-screen">
            <div class="mb-5 flex items-center">
              <span class="text-lg">Options</span>
            </div>
            <div v-if="extra">
              <updateExtra
                ref="update-extra"
                :key="extra.id"
                :groups="groups"
                :group-id="groupId"
                :extra="extra"
                :currency="currency"
                @close="loadSettings($event)"
              >
              </updateExtra>
            </div>
          </div>
        </div>
      </div>
    </loading>
  </div>
</template>

<script>
import updateExtra from '@/components/business/manageTouchAccount/extras/UpdateExtra.vue';
import currencyHelper from '@/mixins/currencyHelper';
import validate from 'validate.js';

export default {
  components: {
    updateExtra,
  },
  mixins: [currencyHelper],
  data() {
    return {
      groupId: undefined,
      isAddExtra: false,
      isAddGroup: false,
      extra: undefined,
      groups: [],
      items: [],
      groupName: '',
      currency: undefined,
      itemIdBeingMoved: undefined,
      loading: true
    };
  },
  async mounted() {
    await this.loadSettings();
    this.currency = await this.getCurrency();
    this.loading = false
  },
  methods: {
    async addGroup() {
      const validationErrors = validate(
        {
          'Group Name': this.groupName,
        },
        {
          'Group Name': {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }
      const { newIds } = await this.$store.dispatch('touch/AddExtraItemGroup', this.groupName);
      await this.loadSettings();
      const [newId] = newIds;
      this.groupId = newId;
      this.groupName = '';
      this.isAddGroup = false;
    },
    async deleteGroup(categoryId) {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to delete this group?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.extra = undefined;
            this.groupId = undefined;
            await this.$store.dispatch('touch/deleteExtraItemGroup', categoryId);
            await this.loadSettings();
          }
        });
    },
    async deleteItem(itemId) {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to delete this item?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await window.touch.customerUpdateExtraItems([
              {
                Id: itemId,
                Delete: true,
              },
            ]);
            this.extra = undefined;
            await this.loadSettings();
          }
        });
    },
    async checkForChanges(data, isByHeading) {
      if (this.items.length > 0 && this.$refs['update-extra'] && this.isChanges()) {
        this.alertBox()
          .fire({
            title: 'There are unsaved changes',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Go Back',
            confirmButtonText: 'Ignore & Continue',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              if (isByHeading) {
                await this.setGroup(data);
              } else {
                this.setItem(data);
              }
            }
          });
      } else if (isByHeading) {
        await this.setGroup(data);
      } else {
        this.setItem(data);
      }
    },
    isChanges() {
      const updateExtraRefs = this.$refs['update-extra'];
      const item = {
        id: updateExtraRefs.id,
        name: updateExtraRefs.name,
        unit_of_measure: updateExtraRefs.unit_of_measure,
        price: updateExtraRefs.price,
        cost: updateExtraRefs.cost,
        type: updateExtraRefs.type,
        useProductMeasurements: updateExtraRefs.useProductMeasurements,
        options: updateExtraRefs.options,
      };
      return JSON.stringify(item) !== JSON.stringify(updateExtraRefs.originalExtra);
    },
    async loadSettings(extraId) {
      this.groups = (await window.touch.GetExtraItemGroups()).groups;
      this.groups.sort((a, b) =>
        a.description.localeCompare(b.description, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      );
      if (!this.groupId && this.groups.length > 0) {
        this.groupId = this.groups[0].id;
        this.items = await this.getExtras(this.groupId);
      } else if (extraId && this.groupId) {
        this.items = await this.getExtras(this.groupId);
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id === extraId) {
            this.extra = this.items[i];
            break;
          }
        }
      } else if (this.groupId) {
        this.items = await this.getExtras(this.groupId);
      }
    },
    async getExtras(groupId) {
      const { items } = await window.touch.customerGetExtraItems(groupId);
      items.sort((a, b) =>
        a.description.localeCompare(b.description, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      );
      return items;
    },
    async getCurrency() {
      const currencies = await window.touch.commonGetCurrencies();
      return currencies.filter((currency) => currency.isDefault)[0].code;
    },
    async moveItem(groupId) {
      await window.touch.customerUpdateExtraItems([
        {
          id: this.itemIdBeingMoved,
          GroupId: groupId,
        },
      ]);
      await this.loadSettings();
      this.itemIdBeingMoved = undefined;
    },
    async setGroup(groupId) {
      this.groupId = groupId;
      this.items = await this.getExtras(this.groupId);
      this.extra = undefined;
    },
    setItem(extra) {
      this.extra = extra;
    },
  },
};
</script>
