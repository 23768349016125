<template>
  <div class="relative">
    <i
      class="text-xl cursor-pointer fa-regular fa-info-circle"
      @mouseenter="isToolTip = true" 
      @mouseleave="isToolTip = false"
    >
    </i>
    <div
      v-if="isToolTip"
      class="text-white p-3 rounded tooltip bg-black absolute right-0 mt-1"
      style="width: 200px; z-index: 999"
    >
      <slot></slot>
    </div>
  </div>
</template>


<script>

export default {
  data () {
    return {
      isToolTip: false
    }
  }
}

</script>
