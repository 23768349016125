<template>
  <div class="overflow-x-hidden">
    <portal v-if="editGroupId" to="portal_popup">
      <modal-window
        :modal_open="editGroupId"
        :max-width="800"
        title="Edit Group"
        @close="editGroupId = undefined"
      >
        <edit-product-group
          v-if="editGroupId"
          :group="groupToEdit"
          @update-product-group="updateProductGroup(editGroupId, $event)"
          @close="editGroupId = undefined"
          @delete="deleteGroup($event)"
        ></edit-product-group>
      </modal-window>
    </portal>
    <portal v-if="showAddGroup" to="portal_popup">
      <modal-window
        :modal_open="showAddGroup"
        :max-width="800"
        title="Add Group"
        @close="showAddGroup = false"
      >
        <create-new-product-group
          v-if="showAddGroup"
          @new-product-group="addProductGroup"
          @close="showAddGroup = false"
        ></create-new-product-group>
      </modal-window>
    </portal>
    <portal v-if="editTypeId" to="portal_popup">
      <modal-window
        :modal_open="editTypeId"
        :max-width="800"
        title="Edit Type"
        @close="editTypeId = undefined"
      >
        <edit-product-type
          v-if="editTypeId"
          :type="typeToEdit"
          @update-product-type="updateProductType(editTypeId, $event)"
          @close="editTypeId = undefined"
          @delete="deleteType($event)"
        ></edit-product-type>
      </modal-window>
    </portal>
    <portal v-if="showAddType" to="portal_popup">
      <modal-window
        :modal_open="showAddType"
        :max-width="800"
        title="Add Type"
        @close="showAddType = false"
      >
        <create-new-product-type
          v-if="showAddType"
          @new-product-type="addProductType"
          @close="showAddType = false"
        ></create-new-product-type>
      </modal-window>
    </portal>
    <div class="flex flex-wrap-reverse justify-between items-center m-3 xs:m-10 mb-0">
      <div class="text-2xl font-medium">Groups</div>
      <div class="flex flex-col justify-around ml-auto">
        <button class="btn-action" @click="showAddGroup = true">Add A Group</button>
      </div>
    </div>
    <div class="bg-white border m-3 xs:m-10 p-3 xs:p-10 mt-4">
      <show-all-product-groups
        v-if="groups.length > 0"
        :groups="sortedGroups"
        @delete="deleteGroup($event)"
        @edit="editGroup($event)"
      ></show-all-product-groups>
      <div v-else>
        <h1>No Groups Defined Yet</h1>
      </div>
    </div>
    <hr class="-mx-10" />
    <div class="flex flex-wrap-reverse justify-between items-center m-3 xs:m-10 mb-0">
      <div class="text-2xl font-medium">Types</div>
      <div class="flex flex-col justify-around ml-auto">
        <button class="btn-action" @click="showAddType = true">Add A Type</button>
      </div>
    </div>
    <div class="bg-white border m-3 xs:m-10 p-3 xs:p-10 mt-4">
      <show-all-product-types
        v-if="types.length > 0"
        :types="sortedTypes"
        @delete="deleteType($event)"
        @edit="editType($event)"
      ></show-all-product-types>
      <div v-else>
        <h1>No Types Defined Yet</h1>
      </div>
    </div>
    <hr class="-mx-10" />
    <div class="flex flex-wrap-reverse justify-between items-center m-3 xs:m-10 mb-0">
      <div class="text-2xl font-medium">Products</div>
      <div class="flex flex-col justify-around ml-auto">
        <button class="btn-action" @click="saveProductChanges">Save Product Changes</button>
      </div>
    </div>
    <div class="bg-white border m-3 xs:m-10 p-3 xs:p-10 mt-4">
      <table class="table-lined xs:table-unstacked _with-hover w-full">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Group</th>
            <th>Type</th>
            <th>Lead Time</th>
            <th v-if="!$store.state.auth.accountSettings.hideFitting">Fitting Cost / Product</th>
            <th v-if="!$store.state.auth.accountSettings.hideFitting">Fitting Cost / Child Product</th>
          </tr>
        </thead>
        <draggable v-model="products" class="w-full" :tag="'tbody'" handle=".handle">
          <tr v-for="product in products" :key="product.productId">
            <td>
              <i class="td-content cursor-move fa fa-align-justify handle"></i>
            </td>
            <td>
              <input
                v-model="product.productName"
                class="td-content w-full form-control"
                type="text"
              />
            </td>
            <td>
              <div class="td-title w-24">Group:</div>
              <div class="td-content">
                <select-product-group
                  :options="groups"
                  :selected-option="product.productSelectionGroup.id"
                  @input="setProductGroup(product, $event)"
                ></select-product-group>
              </div>
            </td>
            <td>
              <div class="td-title w-24">Type:</div>
              <div class="td-content">
                <select-product-type
                  :options="types"
                  :selected-option="product.productSelectionType.id"
                  @input="setProductType(product, $event)"
                ></select-product-type>
              </div>
            </td>
            <td>
              <input
                v-model="product.leadTime"
                class="td-content w-full form-control"
                type="number"
                onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              />
            </td>
            <td v-if="!$store.state.auth.accountSettings.hideFitting">
              <input
                v-model="product.fittingCost"
                class="td-content w-full form-control"
                type="number"
                onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              />
            </td>
            <td v-if="!$store.state.auth.accountSettings.hideFitting">
              <input
                v-model="product.fittingCostPerFrame"
                class="td-content w-full form-control"
                type="number"
                onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              />
            </td>
          </tr>
        </draggable>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CreateNewProductGroup from '@/components/business/productSetup/manageProductGroups/CreateNewProductGroup.vue';
import EditProductGroup from '@/components/business/productSetup/manageProductGroups/EditProductGroup.vue';
import ShowAllProductGroups from '@/components/business/productSetup/manageProductGroups/ShowAllProductGroups.vue';
import CreateNewProductType from '@/components/business/productSetup/manageProductGroups/CreateNewProductType.vue';
import EditProductType from '@/components/business/productSetup/manageProductGroups/EditProductType.vue';
import ShowAllProductTypes from '@/components/business/productSetup/manageProductGroups/ShowAllProductTypes.vue';
import SelectProductDropdown from '@/components/business/productSetup/manageProductGroups/SelectProductDropdown.vue';
import draggable from 'vuedraggable';

export default {
  components: {
    'create-new-product-group': CreateNewProductGroup,
    'edit-product-group': EditProductGroup,
    'show-all-product-groups': ShowAllProductGroups,
    'create-new-product-type': CreateNewProductType,
    'edit-product-type': EditProductType,
    'show-all-product-types': ShowAllProductTypes,
    'select-product-group': SelectProductDropdown,
    'select-product-type': SelectProductDropdown,
    draggable,
  },
  data() {
    return {
      groups: [],
      showAddGroup: false,
      editGroupId: undefined,
      types: [],
      showAddType: false,
      editTypeId: undefined,
      products: [],
      savingProductChanges: false,
    };
  },
  computed: {
    groupToEdit() {
      return this.groups.find((group) => group.id === this.editGroupId);
    },
    typeToEdit() {
      return this.types.find((group) => group.id === this.editTypeId);
    },
    sortedGroups() {
      const data = this.groups;
      return data.sort((a, b) => a.listOrder - b.listOrder);
    },
    sortedTypes() {
      const data = this.types;
      return data.sort((a, b) => a.listOrder - b.listOrder);
    },
  },
  async mounted() {
    await this.setGroups();
    await this.setTypes();
    await this.setProducts();
  },
  methods: {
    ...mapActions({
      loadProducts: 'user/customerGetAllProductsWithOverrides',
      loadGroups: 'common/getProductSelectionGroups',
      saveProductGroup: 'touch/saveProductGroup',
      touchUpdateProductGroup: 'touch/updateProductGroup',
      touchUpdateProductType: 'touch/updateProductType',
      deleteProductGroup: 'touch/deleteProductGroup',
      loadTypes: 'common/getProductSelectionTypes',
      saveProductType: 'touch/saveProductType',
      deleteProductType: 'touch/deleteProductType',
      updateProduct: 'touch/setupUpdateProducts',
    }),
    async saveProductChanges() {
      if (this.savingProductChanges) {
        return;
      }
      this.savingProductChanges = true;
      await window.touch.setupOrderBusinessProducts(
        this.products.map((x, index) => ({
          ProductId: x.productId,
          Name: x.productName,
          RetailName: x.productRetailName,
          ListIndex: index,
          DefaultLeadTime: x.leadTime,
          FittingCost: x.fittingCost,
          FittingCostPerFrame: x.fittingCostPerFrame,
        })),
      );
      this.savingProductChanges = false;
    },
    async setGroups() {
      this.groups = Object.values(await this.loadGroups()).sort(
        (a, b) => a.listOrder - b.listOrder,
      );
    },
    async setTypes() {
      this.types = Object.values(await this.loadTypes()).sort((a, b) => a.listOrder - b.listOrder);
    },
    async setProducts() {
      this.products = Object.values(await this.loadProducts()).sort(
        (a, b) => a.listIndex - b.listIndex,
      );
    },
    setProductGroup(product, group) {
      this.updateProduct({
        id: product.productId,
        ProductSelectionGroupId: group,
      });
    },
    setProductType(product, type) {
      this.updateProduct({
        id: product.productId,
        ProductSelectionTypeId: type,
      });
    },
    editGroup(groupId) {
      this.editGroupId = undefined;
      this.$nextTick(() => {
        this.editGroupId = groupId;
      });
    },
    editType(typeId) {
      this.editTypeId = undefined;
      this.$nextTick(() => {
        this.editTypeId = typeId;
      });
    },
    deleteGroup(event) {
      this.deleteProductGroup(event.groupId).then(async () => {
        this.alertBox().fire({
          title: 'Group Deleted',
          icon: 'success',
        });
        await this.setGroups();
        this.editGroupId = undefined;
      });
    },
    updateProductGroup(id, { title, description, ImageBlob, listOrder }) {
      this.touchUpdateProductGroup({
        id,
        title,
        description,
        ImageBlob,
        listOrder,
      })
        .then(async () => {
          this.editGroupId = undefined;
          this.alertBox().fire({
            title: 'Group Updated',
            icon: 'success',
          });

          await this.setGroups();
          this.editGroupId = undefined;
        })
        .catch(() => {
          throw new Error('Unknown Error');
        });
    },
    updateProductType(id, { title, description, ImageBlob, listOrder }) {
      this.touchUpdateProductType({
        id,
        title,
        description,
        ImageBlob,
        listOrder,
      })
        .then(async () => {
          this.editTypeId = undefined;
          this.alertBox().fire({
            title: 'Type Updated',
            icon: 'success',
          });

          await this.setTypes();
          this.editTypeId = undefined;
        })
        .catch(() => {
          throw new Error('Unknown Error');
        });
    },
    addProductGroup({ title, description, ImageBlob }) {
      this.saveProductGroup({ title, description, ImageBlob })
        .then(async () => {
          this.showAddGroup = false;
          this.alertBox().fire({
            title: 'New Group Added',
            icon: 'success',
          });

          await this.setGroups();
        })
        .catch(() => {
          throw new Error('Unknown Error');
        });
    },
    deleteType(event) {
      this.deleteProductType(event.typeId).then(async () => {
        this.alertBox().fire({
          title: 'Type Deleted',
          icon: 'success',
        });
        await this.setTypes();
        this.editTypeId = undefined;
      });
    },
    addProductType({ title, description, ImageBlob }) {
      this.saveProductType({ title, description, ImageBlob })
        .then(async () => {
          this.showAddType = false;
          this.alertBox().fire({
            title: 'New Type Added',
            icon: 'success',
          });
          await this.setTypes();
        })
        .catch(() => {
          throw new Error('Unknown Error');
        });
    },
  },
};
</script>
