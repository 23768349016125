import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import * as Swal from 'sweetalert2';
import wysiwyg from 'vue-wysiwyg';

import PortalVue from 'portal-vue';
import Vue from 'vue';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueScrollTo from 'vue-scrollto';
import ApiImg from '@/components/shared/ApiImg.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Touch from './touch-api/touch';
import TouchLayout from './components/shared/TouchLayout.vue';
import PortalSidebarMenu from './components/shared/nav/PortalSidebarMenu.vue';
import TouchBusinessSidebarMenu from './components/shared/nav/TouchBusinessSidebarMenu.vue';
import ErrorBox from './components/shared/Error.vue';
import Loading from './components/shared/Loading.vue';
import ShowSvg from './components/shared/ShowSvg.vue';
import ModalWindow from './components/shared/ModalWindow.vue';
import AnimationStaggeredFade from './components/shared/animation/StaggeredFade.vue';
import AnimationStaggeredSlide from './components/shared/animation/StaggeredSlide.vue';
import InfoPopup from './components/shared/InfoPopup.vue';
import i18n from './i18n';

Vue.component('TouchLayout', TouchLayout);
Vue.component('PortalSidebarMenu', PortalSidebarMenu);
Vue.component('BusinessSidebarMenu', TouchBusinessSidebarMenu);
Vue.component('Error', ErrorBox);
Vue.component('Loading', Loading);
Vue.component('ApiImg', ApiImg);
Vue.component('ShowSvg', ShowSvg);
Vue.component('AnimationStaggeredFade', AnimationStaggeredFade);
Vue.component('AnimationStaggeredSlide', AnimationStaggeredSlide);
Vue.component('ModalWindow', ModalWindow);
Vue.component('InfoPopup', InfoPopup);

window.logoutPush = false;

Vue.config.productionTip = false;

require('./libraries/diagram-interaction');

window.alertBox = Swal.mixin({
  customClass: {
    confirmButton: 'btn-action m-1',
    cancelButton: 'btn m-1',
    denyButton: 'btn m-1',
  },
  reverseButtons: true,
  buttonsStyling: false,
});

window.enum = require('./enum').default;

window.helpers = require('./helpers').default;

window.imageTypes = {
  [window.enum.imageType.EXTERNAL]: 'External',
  [window.enum.imageType.INTERNAL]: 'Internal',
  [window.enum.imageType.PLAN]: 'Bow/Bay Plan',
  [window.enum.imageType.GLASS_SCHEMATIC]: 'Glass Schematic',
  [window.enum.imageType.FOLDING_SASH_VIEW]: 'Folding Sash Plan',
};

window.salesSectorTypes = {
  [window.enum.salesSectorType.NONE]: '-',
  [window.enum.salesSectorType.COMMERCIAL]: 'Commercial',
  [window.enum.salesSectorType.NEWBUILD]: 'New Build',
  [window.enum.salesSectorType.RETAIL]: 'Retail',
  [window.enum.salesSectorType.TRADE]: 'Trade',
};

window.priceRates = {
  [window.enum.priceRateType.EACH]: ' unit(s)',
  [window.enum.priceRateType.METER_SQUARED]: 'm<sup>2</sup>',
  [window.enum.priceRateType.PER_METER]: 'm',
  [window.enum.priceRateType.PERCENT]: '%',
  [window.enum.priceRateType.PER_KG]: 'kg',
};

// name is optional
Vue.use(VueLodash, { name: 'custom', lodash });

Vue.use(PortalVue);

Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    code: true,
    headings: true,
    orderedList: true,
    unorderedList: true,
    separator: true,
  },
});

Vue.use(VueScrollTo, {
  container: '#scroll-to-wrapping-element',
  offset: 0,
});

Vue.mixin({
  computed: {
    enums() {
      return window.enum;
    },
    style() {
      return {
        fabricator_logo: this.$store.getters['style/fabricator_logo'],
        customer_logo: this.$store.getters['style/customer_logo'],
        fabricator_name: this.$store.getters['style/fabricator_name'],
        customer_name: this.$store.getters['style/customer_name'],
        back_to_site_link: this.$store.getters['style/back_to_site_link'],
        fabricator: this.$store.getters['style/fabricator'],
      };
    },
    branded_mode_enabled() {
      if (this.touch_portal_installation) {
        return this.$store.getters['style/customer_has_branded_mode_enabled'];
      }
      return false;
    },
    branded_mode_settings() {
      return this.$store.getters['style/branded_mode_settings'];
    },
    authenticated_user() {
      return store.state.auth.loggedIn === true;
    },
    is_admin_user() {
      return store.state.auth.admin === true;
    },
    guest_mode_user() {
      return store.state.auth.loggedIn === false;
    },
    touch_business_user() {
      return this.touch_business_installation && !this.guest_mode_user;
    },
    touch_portal_user() {
      return this.touch_portal_installation && !this.guest_mode_user;
    },
    branded_mode_user() {
      return (
        this.touch_portal_installation &&
        !this.guest_mode_user &&
        store.state.auth.usingBrandedModeToken
      );
    },
    touch_portal_installation() {
      return window.VUE_APP_INSTALLATION_TYPE === 'portal';
    },
    touch_business_installation() {
      return window.VUE_APP_INSTALLATION_TYPE === 'business';
    },
    demo_mode() {
      return process.env.VUE_APP_FORCE_DEMO === 'yes' || this.$store.state.auth.demoMode;
    },
    is_iOS() {
      return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
          navigator.platform,
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    },
  },
  methods: {
    setSentryUser() {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: store.state.auth.id, email: store.state.auth.email });
      });
    },
    positionAvatar(event) {
      const img = document.getElementById(event.target.id);
      if (img) {
        if (img.width >= img.height) {
          img.classList.add('h-full');
          img.classList.remove('w-full');
        } else {
          img.classList.add('w-full');
          img.classList.remove('h-full');
        }
      }
    },
    formatConsumer(consumer) {
      try {
        return [
          [consumer.firstName, consumer.lastName].filter(Boolean).join(' '),
          consumer.addressLine1,
          consumer.addressLine2,
          consumer.addressLine3,
          consumer.addressTown,
          consumer.addressCounty,
          consumer.addressIso,
        ]
          .filter(Boolean)
          .join(', ');
      } catch (e) {
        return 'Consumer not found';
      }
    },
    routerPush(url, onComplete = () => {}, onAbort = () => {}) {
      return this.$router.push(url, onComplete, onAbort);
    },
    routerReplace(url, onComplete = () => {}, onAbort = () => {}) {
      return this.$router.replace(url, onComplete, onAbort);
    },
    alertBox() {
      return window.alertBox;
    },
  },
});

window.VUE_APP_INSTALLATION_TYPE = process.env.VUE_APP_INSTALLATION_TYPE;
window.VUE_APP_OVERRIDE = process.env.VUE_APP_OVERRIDE;
window.touch = new Touch(
  process.env.VUE_APP_ENDPOINT_URL,
  process.env.VUE_APP_IMAGE_BASE_URL,
  router,
  store,
);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

if (
  (process.env.VUE_APP_SENTRY_ON === 'true'
  || process.env.NODE_ENV === 'production'
  || process.env.NODE_ENV === 'staging'
  || process.env.NODE_ENV === 'test')
) {
  Sentry.init({
    dsn: 'https://4372a73ed9634417b01faf442f11a8f7@sentry.io/2524389',
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
    ],
    initialScope: {
      user: { id: store.state.auth.id, email: store.state.auth.email },
    },
    environment: process.env.VUE_APP_ENV,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.'],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.event_id && !window.logoutPush) {
        if (event.exception && event.exception.values[0].type === 'SyntaxError') {
          Sentry.captureEvent({
            message: 'Reloaded Page - SyntaxError',
          });

          window.alertBox.fire('System updated, we need to refresh your page.').then(() => {
            window.location.reload();
          });

          return null;
        }

        const excludedExceptions = [
          'ResizeObserver loop limit exceeded',
          'ResizeObserver loop completed with undelivered notifications',
        ].map((x) => x.toLowerCase());

        if (
          !excludedExceptions.includes(event?.exception?.values[0]?.value?.toLowerCase()) &&
          ![400, 401, 403].includes(event?.extra?.status)
        ) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            user: {
              email: store.state.auth.email,
              name: `${store.state.auth.firstName} ${store.state.auth.lastName}`,
            },
          });
        }
      }

      return event;
    },
  });
}

Sentry.configureScope((scope) => {
  scope.setExtra('store.basket', store.state.basket);
  scope.setExtra(
    'basketItems',
    store.state.basket.basket.map((basketItem) => {
      const item = Object.assign({}, basketItem);
      delete item.image;
      return item;
    }),
  );
  scope.setUser({
    email: store.state.auth.email,
    processingLevel: store.state.auth.processingLevel,
  });
  scope.setExtra('store.auth', store.state.auth);
  scope.setExtra('store.touch', store.state.touch);
  scope.setExtra('store.search', store.state.search);
  scope.setExtra('store.stockParts', store.state.stockParts);
});

store.watch(
  (state, getters) => getters['style/page_title'],
  (newValue) => {
    window.document.title = newValue;
  },
  { immediate: true },
);

store.subscribe((mutation) => {
  switch (true) {
  case mutation.type.startsWith('basket'):
    Sentry.configureScope((scope) => {
      scope.setExtra('store.basket', store.state.basket);
      scope.setExtra(
        'basketItems',
        store.state.basket.basket.map((basketItem) => {
          const item = Object.assign({}, basketItem);

          delete item.image;

          return item;
        }),
      );
    });
    break;
  case mutation.type.startsWith('auth'):
    Sentry.configureScope((scope) => {
      scope.setUser({
        email: store.state.auth.email,
        processingLevel: store.state.auth.processingLevel,
      });

      scope.setExtra('store.auth', store.state.auth);
    });
    break;
  case mutation.type.startsWith('touch'):
    Sentry.configureScope((scope) => {
      scope.setExtra('store.touch', store.state.touch);
    });
    break;
  case mutation.type.startsWith('style'):
    Sentry.configureScope((scope) => {
      scope.setExtra('store.style', store.state.style);
    });
    break;
  case mutation.type.startsWith('branded'):
    Sentry.configureScope((scope) => {
      scope.setExtra('store.branded', store.state.branded);
    });
    break;
  case mutation.type.startsWith('designer'):
  case mutation.type.startsWith('feature'):
    break;
  case mutation.type.startsWith('infoPopup'):
    Sentry.configureScope((scope) => {
      scope.setExtra('store.infoPopup', store.state.infoPopup);
    });
    break;
  case mutation.type.startsWith('visualiser'):
    Sentry.configureScope((scope) => {
      scope.setExtra('store.visualiser', store.state.visualiser);
    });
    break;
  case mutation.type.startsWith('stockParts'):
    Sentry.configureScope((scope) => {
      scope.setExtra('store.stockParts', store.state.stockParts);
    });
    break;
  case mutation.type.startsWith('debug'):
    Sentry.configureScope((scope) => {
      scope.setExtra('store.debug', store.state.debug);
    });
    break;
  case mutation.type.startsWith('sandbox'):
    Sentry.configureScope((scope) => {
      scope.setExtra('store.sandbox', store.state.sandbox);
    });
    break;
  case mutation.type.startsWith('user'):
    Sentry.configureScope((scope) => {
      scope.setExtra('store.user', store.state.user);
    });
    break;
  case mutation.type.startsWith('knowledgeBase'):
    Sentry.configureScope((scope) => {
      scope.setExtra('store.knowledgeBase', store.state.knowledgeBase);
    });
    break;
  case mutation.type === 'reset':
    break;
  default:
    throw new Error(`Please add info for  ${mutation.type} to main.js store subscription`);
  }
});

function setViewportProperty(doc) {
  let prevClientHeight;
  const customVar = '--vh';
  function handleResize() {
    const { clientHeight } = doc;
    if (clientHeight === prevClientHeight) return;
    requestAnimationFrame(() => {
      doc.style.setProperty(customVar, `${clientHeight * 0.01}px`);
      prevClientHeight = clientHeight;
    });
  }
  handleResize();
  return handleResize;
}
window.addEventListener('resize', setViewportProperty(document.documentElement));
