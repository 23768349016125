<template>
  <div>
    <create-staff v-if="add_staff_popup_open" @close="$emit('close-add-staff-popup')" @saved="loadStaff"></create-staff>
    <div class="bg-white border m-3 xs:m-10 p-3">
      <table class="table-lined xs:table-unstacked _with-hover w-full">
        <thead>
          <tr>
            <th colspan="2"> Name </th>
            <th> Email </th>
            <th> Mobile </th>
            <th> Admin </th>
            <th class="cursor-pointer" @click="license_filter_dropdown_open = !license_filter_dropdown_open, status_filter_dropdown_open = false">
              License
              <i class="fal fa-filter mx-1"></i>
              <span v-if="$route.query.licenseFilter == enums.licenseStatusType.ENABLED" class="font-blue-500">
                Enabled
              </span>
              <span v-else-if="$route.query.licenseFilter == enums.licenseStatusType.DISABLED">
                Disabled
              </span>
              <div v-show="license_filter_dropdown_open" class="relative">
                <div class="absolute bg-white z-10 border border-gray-300 text-xs rounded">
                  <div class="p-1 border-b border-gray-300 font-medium flex justify-between m-2">
                    <div class="flex flex-col justify-around pr-6 whitespace-no-wrap">License Status</div>
                    <div class="flex flex-col justify-around">
                      <i class="fal fa-times" @click.stop="license_filter_dropdown_open = false"></i>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div v-for="status in [enums.licenseStatusType.ENABLED, enums.licenseStatusType.DISABLED]" :key="status.id" class="py-2 px-4 hover:bg-gray-200" @click="setLicenseFilter(status)">
                      <span v-if="status == enums.licenseStatusType.ENABLED">
                        <span class="inline-block rounded-full w-2 h-2 mr-2 bg-green-500"></span>
                        Enabled
                      </span>
                      <span v-else-if="status == enums.licenseStatusType.DISABLED">
                        <span class="inline-block rounded-full w-2 h-2 mr-2 bg-red-500"></span>
                        Disabled
                      </span>
                      <span v-show="$route.query.licenseFilter == status" class="text-green-600 pl-2">
                        <i class="fal fa-check"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th class="cursor-pointer" @click="status_filter_dropdown_open = !status_filter_dropdown_open, license_filter_dropdown_open = false">
              Status
              <i class="fal fa-filter mx-1"></i>
              <span v-if="$route.query.statusFilter == enums.customerStatus.LIVE" class="font-blue-500">
                Live
              </span>
              <span v-else-if="$route.query.statusFilter == enums.customerStatus.ARCHIVED">
                Archived
              </span>
              <div v-show="status_filter_dropdown_open" class="relative">
                <div class="absolute bg-white z-10 border border-gray-300 text-xs rounded">
                  <div class="p-1 border-b border-gray-300 font-medium flex justify-between m-2">
                    <div class="flex flex-col justify-around pr-6 whitespace-no-wrap">Filter Status</div>
                    <div class="flex flex-col justify-around">
                      <i class="fal fa-times" @click.stop="status_filter_dropdown_open = false"></i>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div v-for="status in [enums.customerStatus.LIVE, enums.customerStatus.ARCHIVED]" :key="status.id" class="py-2 px-4 hover:bg-gray-200" @click="setStatusFilter(status)">
                      <span v-if="status == enums.customerStatus.LIVE">
                        <span class="inline-block rounded-full w-2 h-2 mr-2 bg-green-500"></span>
                        Live
                      </span>
                      <span v-else-if="status == enums.customerStatus.ARCHIVED">
                        <span class="inline-block rounded-full w-2 h-2 mr-2 bg-red-500"></span>
                        Archived
                      </span>
                      <span v-show="$route.query.statusFilter == status" class="text-green-600 pl-2">
                        <i class="fal fa-check"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th> </th>
          </tr>
        </thead>
        <tbody v-if="realStaff.length > 0">
          <tr
            v-for="(user, index) in realStaff"
            :key="index"
            :class="{ '_hovered': hovered === index }"
            @click.stop.prevent="editing_user = user.id"
            @mouseover="hovered = index"
            @mouseleave="hovered = null"
          >
            <td style="width: 60px;">
              <div
                class="rounded-full border flex flex-col justify-around overflow-hidden relative"
                style="width: 35px; height: 35px;"
              >
                <img
                  :id="'userImg_' + user.id"
                  :src="staffImageUrl(user.image)"
                  class="m-auto"
                  @error="imgError"
                  @load="positionAvatar"
                />
              </div>
            </td>
            <td>
              <div class="td-title w-24"> Name: </div>
              <div class="td-content">{{ user.firstName }} {{ user.lastName }}</div>
            </td>
            <td>
              <div class="td-title w-24"> Email: </div>
              <div class="td-content break-all">{{ user.email }}</div>
            </td>
            <td>
              <div class="td-title w-24"> Mobile: </div>
              <div class="td-content">{{ user.mobile }}</div>
            </td>
            <td>
              <div class="td-title w-24"> Admin: </div>
              <div class="td-content">
                <template v-if="user.isAdmin">
                  Yes
                </template>
                <template v-else>
                  No
                </template>
              </div>
            </td>
            <td>
              <div class="td-title w-24"> License: </div>
              <div class="td-content text-center">
                <div v-if="user.licenseStatusType === enums.licenseStatusType.ENABLED" class="bg-green-500 rounded text-white px-2 py-1">
                  Enabled
                </div>
                <div v-else class="bg-red-500 rounded text-white px-2 py-1">
                  Disabled
                </div>
              </div>
            </td>
            <td>
              <div class="td-title w-24"> Status: </div>
              <div class="td-content text-center">
                <div v-if="user.archived" class="bg-red-500 rounded text-white px-2 py-1">
                  Archived
                </div>
                <div v-else class="bg-green-500 rounded text-white px-2 py-1">
                  Live
                </div>
              </div>
            </td>
            <td class="td-action-buttons">
              <div
                v-if="touch_business_user || loggedInUser.isAdmin || loggedInUser.id === user.id"
                class="table-edit-btn"
              >
                <i class="fa fa-pencil"></i>
              </div>
              <edit-staff
                v-if="editing_user === user.id"
                :user_id="user.id"
                @close="editing_user = null"
                @updated="loadStaff"
              ></edit-staff>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="realStaff.length === 0" key="none-found" class="p-3 xs:p-10">
        <div class="flex justify-between">
          <div>
            No Staff to show
          </div>
          <div>
            <a class="btn-action" @click.stop="setDefaultFilter()">
              Clear Search
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EditStaff from '@/views/Business/ManageTouchAccount/Staff/Edit.vue';
import CreateStaff from '@/views/Business/ManageTouchAccount/Staff/Create.vue';

export default {
  components: {
    EditStaff,
    CreateStaff,
  },
  props: [
    'add_staff_popup_open',
  ],
  data() {
    return {
      staff: [],
      hovered: null,
      editing_user: null,
      license_filter_dropdown_open: false,
      status_filter_dropdown_open: false,
    };
  },
  computed: {
    realStaff() {
      const isLive = this.enums.customerStatus.LIVE === Number(this.$route.query.statusFilter);
      return this.staff.filter(staff => !staff.isGuest && ((staff.archived && !isLive) || (!staff.archived && isLive)) && staff.licenseStatusType === Number(this.$route.query.licenseFilter));
    },
  },
  mounted() {
    if (!this.$route.query.statusFilter || !this.$route.query.licenseFilter) {
      this.setDefaultFilter();
    }
    this.loadStaff();
  },
  methods: {
    ...mapActions({
      customerListContacts: 'user/customerListContacts',
    }),
    async loadStaff() {
      this.staff = await this.customerListContacts();
    },
    staffImageUrl(id) {
      return `${window.touch.imageBaseURL}${id}`;
    },
    imgError(event) {
      const element = document.getElementById(event.target.id);
      element.src = '/images/blank-user.png';
    },
    setStatusFilter(statusFilter) {
      this.$router.push({ query: { ...this.$route.query, statusFilter } }).catch(() => {});
    },
    setLicenseFilter(licenseFilter) {
      this.$router.push({ query: { ...this.$route.query, licenseFilter } }).catch(() => {});
    },
    setDefaultFilter() {
      this.license_filter_dropdown_open = false
      this.status_filter_dropdown_open = false
      this.$router.replace({
        query: {
          ...this.$route.query, statusFilter: this.enums.customerStatus.LIVE, licenseFilter: this.enums.licenseStatusType.ENABLED,
        },
      }).catch(() => {});
    },
  },
};
</script>