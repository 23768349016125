<template>
  <div></div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      initialised: false,
      ticketingEnabled: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      fabricatorName: 'style/fabricator_name',
      freshdeskEnabled: 'user/getFreshdeskWidgetEnabled',
      customer: 'user/getCustomer',
    }),
  },
  watch: {
    $route: {
      handler(to) {
        if (this.initialised) {
          this.autoFillUrl();
        }
        if (this.freshdeskEnabled) {
          window.FreshworksWidget('show');
        } else {
          window.FreshworksWidget('hide');
        }
        this.toggleWidgetLocation(to.name);
      },
    },
    async freshdeskEnabled(freshdeskEnabled) {
      if (freshdeskEnabled) {
        if (this.ticketingEnabled) {
          await this.autoFillTicket();
        }
        FreshworksWidget('show');
      } else {
        FreshworksWidget('hide');
      }
    },
  },
  created () {
    const freshDeskComponent = document.getElementById('freshworks-container')
    if (!freshDeskComponent) {
      const widgetId = this.getWidgetId()
      const src = `https://euc-widget.freshworks.com/widgets/${widgetId}.js`;
        window.fwSettings = { widget_id: `${widgetId}` };
        !(function () {
          if ('function' != typeof window.FreshworksWidget) {
            var n = function () {
              n.q.push(arguments);
            };
            (n.q = []), (window.FreshworksWidget = n);
          }
        })();
        
      const widgetSrc = document.createElement('script');
      widgetSrc.src = src;
      widgetSrc.async = true;
      widgetSrc.defer = true;
      widgetSrc.type = 'text/javascript';
      document.head.appendChild(widgetSrc);
    }
  },
  async mounted() {
    if (this.freshdeskEnabled) {
      window.FreshworksWidget('show');
    } else {
      window.FreshworksWidget('hide');
    }
    this.initialised = true;
    window.openFreshdeskWidget = (articleId) => {
      this.openWidget(articleId);
    };
    this.toggleWidgetLocation(this.$route.name);
  },
  methods: {
    async autoFillTicket() {
      FreshworksWidget('hide');

      const orgDetails = await window.touch.commonOrganisationDetails();
      const contacts = await window.touch.listTicketContacts();
      const contact = contacts.find(
        (x) =>
          x.id ===
          (this.customer?.customer?.ticketContact !== 0
            ? this.customer?.customer?.ticketContact
            : orgDetails.defaultTicketContact),
      );

      if (typeof this.customer === 'undefined' || this.customer === null || typeof contact === 'undefined') {
        FreshworksWidget('hide');
        return;
      }

      FreshworksWidget('hide', 'ticketForm', [
        'email',
        'name',
        'status',
        'custom_fields.cf_company_customer',
        'custom_fields.cf_contact_name_customer',
        'custom_fields.cf_contact_email_customer',
        'custom_fields.cf_company',
        'custom_fields.cf_product',
        'custom_fields.cf_url',
      ]);

      FreshworksWidget('identify', 'ticketForm', {
        email: this.touch_business_installation ? this.user.email : contact.email,
      });

      if (
        !this.touch_business_installation &&
        (this.user.companyName === undefined ||
          this.user.firstName === undefined ||
          this.user.email === undefined)
      ) {
        throw new Error('Freshdesk Error');
      }

      FreshworksWidget('prefill', 'ticketForm', {
        // we want to set the status to open if it is an admin request so the ticket comes to us.
        // otherwise by setting it to pending it will be addressed by the user with the prefilled email.
        status: this.touch_business_installation ? 2 : 3,
        custom_fields: {
          cf_company_customer: this.user.companyName,
          cf_contact_name_customer: `${this.user.firstName} ${this.user.lastName}`,
          cf_contact_email_customer: this.user.email,
          cf_company: this.style?.fabricator_name,
          cf_product: this.touch_business_installation ? 'Touch Admin' : 'Touch Portal',
          cf_url: window.location.href,
        },
      });
      FreshworksWidget('show');
    },
    getWidgetId () {
      if (!this.touch_business_installation && this.fabricatorName !== 'Compdoor Ltd') {
        this.ticketingEnabled = false;
      } else {
        this.ticketingEnabled = true; 
      }

      let widgetId
      if (process.env.VUE_APP_ENV === 'prod') {
        if (this.touch_business_installation) {
          widgetId = this.ticketingEnabled ? '101000007694' : '101000007732';
        } else {
          widgetId = this.ticketingEnabled ? '101000007693' : '101000007733';
        }
      } else if (this.touch_business_installation) {
        widgetId = this.ticketingEnabled ? '101000007637' : '101000007735';
      } else {
        widgetId = this.ticketingEnabled ? '101000007638' : '101000007734';
      }
      return widgetId
    },
    autoFillUrl() {
      FreshworksWidget('prefill', 'ticketForm', {
        custom_fields: {
          cf_url: window.location.href,
        },
      });
    },
    async openWidget(articleId) {
      if (this.initialised) {
        if (articleId) {
          FreshworksWidget('open', 'article', {
            id: articleId,
          });
        } else {
          FreshworksWidget('open');
        }
      }
    },
    toggleWidgetLocation(name) {
      if (!window.touch.urlIs('endurance') && ['Designer'].includes(name)) {
        document.body.classList.add('widget-right');
      } else {
        document.body.classList.remove('widget-right');
      }
    },
  },
};
</script>

<style>
@media (min-width: 1024px) {
  #launcher-frame {
    left: calc(125px - 44px) !important;
    width: 88px !important;
    bottom: 50px !important;
  }
  #freshworks-frame-wrapper {
    bottom: 110px !important;
  }
}

@media (max-width: 1024px) {
  #launcher-frame {
    left: 25px !important;
    width: 88px !important;
    bottom: 25px !important;
  }
  #freshworks-frame-wrapper {
    bottom: 110px !important;
  }
}

.widget-right #launcher-frame {
  right: 22px !important;
  left: unset !important;
  width: 88px !important;
  bottom: 40px !important;
}
.widget-right #freshworks-frame-wrapper {
  left: unset !important;
  right: 30px !important;
  bottom: 110px !important;
  transform-origin: bottom right !important;
}
</style>
