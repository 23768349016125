<template>
  <div class="w-full">
    <div class="bg-white overflow-x-auto pl-3 md:pl-10">
      <div class="flex bg-white gap-10 border-t-4 border-white">
        <router-link to="/product-setup/product-selections">
          <div
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black"
            :class="{
              'border-black': $route.name === 'product-selections',
              'border-white': $route.name !== 'product-selections',
            }"
            v-text="'Product Selections'"
          ></div>
        </router-link>
        <router-link to="/product-setup/pricing">
          <div
            v-if="!isLeadGen"
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black"
            :class="{
              'border-black': $route.name === 'pricing',
              'border-white': $route.name !== 'pricing',
            }"
            v-text="'Pricing'"
          ></div>
        </router-link>
        <router-link to="/product-setup/parts">
          <div
            v-if="!isLeadGen"
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black"
            :class="{
              'border-black': $route.name === 'parts',
              'border-white': $route.name !== 'parts',
            }"
            v-text="'Parts'"
          ></div>
        </router-link>
        <router-link to="/product-setup/touch-connect-business">
          <div
            v-if="!isLeadGen && $store.state.auth.superuser"
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black"
            :class="{
              'border-black': $route.name === 'touch-connect-business',
              'border-white': $route.name !== 'touch-connect-business',
            }"
            v-text="'Touch Connect'"
          ></div>
        </router-link>
        <router-link to="/product-setup/discounts">
          <div
            v-if="!isLeadGen"
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black"
            :class="{
              'border-black': $route.name === 'discounts',
              'border-white': $route.name !== 'discounts',
            }"
            v-text="'Discounts'"
          ></div>
        </router-link>
        <router-link v-if="!isLeadGen && !$store.state.auth.accountSettings.hideFitting" to="/product-setup/extras">
          <div
            v-if="true"
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black"
            :class="{'border-black' : $route.name === 'extras', 'border-white' : $route.name !== 'extras'}"
            v-text="'Extras'"
          >
          </div>
        </router-link>
        <router-link
          v-if="!isLeadGen"
          class="flex-shrink-0"
          to="/product-setup/option-lead-times"
        >
          <div
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 mx-3 hover:border-black text-sm"
            :class="{
              'border-black': $route.name === 'option-lead-times',
              'border-white': $route.name !== 'option-lead-times',
            }"
            v-text="'Option Lead Times'"
          ></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    isLeadGen() {
      return (
        this.$store.state.style?.fabricator?.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
  },
};

</script>
