import Touch from './touch';

Touch.prototype.reportGenerate = async function reportGenerate(contractId, jobId, reportType) {
  return this.download(`report/Generate/${contractId}/${jobId}/${reportType}`);
};
Touch.prototype.downloadRequoteReport = function downloadRequoteReport(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 3);
};
Touch.prototype.downloadOrderReport = function downloadOrderReport(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 2);
};
Touch.prototype.downloadQuotePDF = function downloadQuotePDF(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 10);
};
Touch.prototype.downloadQuoteReport = function downloadQuoteReport(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 1);
};
Touch.prototype.downloadQuoteRequestReport = function downloadQuoteRequestReport(
  contractId,
  jobId,
) {
  return this.reportGenerate(contractId, jobId, 8);
};
Touch.prototype.downloadManualSurveyReport = function downloadManualSurveyReport(
  contractId,
  jobId,
) {
  return this.reportGenerate(contractId, jobId, 6);
};
Touch.prototype.downloadGlassOrderReport = function downloadGlassOrderReport(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 11);
};
Touch.prototype.downloadJobXML = async function downloadJobXML(contractId, jobId) {
  const response = await this.authenticatedPost('export/GrabJobSuperUser', {
    ContractId: contractId,
    JobKey: jobId,
  });

  if (response.status === 200) {
    const xml = response.data;
    const xmlDL = new Blob([xml], {
      type: 'text/xml',
    });
    const url = window.URL.createObjectURL(xmlDL);
    const link = document.createElement('a');
    link.href = url;
    const filename = `job-${contractId}.xml`;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }

  return response;
};

// eslint-disable-next-line class-methods-use-this
Touch.prototype.downloadSampleReport = function downloadSampleReport(url) {
  const link = document.createElement('a');
  link.setAttribute('download', url);
  link.href = `https://store.bm-touch.co.uk/images/demo/${url}`;
  document.body.appendChild(link);
  link.click();
};

Touch.prototype.samplePriceRequestReport = function samplePriceRequestReport() {
  return this.downloadSampleReport('pricerequestpdf.pdf');
};

Touch.prototype.sampleSurveyReport = function sampleSurveyReport() {
  return this.downloadSampleReport('surveysheetpdf.pdf');
};

Touch.prototype.sampleRequoteReport = function sampleRequoteReport() {
  return this.downloadSampleReport('customerrequotepdf.pdf');
};
