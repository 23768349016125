<template>
  <div class="flex items-center">
    <div v-for="(route, index) in routes" :key="route.id" class="flex items-center">
      <router-link v-if="route[1]" :to="route[1]">{{ route[0] }}</router-link>
      <span v-else>{{ route[0] }}</span>
      <span v-if="index < routes.length - 1" class="px-2">></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    routes: Array,
  },
};
</script>
