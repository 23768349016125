<template>
  <div v-if="!showDetail" class="mb-2">
    <div
      v-for="(extra, index) in extras"
      :key="extra.itemKey"
      class="line-item-header _extras mb-4 md:mb-0"
    >
      <div class="_header-attributes-group">
        <div class="_header-attribute">
          <div v-show="index === 0" class="_label hidden md:block">Extras</div>
          <div class="_label md:hidden">Extra</div>
          <div class="_description">
            {{ extra.description }}
            <span v-if="extra.size">({{ extra.size.split(" ")[0] }})</span>
          </div>
        </div>
      </div>
      <div class="_header-attributes-group">
        <div class="_header-attribute"></div>
        <div class="_header-attribute"></div>
      </div>
      <div class="_header-attributes-group">
        <div class="_header-attribute _item-qty">
          <div v-show="index === 0" class="_label hidden md:block">Qty</div>
          <div class="_label md:hidden">Qty</div>
          <div class="_description">
            {{ extra.quantity }}
          </div>
        </div>
        <div class="_header-attribute _item-price">
          <div v-show="index === 0" class="_label hidden md:block">Item Price</div>
          <div class="_label md:hidden">Item Price</div>
          <div class="_description">
            <div
              class="_description"
              v-html="formatCurrency(job.currency(), extra.price / extra.quantity)"
            ></div>
          </div>
        </div>
        <div class="_header-attribute _item-price">
          <div v-show="index === 0" class="_label hidden md:block">Total Price</div>
          <div class="_label md:hidden">Item Price</div>
          <div class="_description">
            <div class="_description" v-html="formatCurrency(job.currency(), extra.price)"></div>
          </div>
        </div>
        <div class="_show-detail-control h-0">
          <i class="fa fa-caret-right opacity-0"></i>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="item-summary-group">
    <header class="group-title">
      <h2>Extras</h2>
    </header>
    <div v-for="extra in extras" :key="extra.itemKey" class="group-attributes">
      <div class="attribute-label">
        <strong>{{ extra.quantity }} x {{ extra.description }}
          <span v-html="extra.optionDescription"></span></strong>
      </div>
      <div class="attribute-value">
        <span v-html="formatCurrency(job.currency(), extra.price / item.quantity)"></span>
        <span v-if="extra.size"> - {{ extra.size }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import currencyHelper from '@/mixins/currencyHelper';

export default {
  mixins: [currencyHelper],
  props: ['extras', 'item', 'job', 'showDetail'],
};
</script>

<style scoped></style>
