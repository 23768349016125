<script>
import BasicLine from '@/components/shared/priceLines/List/Lines/BasicLine.vue';

export default {
  mixins: [BasicLine],
  methods: {
    setupLayout() {
      this.subtitle = 'Each';
      this.icon = this.currencySymbol(
        this.touch_business_installation
          ? this.defaultCurrency
          : this.$store.state.style.customer.currency,
      );
    },
  },
};
</script>
