<template>
  <div class="bg-white border text-center mb-5 m-3 xs:m-5 p-5 flex flex-col w-full">
    <edit-file
      v-if="edit_file_modal_open"
      :categories="categories"
      :document="marketingItem"
      @updated="$emit('updated')"
      @close="edit_file_modal_open = false"
    ></edit-file>
    <div v-if="touch_business_user" class="flex justify-between mb-3">
      <div
        class="bg-white rounded-full flex flex-col justify-around w-8 h-8 cursor-pointer"
        @click="edit_file_modal_open = true"
      >
        <i class="fal fa-pencil"></i>
      </div>
      <div
        class="bg-white rounded-full flex flex-col justify-around w-8 h-8 cursor-pointer"
        @click="deleteItem()"
      >
        <i class="fal fa-times text-red-600"></i>
      </div>
    </div>
    <div class="mt-auto">
      <img
        v-if="!image_error"
        :src="marketingItem.thumbnail"
        class="w-full"
        @error="image_error = true"
      />
      <div v-else style="font-size: 10rem">
        <i class="fal fa-file"></i>
      </div>
      <div class="mt-5">
        {{ marketingItem.name }}
      </div>
      <div class="mt-5">
        <a v-if="!marketingItem.isUrl" class="btn-primary" @click.stop="$emit('download')">
          Download
        </a>
        <a v-else class="btn-primary" @click.stop="viewUrlFile"> View </a>
      </div>
    </div>
  </div>
</template>

<script>
import EditFile from '@/components/shared/marketing/EditFile.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    'edit-file': EditFile,
  },
  props: ['marketingItem', 'categories'],
  data() {
    return {
      image_error: false,
      edit_file_modal_open: false,
    };
  },
  methods: {
    ...mapActions({
      deleteDocument: 'marketing/deleteMarketingDocuments',
    }),
    async deleteItem() {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to delete this item?',
          text: 'This will not be recoverable.',
          showCancelButton: true,
        })
        .then(async (response) => {
          if (response.isConfirmed) {
            await this.deleteDocument([this.marketingItem.id]).then(() => {
              this.$emit('deleted');
            });
          }
        });
    },
    viewUrlFile() {
      this.alertBox()
        .fire({
          title: 'You are about to open an external file link',
          html: `<p>Would you like to proceed to open the following url address?</p><br>${this.marketingItem.url}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Proceed',
          cancelButtonText: 'Cancel',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            window.open(this.marketingItem.url);
          }
        });
    },
  },
};
</script>
