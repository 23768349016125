<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Branch Management', '/branch'],
        ]"
      />
    </template>
    <template #header_page_title> Branch Management </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <a class="btn-action" @click.stop.prevent="create_user_popup_open = true"> Create New </a>
      </div>
    </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for branch..." />
    </portal>
    <portal v-if="create_user_popup_open" to="portal_popup">
      <modal-window
        :modal_open="create_user_popup_open"
        title="Add Branch"
        @close="create_user_popup_open = false"
      >
        <branch-create @close="create_user_popup_open = false"></branch-create>
      </modal-window>
    </portal>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10"
    >
      <div v-if="branches && branches.length > 0" class="bg-white p-3 xs:p-10 border">
        <table class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th>Branch Name</th>
              <th>Tel</th>
              <th>Email</th>
              <th>Town</th>
              <th>County</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <router-link
              v-for="(branch, index) in branches"
              :key="index"
              tag="tr"
              :to="branch.url"
              :class="{ _hovered: hovered === index }"
              @mouseover.native="hovered = index"
              @mouseleave.native="hovered = null"
            >
              <td>
                <div class="td-title w-24">Branch Name:</div>
                <div class="td-content">{{ branch.name }}</div>
              </td>
              <td>
                <div class="td-title w-24">Tel:</div>
                <div class="td-content">{{ branch.phone }}</div>
              </td>
              <td>
                <div class="td-title w-24">Email:</div>
                <div class="td-content break-all">{{ branch.email }}</div>
              </td>
              <td>
                <div class="td-title w-24">Town:</div>
                <div v-if="branch.address" class="td-content">{{ branch.address.town }}</div>
              </td>
              <td>
                <div class="td-title w-24">County:</div>
                <div v-if="branch.address" class="td-content">{{ branch.address.county }}</div>
              </td>
              <td class="td-action-buttons">
                <div class="table-edit-btn">
                  <i class="fa fa-pencil"></i>
                </div>
              </td>
            </router-link>
          </tbody>
        </table>
      </div>
      <div v-else key="none-found" class="bg-white p-3 xs:p-10">
        <div v-if="$route.query.search" class="flex justify-between">
          <div>No Branches match "{{ $route.query.search }}"</div>
        </div>
        <div v-else>No Branches to show</div>
      </div>
      <div v-show="branches && branches.length > 0" class="mt-3">
        <pagination :total_items="total_items" :limit="limit" @load-feed="loadFeed"></pagination>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import { mapActions } from 'vuex';
import BranchCreate from '@/components/business/branch/Create.vue';
import Pagination from '@/components/shared/Pagination.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    pagination: Pagination,
    'branch-create': BranchCreate,
    Breadcrumbs,
    SearchBox,
  },
  data() {
    return {
      branches: null,
      loading: true,
      limit: 20,
      total_items: 0,
      create_user_popup_open: false,
      hovered: null,
    };
  },
  watch: {
    '$route.query': {
      handler() {
        this.loadFeed();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      customerListBranches: 'user/customerListBranches',
    }),
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await this.customerListBranches({
        limit: this.limit,
        offset,
        search: this.$route.query.search,
      });
      this.branches = response.branches;
      this.total_items = Object.keys(response.branches).length;
      this.loading = false;
    },
  },
};
</script>
