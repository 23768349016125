<template>
  <aside
    class="bg-white shadow-xl sm:w-1/3 overflow-y-auto scrolling-touch sm:p-5 xl:p-10 absolute left-0 right-0 bottom-0 sm:relative z-10 sm:z-auto"
  >
    <div v-if="!isCostsHidden" class="hidden sm:block">
      <div class="font-medium">{{ breakdown }} Summary</div>

      <div class="mb-4">Basket showing {{ basketQty }} item<span v-if="basketQty > 1">s</span></div>

      <slot name="extras"></slot>

      <div v-if="delivery" class="flex border-t border-gray-300 p-3">
        <div>Delivery Charge</div>
        <div class="ml-auto" v-html="formatCurrency(basketCurrency, delivery)"></div>
      </div>

      <div v-if="fittingPrice" class="flex border-t border-gray-300 p-3">
        <div>Fitting Charge</div>
        <div class="ml-auto" v-html="formatCurrency(basketCurrency, fittingPrice)"></div>
      </div>

      <div v-if="surveyPrice" class="flex border-t border-gray-300 p-3">
        <div>Survey Charge</div>
        <div class="ml-auto" v-html="formatCurrency(basketCurrency, surveyPrice)"></div>
      </div>

      <div v-if="salesSectorType !== enums.salesSectorType.RETAIL" class="flex border-t border-gray-300 p-3">
        <div>Subtotal</div>
        <div class="ml-auto" v-html="formatCurrency(basketCurrency, subtotal)"></div>
      </div>

      <div v-if="salesSectorType !== enums.salesSectorType.RETAIL" class="flex border-t border-gray-300 p-3">
        <div>VAT</div>
        <div class="ml-auto" v-html="formatCurrency(basketCurrency, vat)"></div>
      </div>

      <div class="flex border-t border-b border-gray-300 p-3 font-medium">
        <div>Total</div>
        <div class="ml-auto" v-html="formatCurrency(basketCurrency, total)"></div>
      </div>
    </div>
    <div v-if="!sandboxIsActive" class="m-3 sm:m-0 sm:mb-6">
      <div class="sm:mt-6 mb-2">Reference</div>
      <input
        v-model="reference"
        type="text"
        class="form-control"
        :class="{ 'border-red-600': referenceMissing }"
      />
      <div v-if="referenceMissing" class="text-red-600 mt-3">Please enter a reference</div>
    </div>
    <div
      v-if="isEditingOrder"
      class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col"
    >
      <div class="xs:m-2 xs:mr-1 sm:m-0 xs:mb-2 xs:w-1/2 sm:w-full xs:w-full">
        <button
          v-if="showSaveProductButton"
          class="btn-action btn-lg w-full"
          @click="saveQuote(false)"
        >
          Save Products
        </button>
        <button
          v-if="showPlaceOrderButton"
          class="btn-action btn-lg w-full mt-4"
          @click="saveQuote(true)"
        >
          Place Order
        </button>
        <button
          v-if="isFabricatorForMondayDemo()"
          class="btn-action btn-lg w-full mt-4"
          @click="$emit('showBasketInvisionAppLinks')"
        >
          Contract Mode
        </button>
      </div>
    </div>
    <div
      v-else-if="!sandboxIsActive"
      class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col"
    >
      <div
        class="xs:m-2 xs:mr-1 sm:m-0 xs:mb-2 xs:w-1/2 sm:w-full"
        :class="{ 'xs:w-full': !canBeOrdered }"
      >
        <button class="btn-action btn-lg w-full" @click="saveQuote(true)">Complete</button>
      </div>
    </div>
    <div
      v-else
      class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col"
    >
      <div class="m-3 sm:m-0 sm:mb-6">
        <div class="sm:mt-6 mb-2">feedback:</div>
        <textarea v-model="sandboxNote" class="form-control"></textarea>
      </div>
      <div
        class="xs:m-2 xs:mr-1 sm:m-0 xs:mb-2 xs:w-1/2 sm:w-full"
        :class="{ 'xs:w-full': !canBeOrdered }"
      >
        <button class="btn-action btn-lg w-full" @click="leaveSandboxNote">
          Create feedback on sandbox
        </button>
      </div>
    </div>
    <!--<img src="/images/card-images.png" class="w-64 mt-3 mx-auto">-->
  </aside>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import currencyHelper from '@/mixins/currencyHelper';

export default {
  mixins: [currencyHelper],
  props: ['extras', 'showSaveProductButton', 'showPlaceOrderButton', 'customer', 'breakdown', 'isCostsHidden',],
  data() {
    return {
      referenceMissing: false,
      sandboxNote: '',
    };
  },
  computed: {
    hasFittedExtras() {
      return false;
    },
    canBeOrdered() {
      return !this.isEnquiry && this.contractHasSizing;
    },
    reference: {
      get() {
        return this.$store.state.basket.reference;
      },
      set(value) {
        this.$store.commit('basket/setReference', value);
      },
    },
    ...mapState('basket', {
      basketItems: 'basket',
      salesSectorType: 'salesSectorType',
      isEnquiry: 'isEnquiry',
      isEditingOrder: 'isOrder',
      isFit: 'isFit',
      contractData: 'contractData',
    }),
    ...mapState('sandbox', {
      selectedSandbox: 'selectedSandbox',
    }),
    ...mapGetters({
      basketCurrency: 'basket/getCurrency',
      total: 'basket/total',
      vat: 'basket/vat',
      subtotal: 'basket/subtotal',
      fittingPrice: 'basket/fittingPrice',
      surveyPrice: 'basket/surveyPrice',
      delivery: 'basket/delivery',
      contractHasSizing: 'basket/contractHasSizing',
      basketQty: 'basket/basketQty',
      sandboxIsActive: 'sandbox/sandboxIsActive',
    }),
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
    }),
    leaveSandboxNote() {
      this.$store
        .dispatch('sandbox/addNoteOnSandbox', {
          sandboxId: this.selectedSandbox.id,
          contractId: this.contractData.id,
          note: this.sandboxNote,
        })
        .then(() => {
          window.alertBox.fire('Your note has been submitted');
          this.sandboxNote = '';
        })
        .catch(() => {
          window.alertBox.fire('Failed saving note');
        });
    },
    setFit(fit) {
      if (fit !== this.isFit) {
        if (fit === true || !this.hasFittedExtras) {
          this.$store.dispatch('basket/setFit', fit);
          return;
        }

        this.alertBox()
          .fire({
            title: 'Fitting extras will be removed, are you sure?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch('basket/setFit', fit);
            }
          });
      }
    },
    saveQuote(bypassSOP) {
      if (this.reference.trim().length <= 3) {
        this.referenceMissing = true;
        return;
      }

      if (this.isEditingOrder) {
        this.referenceMissing = false;
        this.$emit('save-order', bypassSOP);
        return;
      }
      this.referenceMissing = false;
      this.$emit('save-quote');
    },
    isFabricatorForMondayDemo() {
      return window.location.hostname === 'stddemofab.portal.bm-touch.co.uk';
    },
  },
};
</script>
