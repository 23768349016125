<template>
  <div v-show="!loading">
    <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
      * Required Fields
    </div>
    <div class="bg-white mt-5 rounded-lg">
      <div class="flex flex-col xs:flex-row">
        <div class="w-full md:w-1/2 xs:pr-3">
          <table class="w-full">
            <tbody>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">
                  Company: <span class="text-red-600">*</span>
                </td>
                <td class="p-3">
                  <input v-model="customer.name" class="form-control" />
                </td>
              </tr>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">
                  Contact Number: <span class="text-red-600">*</span>
                </td>
                <td class="p-3">
                  <input v-model="customer.phone" class="form-control" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full md:w-1/2 xs:pl-3">
          <table class="w-full">
            <tbody>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">Email: <span class="text-red-600">*</span></td>
                <td class="p-3">
                  <input v-model="customer.email" class="form-control" />
                </td>
              </tr>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">
                  Website:
                </td>
                <td class="p-3">
                  <input v-model="customer.web" class="form-control" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex flex-col xs:flex-row">
        <div class="w-full xs:w-1/2 xs:pr-3">
          <table class="w-full">
            <tbody>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">
                  Customer Type: <span class="text-red-600">*</span>
                </td>
                <td class="p-3">
                  <select v-model="customer.salesSector" class="form-control bg-white">
                    <option value="">None</option>
                    <option :value="1">Trade</option>
                    <option :value="2">Retail</option>
                    <option :value="3">Commercial</option>
                    <option :value="4">New Build</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full xs:w-1/2 xs:pl-3">
          <table class="w-full">
            <tbody>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">
                  Default Tax Rate: <span class="text-red-600">*</span>
                </td>
                <td class="p-3">
                  <select v-model="customer.defaultTaxId" class="form-control bg-white">
                    <option
                      v-for="tax in tax_rates"
                      :key="tax.id"
                      :value="tax.id"
                      v-text="`${tax.name} - ${tax.value}%`"
                    ></option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="isBranchManagerAvailable" class="flex flex-col xs:flex-row">
        <div class="w-full xs:w-1/2 xs:pr-3">
          <table class="w-full">
            <tbody>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">Linked Branch:</td>
                <td class="p-3">
                  <select v-model="customer.branchCompanyId" class="form-control bg-white">
                    <option :value="0">None</option>
                    <option v-for="branch in branches" :key="branch.id" :value="branch.id">
                      {{ branch.name }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button
          class="btn-action btn-lg"
          :class="{ 'pointer-events-none opacity-50': loading }"
          @click.prevent.stop="saveCustomer()"
        >
          Save Customer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import validate from 'validate.js';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      loading: true,
      customer: {
        salesSector: '',
        defaultTaxId: 0,
        web: '',
        name: '',
        email: '',
        phone: '',
        branchCompanyId: 0,
      },
      tax_rates: [],
      options: {
        limit: 20,
        offset: 0,
      },
      branches: {},
    };
  },
  computed: {
    ...mapState('auth', {
      isBranchManagerAvailable: 'isBranchManagerAvailable',
    }),
  },
  async mounted() {
    this.tax_rates = await this.staffGetTaxRates();

    if (this.tax_rates.length === 0) {
      this.alertBox().fire('No tax rates available');
    } else {
      this.customer.defaultTaxId =
        this.tax_rates.find((x) => x.isDefault)?.id ?? this.tax_rates[0].id;
    }
    const response = await this.customerListBranches(this.options);
    this.branches = response.branches;

    this.loading = false;
  },
  methods: {
    ...mapActions({
      staffGetTaxRates: 'user/staffGetTaxRates',
      customerCreate: 'user/customerCreate',
      customerListBranches: 'user/customerListBranches',
    }),
    async saveCustomer() {
      this.loading = true;
      if (this.customer.web.length > 0 && !this.customer.web.match(/\/\//)) {
        this.customer.web = `http://${this.customer.web}`;
      }

      const validationErrors = validate(
        {
          company: this.customer.name,
          email: this.customer.email,
          'Contact Number': this.customer.phone,
          website: this.customer.web,
          defaultTaxId: this.customer.defaultTaxId,
          'Customer Type': this.customer.salesSector,
        },
        {
          company: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          email: {
            presence: { allowEmpty: false },
            length: {
              maximum: 255,
            },
            email: true,
          },
          'Contact Number': {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          website: this.customer.web ? { url: { message: 'must be valid and include http:// or https://' } } : undefined,
          'Customer Type': {
            presence: { allowEmpty: false, message: 'cannot be none' },
          },
          defaultTaxId: {
            presence: { allowEmpty: false },
            numericality: {
              greaterThan: 0,
              message: 'You must set a default tax rate',
            },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        this.loading = false;
        return;
      }

      if (
        [this.enums.salesSectorType.TRADE, this.enums.salesSectorType.RETAIL].includes(
          this.customer.salesSector,
        )
      ) {
        const html =
          this.customer.salesSector === this.enums.salesSectorType.TRADE
            ? '<strong>Trade Customers</strong> <br> Consumer data is not visible on jobs in Admin. <br> Jobs are marked as Supply Only. <br> Can only access Supply Only extras. <br> Delivery Charges can be applied. '
            : '<strong>Retail Customers</strong> <br> Consumer data is visible on jobs in Admin. <br> Jobs are marked as Supply & Fit <br> Can access both Supply Only and Supply & Fit extras. <br> Delivery Charges are never applied.';
        this.alertBox()
          .fire({
            title: 'Customer Type cannot be changed',
            html,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Save Customer',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const resp = await this.customerCreate(this.customer);
              this.routerPush(`/customer/${resp}/details`);
            }
          });
      }
      this.loading = false;
    },
  },
};
</script>
