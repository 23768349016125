<template>
  <div class="bg-white">
    <div class="flex bg-white px-5 border-t-4 border-white whitespace-no-wrap">
      <router-link
        :to="{ query: { active_section: 'images-and-text' } }"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
        :class="{
          'border-black': $route.query.active_section === 'images-and-text',
          'border-white': $route.query.active_section !== 'images-and-text',
        }"
      >
        Images & Text
      </router-link>
      <router-link
        v-if="touch_business_installation"
        :to="{ query: { active_section: 'emails' } }"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
        :class="{
          'border-black': $route.query.active_section === 'emails',
          'border-white': $route.query.active_section !== 'emails',
        }"
      >
        Emails
      </router-link>
      <router-link
        :to="{ query: { active_section: 'paperwork' } }"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
        :class="{
          'border-black': $route.query.active_section === 'paperwork',
          'border-white': $route.query.active_section !== 'paperwork',
        }"
      >
        Paperwork
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
