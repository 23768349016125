import { errorCallback, successCallback } from '@/helpers/validation';
import Touch from './touch';

Touch.prototype.contractSendTradeCustomerQuoteReadyEmail =
  async function contractSendTradeCustomerQuoteReadyEmail(contractId, jobKey) {
    return this.parseResponse(
      await this.authenticatedPost('contract/SendTradeCustomerQuoteReadyEmail', {
        contractId,
        jobKey,
      }),
    );
  };

Touch.prototype.updateJobPriceOverride = async function updateJobPriceOverride(
  contractId,
  jobId,
  overridePrice,
  overrideFitPrice,
) {
  return this.authenticatedPost('contract/updateJobPriceOverride', {
    ContractId: parseInt(contractId, 10),
    JobKey: parseInt(jobId, 10),
    OverrideCalculatedPrice: true,
    OverrideCalculatedFittingPrice: true,
    OverridePriceTaxable: parseFloat(overridePrice),
    OverrideFittingPriceTaxable: parseFloat(overrideFitPrice),
    OverrideFittingPriceTaxExempt: 0,
    OverridePriceTaxExempt: 0,
  });
};

Touch.prototype.contractList = async function contractList(
  type,
  limit,
  offset,
  searchForReference,
  statusId,
  customerId,
  enquirySource,
  onlyShowLocked,
) {
  const options = {
    type,
    limit: parseInt(limit, 10),
    offset: parseInt(offset, 10),
    searchForReference,
    GetTotal: true,
    CustomerId: customerId,
    EnquirySource: enquirySource,
    OnlyShowLocked: onlyShowLocked,
  };

  if (type === window.touch.CONTRACT_TYPE_QUOTE && statusId !== '') {
    options.QuoteStatus = Number(statusId);
  }

  if (type === window.touch.CONTRACT_TYPE_ORDER && statusId !== '') {
    options.OrderStatus = Number(statusId);
  }

  if (type === window.touch.CONTRACT_TYPE_ENQUIRY && statusId !== '') {
    options.EnquiryStatus = Number(statusId);
  }

  return this.parseResponse(await this.authenticatedPost('contract/List', options));
};

Touch.prototype.contractSummary = async function contractSummary({
  type,
  limit,
  offset,
  searchForReference,
  statusId,
  customerId,
  enquirySource,
  onlyShowLocked,
  enquiryStatus,
  IncludeItems,
  customQuoteStageId,
}) {
  const options = {
    type,
    limit: parseInt(limit, 10),
    offset: parseInt(offset, 10),
    searchForReference,
    GetTotal: true,
    CustomerId: customerId,
    CustomQuoteStageId: customQuoteStageId,
    EnquirySource: enquirySource,
    OnlyShowLocked: onlyShowLocked,
    enquiryStatus,
    IncludeItems,
  };

  if (type === window.touch.CONTRACT_TYPE_QUOTE && statusId) {
    options.QuoteStatus = Number(statusId);
  }

  if (type === window.touch.CONTRACT_TYPE_ORDER && statusId) {
    options.OrderStatus = Number(statusId);
  }

  if (type === window.touch.CONTRACT_TYPE_ENQUIRY && statusId) {
    if (Array.isArray(statusId)) {
      options.EnquiryStatus = statusId;
    } else {
      options.EnquiryStatus = [Number(statusId)];
    }
  }

  return this.parseResponse(await this.authenticatedPost('contract/Summary', options));
};

Touch.prototype.ListSaveStates = async function contractSummary({
  type,
  customerId,
  OnlyTheseContracts,
}) {
  return this.parseResponse(
    await this.authenticatedPost('contract/ListSaveStates', {
      type,
      customerId,
      OnlyTheseContracts,
    }),
  );
};

Touch.prototype.contractConvertToOrder = async function contractConvertToOrder(
  contractId,
  jobIds,
  customerId = undefined,
) {
  return this.parseResponse(
    await this.authenticatedPost('contract/ConvertToOrder', {
      ContractId: contractId,
      JobKeys: jobIds,
      CustomerId: customerId,
    }),
    (error) => {
      throw new Error(error.data.message);
    },
    (response) => response.data.contractId,
  );
};

Touch.prototype.contractUpdateJobRequoteFlag = async function contractUpdateJobRequoteFlag(
  contractId,
  jobId,
  flag,
) {
  const { data } = await this.authenticatedPost('contract/UpdateJobRequoteFlag', {
    ContractId: contractId,
    JobKey: jobId,
    requoted: flag,
  });

  return data;
};

Touch.prototype.contractPlaceTradeOrder = async function contractPlaceTradeOrder(contractId) {
  return this.parseResponse(
    await this.authenticatedPost('contract/PlaceTradeOrder', {
      ContractId: contractId,
    }),
    (error) => {
      throw new Error(error.data.message);
    },
    (response) => response.data.contractId,
  );
};

Touch.prototype.contractConvertToQuote = async function contractConvertToQuote(contractId, jobIds) {
  const { data } = await this.authenticatedPost('contract/ConvertToQuote', {
    ContractId: Number(contractId),
    JobKeys: jobIds.map((jobId) => Number(jobId)),
  });

  return data.contractId;
};

Touch.prototype.contractListFirstJobMinimal = async function contractListFirstJobMinimal(
  contractId,
  processingLevel,
) {
  return this.contractListJobs(contractId, 1, processingLevel, false);
};

Touch.prototype.contractListJobs = async function contractListJobs(
  contractId,
  jobKey,
  processingLevel,
  includeItems = true,
) {
  const { jobs } = await this.parseResponse(
    await this.authenticatedPost('contract/ListJobs', {
      ContractId: parseInt(contractId, 10),
    }),
    errorCallback,
  );

  return Promise.all(
    jobs.map(async (job) => {
      if (jobKey !== undefined && job.jobKey !== jobKey) {
        return {
          ...job,
          items: [],
        };
      }

      const fullJob = await this.contractJobDetails(contractId, job.jobKey);

      if (!includeItems) {
        return fullJob;
      }

      const formattedItems = await Promise.all(
        fullJob.items.map(async (item) => {
          const fullItem = await window.touch.contractJobLineItemDetails(
            contractId,
            job.jobKey,
            item.key,
            processingLevel,
            fullJob.exchangeRate,
          );

          return {
            ...item,
            ...fullItem,
          };
        }),
      );

      return {
        ...job,
        ...fullJob,
        items: formattedItems,
      };
    }),
  );
};

Touch.prototype.contractUpdateNote = async function contractUpdateNote(params) {
  return (
    await this.authenticatedPost('contract/UpdateNote', {
      ...params,
    })
  ).data;
};

Touch.prototype.contractGetNotes = async function contractGetNotes(params) {
  return this.parseResponse(
    await this.authenticatedPost('contract/GetNotes', {
      ...params,
      ContractId: Number(params.ContractId),
    }),
    () => null,
  );
};

Touch.prototype.contractJobDetails = async function contractJobDetails(contractId, jobKey) {
  const fullJob = (
    await this.authenticatedPost('contract/JobDetails', {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobKey, 10),
    })
  ).data;

  if (fullJob.exchangeRate === null || fullJob.exchangeRate === 0) {
    fullJob.exchangeRate = 1;
  }

  fullJob.calculatedFittingPriceTaxExempt *= fullJob.exchangeRate;
  fullJob.calculatedFittingPriceTaxable *= fullJob.exchangeRate;
  fullJob.calculatedPriceTaxExempt *= fullJob.exchangeRate;
  fullJob.calculatedPriceTaxable *= fullJob.exchangeRate;
  if (!fullJob.overrideCalculatedPrice) {
    fullJob.priceBeforeTax *= fullJob.exchangeRate;
    fullJob.priceIncludingTax *= fullJob.exchangeRate;
    fullJob.priceTaxExempt *= fullJob.exchangeRate;
    fullJob.priceTaxable *= fullJob.exchangeRate;
    fullJob.requotePriceBeforeTax *= fullJob.exchangeRate;
    fullJob.requoteTax *= fullJob.exchangeRate;
    fullJob.requotePriceIncludingTax *= fullJob.exchangeRate;
    fullJob.tax *= fullJob.exchangeRate;
  }

  fullJob.items = fullJob.items.map((item) => ({
    ...item,
    calculatedPrice: item.calculatedPrice * fullJob.exchangeRate,
    price: item.price * fullJob.exchangeRate,
  }));

  return fullJob;
};

Touch.prototype.contractDetails = async function contractDetails(
  customerId,
  contractId,
  jobId = 1,
) {
  const params = {
    ContractIds: [Number(contractId)],
  };

  if (window.VUE_APP_INSTALLATION_TYPE === 'business') {
    params.CustomerId = customerId;
  }

  const contract = await this.parseResponse(await this.authenticatedPost('contract/Details', params));
  const fullJob = await this.contractJobDetails(contractId, jobId);
  fullJob.currency = contract[0].currencyCode
  fullJob.customerCurrency = contract[0].customerCurrencyCode
  return {
    ...contract[0],
    jobs: [fullJob]
  };
};

Touch.prototype.contractJobLineItemDetails = async function contractJobLineItemDetails(
  contractId,
  jobKey,
  itemKey,
  processingLevel,
  exchangeRate,
) {
  const result = (
    await this.authenticatedPost('contract/JobLineItemDetails', {
      ContractId: parseInt(contractId, 10),
      JobKey: jobKey,
      ItemKey: parseInt(itemKey, 10),
      ProcessingLevel: processingLevel,
    })
  ).data;

  result.calculatedPrice *= exchangeRate;
  result.price *= exchangeRate;

  return result;
};

Touch.prototype.contractUpdateJobRequote = async function contractUpdateJobRequote({
  contractId,
  jobKey,
  emailSubject,
  emailBodyText,
  requotePriceBeforeTax,
}) {
  await this.authenticatedPost('contract/UpdateJobRequote', {
    ContractId: contractId,
    JobKey: jobKey,
    EmailSubject: emailSubject,
    EmailBodyText: emailBodyText,
    RequotePriceBeforeTax: requotePriceBeforeTax,
  });
};

Touch.prototype.contractSendRequoteEmailToConsumer =
  async function contractSendRequoteEmailToConsumer(contractId, jobKey) {
    await this.authenticatedPost('contract/SendRequoteEmailToConsumer', {
      ContractId: contractId,
      JobKey: jobKey,
    });
  };

Touch.prototype.contractUpdateVisibility = async function contractUpdateVisibility(
  contractId,
  { visibleToAdmin, visibleToPortal },
) {
  const res = await this.authenticatedPost('contract/UpdateVisibility', {
    ContractId: contractId,
    VisibleToPortal: visibleToPortal,
    VisibleToAdmin: visibleToAdmin,
  });
  return res;
};

Touch.prototype.cancelOrder = async function cancelOrder(contractId) {
  const res = await this.authenticatedPost('contract/CancelOrder', {
    ContractId: contractId,
  });
  return res;
};

Touch.prototype.contractUpdateConsumer = async function contractUpdateConsumer({
  contractId,
  customerId,
  salutation,
  firstName,
  lastName,
  email,
  mobile,
  addressLine1,
  addressLine2,
  addressLine3,
  addressTown,
  addressCounty,
  addressCode,
  addressIso,
}) {
  return this.parseResponse(
    await this.authenticatedPost('contract/UpdateConsumer', {
      ContractId: contractId,
      CustomerId: customerId,
      Salutation: salutation,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Mobile: mobile,
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      AddressLine3: addressLine3,
      AddressTown: addressTown,
      AddressCounty: addressCounty,
      AddressCode: addressCode,
      AddressIso: addressIso,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.AssignCustomQuoteStage = async function AssignCustomQuoteStage(
  ContractId,
  CustomerId,
  CustomQuoteStageId,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      'contract/AssignCustomQuoteStage',
      {
        ContractId,
        CustomerId,
        CustomQuoteStageId,
      },
      errorCallback,
      () => successCallback('Save'),
    ),
  );
};

Touch.prototype.contractAddStaffToContract = async function contractAddStaffToContract(
  ContractId,
  JobKey,
  Role,
  StaffId,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      'contract/AddStaffToContract',
      {
        ContractId,
        JobKey,
        Role,
        StaffId,
      },
      errorCallback,
      () => successCallback('Save'),
    ),
  );
};

Touch.prototype.GetTotalOrdersPlaced = async function GetTotalOrdersPlaced() {
  return this.parseResponse(await this.authenticatedPost('contract/GetTotalOrdersPlaced'));
};

Touch.prototype.GetTotalDealerEnquiries = async function GetTotalDealerEnquiries() {
  return this.parseResponse(await this.authenticatedPost('contract/GetTotalDealerEnquiries'));
};

Touch.prototype.GetTotalQuotesCreated = async function GetTotalQuotesCreated() {
  return this.parseResponse(await this.authenticatedPost('contract/GetTotalQuotesCreated'));
};
