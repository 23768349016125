export default {
  namespaced: true,
  state: {
    selectedComponents: [],
    openMenuType: 'specification',
    clickedComponentId: undefined,
    clickedComponentType: undefined,
    clickedMemberId: undefined,
    currentSelectionType: undefined,
    openSpecificationGroup: undefined,
    showSelectionForHeadingId: undefined,
    openQuestion: undefined,
    isQuickTip: true,
    backgroundColourList: [
      // https://sashamaps.net/docs/resources/20-colors/
      '#e6194B',
      '#3cb44b',
      '#4363d8',
      '#f58231',
      '#911eb4',
      '#42d4f4',
      '#f032e6',
      '#bfef45',
      '#fabed4',
      '#469990',
      '#dcbeff',
      '#9A6324',
      '#fffac8',
      '#800000',
      '#aaffc3',
      '#808000',
      '#ffd8b1',
      '#000075',
      '#a9a9a9',
    ],
  },
  mutations: {
    SET_OPEN_MENU_TYPE(state, type) {
      state.openMenuType = type;
    },
    CLEAR_SELECTION(state) {
      state.clickedMemberId = undefined;
      state.clickedComponentId = undefined;
      state.currentSelectionType = undefined;
      state.clickedComponentType = undefined;
      state.selectedComponents = [];
    },
    SET_CLICKED_COMPONENT_TYPE(state, type) {
      state.clickedComponentType = type;
    },
    SET_CLICKED_COMPONENT_ID(state, id) {
      state.clickedComponentId = id;
    },
    CLEAR_CLICKED_MEMBER_ID(state) {
      state.clickedMemberId = undefined;
    },
    CLEAR_CLICKED_COMPONENT_ID(state) {
      state.clickedComponentId = undefined;
    },
    CLEAR_CLICKED_COMPONENT_TYPE(state) {
      state.clickedComponentType = undefined;
    },
    SET_CLICKED_MEMBER_ID(state, id) {
      state.clickedMemberId = id;
    },
    TOGGLE_CLICKED_COMPONENT_IN_LIST(state, clickedComponent) {
      if (
        !state.selectedComponents.some(
          (selectedComponent) => selectedComponent.id === clickedComponent.id,
        )
      ) {
        state.selectedComponents.push(clickedComponent);
        return;
      }

      state.selectedComponents = state.selectedComponents.filter(
        (selectedComponent) => selectedComponent.id !== clickedComponent.id,
      );
    },
    SET_SELECTION_TYPE_COMPONENT(state) {
      state.currentSelectionType = 'component';
    },
    SET_SELECTION_TYPE_MEMBER(state) {
      state.currentSelectionType = 'member';
    },
    SET_SELECTION_TYPE_NONE(state) {
      state.currentSelectionType = undefined;
    },
    CLEAR_SELECTED_COMPONENTS(state) {
      state.selectedComponents = [];
    },
    clearSpecificationGroup(state) {
      const currentGroup = state.openSpecificationGroup;
      state.openSpecificationGroup = undefined;
      setTimeout(() => {
        const specificationGroupElement = document.querySelectorAll(
          `[data-specification-group-id="${currentGroup}"]`,
        )[0];
        if (specificationGroupElement) {
          document.getElementById('designer_sidebar_scrolling_container').scrollTop =
            specificationGroupElement.offsetTop;
        }
      }, 100);
    },
    setOpenSpecificationGroup(state, group) {
      state.openSpecificationGroup = group;
      setTimeout(() => {
        const specificationGroupElement = document.querySelectorAll(
          `[data-specification-group-id="${group}"]`,
        )[0];
        if (specificationGroupElement) {
          document.getElementById('designer_sidebar_scrolling_container').scrollTop =
            specificationGroupElement.offsetTop;
        }
      }, 100);
    },
    CLEAR_SHOW_SELECTION_FOR_HEADING_ID(state) {
      state.showSelectionForHeadingId = undefined;
    },
    toggleShowSelectionForHeadingId(state, headingId) {
      if (headingId === state.showSelectionForHeadingId) {
        state.showSelectionForHeadingId = undefined;
        return;
      }
      state.showSelectionForHeadingId = headingId;
    },
    clearOpenQuestion(state) {
      state.openQuestion = undefined;
    },
    setOpenQuestion(state, question) {
      const designerOuterContainer = document.getElementById(
        'designer_sidebar_scrolling_container',
      );
      designerOuterContainer.scrollTop = 0;
      state.openQuestion = question;
    },
    setIsQuickTip(state, isQuickTip) {
      state.isQuickTip = isQuickTip;
    },
  },
  getters: {
    getSetupURL() {
      return window.VUE_APP_INSTALLATION_TYPE === 'business' ? '/dealer-network-setup' : '/setup';
    },
    listOfUnansweredForcedQuestions(state, getters, rootState, rootGetters) {
      const currentItem = rootGetters['basket/currentItem'];

      if (!currentItem) {
        return [];
      }

      return currentItem.specification.filter(
        (spec) => spec.mustBeSelected && !spec.hasBeenSelected,
      );
    },
    optionHeadingForOpenQuestion(state) {
      return state.openQuestion;
    },
    idForUnansweredQuestion(state, getters, rootState, rootGetters) {
      if (!getters.itemHasUnansweredForcedQuestions) {
        return undefined;
      }

      const currentItem = rootGetters['basket/currentItem'];

      return currentItem.specification.find((spec) => spec.mustBeSelected && !spec.hasBeenSelected)
        .optionHeadingId;
    },
    itemHasUnansweredForcedQuestions(state, getters, rootState, rootGetters) {
      const currentItem = rootGetters['basket/currentItem'];

      if (!currentItem) {
        return false;
      }

      return currentItem.specification.some((spec) => spec.mustBeSelected && !spec.hasBeenSelected);
    },
    specificationGroups(state, getters, rootState, rootGetters) {
      if (!rootGetters['basket/currentItem']) {
        return false;
      }

      let spec = rootGetters['basket/currentItem'].specification.map((specRow) => ({
        ...specRow,
        optionGroupName:
          specRow.optionGroupName === 'There is no group' ? specRow.name : specRow.optionGroupName,
        optionGroupOrder:
          specRow.optionGroupName === 'There is no group' ? Infinity : specRow.optionGroupOrder,
      }));

      if (getters.somethingIsSelected) {
        spec = spec.filter((specification) => {
          if (getters.interactiveHighlightState.currentSelectionType === 'member') {
            return specification.members.some(
              (member) => member.id === getters.interactiveHighlightState.clickedMemberId,
            );
          }
          if (getters.interactiveHighlightState.currentSelectionType === 'component') {
            return specification.components.some((component) =>
              getters.interactiveHighlightState.selectedComponents.some(
                (selectedComponent) => selectedComponent.id === component.id,
              ),
            );
          }

          return false;
        });

        if (getters.moreThanOneThingIsSelected) {
          spec = spec.filter(
            (specification) =>
              specification.visibilityTypeId !==
              window.enum.visibilityType.ONLY_FOR_DESIGN_SELECTION,
          );
        }
      } else {
        spec = spec.filter(
          (specification) =>
            specification.visibilityTypeId === window.enum.visibilityType.STANDARD &&
            specification.name !== 'Component Type' &&
            specification.name !== 'Sash Type',
        );
      }

      const specGroups = spec.reduce((obj, specItem) => {
        const groupName = specItem.optionGroupName;
        // eslint-disable-next-line no-prototype-builtins
        if (!obj.hasOwnProperty(groupName)) {
          // eslint-disable-next-line no-param-reassign
          obj[groupName] = [];
        }

        obj[groupName].push(specItem);

        return obj;
      }, {});

      const sortable = [];
      Object.keys(specGroups).forEach((specGroup) => {
        if (Object.prototype.hasOwnProperty.call(specGroups, specGroup)) {
          sortable.push([specGroup, specGroups[specGroup]]);
        }
      });

      const sortedQuestions = (specGroup) =>
        specGroup.slice().sort((a, b) => a.listOrder - b.listOrder);

      const sortedGroups = sortable.sort((a, b) => {
        const sortedQuestionsA = sortedQuestions(a[1]);
        const sortedQuestionsB = sortedQuestions(b[1]);
        if (sortedQuestionsA[0].optionGroupOrder < sortedQuestionsB[0].optionGroupOrder) {
          return -1;
        }
        if (sortedQuestionsA[0].optionGroupOrder > sortedQuestionsB[0].optionGroupOrder) {
          return 1;
        }
        return 0;
      });

      const result = sortedGroups.map((specification) => [
        specification[0],
        window._.groupBy(sortedQuestions(specification[1]), 'optionHeadingId'),
      ]);
      return result.sort(
        (a, b) =>
          Object.values(a[1])[0][0].optionGroupOrder - Object.values(b[1])[0][0].optionGroupOrder,
      );
    },
    selectionForHeadingState(state, getters) {
      if (state.showSelectionForHeadingId) {
        return getters.specificationGroups
          .filter((group) => group[1][state.showSelectionForHeadingId] !== undefined)
          .flat()[1]
          [state.showSelectionForHeadingId].map((selectionGroup, index) => ({
            componentIds:
              selectionGroup.members.length === 0
                ? selectionGroup.components.map((component) => component.id)
                : [],
            memberIds: selectionGroup.members.map((member) => member.id), // array of member ids or empty for component mode
            colour: state.backgroundColourList[index], // hex code for colour,
          }));
      }

      return [];
    },
    somethingIsSelected(state) {
      return (
        state.selectedComponents.length > 0 ||
        (state.currentSelectionType === 'member' && state.clickedMemberId)
      );
    },
    moreThanOneThingIsSelected(state) {
      return state.selectedComponents.length > 1;
    },
    interactiveHighlightState(state) {
      return {
        currentSelectionType: state.currentSelectionType,
        clickedMemberId: state.clickedMemberId,
        selectedComponents: state.selectedComponents,
      };
    },
    questionIsOpen(state) {
      return state.openQuestion !== undefined;
    },
  },
  actions: {
    beginSelection(context, { clickedComponent }) {
      context.commit('SET_SELECTION_TYPE_COMPONENT');
      context.commit('CLEAR_SELECTED_COMPONENTS');
      context.commit('TOGGLE_CLICKED_COMPONENT_IN_LIST', clickedComponent);
    },
    clickedInComponentMode(context, { clickedComponent, componentId, memberId }) {
      if (context.state.currentSelectionType === 'member') {
        if (memberId === context.state.clickedMemberId) {
          context.commit('CLEAR_CLICKED_MEMBER_ID');
          context.commit('CLEAR_CLICKED_COMPONENT_ID');
          context.commit('CLEAR_CLICKED_COMPONENT_TYPE');
          context.commit('SET_SELECTION_TYPE_NONE');
          return;
        }

        if (context.state.clickedComponentType === clickedComponent.typeId) {
          return;
        }
      }

      context.commit('SET_SELECTION_TYPE_COMPONENT');

      if (clickedComponent.memberIds.length === 1) {
        context.commit('SET_SELECTION_TYPE_MEMBER');
        context.commit('CLEAR_SELECTED_COMPONENTS');
      }

      // We have clicked twice to drill to member level
      if (
        componentId === context.state.clickedComponentId &&
        memberId &&
        memberId === context.state.clickedMemberId &&
        clickedComponent.memberIds.length > 1
      ) {
        context.commit('SET_SELECTION_TYPE_MEMBER');
        context.commit('CLEAR_SELECTED_COMPONENTS');
        return;
      }

      // We clicked a component of the same type
      if (context.state.clickedComponentType === clickedComponent.typeId) {
        context.commit('TOGGLE_CLICKED_COMPONENT_IN_LIST', clickedComponent);
        return;
      }

      // We clicked a component of a different type
      if (context.state.clickedComponentType !== clickedComponent.typeId) {
        context.commit('CLEAR_SELECTED_COMPONENTS');
        context.commit('TOGGLE_CLICKED_COMPONENT_IN_LIST', clickedComponent);
      }
    },
    async selectorClicked(context, { componentId, memberId }) {
      context.commit('CLEAR_SHOW_SELECTION_FOR_HEADING_ID');
      if (!componentId) {
        window.alertBox.fire('No Component - Bad click???');
        return false;
      }

      const clickedComponent = context.rootGetters['basket/currentItem'].simpleComponents.find(
        (simpleComponent) => simpleComponent.id === componentId,
      );

      if (!clickedComponent) {
        window.alertBox.fire('Could not find component in simpleComponents list');
        return false;
      }

      clickedComponent.memberIds = clickedComponent.members
        .filter((member) => member.hasSpecification)
        .map((member) => member.id);

      if (!context.state.currentSelectionType) {
        await context.dispatch('beginSelection', {
          clickedComponent,
        });
      } else {
        await context.dispatch('clickedInComponentMode', {
          componentId,
          memberId,
          clickedComponent,
        });
      }

      context.commit('SET_CLICKED_COMPONENT_ID', componentId);
      context.commit('SET_CLICKED_MEMBER_ID', memberId);
      context.commit('SET_CLICKED_COMPONENT_TYPE', clickedComponent.typeId);

      return true;
    },
  },
};
