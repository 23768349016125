<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center">
        Customer Management
        <HelpPoint class="ml-2" freshdesk-article-id="101000455581" />
      </div>
    </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for customer..." />
    </portal>
    <template v-if="is_admin_user" #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <a class="btn-action" @click.stop.prevent="create_user_popup_open = true"> Create New </a>
      </div>
    </template>
    <portal v-if="create_user_popup_open" to="portal_popup">
      <modal-window
        :modal_open="create_user_popup_open"
        title="Add Customer"
        @close="create_user_popup_open = false"
      >
        <customer-create @close="create_user_popup_open = false"></customer-create>
      </modal-window>
    </portal>
    <loading :loading="loading" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10">
      <div class="bg-white p-3 xs:p-10 border">
        <table class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th> Account Code </th>
              <th> Company Name </th>
              <th> Tel </th>
              <th> Email </th>
              <th> Town </th>
              <th> County </th>
              <th
                class="cursor-pointer"
                :class="{'pointer-events-none': !$route.query.licenseFilter}"
              >
                License
                <span v-if="$route.query.licenseFilter" @click="license_filter_dropdown_open = !license_filter_dropdown_open, status_filter_dropdown_open = false">
                  <i class="fal fa-filter mx-1"></i>
                  <span v-if="$route.query.licenseFilter == enums.licenseStatusType.ENABLED" class="font-blue-500">
                    Enabled
                  </span>
                  <span v-else-if="$route.query.licenseFilter == enums.licenseStatusType.DISABLED">
                    Disabled
                  </span>
                </span>
                <span v-else>
                  (All)
                </span>
                <div v-show="license_filter_dropdown_open" class="relative">
                  <div class="absolute bg-white z-10 border border-gray-300 text-xs rounded">
                    <div class="p-1 border-b border-gray-300 font-medium flex justify-between m-2">
                      <div class="flex flex-col justify-around pr-6 whitespace-no-wrap">License Status</div>
                      <div class="flex flex-col justify-around">
                        <i class="fal fa-times" @click.stop="license_filter_dropdown_open = false"></i>
                      </div>
                    </div>
                    <div class="mt-2">
                      <div v-for="status in [enums.licenseStatusType.ENABLED, enums.licenseStatusType.DISABLED]" :key="status.id" class="py-2 px-4 hover:bg-gray-200" @click="setLicenseFilter(status)">
                        <span v-if="status == enums.licenseStatusType.ENABLED">
                          <span class="inline-block rounded-full w-2 h-2 mr-2 bg-green-500"></span>
                          Enabled
                        </span>
                        <span v-else-if="status == enums.licenseStatusType.DISABLED">
                          <span class="inline-block rounded-full w-2 h-2 mr-2 bg-red-500"></span>
                          Disabled
                        </span>
                        <span v-show="$route.query.licenseFilter == status" class="text-green-600 pl-2">
                          <i class="fal fa-check"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
              <th
                class="cursor-pointer"
                :class="{'pointer-events-none': !$route.query.statusFilter}"
              >
                Status
                <span v-if="$route.query.statusFilter" @click="status_filter_dropdown_open = !status_filter_dropdown_open, license_filter_dropdown_open = false">
                  <i class="fal fa-filter mx-1"></i>
                  <span v-if="$route.query.statusFilter == enums.customerStatus.LIVE" class="font-blue-500">
                    Live
                  </span>
                  <span v-else-if="$route.query.statusFilter == enums.customerStatus.ON_STOP">
                    On Stop
                  </span>
                  <span v-else-if="$route.query.statusFilter == enums.customerStatus.ARCHIVED">
                    Archived
                  </span>
                </span>
                <span v-else>
                  (All)
                </span>
                <div v-show="status_filter_dropdown_open" class="relative">
                  <div class="absolute bg-white z-10 border border-gray-300 text-xs rounded">
                    <div class="p-1 border-b border-gray-300 font-medium flex justify-between m-2">
                      <div class="flex flex-col justify-around pr-6 whitespace-no-wrap">Filter Status</div>
                      <div class="flex flex-col justify-around">
                        <i class="fal fa-times" @click.stop="status_filter_dropdown_open = false"></i>
                      </div>
                    </div>
                    <div class="mt-2">
                      <div v-for="status in [enums.customerStatus.LIVE, enums.customerStatus.ON_STOP, enums.customerStatus.ARCHIVED]" :key="status.id" class="py-2 px-4 hover:bg-gray-200" @click="setStatusFilter(status)">
                        <span v-if="status == enums.customerStatus.LIVE">
                          <span class="inline-block rounded-full w-2 h-2 mr-2 bg-green-500"></span>
                          Live
                        </span>
                        <span v-else-if="status == enums.customerStatus.ON_STOP">
                          <span class="inline-block rounded-full w-2 h-2 mr-2 bg-blue-500"></span>
                          On Stop
                        </span>
                        <span v-else-if="status == enums.customerStatus.ARCHIVED">
                          <span class="inline-block rounded-full w-2 h-2 mr-2 bg-red-500"></span>
                          Archived
                        </span>
                        <span v-show="$route.query.statusFilter == status" class="text-green-600 pl-2">
                          <i class="fal fa-check"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <router-link
              v-for="(customer, index) in customers"
              :key="index"
              tag="tr"
              :to="customer.url"
              :class="{ '_hovered' : hovered === index }"
              @mouseover.native="hovered = index"
              @mouseleave.native="hovered = null"
            >
              <td>
                <div class="td-title w-24"> Account Code: </div>
                <div class="td-content">{{ customer.accountCode }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Company Name: </div>
                <div class="td-content">{{ customer.name }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Tel: </div>
                <div class="td-content">{{ customer.phone }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Email: </div>
                <div class="td-content break-all">{{ customer.email }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Town: </div>
                <div class="td-content">{{ customer.address.town }}</div>
              </td>
              <td>
                <div class="td-title w-24"> County: </div>
                <div class="td-content">{{ customer.address.county }}</div>
              </td>
              <td>
                <div class="td-title w-24"> License: </div>
                <div class="td-content text-center">
                  <div v-if="customer.generalLicenseStatus === enums.licenseStatusType.ENABLED" class="bg-green-500 rounded text-white px-10 py-1">
                    Enabled
                  </div>
                  <div v-else class="bg-red-500 rounded text-white px-10 py-1">
                    Disabled
                  </div>
                </div>
              </td>
              <td>
                <div class="td-title w-24"> Status: </div>
                <div class="td-content text-center">
                  <div v-if="customer.customerStatus === enums.customerStatus.ARCHIVED" class="bg-red-500 rounded text-white px-10 py-1">
                    Archived
                  </div>
                  <div v-else-if="customer.customerStatus === enums.customerStatus.ON_STOP" class="bg-blue-500 rounded text-white px-10 py-1">
                    On Stop
                  </div>
                  <div v-else class="bg-green-500 rounded text-white px-10 py-1">
                    Live
                  </div>
                </div>
              </td>
              <td class="td-action-buttons">
                <div class="table-edit-btn">
                  <i class="fa fa-pencil"></i>
                </div>
              </td>
            </router-link>
          </tbody>
        </table>
        <div v-if="customers.length === 0" key="none-found" class="bg-white p-3 xs:p-10">
          <div class="flex justify-between">
            <div>
              No Customers to show
            </div>
            <div>
              <a class="btn-action" @click.stop="setDefaultFilter()">
                Clear Search
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-show="customers.length > 0" class="mt-3">
        <pagination
          :key="JSON.stringify($route.query)"
          :total_items="total_items"
          :limit="limit"
          @load-feed="loadFeed"
        ></pagination>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import { mapActions } from 'vuex';
import HelpPoint from '@/components/shared/HelpPoint.vue';
import Pagination from '@/components/shared/Pagination.vue';
import CustomerCreate from '@/components/shared/CustomerCreate.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    pagination: Pagination,
    'customer-create': CustomerCreate,
    HelpPoint,
    Breadcrumbs,
    SearchBox,
  },
  data() {
    return {
      customers: [],
      loading: true,
      limit: 20,
      total_items: 0,
      create_user_popup_open: false,
      hovered: null,
      license_filter_dropdown_open: false,
      status_filter_dropdown_open: false,
    };
  },
  watch: {
    '$route.query': {
      handler(query) {
        this.license_filter_dropdown_open = false;
        this.status_filter_dropdown_open = false;
        if (query.search) {
          this.$router.push({ query: { ...this.$route.query, statusFilter: undefined, licenseFilter: undefined } }).catch(() => {});
        } else if (!query.statusFilter || !query.licenseFilter) {
          this.setDefaultFilter();
        }
        if ((query.statusFilter && query.licenseFilter && !query.search) || (query.search && (!query.statusFilter || !query.licenseFilter))) {
          this.loadFeed();
        }
      },
    },
  },
  created() {
    if (this.$route.query.statusFilter || this.$route.query.licenseFilter || this.$route.query.search) {
      this.loadFeed()
    } else {
      this.setDefaultFilter();
    }
  },
  methods: {
    ...mapActions({
      staffListCustomers: 'user/staffListCustomers',
    }),
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await this.staffListCustomers({
        limit: this.limit,
        offset,
        search: this.$route.query.search,
        customerStatus: this.$route.query.statusFilter ? parseInt(this.$route.query.statusFilter) : undefined,
        generalLicenseStatus: this.$route.query.licenseFilter ? parseInt(this.$route.query.licenseFilter) : undefined
      });
      this.customers = response.customers;
      this.total_items = response.total_items;
      this.loading = false;
    },
    setStatusFilter(statusFilter) {
      this.$router.push({ query: { ...this.$route.query, statusFilter } }).catch(() => {});
      this.status_filter_dropdown_open = false
    },
    setLicenseFilter(licenseFilter) {
      this.$router.push({ query: { ...this.$route.query, licenseFilter } }).catch(() => {});
      this.license_filter_dropdown_open = false
    },
    setDefaultFilter() {
      this.license_filter_dropdown_open = false
      this.status_filter_dropdown_open = false
      this.$router.replace({
        query: {
          ...this.$route.query, statusFilter: this.enums.customerStatus.LIVE, licenseFilter: this.enums.licenseStatusType.ENABLED, search: undefined,
        },
      }).catch(() => {});
    },
  }
};
</script>