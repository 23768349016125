<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          [$t('link.My Branded Mode Account'), '/setup'],
          ['Branded Mode Designer'],
        ]"
      />
    </template>
    <template #header_page_title> Branded Mode Designer </template>
    <Website @afterSaved="afterSaved"></Website>
  </touch-layout>
</template>

<script>
import Website from '@/components/shared/setup/Website.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    Website,
    Breadcrumbs,
  },
  methods: {
    ...mapActions({
      setCustomer: 'user/setCustomer',
    }),
    async afterSaved() {
      this.$store.dispatch('auth/checkWizard');
      await this.setCustomer();
    },
  },
};
</script>
