<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        v-if="current_category_name !== ''"
        :routes="[['Dashboard', '/'], ['News', '/news'], [current_category_name]]"
      />
      <Breadcrumbs
        v-else
        :routes="[
          ['Dashboard', '/'],
          ['News', '/news'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div>
        <template v-if="current_category_name === ''"> Search News </template>
        <template v-else> News - {{ current_category_name }} </template>
      </div>
    </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search news..." />
    </portal>
    <template #header_page_actions>
      <div class="mr-3 md:px-10 flex items-center">
        <div class="relative" tabindex="3" @focusout="category_dropdown_open = false">
          <div class="border border-l-0 border-r-0 border-b-0 border-white w-full" />
          <div
            class="p-2 h-10 border flex gap-5 justify-between cursor-pointer"
            @click="category_dropdown_open = !category_dropdown_open"
          >
            <div class="whitespace-no-wrap text-gray-600">Choose Category</div>
            <div>
              <i v-if="category_dropdown_open" class="fa fa-caret-up" />
              <i v-else class="fa fa-caret-down" />
            </div>
          </div>
          <div
            v-if="category_dropdown_open"
            class="absolute bg-gray-200 w-full md:w-64 border border-t-0 z-10 text-xs sm:text-sm"
          >
            <div
              v-for="category in news_categories.filter((x) => !x.isEmpty)"
              :key="category.id"
              class="flex cursor-pointer bg-white m-2 rounded-lg cursor-pointer hover:bg-brand-primary hover:text-white"
              :class="{ 'bg-brand-primary text-white': currentCategory === category.id }"
              @click="setCategory(category.id), (category_dropdown_open = false)"
            >
              <div v-if="currentCategory === category.id" class="p-3">
                <i class="fal fa-check" />
              </div>
              <div class="p-3">
                <label :title="category.name"> {{ category.name }} </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <loading
      v-if="!loading"
      :loading="loading"
      appear
      class="bg-gray-200 w-full h-full overflow-y-auto overflow-x-hidden scrolling-touch p-3 md:p-10 relative"
    >
      <div key="1" class="flex" data-index="1">
        <div v-if="total_items > 0 && news.length > 0" class="w-full">
          <transition :key="otherPosts.length" appear name="fade-in" mode="out-in">
            <animation-staggered-fade :start-delay="500" appear class="flex flex-wrap">
              <router-link
                v-for="(news_post, index) in otherPosts"
                :key="index"
                tag="article"
                :to="news_post.url"
                class="w-full xs:w-1/2 lg:w-1/3 flex flex-col cursor-pointer hover-zoom"
                :data-index="index"
              >
                <div class="flex flex-col flex-1 m-3 bg-white border p-3">
                  <div v-if="news_post.imageUrl" class="flex flex-col justify-around flex-grow">
                    <api-img :src="news_post.imageUrl" class="w-full" />
                  </div>
                  <div class="flex flex-col mt-auto">
                    <div class="font-medium border-b pb-3 my-3">
                      {{ news_post.title }}
                    </div>
                    <div class="mb-2" v-html="news_post.summary" />
                    <div class="text-sm">
                      {{ formatDate(news_post.publishAt) }}
                    </div>
                  </div>
                </div>
              </router-link>
            </animation-staggered-fade>
          </transition>
        </div>
        <div v-else key="none-found" class="w-full">
          <div
            v-if="$route.query.search"
            class="flex items-center justify-between bg-white p-3 border"
          >
            <div>No News Articles match "{{ $route.query.search }}"</div>
            <div>
              <a class="btn-action" @click.stop="$router.push({ query: undefined })">
                Clear Search
              </a>
            </div>
          </div>
          <div v-else class="bg-white p-3 border">No News Articles to show</div>
        </div>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import { mapActions } from 'vuex';
import dateHelper from '@/mixins/dateHelper';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    Breadcrumbs,
    SearchBox,
  },
  mixins: [dateHelper],
  data() {
    return {
      news: [],
      news_categories: [],
      loading: true,
      limit: 20,
      current_page: 1,
      show_load_more: true,
      total_items: 0,
      category_dropdown_open: false,
    };
  },
  computed: {
    current_category_name() {
      // eslint-disable-next-line eqeqeq
      const currentCategory = this.news_categories.find(
        (category) => category.id == this.currentCategory,
      );
      if (currentCategory !== undefined) {
        return currentCategory.name;
      }
      return '';
    },
    otherPosts() {
      // return this.news.slice(1);
      return this.news;
    },
    currentCategory() {
      return this.$route.query.category;
    },
  },
  watch: {
    current_page() {
      this.loadFeed(false);
    },
    '$route.query': {
      handler() {
        this.loadFeed();
      },
      immediate: true,
    },
  },
  async mounted() {
    this.news_categories = await this.getNewsCategories();
  },
  methods: {
    ...mapActions({
      getNewsCategories: 'news/getNewsCategories',
      newsItems: 'news/newsItems',
    }),
    async loadFeed() {
      this.loading = true;
      const offset = this.limit * (this.current_page - 1);

      const loadedNews = await this.newsItems({
        limit: this.limit,
        offset,
        search: this.$route.query.search,
        categoryId: this.currentCategory,
      });

      if (offset > 0) {
        for (let i = 0; i < loadedNews.news_items.length; i += 1) {
          this.news.push(loadedNews.news_items[i]);
        }
      } else {
        this.news = loadedNews.news_items;
      }

      this.total_items = loadedNews.total_items;
      this.show_load_more = this.total_items > this.news.length;
      this.loading = false;
    },
    setCategory(category) {
      this.$router
        .push({ query: { ...this.$route.query, category: category || undefined } })
        .catch(() => {});
    },
  },
};
</script>
