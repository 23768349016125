export const errorCallback = (error) => {
  if ([400, 403].includes(error.status)) {
    let message = '';
    if (typeof error.data === 'string') {
      message += `${error.data}<br>`;
    }
    if (error.data.title) {
      message += `${error.data.title}<br>`;
    }
    if (error.data.message) {
      message += `${error.data.message}<br>`;
    }
    if (error.data.issues) {
      message += error.data.issues.join('<br>');
    }
    if (message) {
      const title = message.split('<br>')[0];
      const html = message.split('<br>').slice(1).join('<br>');
      window.alertBox.fire({
        title,
        html,
      });
    }
  } else if (error.status === 401) {
    window.alertBox.fire({
      title: 'Unauthorised Access',
      html: 'Redirected user to login page.',
    })
  }
  return
};

export const successCallback = (operation) => {
  window.alertBox.fire({
    title: `${operation} Successful`,
    icon: 'success',
  });
};
