<template>
  <div class="relative" @click.stop="visible = !visible">
    <router-link
      class="flex flex-col justify-center p-4 text-center border-l border-b cursor-pointer h-full w-16 sm:w-32"
      :to="basketButtonUrl"
    >
      <div>
        <div
          class="inline-block relative pr-3 -mx-1"
          :class="{ 'md:mx-0 md:ml-3': branded_mode_user, 'sm:mx-0 sm:ml-3': !branded_mode_user }"
        >
          <div class="text-2xl">
            <i class="fal fa-shopping-basket"></i>
          </div>
          <div
            class="rounded-full bg-green-600 text-white absolute bottom-0 right-0 flex flex-col justify-around w-5 h-5 text-xs"
          >
            {{ basketQty }}
          </div>
        </div>
      </div>
      <div class="hidden sm:block text-sm capitalize mt-3">
        <div class="whitespace-no-wrap">
          <span v-if="basketQty > 0"> {{ basketQty }} </span>
          <span v-else> No </span>
          Items
        </div>
        <div
          class="whitespace-no-wrap"
          v-text="branded_mode_user ? 'in Basket' : `in ${buttonName}`"
        ></div>
      </div>
    </router-link>
    <info-popup
      v-if="on_home_page"
      :id="'dashboard:basket'"
      class="absolute right-0 top-0"
      :info_message="'If you return to the dashboard whilst in the process of creating a quote, your quote will be saved here allowing you to retrieve the quote at anytime.'"
      :next_id="'dashboard:latest_news_widget'"
    ></info-popup>
    <info-popup
      v-if="on_designer_page"
      :id="'designer_header:basket'"
      class="absolute right-0 top-0"
      :info_message="'Quickly jump back to your basket to see your saved products.'"
      :next_id="'designer_header:price_breakdown'"
    ></info-popup>
    <transition appear name="fade-in">
      <div v-show="visible">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 background-black_80 z-40"
          @click.stop="visible = false"
        ></div>
        <div class="relative">
          <div
            class="absolute inline-block shadow-xl bg-white right-0 top-0 w-64 p-6 text-xs overflow-x-auto z-50"
            style="max-height: 70vh"
          >
            <template v-if="basketQty > 0">
              <div v-for="item in basket" :key="item.x">
                <div v-if="item.description" class="flex flex-wrap justify-between mb-3">
                  <strong>{{ item.description }}</strong>
                  <strong
                    v-if="hasPricing"
                    v-html="formatCurrency(basketCurrency, item.price)"
                  ></strong>
                </div>
                <div v-else class="flex flex-wrap justify-between">
                  <strong>{{ item.code }}</strong>
                  <strong
                    v-if="hasPricing"
                    v-html="formatCurrency(basketCurrency, item.price)"
                  ></strong>
                </div>
                <div v-if="item.code" class="flex flex-wrap justify-between">
                  <strong class="pr-2">Code:</strong>
                  <span v-text="item.code"></span>
                </div>
                <div v-if="item.color" class="flex flex-wrap justify-between">
                  <strong class="pr-2">Colour:</strong>
                  <span v-text="item.color"></span>
                </div>
                <div v-if="contractHasSizing && item.size" class="flex flex-wrap justify-between">
                  <strong class="pr-2">Size:</strong>
                  <span v-text="item.size"></span>
                </div>
                <div class="flex flex-wrap justify-between">
                  <strong class="pr-2">Qty:</strong>
                  <span v-text="item.quantity"></span>
                </div>
                <hr class="my-3" />
              </div>
              <strong v-if="hasPricing" class="flex flex-wrap justify-between">
                <span>Total:</span> <span v-html="formatCurrency(basketCurrency, total)"></span>
              </strong>
              <router-link :to="basketLink" class="btn-action w-full mt-6">
                Show Full Basket
              </router-link>
            </template>
            <template v-else>
              <p>No Items in {{ buttonName }}</p>
              <router-link :to="newQuoteLink" type="button" class="btn-action w-full mt-6">
                Design a Product
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import currencyHelper from '@/mixins/currencyHelper';

export default {
  mixins: [currencyHelper],
  props: ['not_on_basket_page', 'on_home_page', 'on_designer_page'],
  data() {
    return {
      visible: false,
      hover: false,
    };
  },
  computed: {
    ...mapState('basket', {
      basket: 'basket',
      buttonName: 'buttonName',
    }),
    ...mapGetters({
      contractHasSizing: 'basket/contractHasSizing',
      total: 'basket/total',
      brandedBasketURL: 'branded/basketURL',
      brandedChooseProductURL: 'branded/chooseProductURL',
      basketCurrency: 'basket/getCurrency',
      basketQty: 'basket/basketQty',
    }),
    hasPricing() {
      return !this.branded_mode_user || this.$store.getters['style/hasBrandedPricingOn'];
    },
    basketButtonUrl() {
      if (this.basketQty > 0) {
        return this.basketLink;
      }
      return this.newQuoteLink;
    },
    newQuoteLink() {
      return this.branded_mode_user ? this.brandedChooseProductURL : '/new-quote';
    },
    basketLink() {
      return this.branded_mode_user ? this.brandedBasketURL : '/basket';
    },
  },
};
</script>
