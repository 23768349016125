<template>
  <modal-window :modal_open="open" title="Send Customer Quote" @close="$emit('close')">
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="font-medium">Subject</div>
        <input v-model="emailSubject" type="text" class="form-control" />
      </div>
      <div class="w-full mt-4 xs:mt-8">
        <div class="font-medium">Body Text</div>
        <textarea v-model="emailBodyText" class="form-control"></textarea>
      </div>
    </div>
    <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
      <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
        Cancel
      </button>
      <button class="btn-action btn-lg" @click="send()">Send</button>
    </div>
  </modal-window>
</template>

<script>
export default {
  props: ['job', 'customer', 'open'],
  data() {
    return {
      emailSubject: '',
      emailBodyText: '',
    };
  },
  mounted() {
    if (this.job.job.requoteEmailSubject !== null) {
      this.emailSubject = this.job.job.requoteEmailSubject;
    } else {
      this.emailSubject = this.customer.customer.requoteEmailSubject ?? '';
    }

    if (this.job.job.requoteEmailBodyText !== null) {
      this.emailBodyText = this.job.job.requoteEmailBodyText;
    } else {
      this.emailBodyText = this.customer.customer.requoteEmailBodyText ?? '';
    }
  },
  methods: {
    async send() {
      this.$emit('saving');

      await this.$store
        .dispatch('touch/updateJobRequote', {
          contractId: this.job.job.contractId,
          jobKey: this.job.job.jobKey,
          emailSubject: this.emailSubject,
          emailBodyText: this.emailBodyText,
        })
        .then(() => {
          this.$store
            .dispatch('touch/sendRequoteEmailToConsumer', {
              contractId: this.job.job.contractId,
              jobKey: this.job.job.jobKey,
            })
            .then(() => {
              this.$emit('saved');
              this.$emit('close');
            });
        });
    },
  },
};
</script>
