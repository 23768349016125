<template>
  <transition v-if="loading" name="fade-in">
    <div
      class="z-50 bg-white absolute bottom-0 left-0 right-0 top-0 p-10 flex flex-col justify-around items-center"
    >
      <div>
        <div class="loading-spinner"></div>
        <div class="flex items-center tracking-widest mt-3">Loading, please wait</div>
      </div>
    </div>
  </transition>
  <div
    v-else
    class="absolute top-0 left-0 right-0 bottom-0 z-50 md:flex md:flex-col md:justify-around md:bg-black_80 md:p-10"
    :class="{
      'bg-black_80 p-3 md:p-10 flex flex-col justify-around': !selectedGridId,
      'bg-white': selectedGridId,
    }"
    @click.prevent="closeIfSaved(false)"
  >
    <div
      class="bg-white m-auto overflow-auto max-w-full relative p-6 md:rounded-lg md:p-10 md:my-10"
      :style="gridPopupMaxWidth"
      :class="{ 'rounded-lg p-3 md:p-10 my-10': !selectedGridId }"
      style="z-index: 9999999999"
      @click.prevent.stop
    >
      <!--<a class="absolute top-0 right-0 p-3 md:p-6 cursor-pointer" @click.prevent="closeIfSaved()">-->
      <!--<i class="fal fa-times"></i>-->
      <!--</a>-->
      <div class="flex flex-col mb-6">
        <div class="flex">
          <div v-if="selectedGridId" class="text-lg flex flex-col justify-around">
            Edit {{ price.description }} {{ selectedGrid.description || "Grid" }}
          </div>
          <div v-else class="text-lg flex flex-col justify-around">
            Select {{ price.description }} Configuration
          </div>
        </div>
      </div>
      <edit-grid
        v-if="selectedGridId"
        :unsaved="unsaved"
        :grid="selectedGrid"
        :customer-id="customerId"
        :limits="limits"
        @new-prices="newPrices($event)"
      ></edit-grid>
      <table v-else class="table-pricing">
        <tr v-for="grid in grids.grids" :key="grid.financialGridConfigurationId">
          <td class="py-1 px-3">
            <span>{{ grid.description }}</span>
          </td>
          <td class="p-1 text-right">
            <button class="btn-action" @click="selectGrid(grid)">Edit Grid</button>
          </td>
        </tr>
      </table>
      <div class="flex justify-between mt-6">
        <div>
          <span v-if="unsaved" class="text-red-600">You have unsaved changes</span>
        </div>
        <div>
          <button class="btn mr-2" @click.prevent="closeIfSaved()">Close</button>
          <button
            v-if="selectedGridId && grids.grids.length !== 1"
            class="btn mr-2"
            @click.prevent="backToSelect"
          >
            Back
          </button>
          <button v-if="unsaved" class="btn-action mr-2" @click="discardChanges()">
            Discard Changes
          </button>
          <button v-if="unsaved" class="btn-action" @click="save()">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import gridHelper from '@/mixins/gridHelper';
import EditGrid from '@/components/shared/priceLines/Grid/EditGrid.vue';

export default {
  components: {
    'edit-grid': EditGrid,
  },
  mixins: [gridHelper],
  props: ['price', 'datasetId', 'customerId', 'isNewBasePrice'],
  data() {
    return {
      unsaved: false,
      grids: null,
      selectedGridId: null,
      updatedPrices: null,
      loading: true,
    };
  },
  computed: {
    gridPopupMaxWidth() {
      if (this.selectedGridId !== null) {
        return {
          maxWidth: '100%',
        };
      }
      return {
        maxWidth: '600px',
      };
    },
    originalPrices() {
      return this.getMergedCellsFromGrid(this.selectedGrid);
    },
    changedPrices() {
      if (!this.updatedPrices || !this.selectedGridId) {
        return [];
      }

      return this.updatedPrices.filter(
        (price) => Number(this.originalPrice(price.width, price.height)) !== Number(price.value),
      );
    },
    limits() {
      return {
        height: {
          min: this.grids.heightMinimum,
          max: this.grids.heightMaximum,
          steps: this.grids.heightSteps,
        },
        width: {
          min: this.grids.widthMinimum,
          max: this.grids.widthMaximum,
          steps: this.grids.widthSteps,
        },
      };
    },
    selectedGrid() {
      return this.grids.grids.find(
        (grid) => grid.financialGridConfigurationId === this.selectedGridId,
      );
    },
  },
  async mounted() {
    this.loadGrids();
  },
  methods: {
    closeIfSaved(prompt = true) {
      if (!this.unsaved) {
        this.$emit('close');
        return;
      }

      if (prompt) {
        this.alertBox()
          .fire({
            title: 'You will lose unsaved changes, are you sure?',
            text: 'Your edits on this grid will be lost',
            showCancelButton: true,
          })
          .then(async (response) => {
            if (response.isConfirmed) {
              this.$emit('close');
            }
          });
      }
    },
    async loadGrids() {
      try {
        if (this.isNewBasePrice) {
          this.grids = await window.touch.staffGetBasePriceGrid(this.datasetId, this.price.id);
        } else {
          this.grids = await this.$store.dispatch('touch/vendorGetThisGridAnomaly', {
            id: this.price.id,
            customerId: this.customerId,
            datasetId: this.touch_business_user ? this.datasetId : undefined,
          });
        }

        this.grids.grids.sort((a, b) =>
          a.description.localeCompare(b.description, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        );
        if (this.grids.grids.length === 1) {
          this.selectedGridId = this.grids.grids[0].financialGridConfigurationId;
        }
      } catch (error) {
        Sentry.captureEvent({
          message: 'Failed to load grid.',
        });
        this.$emit('close');
        this.alertBox().fire({
          title: 'Failed to load Grid',
          icon: 'error',
        });
      }
      this.loading = false;
    },
    backToSelect() {
      if (!this.unsaved) {
        this.$emit('close');
        return;
      }

      this.alertBox()
        .fire({
          title: 'You will lose unsaved changes, are you sure?',
          text: 'Your edits on this grid will be lost',
          showCancelButton: true,
        })
        .then(async (response) => {
          if (response.isConfirmed) {
            this.unsaved = false;
            this.selectedGridId = null;
          }
        });
    },
    originalPrice(width, height) {
      return Number(
        this.originalPrices.find((price) => price.width === width && price.height === height).value,
      );
    },
    newPrices(prices) {
      this.unsaved = true;
      this.updatedPrices = prices;
    },
    selectGrid(grid) {
      this.loading = true;
      this.selectedGridId = grid.financialGridConfigurationId;
      this.loading = false;
    },
    discardChanges() {
      this.alertBox()
        .fire({
          title: 'You will lose unsaved changes, are you sure?',
          text: 'Your edits on this grid will be lost',
          showCancelButton: true,
        })
        .then(async (response) => {
          if (response.isConfirmed) {
            const selectedGrid = this.selectedGridId;
            this.selectedGridId = null;
            this.unsaved = false;
            this.loadGrids();
            this.$nextTick(() => {
              this.selectedGridId = selectedGrid;
            });
          }
        });
    },
    async save() {
      this.alertBox().fire({
        title: 'Saving',
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      if (this.isNewBasePrice) {
        window.touch
          .staffUpdateBasePriceGrid(
            this.datasetId,
            this.price.id,
            this.selectedGridId,
            this.updatedPrices.map((price) => ({
              Id: price.id,
              Value: price.value,
              Width: price.width,
              Height: price.height,
            })),
          )
          .then(() => {
            this.loadGrids();
            this.unsaved = false;
            this.alertBox().fire({
              title: 'Grid Saved',
              icon: 'success',
            });
          })
          .catch(() => {
            this.alertBox().fire({
              title: 'Failed to Save Grid',
              icon: 'error',
            });
          });
      } else {
        this.$store
          .dispatch('touch/vendorUpdateThisGridAnomaly', {
            OverrideCells: this.changedPrices,
            Id: this.price.id,
            FinancialGridConfigurationId: this.selectedGridId,
          })
          .then(() => {
            this.loadGrids();
            this.unsaved = false;
            this.alertBox().fire({
              title: 'Grid Saved',
              icon: 'success',
            });
          })
          .catch(() => {
            this.alertBox().fire({
              title: 'Failed to Save Grid',
              icon: 'error',
            });
          });
      }
    },
  },
};
</script>

<style scoped></style>
