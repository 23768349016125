export default {
  namespaced: true,
  isMeasurementsOn: false,
  state: {
    slug: null,
    hideIntroPopUp: true,
    dateSinceStoreCleared: new Date(),
    showDesignNames: undefined
  },
  mutations: {
    setShowDesignNames (state, showDesignNames) {
      state.showDesignNames = showDesignNames;
    },
    setSlugAndKey: function setSlugAndKeyd(state, { slug }) {
      state.slug = slug;
    },
    setIsMeasurementsOn: function setIsMeasurementsOn(state, isMeasurementsOn) {
      state.isMeasurementsOn = isMeasurementsOn;
    },
    setHideIntroPopUp(state, hideIntroPopUp) {
      state.hideIntroPopUp = hideIntroPopUp;
    },
  },
  actions: {
    async getAnomalies(context) {
      return window.touch.getAnomalies(context.rootState.style.customer.datasetIds[0]);
    },
    async updateAnomalies(context, anomalies) {
      return window.touch.updateAnomalies(
        context.rootState.style.customer.datasetIds[0],
        anomalies,
      );
    },
    async setSlugAndKey(context, { slug }) {
      context.commit('setSlugAndKey', {
        slug,
      });
    },
    async setIsMeasurementsOn(context, isMeasurementsOn) {
      context.commit('setIsMeasurementsOn', isMeasurementsOn);
    },
  },
  getters: {
    ready(state) {
      return state.slug !== null;
    },
    launchURL(state) {
      return `/launch/${state.slug}`;
    },
    thanksURL(state) {
      return `/${state.slug}/thanks`;
    },
    brandedItemLink(state) {
      return `/${state.slug}/design/:id`;
    },
    chooseProductURL(state) {
      return `/${state.slug}/choose-product`;
    },
    basketURL(state) {
      return `/${state.slug}/basket`;
    },
  },
};
