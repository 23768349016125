<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Dealer Network Enquiries', '/dealer-network'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center">
        Dealer Network Enquiries
        <HelpPoint class="ml-2" freshdesk-article-id="101000455614" />
      </div>
    </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for order reference..." />
    </portal>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <router-link to="/dealer-network-assigned" class="btn-action"> View Assigned </router-link>
      </div>
    </template>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10"
    >
      <div
        v-if="orders.length > 0"
        key="orders_list"
        class="bg-white p-3 md:p-10 pb-6 border"
        data-index="1"
      >
        <table class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th>Created</th>
              <th>Number</th>
              <th>Consumer Details</th>
              <th>Postcode</th>
              <th></th>
              <th class="w-16"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders" :key="order.id">
              <td>
                <div class="td-title w-24">Created</div>
                <div class="td-content">{{ formatDate(order.date) }}</div>
              </td>
              <td>
                <div class="td-title w-24">Number:</div>
                <div class="td-content">{{ order.id }}</div>
              </td>
              <template v-if="order.consumer">
                <td>
                  <div class="td-title w-24">Consumer:</div>
                  <div class="td-content truncate w-full">{{ formatConsumer(order.consumer) }}</div>
                </td>
                <td>
                  <div class="td-title w-24">Postcode:</div>
                  <div class="td-content">{{ order.consumer.addressCode }}</div>
                </td>
              </template>
              <td v-else colspan="2">
                <div class="td-title w-24">Consumer:</div>
                <div class="td-content">No Consumer Record Found</div>
              </td>
              <td class="text-red-600 text-bold">
                <span v-if="order.isEnquiryCompletelyRefused">Enquiry Rejected</span>
                <span v-else-if="order.isEnquiryOverdue">Enquiry Overdue</span>
                <span v-else></span>
              </td>
              <td class="td-action-buttons">
                <router-link
                  v-if="!order.isEnquiryCompletelyRefused && !order.isEnquiryOverdue"
                  :to="`/dealer-network/assign/${order.id}`"
                  class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600 inline-block"
                >
                  Assign
                </router-link>
                <router-link
                  v-else
                  :to="`/dealer-network/assign/${order.id}`"
                  class="px-2 py-1 text-2xs rounded border cursor-pointer hover:text-white hover:bg-red-600 inline-block text-red-600 border-red-600"
                >
                  Reassign
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-3">
          <pagination :total_items="total_items" :limit="limit" @load-feed="loadFeed"></pagination>
        </div>
      </div>
      <div v-else key="none-found" class="bg-white p-3 md:p-10 border">
        <div v-if="$route.query.search" class="flex justify-between">
          <div>No Enquiries match "{{ $route.query.search }}"</div>
          <div>
            <a class="btn-action" @click="$router.push({ query: undefined })"> Clear Search </a>
          </div>
        </div>
        <div v-else>No Enquiries to show</div>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import HelpPoint from '@/components/shared/HelpPoint.vue';
import dateHelper from '@/mixins/dateHelper';
import Pagination from '@/components/shared/Pagination.vue';
import currencyHelper from '@/mixins/currencyHelper';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    pagination: Pagination,
    HelpPoint,
    Breadcrumbs,
    SearchBox,
  },
  mixins: [dateHelper, currencyHelper],
  data() {
    return {
      orders: [],
      loading: true,
      limit: 10,
      total_items: 0,
    };
  },
  watch: {
    '$route.query': {
      handler() {
        this.loadFeed();
      },
      immediate: true,
    },
  },
  methods: {
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await this.$store.dispatch('touch/loadDealerNetworkList', {
        limit: this.limit,
        statusId: [
          this.enums.enquiryStatus.NONE,
          this.enums.enquiryStatus.UNASSIGNED,
          this.enums.enquiryStatus.REFUSED,
          this.enums.enquiryStatus.Expired,
        ],
        offset,
        search: this.$route.query.search,
      });
      this.orders = response.orders;
      this.total_items = response.total_items;
      this.loading = false;
    },
  },
};
</script>
