<template>
  <div>
    <div class="text-center mb-4 bg-white py-4 sm:hidden">
      <div class="text-xl sm:text-4xl mb-2 sm:font-medium">Forgot your password?</div>
      <div class="sm:text-xl">Enter your email address to reset it.</div>
    </div>
    <form class="login-form">
      <div class="text-center mb-4 bg-white py-0 hidden sm:block">
        <div class="text-4xl mb-2 font-medium">Forgot your password?</div>
        <div class="text-xl">Enter your email address to reset it.</div>
      </div>
      <error v-if="login_error" :message="login_error"></error>
      <div class="mb-4">
        <label for="email" class="block w-full">Email address</label>
        <input
          id="forgot_email"
          v-model="email"
          type="email"
          class="border border-solid border-gray-400 rounded block w-full p-2"
          autocomplete="email"
          autofocus
        />
      </div>
      <div class="mb-4 text-right">
        <div
          class="text-gray-600 text-xs hover:text-gray-800 cursor-pointer"
          @click.prevent="$emit('close')"
        >
          Return to login
        </div>
      </div>
      <button class="btn-primary btn-lg w-full" @click.prevent="forgotPassword()">
        Reset Password
      </button>
      <div class="text-center mt-10">
        <a
          :href="$t('https://store.bm-touch.co.uk')"
          target="_blank"
          class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
        >
          <div class="p-1">
            <img src="../../assets/images/touch-logo.svg" width="75px" />
          </div>
          <div class="">
            <template v-if="touch_portal_installation">
              <img src="/images/touch-portal-icon.png" style="max-height: 27px" />
            </template>
          </div>
          <div class="p-1 text-xs">By BM Group</div>
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      login_error: null,
    };
  },
  methods: {
    ...mapActions({
      requestPasswordReset: 'auth/requestPasswordReset',
    }),
    async forgotPassword() {
      this.login_error = false;
      return this.requestPasswordReset(this.email).then((resp) => {
        if (resp.error) {
          this.login_error = resp.data.response.data.message;
          return;
        }
        this.$emit('close');
        this.alertBox().fire({
          title: 'Password Reset Request Sent',
          icon: 'success',
          html: `<div>${resp.data.message}</div>`,
        });
      });
    },
  },
};
</script>
