<template>
  <aside
    class="bg-white shadow-xl sm:w-1/3 overflow-y-auto scrolling-touch sm:p-5 xl:p-10 sm:flex-grow absolute left-0 right-0 bottom-0 sm:relative z-10"
  >
    <div class="xs:p-5 md:p-10 m-auto">
      <div class="hidden sm:block">
        <div class="font-medium">{{ breakdown }} Summary</div>

        <div class="mb-4">
          Basket showing {{ basketQty }} item<span v-if="basketQty > 1">s</span>
        </div>

        <div class="flex border-t border-gray-300 p-6 mt-6">
          <div>Subtotal</div>
          <div class="ml-auto" v-html="formatCurrency(basketCurrency, subtotal)"></div>
        </div>

        <div class="flex border-t border-gray-300 p-6">
          <div>VAT</div>
          <div class="ml-auto" v-html="formatCurrency(basketCurrency, vat)"></div>
        </div>

        <div class="flex border-t border-b border-gray-300 p-6 font-medium">
          <div>Total</div>
          <div class="ml-auto" v-html="formatCurrency(basketCurrency, total)"></div>
        </div>
      </div>
      <div class="m-3 sm:m-0 sm:mb-6">
        <div class="mt-6 mb-2">Reference</div>
        <input
          v-model="reference"
          type="text"
          class="form-control"
          :class="{ 'border-red-600': referenceMissing }"
        />
        <div v-if="referenceMissing" class="text-red-600 mt-3">Please enter a reference</div>
      </div>
      <div class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col">
        <a v-if="!isOrder" class="btn-action btn-lg w-full" @click.prevent="placeOrder()">
          Log Order With {{ style.fabricator_name }}
        </a>
        <a v-else class="btn-action btn-lg w-full" @click.prevent="placeOrder()">
          <span
            v-if="
              $store.state.basket.isPaymentGateway &&
                [enums.PaymentStatus.AWAITING, enums.PaymentStatus.FAILED].includes(
                  contractData.paymentStatus,
                )
            "
          >
            <span v-if="touch_portal_installation">Make Payment</span>
            <span v-else>Place Order as Pending Payment</span>
          </span>
          <span v-else> Charge to Account With {{ style.fabricator_name }} </span>
        </a>
      </div>
      <div class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col">
        <a class="btn btn-lg w-full" @click.prevent="cancelOrder()"> Cancel </a>
      </div>

      <!--<img src="/images/card-images.png" class="w-64 mt-3 mx-auto">-->
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import currencyHelper from '@/mixins/currencyHelper';

export default {
  mixins: [currencyHelper],
  props: {
    breakdown: String,
    customer: Object
  },
  data() {
    return {
      referenceMissing: false,
    };
  },
  computed: {
    reference: {
      get() {
        return this.$store.state.basket.reference;
      },
      set(value) {
        this.$store.commit('basket/setReference', value);
      },
    },
    ...mapState('basket', {
      basketItems: 'basket',
      isOrder: 'isOrder',
      contractData: 'contractData',
    }),
    ...mapGetters({
      total: 'basket/total',
      vat: 'basket/vat',
      subtotal: 'basket/subtotal',
      basketCurrency: 'basket/getCurrency',
      basketQty: 'basket/basketQty',
    }),
  },
  methods: {
    cancelOrder() {
      this.$emit('cancel-order');
    },
    placeOrder() {
      if (this.reference.trim().length <= 3) {
        this.referenceMissing = true;
        return;
      }
      this.referenceMissing = false;
      
      if (this.customer?.financial?.paymentWithOrder && !this.touch_portal_installation) {
        this.alertBox()
          .fire({
            title: "Order will be marked as 'Placed (Pending Payment)'",
            text: 'Customer will need to login and pay for the order.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('place-order');
            }
          });
      } else {
        this.$emit('place-order');
      }
    },
  },
};
</script>
