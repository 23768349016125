<template>
  <div v-if="!loading">
    <div
      v-if="privacyPolicyDocument !== undefined && termsAndConditionsDocument !== undefined"
      class="bg-white p-4 xs:p-10 border"
    >
      <div class="mb-3">
        <strong class="text-base">
          Deploy {{ setup }}
          <info-popup
            :id="'vendor_settings_website_details:deploy'"
            class=""
            :info_message="'You can personalise your website address to match your company name. Once you are happy, make sure you click to Save Changes and you can then pass this URL to your website developer who can then add links to your current website to link to your new designer.'"
            :next_id="'vendor_settings_website_details:pricing_display'"
          ></info-popup>
        </strong>
      </div>
      <div class="flex flex-col justify-around mb-10">
        Follow the instructions below to deploy {{ setup }}.
      </div>
      <div class="flex flex-col mb-10">
        <strong class="text-base mb-3">
          {{ setup }} Address
        </strong>
        <div class="flex flex-col justify-around mb-3">
          Personalise the URL/website address for your {{ setup }}. This is usually your company
          name.
        </div>
        <div class="">
          <input
            v-model="alias"
            :disabled="!is_admin_user || demo_mode"
            type="text"
            class="form-control"
          />
        </div>
      </div>
      <div class="mb-3">
        <strong class="text-base"> How to deploy </strong>
      </div>
      <div class="mb-3">1) Copy the link using the blue button below.</div>
      <div class="mb-3">
        2) Add this link onto your website. We recommend linking this up to a button or banner. You
        can also add a link to the navigation of your website.
      </div>
      <div class="mb-3">
        3) Once the links have been added, your {{ setup }} will now be live on your website and any
        updates you make to your settings will be automatically applied to your live {{ setup }}.
      </div>
      <div class="mb-3">
        Tip: This link can also be posted directly to social media to generate further enquiries.
      </div>
      <div class="bg-gray-200 flex flex-col sm:flex-row justify-between">
        <div class="p-5 sm:text-lg overflow-y-auto">
          <strong>
            {{ launch_url }}
            <input
              id="designer_url"
              type="text"
              readonly
              :value="launch_url"
              style="position: absolute; top: -2000px; left: -2000px"
            />
          </strong>
        </div>
        <div class="px-5 pb-5 sm:p-5">
          <button
            class="w-full sm:w-auto"
            :class="{
              'btn-action': copy_url_button_clicked,
              'btn-primary': !copy_url_button_clicked,
            }"
            @click.prevent="copyURL()"
          >
            Copy URL
            <transition name="fade-in" mode="out-in">
              <i v-if="!copy_url_button_clicked" class="fal fa-copy ml-3"></i>
              <i v-else class="fal fa-check ml-3"></i>
            </transition>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="bg-white p-4 xs:p-10 rounded-lg text-red-600">
      Please upload your GDPR documents, you will then be able to deploy your designer.
    </div>
  </div>
</template>

<script>
import validate from 'validate.js';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['bus', 'prop_settings', 'customerId', 'setup'],
  data() {
    return {
      loading: false,
      copy_url_button_clicked: false,
      alias: '',
      priceDisplayMode: 0,
      privacyPolicyDocument: false,
      termsAndConditionsDocument: false,
    };
  },
  computed: {
    ...mapGetters({
      customer: 'user/getCustomer',
    }),
    launch_url() {
      return `${window.location.origin.replace('.business.', '.portal.').replace('.admin.', '.portal.')}/launch/${this.customer.eCommerceIntegration.alias}`;
    },
  },
  watch: {
    alias: {
      handler() {
        const unsaved =
          JSON.stringify(this.alias) !== JSON.stringify(this.customer.eCommerceIntegration.alias);
        this.bus.$emit('update', {
          name: 'Settings',
          isSaved: !unsaved,
          isValid: true,
          errors: [],
        });
      },
      deep: true,
    },
  },
  async mounted() {
    this.bus.$emit('register', {
      name: 'Settings',
      isSaved: true,
      isValid: true,
      errors: [],
    });

    await Promise.all([this.getPrivacyPolicyDocument(), this.getTermsAndConditionsDocument()]).then(
      ([privacyPolicyDocument, termsAndConditionsDocument]) => {
        this.privacyPolicyDocument = privacyPolicyDocument;
        this.termsAndConditionsDocument = termsAndConditionsDocument;
        this.priceDisplayMode = this.customer.eCommerceIntegration.priceDisplayMode;
        this.alias = this.customer.eCommerceIntegration.alias;
        this.loading = false;
      },
    );
    this.bus.$on('save', () => {
      this.saveSettings();
    });
  },
  methods: {
    ...mapActions({
      getTermsAndConditionsDocument: 'user/getTermsAndConditionsDocument',
      getPrivacyPolicyDocument: 'user/getPrivacyPolicyDocument',
      uploadDocumentForm: 'marketing/uploadDocumentForm',
      customerUploadDocumentWithUrl: 'user/customerUploadDocumentWithUrl',
      customerUpdateCommerceIntegrationSettings: 'user/customerUpdateCommerceIntegrationSettings',
    }),
    async saveSettings() {
      this.loading = true;
      const options = {
        alias: this.alias,
        priceDisplayMode: this.priceDisplayMode,
      };

      const validationErrors = validate(
        {
          'Personalised Web Address': this.alias,
        },
        {
          'Personalised Web Address': {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        this.loading = false;
        return;
      }

      await this.customerUpdateCommerceIntegrationSettings({
        eCommerceIntegrationSettings: options,
        customerId: this.customerId,
      });

      this.loading = true;
      this.bus.$emit('saved', {
        name: 'Settings',
        isSaved: true,
        isValid: true,
        errors: [],
      });

      this.customer.eCommerceIntegration.alias = this.alias;
      this.loading = false;
    },
    copyURL() {
      const input = document.getElementById('designer_url');

      if (this.is_iOS) {
        const range = document.createRange();
        range.selectNodeContents(input);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        input.setSelectionRange(0, 999999);
      } else {
        input.select();
      }

      document.execCommand('copy');
      this.copy_url_button_clicked = true;
      setTimeout(() => {
        this.copy_url_button_clicked = false;
      }, 2000);
    },
  },
};
</script>
