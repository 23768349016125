var render = function render(){var _vm=this,_c=_vm._self._c;return _c('touch-layout',{scopedSlots:_vm._u([{key:"header_page_breadcrumb",fn:function(){return [_c('Breadcrumbs',{attrs:{"routes":[
        ['Dashboard', '/'],
        ['Customer Management', '/customer'],
        [`Customer (${_vm.customer.company.name})`, `/customer/${_vm.customer.customerId}`],
        ['Processing Options'],
      ]}})]},proxy:true},{key:"header_page_title",fn:function(){return [_vm._v(" Processing Options ")]},proxy:true}])},[_c('Menu'),_c('div',{staticClass:"bg-gray-200 w-full h-full overflow-y-auto scrolling-touch",attrs:{"id":"processing-options-scroll"}},[_c('loading',{staticClass:"min-h-full",attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"grid grid-cols-3 gap-5 p-5"},[_c('div',[_c('div',{staticClass:"bg-white p-5 border border-gray overflow-y-scroll h-screen",attrs:{"id":"processing-options-groups-scroll"}},[_c('div',{staticClass:"mb-5 flex items-center gap-5"},[_c('span',{staticClass:"text-lg"},[_vm._v("Option Groups")]),(_vm.groups.length > 0)?_c('div',{staticClass:"ml-auto bg-gray-200 text-center rounded-lg cursor-pointer",style:({ height: '40px', width: '40px' }),on:{"click":function($event){(_vm.isSearchGroups = !_vm.isSearchGroups),
                  (_vm.searchGroups = ''),
                  _vm.$nextTick(() => _vm.$refs.searchGroups.focus())}}},[(_vm.isSearchGroups)?_c('i',{staticClass:"fa fa-times",style:({ 'line-height': '40px' })}):_c('i',{staticClass:"fa fa-search",style:({ 'line-height': '40px' })})]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearchGroups),expression:"isSearchGroups"}],staticClass:"mb-5"},[_c('div',{staticClass:"flex border"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchGroups),expression:"searchGroups"}],ref:"searchGroups",staticClass:"p-2 h-10 w-full border w-full focus:outline-none border-0",attrs:{"type":"text","placeholder":"Search Groups..."},domProps:{"value":(_vm.searchGroups)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchGroups=$event.target.value},function($event){(_vm.isSearchHeadings = false),
                    (_vm.searchHeadings = ''),
                    (_vm.headings = []),
                    (_vm.isSearchItems = false),
                    (_vm.searchItems = ''),
                    (_vm.groupId = undefined),
                    (_vm.headingId = undefined),
                    (_vm.items = []),
                    (_vm.originalItems = [])}]}}),(_vm.searchGroups.length > 0)?_c('div',{staticClass:"w-10 h-10 border border-l-0 text-center flex flex-col justify-around border-0 border-l",on:{"click":function($event){_vm.searchGroups = ''}}},[_c('i',{staticClass:"fal fa-times cursor-pointer"})]):_vm._e()])]),(_vm.groupId)?_c('div',{staticClass:"mb-5 flex gap-3"},[_c('span',{staticClass:"text-sm opacity-50"},[_vm._v("Selected: ")]),_c('strong',[_vm._v(_vm._s(_vm.groups.filter((x) => x.id === _vm.groupId)[0].name))])]):_vm._e(),_c('div',{staticClass:"flex flex-col gap-2"},_vm._l((_vm.filteredGroups),function(group){return _c('div',{key:group.id,staticClass:"bg-gray-200 rounded lg p-2 cursor-pointer hover:bg-green-300 hover:text-white",class:{ 'bg-green-600 text-white': group.id === _vm.groupId },on:{"click":function($event){return _vm.validateOptionItems('groupId', group.id)}}},[_c('span',[_vm._v(_vm._s(group.name))])])}),0)])]),_c('div',[_c('div',{staticClass:"bg-white p-5 border border-gray overflow-y-scroll h-screen",attrs:{"id":"processing-options-headings-scroll"}},[_c('div',{staticClass:"mb-5 flex items-center gap-5"},[_c('span',{staticClass:"text-lg"},[_vm._v("Option Headings")]),(_vm.headings.length > 0)?_c('div',{staticClass:"ml-auto bg-gray-200 text-center rounded-lg cursor-pointer",style:({ height: '40px', width: '40px' }),on:{"click":function($event){(_vm.isSearchHeadings = !_vm.isSearchHeadings),
                  (_vm.searchHeadings = ''),
                  _vm.$nextTick(() => _vm.$refs.searchHeadings.focus())}}},[(_vm.isSearchHeadings)?_c('i',{staticClass:"fa fa-times",style:({ 'line-height': '40px' })}):_c('i',{staticClass:"fa fa-search",style:({ 'line-height': '40px' })})]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearchHeadings),expression:"isSearchHeadings"}],staticClass:"mb-5"},[_c('div',{staticClass:"flex border"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchHeadings),expression:"searchHeadings"}],ref:"searchHeadings",staticClass:"p-2 h-10 w-full border w-full focus:outline-none border-0",attrs:{"type":"text","placeholder":"Search Headings..."},domProps:{"value":(_vm.searchHeadings)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchHeadings=$event.target.value},function($event){(_vm.isSearchItems = false),
                    (_vm.searchItems = ''),
                    (_vm.headingId = undefined),
                    (_vm.items = []),
                    (_vm.originalItems = [])}]}}),(_vm.searchHeadings.length > 0)?_c('div',{staticClass:"w-10 h-10 border border-l-0 text-center flex flex-col justify-around border-0 border-l",on:{"click":function($event){_vm.searchHeadings = ''}}},[_c('i',{staticClass:"fal fa-times cursor-pointer"})]):_vm._e()])]),(_vm.headingId)?_c('div',{staticClass:"mb-5 flex gap-3"},[_c('span',{staticClass:"text-sm opacity-50"},[_vm._v("Selected: ")]),_c('strong',[_vm._v(_vm._s(_vm.headings.filter((x) => x.uniqueValue === _vm.headingId)[0].name))])]):_vm._e(),_c('div',{staticClass:"flex flex-col gap-2"},_vm._l((_vm.filteredHeadings),function(heading){return _c('div',{key:heading.uniqueValue,staticClass:"bg-gray-200 rounded lg p-2 cursor-pointer hover:bg-green-300 hover:text-white",class:{ 'bg-green-600 text-white': heading.uniqueValue === _vm.headingId },on:{"click":function($event){return _vm.validateOptionItems('headingId', heading.uniqueValue)}}},[_c('span',[_vm._v(_vm._s(heading.name))])])}),0)])]),_c('div',[_c('div',{staticClass:"bg-white p-5 border border-gray overflow-y-scroll h-screen"},[_c('div',{staticClass:"mb-5 flex items-center gap-5"},[_c('span',{staticClass:"text-lg"},[_vm._v("Option Items")]),(_vm.items.length > 0)?_c('div',{staticClass:"ml-auto bg-gray-200 text-center rounded-lg cursor-pointer",style:({ height: '40px', width: '40px' }),on:{"click":function($event){(_vm.isSearchItems = !_vm.isSearchItems),
                  (_vm.searchItems = ''),
                  _vm.$nextTick(() => _vm.$refs.searchItems.focus())}}},[(_vm.isSearchItems)?_c('i',{staticClass:"fa fa-times",style:({ 'line-height': '40px' })}):_c('i',{staticClass:"fa fa-search",style:({ 'line-height': '40px' })})]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearchItems),expression:"isSearchItems"}],staticClass:"mb-5"},[_c('div',{staticClass:"flex border"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchItems),expression:"searchItems"}],ref:"searchItems",staticClass:"p-2 h-10 w-full border w-full focus:outline-none border-0",attrs:{"type":"text","placeholder":"Search Items..."},domProps:{"value":(_vm.searchItems)},on:{"input":function($event){if($event.target.composing)return;_vm.searchItems=$event.target.value}}}),(_vm.searchItems.length > 0)?_c('div',{staticClass:"w-10 h-10 border border-l-0 text-center flex flex-col justify-around border-0 border-l",on:{"click":function($event){_vm.searchItems = ''}}},[_c('i',{staticClass:"fal fa-times cursor-pointer"})]):_vm._e()])]),_c('div',{staticClass:"flex flex-col gap-2"},_vm._l((_vm.filteredItems),function(item){return _c('div',{key:item.id,staticClass:"flex items-center bg-gray-200 rounded lg p-2"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"ml-auto flex items-center gap-5 cursor-pointer",on:{"click":function($event){$event.preventDefault();item.disabled = !item.disabled}}},[_c('span',{staticClass:"text-xs opacity-50"},[_vm._v(_vm._s(item.disabled ? 'Disabled' : 'Enabled'))]),_c('div',{staticClass:"relative"},[_c('div',{staticClass:"w-6 h-3 bg-gray-400 rounded-full shadow-inner"}),_c('div',{staticClass:"switch-toggle",class:{ _active: !item.disabled }})])])])}),0)])])])]),_c('div',{staticClass:"sticky bottom-0 bg-gray-200 text-right p-5 bg-opacity-75"},[_c('button',{staticClass:"btn-action btn-lg",class:{
          'opacity-50 pointer-events-none bg-gray-500 border-gray-500':
            JSON.stringify(_vm.items) === JSON.stringify(_vm.originalItems),
        },on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save Changes ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }