export default class TouchJob {
  constructor(job) {
    this.job = job;
  }

  currency() {
    return this.job.customerCurrency;
  }

  isFit() {
    return this.job.fittingType === window.enum.fittingType.SUPPLY_AND_FIT;
  }

  // eslint-disable-next-line class-methods-use-this
  wasRequotedAtJobLevel() {
    return !this.wasRequotedAtItemLevel();
  }

  wasRequotedAtItemLevel() {
    return this.job.items.find((item) => item.override) !== undefined;
  }

  itemHasExtras(item) {
    return this.job.items.some((extraItem) => extraItem.parentItemKey === item.itemKey);
  }

  totalPriceForItem(item) {
    if (this.wasRequotedAtJobLevel()) {
      return 0;
    }

    return this.job.items
      .filter((extraItem) => extraItem.parentItemKey === item.itemKey)
      .reduce((total, extraItem) => total + extraItem.price, item.price);
  }

  itemPriceForItem(item) {
    // eslint-disable-line
    return item.price;
  }

  taxRate() {
    return this.job.taxRate / 100;
  }

  itemCost() {
    return this.job.priceBeforeTax;
  }

  deliveryCost() {
    return this.job.priceDeliveryCharge;
  }

  fittingPrice() {
    return this.job.fittingPrice;
  }

  surveyPrice() {
    return this.job.surveyPrice;
  }

  markupItemCost(markup) {
    return (this.itemCost() * (markup / 100 + 1)).toFixed(2);
  }

  fittingCost() {
    return this.job.items
      .filter((item) => item.fittingItem)
      .reduce((total, item) => total + item.calculatedPrice, 0);
  }

  taxCost() {
    return this.job.tax;
  }

  totalCost() {
    return this.job.priceIncludingTax;
  }

  totalCostExcVAT() {
    return this.job.priceBeforeTax;
  }
}
