<template>
  <div v-if="$store.state.style.fabricator">
    <editable-company-details></editable-company-details>
    <AccountSettings v-if="false"></AccountSettings>
    <FittingAndSurvey v-if="!isLeadGen && !$store.state.auth.accountSettings.hideFitting"></FittingAndSurvey>
    <tax-rates v-if="!isLeadGen"></tax-rates>
    <manage-currencies v-if="!isLeadGen"></manage-currencies>
    <WorkingDays v-if="!isLeadGen" />
    <Holidays v-if="!isLeadGen" />
  </div>
</template>

<script>
import AccountSettings from '@/components/business/manageTouchAccount/AccountSettings.vue';
import FittingAndSurvey from '@/components/business/manageTouchAccount/FittingAndSurvey.vue';
import Holidays from '@/components/business/manageTouchAccount/holiday/Holidays.vue';
import TaxRates from '@/components/business/manageTouchAccount/taxRate/List.vue';
import Currencies from '@/components/business/manageTouchAccount/currency/List.vue';
import WorkingDays from '@/components/business/manageTouchAccount/workingDays/WorkingDays.vue';
import EditableCompanyDetails from '@/components/business/manageTouchAccount/partial/EditableCompanyDetails.vue';

export default {
  components: {
    AccountSettings,
    FittingAndSurvey,
    'manage-currencies': Currencies,
    'tax-rates': TaxRates,
    'editable-company-details': EditableCompanyDetails,
    WorkingDays,
    Holidays,
  },
  computed: {
    isLeadGen() {
      return (
        this.$store.state.style?.fabricator?.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
  },
};
</script>
