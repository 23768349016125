var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"bg-white overflow-x-auto pl-3 md:pl-10"},[_c('div',{staticClass:"flex bg-white gap-10 border-t-4 border-white"},[_c('router-link',{attrs:{"to":"/product-setup/product-selections"}},[_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black",class:{
            'border-black': _vm.$route.name === 'product-selections',
            'border-white': _vm.$route.name !== 'product-selections',
          },domProps:{"textContent":_vm._s('Product Selections')}})]),_c('router-link',{attrs:{"to":"/product-setup/pricing"}},[(!_vm.isLeadGen)?_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black",class:{
            'border-black': _vm.$route.name === 'pricing',
            'border-white': _vm.$route.name !== 'pricing',
          },domProps:{"textContent":_vm._s('Pricing')}}):_vm._e()]),_c('router-link',{attrs:{"to":"/product-setup/parts"}},[(!_vm.isLeadGen)?_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black",class:{
            'border-black': _vm.$route.name === 'parts',
            'border-white': _vm.$route.name !== 'parts',
          },domProps:{"textContent":_vm._s('Parts')}}):_vm._e()]),_c('router-link',{attrs:{"to":"/product-setup/touch-connect-business"}},[(!_vm.isLeadGen && _vm.$store.state.auth.superuser)?_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black",class:{
            'border-black': _vm.$route.name === 'touch-connect-business',
            'border-white': _vm.$route.name !== 'touch-connect-business',
          },domProps:{"textContent":_vm._s('Touch Connect')}}):_vm._e()]),_c('router-link',{attrs:{"to":"/product-setup/discounts"}},[(!_vm.isLeadGen)?_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black",class:{
            'border-black': _vm.$route.name === 'discounts',
            'border-white': _vm.$route.name !== 'discounts',
          },domProps:{"textContent":_vm._s('Discounts')}}):_vm._e()]),(!_vm.isLeadGen && !_vm.$store.state.auth.accountSettings.hideFitting)?_c('router-link',{attrs:{"to":"/product-setup/extras"}},[(true)?_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black",class:{'border-black' : _vm.$route.name === 'extras', 'border-white' : _vm.$route.name !== 'extras'},domProps:{"textContent":_vm._s('Extras')}}):_vm._e()]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0",attrs:{"to":"/product-setup/option-lead-times"}},[_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 mx-3 hover:border-black text-sm",class:{
            'border-black': _vm.$route.name === 'option-lead-times',
            'border-white': _vm.$route.name !== 'option-lead-times',
          },domProps:{"textContent":_vm._s('Option Lead Times')}})]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }