<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Product Setup', '/product-setup'],
          ['Discounts'],
        ]"
      />
    </template>
    <template #header_page_title> Discounts </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <button class="btn-action" @click="add_discount_open = true">Add Discount</button>
      </div>
    </template>
    <Menu></Menu>
    <loading
      :loading="false"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden"
    >
      <Discounts
        :add_discount_open="add_discount_open"
        @close-add-discount-popup="add_discount_open = false"
      ></Discounts>
    </loading>
  </touch-layout>
</template>

<script>
import Discounts from '@/components/business/productSetup/Discounts.vue';
import Menu from '@/components/business/productSetup/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Menu,
    Discounts,
    Breadcrumbs,
  },
  data() {
    return {
      add_discount_open: false,
    };
  },
};
</script>
