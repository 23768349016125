import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import moment from 'moment';

const axios = require('axios').default;

export default class Touch {
  CONTRACT_TYPE_ENQUIRY = 1;

  CONTRACT_TYPE_QUOTE = 2;

  CONTRACT_TYPE_ORDER = 3;

  endpoint = undefined;

  token = undefined;

  tokenExpiry = undefined;

  installation = undefined;

  prefix = 'pearl';

  axios = axios;

  router = undefined;

  store = undefined;

  constructor(endpoint, imageBaseURL, router, store) {
    this.endpoint = endpoint;
    this.imageBaseURL = imageBaseURL;
    this.router = router;
    this.store = store;

    const urlSuffix = process.env.FQDN_URL ? process.env.FQDN_URL : 'bm-touch.co.uk';
    const apiSuffix = process.env.FQDN_API ? process.env.FQDN_API : 'bm-touch.co.uk';

    const regEx = new RegExp(
      `^(([^.]+)\\.)?(vendor|business|admin|portal)(\\.([^.]+))?\\.${urlSuffix.replace(/\./gi, '\\.')}$`,
    );

    const hostname =
      window.location.hostname === 'vendor.demo.bm-touch.co.uk'
        ? 'vendor.risky.bm-touch.co.uk'
        : window.location.hostname;
    const matches = hostname.match(regEx);

    if (matches) {
      const type = matches[3];
      // eslint-disable-next-line no-nested-ternary
      this.prefix = matches[2] ? matches[2] : type;
      const environment = matches[5];

      const domain = [this.prefix, 'server', environment].filter(Boolean).join('.');

      this.endpoint = `https://${domain}.${apiSuffix}/api/`;
      this.imageBaseURL = `https://${domain}.${apiSuffix}`;

      switch (type) {
        case 'vendor':
        case 'portal':
          window.VUE_APP_INSTALLATION_TYPE = 'portal';
          break;
        case 'business':
        case 'admin':
          window.VUE_APP_INSTALLATION_TYPE = 'business';
          break;
        default:
          throw new Error(`Unknown Type In URL: ${type}`);
      }
      window.VUE_APP_OVERRIDE = type;
    }

    if (this.endpoint === undefined) {
      throw new Error('Endpoint not defined');
    }
    if (this.imageBaseURL === undefined) {
      throw new Error('imageBaseURL not defined');
    }
    window.setTimeout(this.sessionTimeoutCheck.bind(this), 600000);
  }

  urlIs = function urlIs(prefix) {
    return this.prefix === prefix;
  };

  visualiserUploadURL = function visualiserUploadURL() {
    return `${this.endpoint}visualisation/UpdateImageForm`;
  };

  sessionTimeoutCheck = function sessionTimeoutCheck() {
    if (this.tokenExpiry) {
      Object.keys(this.tokenExpiry).forEach((key) => {
        if (this.tokenExpiry[key]) {
          if (moment(this.tokenExpiry[key]).isBefore()) {
            this.token[key] = undefined;
            this.tokenExpiry[key] = undefined;
            this.router
              .push({ path: this.store.state.auth.login_url, query: { redirect: this.router.currentRoute.path } })
              .catch(() => {});
          } else if (moment(this.tokenExpiry[key]).subtract(6, 'hours').isBefore()) {
            this.store.dispatch('auth/GetJWT', key);
          }
        }
      });
    }
    window.setTimeout(this.sessionTimeoutCheck.bind(this), 600000);
  };

  parseResponseWith400(
    response,
    errorCallback,
    successCallback,
    successCodes = [200, 201, 202, 204, 400, 404],
  ) {
    const defaultCallback = (r) => ({
      ...r.data,
      code: response.status,
    });

    return this.parseResponse(
      response,
      errorCallback,
      successCallback || defaultCallback,
      successCodes,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  parseResponse(
    response,
    errorCallback,
    successCallback = (r) => r.data,
    successCodes = [200, 201, 202, 204],
  ) {
    if (response !== null) {
      if (successCodes.includes(response.status)) {
        return successCallback(response);
      }

      Sentry.captureEvent({
        message: `Status ${response.status} : ${response.config.url.replace('https://', '')}`,
        extra: {
          ajax_input: response.config.data,
          status: response.status
        },
      });
    } else {
      Sentry.captureEvent({
        message: 'NULL RESPONSE',
      });
    }

    if (typeof errorCallback === 'function') {
      return errorCallback(response);
    }

    // const

    window.alertBox.fire({
      title: response?.data?.title || 'Unknown Error',
      text: response?.data?.detail || 'An unknown error occurred',
    });

    return errorCallback;
  }

  hasToken() {
    return this.token !== undefined;
  }

  setEndpoint(endpoint) {
    this.endpoint = endpoint;
  }

  setImageBaseURL(url) {
    this.imageBaseURL = url;
  }

  // eslint-disable-next-line class-methods-use-this
  async download(url) {
    Cookies.remove('Bearer');
    await this.authGetCookie();
    const link = document.createElement('a');
    link.href = `/api/${url}`;

    document.body.appendChild(link);
    link.click();
  }

  async displayStream(url, options = {}) {
    const response = await this.authenticatedPost(
      url,
      {},
      {},
      {
        responseType: 'blob',
      },
    );
    return window.URL.createObjectURL(new Blob([response.data], options));
  }

  post(method, payload = {}, headers = {}) {
    return this.axios.post(this.endpoint + method, payload, {
      ...headers,
      validateStatus(status) {
        return status >= 200 && status < 500; // Resolve only if the status code is less than 500
      },
    });
  }

  authenticatedGet(method) {
    let url;

    if (method.endpoint) {
      url = method.endpoint + method.method;
    } else {
      url = this.endpoint + method;
    }
    return this.axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.token[this.installation]}`,
        },
        validateStatus(status) {
          return status >= 200 && status < 500; // Resolve only if the status code is less than 500
        },
      })
      .then((response) => {
        if (response.status >= 500) {
          Sentry.captureEvent({
            message: `${response.status} ${response.statusText} : ${url}`,
            extra: {
              ajax_input: response.config.data,
              response: response.data,
              status: response.status,
            },
          });
        }
        if (response.status === 400) {
          Sentry.captureEvent({
            message: `${response.status} ${response.statusText} : ${url}`,
            extra: {
              ajax_input: response.config.data,
              response: response.data,
              status: response.status,
            },
          });
        }

        if (response.status === 401) {
          Sentry.captureEvent({
            message: `${response.status} Error - redirected user to login page`,
            extra: {
              ajax_input: response.config.data,
              response: response.data,
              status: response.status,
            }
          });

          window.logoutPush = true;

          return null;
        }

        return response;
      })
      .catch((errorResponse) => errorResponse.response);
  }

  authenticatedPost(method, payload = {}, headers = {}, config = {}) {
    let url;

    if (method.endpoint) {
      url = method.endpoint + method.method;
    } else {
      url = this.endpoint + method;
    }
    return this.axios
      .post(url, payload, {
        ...config,
        headers: {
          Authorization: `Bearer ${this.token[this.installation]}`,
          ...headers,
        },
        validateStatus(status) {
          return status >= 200 && status < 500; // Resolve only if the status code is less than 500
        },
      })
      .then((response) => {
        if (response.status >= 500) {
          Sentry.captureEvent({
            message: `${response.status} ${response.statusText} : ${url}`,
            extra: {
              ajax_input: response.config.data,
              response: response.data,
              status: response.status,
            },
          });
        }
        if (response.status === 400) {
          Sentry.captureEvent({
            message: `${response.status} ${response.statusText} : ${url}`,
            extra: {
              ajax_input: response.config.data,
              response: response.data,
              status: response.status,
            },
          });
        }
        // eslint-disable-next-line no-param-reassign
        if (response.status === 401) {
          Sentry.captureEvent({
            message: `${response.status} Error - redirected user to login page`,
            extra: {
              ajax_input: response.config.data,
              response: response.data,
              status: response.status,
            }
          });

          window.logoutPush = true;

          if (this.installation === 'public') {
            const launchURL = this.store.getters['branded/launchURL'];
            this.store.commit('reset', ['basket', 'branded.slug', 'style', 'feature', 'designer.isQuickTip'], {
              root: true,
            });
            this.router.push(launchURL).catch(() => {});
          } else {
            this.router.push(`${this.store.state.auth.login_url}?redirect=${this.router.currentRoute.path}`).catch(() => {});
          }

          return response;
        }

        return response;
      })
      .catch((errorResponse) => errorResponse.response);
  }
}

require('./touch-auth');
require('./touch-orders');
require('./touch-news');
require('./touch-processing');
require('./touch-contract');
require('./touch-report');
require('./touch-common');
require('./touch-staff');
require('./touch-customer');
require('./touch-marketing');
require('./touch-kpi');
require('./touch-branded');
require('./touch-visualisation');
require('./touch-vendor');
require('./touch-setup');
require('./touch-stock');
require('./touch-connect');
require('./touch-social-feeds');
require('./touch-rack');
require('./touch-company');
require('./touch-vehicle');
require('./touch-carrier');
require('./touch-test');
require('./touch-payment');

require('./touch-fake');
