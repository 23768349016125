<template>
  <div></div>
</template>

<script>
export default {
  async mounted() {
    const path = encodeURIComponent(window.location.origin + window.location.pathname);
    const version = new URLSearchParams(window.location.search).get('ecsSelectedVersion') || '';
    const url = `https://eu.engage.app/api/ecs/v1/loader/394dc311-4d62-4087-84c5-15c10ff7c378.js?path=${path}&selectedVersion=${version}`;
    // eslint-disable-next-line no-unused-expressions
    import(/* webpackIgnore: true */ url);
  },
};
</script>
