<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Dealer Network Enquiries', '/dealer-network'],
          ['Assigned', '/dealer-network-assigned'],
        ]"
      />
    </template>
    <template #header_page_title>
      Assigned Dealer <span class="whitespace-no-wrap">Network Enquiries</span>
    </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for order reference..." />
    </portal>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <router-link to="/dealer-network" class="btn-action whitespace-no-wrap">
          View All
        </router-link>
      </div>
    </template>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 xs:p-10"
    >
      <div
        v-if="orders.length > 0"
        key="orders_list"
        class="bg-white p-3 xs:p-10 pb-6 rounded-lg"
        data-index="1"
      >
        <table class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th>Created</th>
              <th>Enquiry Number</th>
              <th>Status</th>
              <th>Postcode</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              v-for="order in orders"
              :key="order.id"
              tag="tr"
              :to="getOrderUrl(order.id)"
              :class="{ _hovered: hovered === order.id }"
              @mouseover.native="hovered = order.id"
              @mouseleave.native="hovered = null"
            >
              <td>
                <div class="td-title w-24">Created</div>
                <div class="td-content">{{ formatDate(order.date) }}</div>
              </td>
              <td>
                <div class="td-title w-24">Enquiry Number:</div>
                <div class="td-content">{{ order.id }}</div>
              </td>
              <td>
                <div class="td-title w-24">Status</div>
                <div class="td-content">{{ getEnquiryStatusName(order.enquiryStatus) }}</div>
              </td>
              <td v-if="order.consumer">
                <div class="td-title w-24">Postcode:</div>
                <div class="td-content">{{ order.consumer.addressCode }}</div>
              </td>
              <td v-else>
                <div class="td-title w-24">Postcode:</div>
                <div class="td-content">No Consumer On Contract</div>
              </td>
            </router-link>
          </tbody>
        </table>
        <div class="mt-3">
          <pagination :total_items="total_items" :limit="limit" @load-feed="loadFeed"></pagination>
        </div>
      </div>
      <div v-else key="none-found" class="bg-white p-3 md:p-10 border">
        <div v-if="$route.query.search" class="flex justify-between">
          <div>No Enquiries match "{{ $route.query.search }}"</div>
          <div>
            <a class="btn-action" @click="$router.push({ query: undefined })"> Clear Search </a>
          </div>
        </div>
        <div v-else>No Enquiries to show</div>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import dateHelper from '@/mixins/dateHelper';
import Pagination from '@/components/shared/Pagination.vue';
import currencyHelper from '@/mixins/currencyHelper';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    pagination: Pagination,
    Breadcrumbs,
    SearchBox,
  },
  mixins: [dateHelper, currencyHelper],
  data() {
    return {
      assignees: [],
      orders: [],
      loading: true,
      myId: 0,
      limit: 10,
      total_items: 0,
      hovered: null,
    };
  },
  watch: {
    '$route.query': {
      handler() {
        this.loadFeed();
      },
      immediate: true,
    },
  },
  methods: {
    getAssignee(customerId) {
      return this.assignees.filter((assignee) => assignee.customerId === customerId)[0];
    },
    getEnquiryStatusName(orderStatus) {
      const status = Object.keys(this.enums.EnquiryStatus).find(
        (key) => this.enums.EnquiryStatus[key] === orderStatus,
      );
      return status.toLowerCase();
    },
    getOrderUrl(enquiryId) {
      const url = `/dealer-network-assigned-statuses/${enquiryId}`;
      return url;
    },
    assignedToDetails(contract) {
      const assignedTo = this.getAssignee(contract.customerId);

      return `${assignedTo.name} ${assignedTo.email}`;
    },
    async loadFeed(offset = 0) {
      this.loading = true;
      this.myId = await window.touch.staffGetOrganisationCustomerId();
      const response = await this.$store.dispatch('touch/loadDealerNetworkAssignedList', {
        limit: this.limit,
        offset,
        search: this.$route.query.search,
      });
      this.orders = response.orders;
      this.assignees = response.assignees;
      this.total_items = response.total_items;
      this.loading = false;
    },
  },
};
</script>
