<template>
  <div>
    <div class="flex flex-col gap-5 sm:flex-row border-b pb-10">
      <div class="new-image-zone">
        <div class="flex flex-col justify-around text-base">
          <strong>{{ setup }} Landing Page Background</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div><strong>Location:</strong> Branded Mode designer introduction page {{ setup }}</div>
          <div>
            <strong>Purpose:</strong> Personalise the landing page for your customers who visit
            {{ setup }}
          </div>
        </div>
        <div>
          <div v-if="enquiryWelcomeImageDetails.src">
            <div class="btn-action" @click.prevent="$emit('resetImage', enquiryWelcomeImageDetails.imageType)">Reset To Default</div>
          </div>
          <vue-dropzone
            v-else
            id="dropzone"
            ref="fileUploadDropzone-1"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            class="p-0 hover:bg-white"
            style="min-height: auto"
            @vdropzone-file-added="
              $emit('fileAddedToDropZone', {
                uploadedFile: $event,
                imageType: enquiryWelcomeImageDetails.imageType,
                refs: 'fileUploadDropzone-1',
              })
            "
            @vdropzone-removed-file=" $emit('fileRemovedFromDropZone', enquiryWelcomeImageDetails.imageType)"
          >
            <div class="max-w-sm text-center block border border-gray-300 p-6 flex flex-col justify-around text-black hover:bg-gray-200 cursor-pointer">
              <div class="mb-1">Drag and drop image file</div>
              <div class="mb-1">or</div>
              <div>
                <a class="btn-action w-32"> Select Image </a>
              </div>
            </div>
          </vue-dropzone>
        </div>
        <div class="text-gray-500 py-3">
          <div>Required file type: JPG, PNG.</div>
          <div>Recommended size: 840 × 813 pixels</div>
          <div>Max File Size: 1MB</div>
        </div>
      </div>
      <div class="sm:ml-auto flex flex-col">
        <div v-if="enquiryWelcomeImageDetails.src" class="flex flex-col justify-center border border-gray-300" style="min-height: 300px; width: 400px;">
          <img :src="enquiryWelcomeImageDetails.src" />
        </div>
        <div
          v-else-if="!enquiryWelcomeImageDetails.src && enquiryWelcomeImageDetails.default"
          class="border border-gray-300"
          style="min-height: 300px; width: 400px;"
        >
          <img :src="enquiryWelcomeImageDetails.default" />
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row sm:flex-row-reverse gap-10 mt-10 border-b pb-10">
      <div>
        <div class="sm:hidden flex flex-col justify-around text-base mb-5">
          <strong>{{ setup }} Landing Page Text</strong>
        </div>
        <div class="max-w-md border border-gray-300" style="width: 400px;">
          <img src="/images/guides/intro-text.jpg" />
        </div>
      </div>
      <div class="mr-auto flex flex-col gap-5">
        <div class="hidden sm:flex flex-col justify-around text-base">
          <strong>{{ setup }} Landing Page Text</strong>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Landing Page Title</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer page as the heading on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.enquiryPageHeading" type="text" class="form-control" />
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Landing Page Intro</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer page as the sub-heading on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.enquiryPageSubHeading" type="text" class="form-control" />
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Landing Page Body Text</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer page as the welcome text on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.enquiryPageWelcomeText" type="text" class="form-control" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-5 sm:flex-row mt-10 border-b pb-10">
      <div class="new-image-zone w-full">
        <div class="flex flex-col justify-around text-base">
          <strong>Virtual Home Banner</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div><strong>Location:</strong> Basket Page</div>
          <div><strong>Purpose:</strong> To launch the Virtual Home feature</div>
        </div>
        <div>
          <div v-if="virtualHomeBannerDetails.src">
            <div class="btn-action" @click.prevent="$emit('resetImage', virtualHomeBannerDetails.imageType)">Reset To Default</div>
          </div>
          <vue-dropzone
            v-else
            id="dropzone"
            ref="fileUploadDropzone-2"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            class="p-0 hover:bg-white"
            style="min-height: auto"
            @vdropzone-file-added="
              $emit('fileAddedToDropZone', {
                uploadedFile: $event,
                imageType: virtualHomeBannerDetails.imageType,
                refs: 'fileUploadDropzone-2',
              })
            "
            @vdropzone-removed-file="$emit('fileRemovedFromDropZone', virtualHomeBannerDetails.imageType)"
          >
            <div class="max-w-sm text-center block border border-gray-300 p-6 flex flex-col justify-around text-black hover:bg-gray-200 cursor-pointer">
              <div class="mb-1">Drag and drop image file</div>
              <div class="mb-1">or</div>
              <div>
                <a class="btn-action w-32"> Select Image </a>
              </div>
            </div>
          </vue-dropzone>
        </div>
        <div class="text-gray-500 py-3">
          <div>Required file type: JPG, PNG.</div>
          <div>Recommended Size: 1057 x 239 pixels.</div>
          <div>Max File Size: 1MB</div>
        </div>
      </div>
      <div class="sm:ml-auto flex flex-col">
        <div
          v-if="virtualHomeBannerDetails.src"
          class="flex flex-col justify-center border border-gray-300"
          style="min-height: 300px; width: 400px;"
        >
          <img :src="virtualHomeBannerDetails.src" />
        </div>
        <div
          v-else-if="!virtualHomeBannerDetails.src && virtualHomeBannerDetails.default"
          class="flex flex-col justify-center border border-gray-300"
          style="min-height: 300px; width: 400px;"
        >
          <img :src="virtualHomeBannerDetails.default" />
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row mt-10 border-b pb-10">
      <div class="new-image-zone">
        <div class="flex flex-col justify-around text-base">
          <strong>Virtual Home Background</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div><strong>Location:</strong> Virtual Home introduction page</div>
          <div>
            <strong>Purpose:</strong> Personalise Virtual Home with a photo displaying your product
          </div>
        </div>
        <div>
          <div
            v-if="virtualHomeBackgroundDetails.src"
            class="btn-action mt-2"
            @click.prevent="$emit('resetImage', virtualHomeBackgroundDetails.imageType)"
          >
            Reset To Default
          </div>
          <vue-dropzone
            v-else
            id="dropzone"
            ref="fileUploadDropzone-3"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            class="p-0 hover:bg-white"
            style="min-height: auto"
            @vdropzone-file-added="
              $emit('fileAddedToDropZone', {
                uploadedFile: $event,
                imageType: virtualHomeBackgroundDetails.imageType,
                refs: 'fileUploadDropzone-3',
              })
            "
            @vdropzone-removed-file="$emit('fileRemovedFromDropZone', virtualHomeBackgroundDetails.imageType)"
          >
            <div class="max-w-sm text-center block border border-gray-300 p-6 flex flex-col justify-around text-black hover:bg-gray-200 cursor-pointer">
              <div class="mb-1">Drag and drop image file</div>
              <div class="mb-1">or</div>
              <div>
                <a class="btn-action w-32"> Select Image </a>
              </div>
            </div>
          </vue-dropzone>
        </div>
        <div class="text-gray-500 py-3">
          <div>Required file type: JPG, PNG.</div>
          <div>Recommended size: 1440 × 961 pixels</div>
          <div>Max File Size: 1MB</div>
        </div>
      </div>
      <div class="sm:ml-auto flex flex-col">
        <div
          v-if="virtualHomeBackgroundDetails.src"
          class="flex flex-col justify-center border border-gray-300"
          style="min-height: 300px; width: 400px;"
        >
          <img :src="virtualHomeBackgroundDetails.src" />
        </div>
        <div
          v-else-if="!virtualHomeBackgroundDetails.src && virtualHomeBackgroundDetails.default"
          class="flex flex-col justify-center border border-gray-300"
          style="min-height: 300px; width: 400px;"
        >
          <img :src="virtualHomeBackgroundDetails.default" />
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row mt-10 border-b pb-10">
      <div class="new-image-zone">
        <div class="flex flex-col justify-around text-base">
          <strong>{{ setup }} Thank You Page Background</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div><strong>Location:</strong> {{ setup }} designer thank you page</div>
          <div>
            <strong>Purpose:</strong> Personalise the landing page for your customers who visit
            {{ setup }}
          </div>
        </div>
        <div class="">
          <div v-if="enquiryCompletionImageDetails.src" class="btn-action mt-2" @click.prevent="$emit('resetImage', enquiryCompletionImageDetails.imageType)">
            Reset To Default
          </div>
          <vue-dropzone
            v-else
            id="dropzone"
            ref="fileUploadDropzone-4"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            class="p-0 hover:bg-white"
            style="min-height: auto"
            @vdropzone-file-added="
              $emit('fileAddedToDropZone', {
                uploadedFile: $event,
                imageType: enquiryCompletionImageDetails.imageType,
                refs: 'fileUploadDropzone-4',
              })
            "
            @vdropzone-removed-file="$emit('fileRemovedFromDropZone', enquiryCompletionImageDetails.imageType)"
          >
            <div class="max-w-sm text-center block border border-gray-300 p-6 flex flex-col justify-around text-black hover:bg-gray-200 cursor-pointer">
              <div class="mb-1">Drag and drop image file</div>
              <div class="mb-1">or</div>
              <div>
                <a class="btn-action w-32"> Select Image </a>
              </div>
            </div>
          </vue-dropzone>
        </div>
        <div class="text-gray-500 py-3">
          <div>Required file type: JPG, PNG.</div>
          <div>Recommended size: 840 × 813 pixels</div>
          <div>Max File Size: 1MB</div>
        </div>
      </div>
      <div class="sm:ml-auto flex flex-col">
        <div
          v-if="enquiryCompletionImageDetails.src"
          class="flex flex-col justify-center border border-gray-300"
          style="min-height: 300px; width: 400px;"
        >
          <img :src="enquiryCompletionImageDetails.src" />
        </div>
        <div
          v-else-if="!enquiryCompletionImageDetails.src && enquiryCompletionImageDetails.default"
          class="flex flex-col justify-center border border-gray-300"
          style="min-height: 300px; width: 400px;"
        >
          <img :src="enquiryCompletionImageDetails.default" />
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row sm:flex-row-reverse gap-10 mt-10 border-b pb-10">
      <div>
        <div class="sm:hidden flex-col justify-around text-base mb-5">
          <strong>{{ setup }} Thank You Page Text</strong>
        </div>
        <div class="border border-gray-300" style="width: 400px;">
          <img src="/images/guides/thank-you-text.jpg" />
        </div>
      </div>
      <div class="mr-auto flex flex-col gap-5">
        <div class="hidden sm:flex flex-col justify-around text-base">
          <strong>{{ setup }} Thank You Page Text</strong>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Thank You Page Title</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer thank you page as the heading on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.thankYouHeadingText" type="text" class="form-control" />
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Thank You Page Intro</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer thank you page as the sub-heading on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.thankYouSubHeadingText" type="text" class="form-control" />
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Thank You Page Body Text</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer thank you page as the welcome text on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.thankYouText" type="text" class="form-control" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';

export default {
  components: {
    'vue-dropzone': vue2Dropzone,
  },
  props: {
    setup: String,
    settings: Object,
    dropzoneOptions: Object,
    enquiryWelcomeImageDetails: Object,
    virtualHomeBannerDetails: Object,
    virtualHomeBackgroundDetails: Object,
    enquiryCompletionImageDetails: Object,
  },
};
</script>

<style scoped>
.preview-section {
  width: 500px;
}
.new-image-zone {
  width: 100%;
}

@media (min-width: theme("screens.sm")) {
  .new-image-zone {
    width: 700px;
  }
}
</style>
