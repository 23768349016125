<template>
  <div id="app" class="text-sm">
    <debug-banner v-if="showDebugBanner" :vue-env="vueEnv" />
    <router-view />
    <FreshdeskWidget />
    <TalkativeWidget v-if="hasTalkative" />
  </div>
</template>

<script>
import DebugBanner from '@/components/shared/DebugBanner.vue';
import FreshdeskWidget from '@/components/shared/FreshdeskWidget.vue';
import TalkativeWidget from '@/components/shared/TalkativeWidget.vue';

export default {
  components: {
    DebugBanner,
    FreshdeskWidget,
    TalkativeWidget,
  },
  data() {
    return {
      vueEnv: process.env.VUE_APP_ENV,
    };
  },
  computed: {
    hasTalkative() {
      return this.touch_portal_user && window.touch.urlIs('endurance');
    },
    showDebugBanner() {
      return (this.vueEnv?.toLowerCase() !== 'prod') & (this.vueEnv?.toLowerCase() !== 'fitshow');
    },
  },
  mounted() {
    this.initialiseStoreData();
    this.$store.state.auth.login_url = ['staging', 'prod'].includes(this.vueEnv?.toLowerCase()) ? '/login' : '/login-admin' 
  },
  methods: {
    initialiseStoreData() {
      this.$store.dispatch('style/refresh');
      this.$store.dispatch('feature/refreshPermissions');
    },
  },
};
</script>

<style src="./assets/css/styles.css"></style>
