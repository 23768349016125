<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Product Setup', '/product-setup'],
          ['Extras'],
        ]"
      />
    </template>
    <template #header_page_title> Extras </template>
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden">
      <Menu></Menu>
      <div class="mx-auto h-full" style="max-width: 1550px">
        <Extras />
      </div>
    </div>
  </touch-layout>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Menu from '@/components/business/productSetup/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Extras from '@/components/business/manageTouchAccount/Extras.vue';

export default {
  components: {
    Breadcrumbs,
    Menu,
    Extras,
  },
  data() {
    return {
      children: {},
      bus: new Vue(),
      test_help_box_open: false,
    };
  },
  computed: {
    ...mapGetters({
      getSetupURL: 'designer/getSetupURL',
    }),
    active_section() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1);
    },
    has_unsaved_changes() {
      return this._.find(this.children, (child) => !child.isSaved) !== undefined;
    },
    has_validation_errors() {
      return this._.find(this.children, (child) => !child.isValid) !== undefined;
    },
  },
  async mounted() {
    this.bus.$on('register', (details) => {
      this.$set(this.children, details.name, details);
    });
    this.bus.$on('update', (details) => {
      Object.entries(details).forEach(([key, entry]) => {
        this.$set(this.children[details.name], key, entry);
      });
    });
    this.bus.$on('saved', (details) => {
      Object.entries(details).forEach(([key, entry]) => {
        this.$set(this.children[details.name], key, entry);
      });
      this.afterSaved();
    });
  },
  methods: {
    getHtmlForValidationErrors() {
      let html = '';
      Object.entries(this.children).forEach(([key, entry]) => {
        if (this.children[key].isValid && !this.children[key].isSaved) {
          html += `<div class="mb-6 text-green-600"><strong>${key}</strong> Saved Successfully</div>`;
        }
        if (!this.children[key].isValid) {
          html += `<div class="mb-2 text-red-600"><strong>${key}</strong> had the following errors:</div>`;
          html += `<ul class="mb-3">${Object.values(entry.errors).join('</li><li>')}</ul>`;
        }
      });
      return html;
    },
    async saveSettings() {
      await this.bus.$emit('save');
      if (this.has_validation_errors) {
        const html = this.getHtmlForValidationErrors();
        this.alertBox().fire({
          title: 'Some settings were not saved',
          icon: 'error',
          html,
        });
      }
    },
    async afterSaved() {
      this.$store.dispatch('auth/checkWizard');
      if (!this.has_validation_errors) {
        if (this.$store.state.basket.basket.length > 0) {
          this.$store.dispatch('basket/refresh', true);
          this.alertBox()
            .fire({
              title: 'Settings Updated',
              showDenyButton: true,
              denyButtonText: 'Resume Basket',
              confirmButtonText: 'Close',
            })
            .then(async (result) => {
              if (!(result.isDismissed || result.isConfirmed)) {
                this.routerPush('/basket');
              }
            });
        } else {
          this.alertBox().fire({
            title: 'Settings Updated',
            icon: 'success',
          });
        }
      }
    },
  },
};
</script>
