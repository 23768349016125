<template>
  <div>
    <hr class="-mx-10" />
    <div class="flex flex-wrap-reverse justify-between items-center m-3 xs:m-10 mt-10 mb-0">
      <div class="text-2xl font-medium" v-text="'Default Delivery Options'"></div>
    </div>
    <div v-if="customer" class="xs:flex m-3 xs:mx-10 gap-5">
      <div class="w-full mt-4 xs:w-1/4 xs:mt-0">
        <div class="font-medium">Minimum Order Value</div>
        <div class="flex relative">
          <div class="absolute py-2 px-3 border border-black rounded-l">£</div>
          <input
            v-model="customer.delivery.minimumOrderValue"
            :disabled="uneditable['min']"
            :class="{ 'text-gray-400': uneditable['min'] }"
            type="number"
            placeholder="0.00"
            class="flex-grow bg-white border-black p-2 rounded pl-16 w-32 border border-l-0"
            @change="formatTo2dp"
          />
        </div>
      </div>
      <div class="w-full mt-4 xs:w-1/4 xs:mt-0">
        <div class="font-medium">Delivery Charge</div>
        <div class="flex relative">
          <div class="absolute py-2 px-3 border border-black rounded-l">£</div>
          <input
            v-model="customer.delivery.deliveryCharge"
            :disabled="uneditable['del']"
            :class="{ 'text-gray-400': uneditable['del'] }"
            placeholder="0.00"
            type="number"
            class="flex-grow bg-white border-black p-2 rounded pl-16 w-32 border border-l-0"
            @change="formatTo2dp"
          />
        </div>
      </div>
      <div class="ml-auto mt-5 text-center">
        <button class="btn-action px-6 md:h-10" @click="saveDelivery()">
          Update Default Delivery
        </button>
      </div>
    </div>
    <div class="ml-10 mb-10 mt-3 text-red-500">
      <strong>Delivery charges are only applicable to Trade Customer.</strong>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      deliveryData: null,
      customer: undefined,
      customerId: undefined,
      uneditable: {
        del: false,
        min: false,
      },
    };
  },
  async mounted() {
    this.customerId = await window.touch.staffGetOrganisationCustomerId();
    await this.loadItems();
    this.formatTo2dp();
  },
  methods: {
    ...mapActions({
      customerUpdate: 'user/customerUpdateRaw',
    }),
    async loadItems() {
      this.customer = await window.touch.customerGet(this.customerId, true);
      this.deliveryData = { ...this.customer?.delivery };
    },
    async saveDelivery() {
      this.loading = true;
      let deliveryChanged = false;
      if (
        this.deliveryData.deliveryCharge !== this.customer.delivery.deliveryCharge ||
        this.deliveryData.minimumOrderValue !== this.customer.delivery.minimumOrderValue
      ) {
        deliveryChanged = true;
      }

      if (deliveryChanged && this.touch_business_user) {
        const delvResponse = await this.customerUpdate({
          CustomerId: this.customer.customerId,
          Delivery: {
            deliveryCharge: this.customer.delivery.deliveryCharge,
            minimumOrderValue: this.customer.delivery.minimumOrderValue,
          },
        });
        if (delvResponse.status === 200) {
          this.alertBox().fire({
            title: 'Delivery Prices Updated',
            icon: 'success',
          });
        }
        this.loadItems();
      }
      this.loading = false;
    },
    formatTo2dp() {
      this.customer.delivery.minimumOrderValue = this.customer.delivery.minimumOrderValue
        ? parseFloat(this.customer.delivery.minimumOrderValue).toFixed(2)
        : 0.0;
      this.customer.delivery.deliveryCharge = this.customer.delivery.deliveryCharge
        ? parseFloat(this.customer.delivery.deliveryCharge).toFixed(2)
        : 0.0;
    },
  },
};
</script>
