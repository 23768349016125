<template>
  <touch-layout>
    <div v-if="!loading" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch xs:p-10">
      <div key="0" class="flex my-3 xs:my-0 p-3 xs:p-0 xs:mb-10" data-index="0">
        <div class="flex">
          <span class="text-xl sm:text-2xl flex flex-col justify-around">
            <i class="fal fa-book-reader"></i>
          </span>
          <span class="text-xl xs:text-2xl sm:text-3xl flex flex-col justify-around pl-3">
            Downloads
          </span>
        </div>
      </div>
      <div key="1" class="bg-white p-6 xs:p-10 rounded-lg xs:mb-10" data-index="1">
        <transition name="fade-in">
          <div
            v-if="saving_document"
            class="z-50 bg-white absolute bottom-0 left-0 right-0 top-0 p-10 flex flex-col justify-around items-center"
          >
            <div>
              <div class="loading-spinner"></div>
              <div class="flex items-center tracking-widest mt-3">Saving ...</div>
            </div>
          </div>
        </transition>
        <div class="flex justify-between border-b border-gray-300 pb-5 mb-5">
          <div class="flex flex-col justify-around">Edit item</div>
          <div class="flex">
            <div class="flex flex-col justify-around mr-1">
              <router-link to="/manage-downloads/category" class="btn">
                Manage Categories
              </router-link>
            </div>
            <div class="flex flex-col justify-around hidden">
              <a class="btn" @click="create_category_modal_open = true"> Add Category </a>
            </div>
          </div>
        </div>
        <div class="2xs:flex mb-5">
          <div class="flex flex-col justify-around w-24">Title</div>
          <div class="flex-grow">
            <input v-model="document.description" type="text" class="form-control" />
          </div>
        </div>
        <div class="flex flex-col sm:flex-row mb-5">
          <div class="flex-1 2xs:flex">
            <div class="flex flex-col justify-around w-24">Category</div>
            <div class="flex-grow">
              <select v-model="document.categoryId" class="h-10 bg-gray-200 w-full">
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id"
                  :selected="category.id == document.categoryId"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex flex-col-reverse xs:flex-row -m-3">
          <div class="w-full 2xs:w-1/2 xs:w-1/2 sm:w-1/3 md:w-1/4 xl:w-1/6 flex align-stretch">
            <div class="bg-gray-200 text-center m-3 p-5 flex flex-col w-full">
              <div class="mb-5">Current File</div>
              <div class="mt-auto">
                <img
                  v-if="!image_error"
                  :src="document.thumbnail"
                  class="w-full"
                  @error="image_error = true"
                />
                <div v-else style="font-size: 10rem">
                  <i class="fal fa-file"></i>
                </div>
                <div class="mt-5">
                  {{ document.description }}
                </div>
                <div class="mt-5">
                  <a class="btn-primary" @click.stop="downloadDocument(document.id)"> Download </a>
                </div>
              </div>
            </div>
          </div>
          <vue-dropzone
            id="dropzone"
            ref="fileUploadDropzone"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            class="text-center block bg-gray-200 flex-grow p-10 m-3 flex flex-col justify-around border-none text-black"
            @vdropzone-file-added="fileAddedToDropZone"
          >
            <div class="text-6xl mb-2">
              <i class="fal fa-file-upload text-black"></i>
            </div>
            <div class="mb-2">Drag and drop a pdf file</div>
            <div class="mb-2">or</div>
            <div>
              <a class="btn-action w-32"> Select PDF </a>
            </div>
          </vue-dropzone>
        </div>
        <div class="mt-2 flex justify-between">
          <transition name="fade-in">
            <div v-if="errors.length > 0" class="flex flex-col justify-around">
              <div
                v-for="(error, index) in errors"
                :key="index"
                class="text-red-600"
                :class="{ 'mt-1': index > 0 }"
              >
                {{ error }}
              </div>
            </div>
          </transition>
          <div class="ml-auto">
            <a class="btn-action w-32" @click.prevent="updateDocument()"> Save </a>
          </div>
        </div>
        <modal-window
          :modal_open="create_category_modal_open"
          :is-closable="false"
          @close="create_category_modal_open = false"
        >
          <create-category @close="create_category_modal_open = false"></create-category>
        </modal-window>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import { mapActions } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import CreateCategory from '@/components/shared/marketing/MarketingCreate.vue';

export default {
  components: {
    'create-category': CreateCategory,
    'vue-dropzone': vue2Dropzone,
  },
  data() {
    return {
      loading: true,
      errors: [],
      categories: {},
      document: {},
      saving_document: false,
      create_category_modal_open: false,
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
      },
      image_error: false,
    };
  },
  mounted() {
    this.loadFeed(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      documentDetails: 'marketing/documentDetails',
      marketingCategories: 'marketing/marketingCategories',
      getDocumentThumbnail: 'marketing/getDocumentThumbnail',
      downloadDocument: 'marketing/downloadDocument',
      uploadDocumentForm: 'marketing/uploadDocumentForm',
    }),
    async loadFeed(id) {
      const categories = await this.marketingCategories();
      this.categories = categories;
      const document = await this.documentDetails(id);
      this.document = document;
      this.document.thumbnail = await this.getDocumentThumbnail(id);
      this.loading = false;
    },
    fileAddedToDropZone(uploadedFile) {
      this.errors = [];
      if (!uploadedFile) {
        return;
      }

      if (uploadedFile.name.split('.').pop().toLowerCase() !== 'pdf') {
        this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
        this.errors.push('Please choose a PDF');
        return;
      }

      this.document.file = uploadedFile;
    },
    async updateDocument() {
      this.saving_document = true;
      let filename = null;
      if (this.document.file) {
        filename = this.document.file.name;
      }
      const formData = new FormData();
      formData.append('Id', this.document.id);
      formData.append('Type', '3');
      formData.append('CategoryId', this.document.categoryId);
      formData.append('Name', filename);
      formData.append('Description', this.document.description);
      formData.append('File', this.document.file);
      await this.uploadDocumentForm(formData).then(() => {
        this.routerPush('/marketing');
      });
    },
  },
};
</script>
