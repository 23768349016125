<template>
  <div class="flex flex-col bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
    <div class="xs:p-10 flex-grow">
      <div class="mx-auto" style="max-width: 1550px">
        <keep-alive>
          <router-view v-if="!loading" :bus="bus" :prop_settings="settings"></router-view>
        </keep-alive>
      </div>
    </div>
    <div
      v-if="!loading && is_admin_user"
      class="sticky bottom-0 bg-white p-3 xs:p-4 text-right border-gray-300 border-t"
    >
      <div class="flex flex-col xs:flex-row justify-between">
        <div class="text-red-600 flex flex-col justify-around text-center xs:text-left">
          <span v-show="has_unsaved_changes"> You have unsaved changes. </span>
        </div>
        <div v-if="!demo_mode" class="mt-2 xs:mt-0">
          <button class="btn-action btn-lg w-full xs:w-auto" @click.prevent.stop="saveSettings()">
            {{ $t("Save all Changes") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  data() {
    return {
      children: {},
      bus: new Vue(),
      loading: true,
      settings: {},
      mobile_menu_open: false,
    };
  },
  computed: {
    active_section() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1);
    },
    has_unsaved_changes() {
      return this._.find(this.children, (child) => !child.isSaved) !== undefined;
    },
    has_validation_errors() {
      return this._.find(this.children, (child) => !child.isValid) !== undefined;
    },
  },
  async mounted() {
    this.loading = true;
    this.bus.$on('register', (details) => {
      this.$set(this.children, details.name, details);
    });
    this.bus.$on('update', (details) => {
      Object.entries(details).forEach(([key, entry]) => {
        this.$set(this.children[details.name], key, entry);
      });
    });
    this.bus.$on('saved', (details) => {
      Object.entries(details).forEach(([key, entry]) => {
        this.$set(this.children[details.name], key, entry);
      });
      this.$emit('afterSaved');
      if (!this.has_validation_errors) {
        this.alertBox().fire({
          title: 'Settings Updated',
          icon: 'success',
        });
        this.loading = false;
      }
    });

    this.loading = false;
  },
  methods: {
    getHtmlForValidationErrors() {
      let html = '';
      Object.entries(this.children).forEach(([key, entry]) => {
        if (this.children[key].isValid && !this.children[key].isSaved) {
          html += `<div class="mb-6 text-green-600"><strong>${key}</strong> Saved Successfully</div>`;
        }
        if (!this.children[key].isValid) {
          html += `<div class="mb-2 text-red-600"><strong>${key}</strong> had the following errors:</div>`;
          html += `<ul class="mb-3">${Object.values(entry.errors).join('</li><li>')}</ul>`;
        }
      });
      return html;
    },
    async saveSettings() {
      await this.bus.$emit('save');
      if (this.has_validation_errors) {
        const html = this.getHtmlForValidationErrors();
        this.alertBox().fire({
          title: 'Some settings were not saved',
          icon: 'error',
          html,
        });
        this.loading = false;
      }
    },
  },
};
</script>
