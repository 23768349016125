<template>
  <portal v-if="modal_open" to="portal_popup">
    <modal-window :modal_open="true" title="Add Staff Member" @close="$emit('close')">
      <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
        * Required Fields
      </div>
      <div class="flex flex-wrap mt-5">
        <div class="w-full xs:w-1/2 xs:pr-5">
          <div class="font-medium">First Name <span class="text-green-600">*</span></div>
          <input v-model="user.firstName" type="text" class="form-control" />
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-0">
          <div class="font-medium">Last Name <span class="text-green-600">*</span></div>
          <input v-model="user.lastName" type="text" class="form-control" />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Email <span class="text-green-600">*</span></div>
          <input v-model="user.email" type="text" class="form-control" />
        </div>
        <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Mobile</div>
          <input v-model="user.mobile" type="text" class="form-control" />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Departments <span class="text-green-600">*</span></div>
          <select v-model="user.departments" multiple class="form-control multiple">
            <option
              v-for="department in contactDepartments"
              :key="department.id"
              :value="department.id"
            >
              {{ department.name }}
            </option>
          </select>
        </div>
        <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <ContactRoles
            :roles="user.roles"
            @setContactRoles="$event === 0 ? (user.roles = $event) : (user.roles += $event)"
          />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Processing Level <span class="text-green-600">*</span></div>
          <select v-model="user.processingLevelId" class="form-control">
            <option disabled="disabled">Please Select</option>
            <option
              v-for="processingLevel in processingLevels"
              :key="processingLevel.id"
              :value="processingLevel.id"
            >
              {{ processingLevel.name }}
            </option>
          </select>
        </div>
        <div v-if="isBranchManagerAvailable" class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Linked Branch</div>
          <select v-model="user.branchCompanyId" class="form-control bg-white">
            <option :value="0">None</option>
            <option v-for="branch in branches" :key="branch.id" :value="branch.id">
              {{ branch.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="w-full mt-4 xs:w-1/2 xs:pr-5 xs:mt-8">
        <div class="w-full flex">
          <vue-dropzone
            id="dropzone"
            ref="fileUploadDropzone"
            class="stealth-vue-dropzone"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            @vdropzone-file-added="fileAddedToDropZone"
            @vdropzone-removed-file="fileRemovedFromDropZone"
          >
            <div class="font-medium">Photo</div>
            <div class="text-green-600 h-10 flex flex-col justify-around cursor-pointer">
              Upload a new photo
            </div>
          </vue-dropzone>
        </div>
        <div class="mt-1">Photos help your teammates recognise you in TOUCH.</div>
      </div>
      <div class="xs:flex">
        <div class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
          <div
            class="flex items-center cursor-pointer"
            @click.prevent="user.isAdmin = !user.isAdmin"
          >
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
              <div class="switch-toggle" :class="{ _active: user.isAdmin }"></div>
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap w-32">
              <span v-text="user.isAdmin ? 'Admin User' : 'Non Admin User'"></span>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
            <div
              class="flex items-center cursor-pointer"
              @click.prevent="user.noLogin = !user.noLogin"
            >
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div class="switch-toggle" :class="{ _active: user.noLogin }"></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">No Login</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button
          class="btn-action btn-lg"
          :class="{ 'pointer-events-none opacity-50': loading }"
          @click.prevent.stop="saveUser()"
        >
          Save
        </button>
      </div>
    </modal-window>
  </portal>
</template>

<script>
import validate from 'validate.js';
import { mapActions, mapGetters, mapState } from 'vuex';
import imageHelper from '@/mixins/imageHelper';
import vue2Dropzone from 'vue2-dropzone';
import ContactRoles from '@/components/shared/ContactRoles.vue';

export default {
  components: {
    'vue-dropzone': vue2Dropzone,
    ContactRoles,
  },
  mixins: [imageHelper],
  data() {
    return {
      user: {
        isAdmin: false,
        licenseStatusType: 1,
        departments: [],
        noLogin: false,
        roles: 0,
      },
      loading: true,
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        addRemoveLinks: true,
      },
      new_user_image: null,
      options: {
        limit: 20,
        offset: 0,
      },
      branches: {},
      modal_open: false,
    };
  },
  computed: {
    ...mapState('touch', {
      processingLevels: 'processingLevels',
    }),
    ...mapState('auth', {
      isBranchManagerAvailable: 'isBranchManagerAvailable',
    }),
    ...mapGetters('touch', {
      contactDepartments: 'contactDepartments',
    }),
  },
  async mounted() {
    this.loadProcessingLevels();
    const response = await this.customerListBranches(this.options);
    this.branches = response.branches;
    this.modal_open = true;
    this.loading = false;
  },
  methods: {
    ...mapActions({
      customerCreateContact: 'user/customerCreateContact',
      loadProcessingLevels: 'touch/loadProcessingLevels',
      customerListBranches: 'user/customerListBranches',
    }),
    async saveUser() {
      this.loading = true;
      const validationErrors = validate(
        {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          processingLevel: this.user.processingLevelId,
          departments: this.user.departments,
        },
        {
          departments: {
            presence: { allowEmpty: false },
          },
          processingLevel: {
            presence: { allowEmpty: false },
          },
          firstName: {
            presence: { allowEmpty: false },
            length: {
              maximum: 32,
            },
          },
          lastName: {
            presence: { allowEmpty: false },
            length: {
              maximum: 32,
            },
          },
          email: {
            presence: { allowEmpty: false },
            length: {
              maximum: 255,
            },
            email: true,
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        this.loading = false;
        return;
      }

      if (this.user.branchCompanyId && this.user.isAdmin) {
        this.alertBox().fire({
          title: 'Branch users are non-admins',
        });
        this.loading = false;
        return;
      }

      if (this.new_user_image !== null) {
        this.user.image = this.new_user_image;
      }

      await this.customerCreateContact(this.user).then(() => {
        this.$emit('saved');
        this.$emit('close');
      });
      this.loading = false;
    },
    async fileAddedToDropZone(uploadedFile) {
      this.errors = [];
      if (!uploadedFile) {
        return;
      }

      const extension = uploadedFile.name.replace(/^.*\./, '');
      const allowedExtensions = ['jpg', 'jpeg', 'png'];

      if (!allowedExtensions.includes(extension.toLowerCase())) {
        this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          text: 'File type not allowed, please ensure the file is one of the following formats: jpg, png',
        });
        return;
      }
      this.new_user_image = await this.imageToBase64(uploadedFile);
    },
    fileRemovedFromDropZone() {
      this.new_user_image = null;
    },
  },
};
</script>
