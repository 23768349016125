<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          [$t('link.My Branded Mode Account'), '/setup'],
          ['Custom Styles'],
        ]"
      />
    </template>
    <template #header_page_title> Custom Styles </template>
    <Menu />
    <CustomStyles ref="customStyles" setup="Branded Mode">
      <template #requote-settings></template>
    </CustomStyles>
  </touch-layout>
</template>

<script>
import CustomStyles from '@/components/shared/setup/CustomStyles.vue';
import Menu from '@/components/shared/setup/customStyles/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Menu,
    CustomStyles,
    Breadcrumbs,
  },
};
</script>
