<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          [$t('link.My Branded Mode Account'), '/dealer-network-setup'],
          ['Dealer Network Mode Designer'],
        ]"
      />
    </template>
    <template #header_page_title> Dealer Network Mode Designer </template>
    <Website @afterSaved="afterSaved"></Website>
  </touch-layout>
</template>

<script>
import Website from '@/components/shared/setup/Website.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    Website,
    Breadcrumbs,
  },
  data() {
    return {
      customerId: undefined,
    };
  },
  async mounted() {
    this.customerId = await window.touch.staffGetOrganisationCustomerId();
  },
  methods: {
    ...mapActions({
      setCustomer: 'user/setCustomer',
    }),
    async afterSaved() {
      this.$store.dispatch('auth/checkWizard', {
        customerId: this.customerId,
        isStaff: true,
      });
      await this.setCustomer(this.$store.state.user.customer.customerId);
    },
  },
};
</script>
