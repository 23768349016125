<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['Rack Manager', '/rack-manager'], [$route.name]]"
      />
    </template>
    <template #header_page_title> Rack Manager </template>
    <div class="flex h-screen flex-col">
      <loading
        :loading="loading"
        class="bg-gray-200 w-full overflow-y-auto scrolling-touch overflow-x-hidden"
      >
        <div class="overflow-x-auto bg-white">
          <div class="flex bg-white px-5 border-t-4 border-white whitespace-no-wrap">
            <router-link
              to="/rack-manager/map"
              class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
            >
              Map
            </router-link>
            <router-link
              to="/rack-manager/vehicles"
              class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
            >
              Vehicles
            </router-link>
            <router-link
              to="/rack-manager/rack-types"
              class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
            >
              Rack Types
            </router-link>
            <router-link
              to="/rack-manager/carriers"
              class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
            >
              Carriers
            </router-link>
          </div>
        </div>
      </loading>
      <router-view class="flex-1"></router-view>
    </div>
  </touch-layout>
</template>

<script>
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
