import Touch from './touch';

Touch.prototype.stockListStockItems = async function stockListStockItems(
  subCategoryId,
  searchText,
) {
  // this can take a partId and/or a subcategory item and get all the items associated with them.
  return (
    await this.authenticatedPost('stock/ListStockItems', {
      Limit: 99999,
      Offset: 0,
      subCategoryId,
      searchText: searchText ?? '',
    })
  ).data;
};
Touch.prototype.stockUpdateStockItems = async function stockUpdateStockItems(stockItems) {
  return (
    await this.authenticatedPost('stock/UpdateStockItems', {
      stockItems,
    })
  ).data;
};
Touch.prototype.stockUpdateStockCustomerPrice = async function stockUpdateStockCustomerPrice(
  stockCustomerPriceDetails,
) {
  return (
    await this.authenticatedPost('stock/UpdateStockCustomerPrice', {
      stockCustomerPriceDetails,
    })
  ).data;
};
Touch.prototype.stockListStockCustomerPrice = async function stockListStockCustomerPrice(
  customerId,
  itemId,
) {
  return (
    await this.authenticatedPost('stock/ListStockCustomerPrice', {
      CustomerId: customerId,
      stockId: itemId,
    })
  ).data;
};
Touch.prototype.stockListStockParts = async function stockListStockParts(query) {
  return (
    await this.authenticatedPost('stock/ListStockParts', {
      Limit: 99999,
      Offset: 0,
      SearchText: query,
    })
  ).data;
};

Touch.prototype.stockListStockPartSubCategories = async function stockListStockPartSubCategories() {
  return (
    await this.authenticatedPost('stock/ListStockPartSubCategories', {
      Limit: 99999,
      Offset: 0,
    })
  ).data;
};

Touch.prototype.stockListStockPartCategories = async function stockListStockPartCategories() {
  return (
    await this.authenticatedPost('stock/ListStockPartCategories', {
      Limit: 99999,
      Offset: 0,
    })
  ).data;
};
