<template>
  <div
    id="customer_index_scrolling_outer_wrapper"
    class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch relative"
  >
    <loading
      id="customer_index_scrolling_outer_wrapper"
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch"
    >
      <div class="flex flex-col justify-around flex-grow px-3 xs:max-w-screen-sm p-0 m-3 md:m-10">
        <div class="border border-gray-300 relative rounded-lg mt-4">
          <div
            v-if="selectedPrices.length === 0"
            class="absolute w-full h-full cursor-not-allowed opacity-50 bg-gray-200"
          ></div>
          <div class="border-b border-gray-300 p-3 flex justify-between md:pointer-events-none">
            <div>
              <strong>Modify Selected Prices</strong>
            </div>
            <div class="md:hidden">
              <i class="fal fa-caret-down" aria-hidden="true"></i>
            </div>
          </div>
          <div class="xs:p-3">
            <table class="table-striped m-auto">
              <tbody>
                <tr>
                  <td class="p-1 xs:p-2 text-xs xs:text-sm">Value:</td>
                  <td class="p-1 xs:p-2">
                    <input
                      v-model="massPriceChangeValue"
                      type="number"
                      class="p-2 w-20 rounded border border-gray-300"
                    />
                  </td>
                  <td class="p-1 xs:p-2">
                    <button class="btn" @click="runMassPriceIncrease(true)">
                      {{ massPriceChangeValue }}%
                    </button>
                  </td>
                  <td class="p-1 xs:p-2">
                    <button class="btn" @click="runMassPriceIncrease(false)">
                      £{{ massPriceChangeValue }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="m-3 md:m-10 table-container">
        <div class="flex flex-col sm:flex-row">
          <div class="mb-3 sm:mb-0 pricing-categories-sidebar">
            <div class="border border-gray-400 bg-white md:h-full">
              <div
                class="p-3 sm:border-none flex justify-between sm:pointer-events-none"
                :class="{ 'border-b border-gray-300': category_list_open }"
                @click="category_list_open = !category_list_open"
              >
                <h1 class="text-center text-base">Pricing Groups</h1>
                <div class="flex flex-col justify-around sm:hidden">
                  <i v-show="!category_list_open" class="fal fa-chevron-down"></i>
                  <i v-show="category_list_open" class="fal fa-chevron-up"></i>
                </div>
              </div>
              <div class="p-3 sm:block" :class="{ hidden: !category_list_open }">
                <ul class="w-full">
                  <li
                    v-for="group in groupsForDisplay"
                    :key="group.id"
                    @drop.prevent="movePrice($event, group.id)"
                    @dragenter.prevent
                    @dragover.prevent
                  >
                    <div
                      v-if="
                        groups.filter((x) => anomalies.find((y) => y.groupId === x.id)) !==
                          undefined && !group.delete
                      "
                      v-scroll-to="`#group_${group.id}`"
                      :class="{ 'bg-green-600 text-white': active_section === group.id }"
                      class="mt-1 items-center flex bg-gray-200 rounded-lg p-2 cursor-pointer group text-xs sm:text-sm hover:bg-green-300"
                      @click="(category_list_open = false), selectComponent(group.id)"
                    >
                      <div class="pr-6">{{ group.description }}</div>
                      <i
                        v-if="group.id !== undefinedGroupId"
                        class="td-content cursor-pointer fa fa-trash-can hover:text-red-600 ml-auto"
                        @click.prevent="handleGroupDelete(group.id)"
                      />
                    </div>
                  </li>
                </ul>
                <div>
                  <div class="mt-5">Add New Group</div>
                  <div
                    class="mt-1 items-center flex bg-gray-200 rounded-lg p-2 group text-xs sm:text-sm"
                  >
                    <input v-model="newGroupName" class="pr-6 bg-transparent" />
                    <i
                      class="td-content cursor-pointer fa fa-plus hover:text-green-600 ml-auto"
                      @click.prevent="handleGroupAdd()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sm:ml-3 relative pricing-category-options-list-wrapper bg-white">
            <div
              id="scroll-to-wrapping-element"
              class="absolute top-0 left-0 right-0 bottom-0 overflow-y-auto border border-gray-400"
              @scroll.passive="scrollAnomalies"
            >
              <div
                v-if="
                  anomalies.length > 0 &&
                    groups.filter((x) => anomalies.find((y) => y.groupId === x.id)) === undefined
                "
                class="flex justify-between bg-white"
              >
                <div class="flex flex-col justify-around">
                  <div class="p-3">No Matching Products Found</div>
                </div>
                <div class="p-3">
                  <button class="btn bg-white" @click="filter = ''">clear search</button>
                </div>
              </div>
              <table class="table-pricing mb-6">
                <tr v-for="price in anomaliesForDisplay" :key="price.id">
                  <td class="flex flex-row items-center">
                    <price-line
                      :customer-id="customerId"
                      :dataset-id="datasetId"
                      :default-currency="defaultCurrency"
                      :price="price"
                      :is-new-base-price="true"
                      :list-value="price.listValue"
                      @show-overrides="showOverridePopup"
                      @price-updated="priceUpdated(price, $event)"
                      @price-selected="togglePriceSelected(price)"
                    ></price-line>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </loading>
    <div v-if="pending_section">
      <modal-window
        :modal_open="pending_section"
        :max-width="800"
        @close="(isEdited = false), (pending_section = undefined)"
      >
        <div>There are unsaved changes in this Pricing Group.</div>
        <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
          <button
            class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
            @click.prevent="pending_section = undefined"
          >
            Go Back
          </button>
          <button
            class="btn-action btn-lg"
            @click="(isEdited = false), selectComponent(pending_section), loadSettings(true)"
          >
            Ignore & Continue
          </button>
        </div>
      </modal-window>
    </div>
    <div v-if="!loading" class="text-right save-btn-container m-5">
      <button class="btn-action btn-lg" @click="saveSettings()">Save Price Updates</button>
    </div>
    <PriceOverrides
      v-if="typeof overridePopupPrice !== 'undefined'"
      :price="overridePopupPrice"
      :dataset-id="datasetId"
      @close="overridePopupPrice = undefined"
      @save-changes="updateSpecficCustomerOverrides"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import validate from 'validate.js';
import PriceLine from '@/components/shared/PriceLine.vue';
import PriceOverrides from '@/components/shared/PriceOverrides.vue';
import roundCurrencyToNDecimals from '@/helpers/currency';

export default {
  components: {
    'price-line': PriceLine,
    PriceOverrides,
  },
  props: {
    filter: String,
  },
  data() {
    return {
      massPriceChangeValue: 0,
      selectedPrices: [],
      loading: true,
      defaultCurrency: undefined,
      customer: {
        delivery: {
          minimumOrderValue: null,
          deliveryCharge: null,
        },
      },
      pricing_info_popup_open: false,
      pricing_info_popup_starting_section: null,
      focFilterActive: false,
      undefinedGroupId: '00000000-0000-0000-0000-000000000000',
      newGroupName: '',
      pricingItemBeingMoved: '',
      movedItems: [],
      filters: [
        {
          label: 'PVC',
          filter: this.filterPVC,
          active: true,
        },
        {
          label: 'Aluminium',
          filter: this.filterAluminium,
          active: true,
        },
        {
          label: 'Timber',
          filter: this.filterTimber,
          active: true,
        },
        {
          label: 'Other',
          filter: this.filterOther,
          active: true,
        },
      ],
      groups: [],
      anomalies: [],
      original_anomalies: [],
      active_section: null,
      category_list_open: false,
      cusomterId: undefined,
      customerId: undefined,
      overridePopupPrice: undefined,
      entriesPerPage: 50,
      page: 0,
      noMoreResults: false,
      isEdited: false,
      pending_section: undefined,
    };
  },
  computed: {
    groupsForDisplay() {
      if (this.filter) {
        return this.groups.filter((x) => this.anomalies.find((y) => y.groupId === x.id));
      }
      return this.groups;
    },
    anomaliesForDisplay() {
      return this.anomalies.filter((x) => x.groupId === this.active_section);
    },
  },
  async mounted() {
    this.customerId = await window.touch.staffGetOrganisationCustomerId();
    this.customer = await window.touch.customerGet(this.customerId, true);
    this.datasetId = this.customer?.datasetIds[0];
    this.defaultCurrency = (await this.$store.dispatch('touch/commonGetCurrencies')).filter(
      (currency) => currency.isDefault,
    )[0].code;
    await this.fullRefresh();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
      customerUpdate: 'user/customerUpdateRaw',
    }),
    selectComponent(groupId) {
      if (this.isEdited) {
        this.pending_section = groupId;
      } else {
        this.scrollToTop();
        this.active_section = groupId;
        this.pending_section = undefined;
        this.isEdited = false;
        this.loadSettings(true);
      }
    },
    async movePrice(ev, groupId) {
      const price = this.anomalies.find((x) => x.id === this.pricingItemBeingMoved);
      price.groupId = groupId;
      await window.touch.staffMoveFinancialItems(this.datasetId, groupId, [
        this.pricingItemBeingMoved,
      ]);
    },
    runMassPriceIncrease(percentageChange) {
      this.selectedPrices.forEach((x) => {
        const price = this.anomalies.find((anomaly) => anomaly.id === x);
        const valueAsNumber = Number(this.massPriceChangeValue);
        let newVal = 0;
        if (price.overrideValue) {
          newVal = percentageChange
            ? price.overrideValue * (1 + Number(valueAsNumber) / 100)
            : price.overrideValue + valueAsNumber;
        } else {
          newVal = percentageChange
            ? price.listValue * (1 + Number(valueAsNumber) / 100)
            : price.listValue + valueAsNumber;
        }
        this.priceUpdated(price, roundCurrencyToNDecimals(newVal, 2));
      });
    },
    async handleGroupDelete(groupId) {
      const prevGroup = this.active_section;
      this.active_section = groupId;
      await this.loadSettings(true);
      if (this.anomalies.length) {
        window.alertBox.fire(
          'Please move all financial items out of this group before you delete it.',
        );
        return;
      }
      this.groups.find((x) => x.id === groupId).delete = true;
      await window.touch.staffUpdateFinancialGroups(this.datasetId, [
        {
          Id: groupId,
          delete: true,
        },
      ]);
      this.active_section = prevGroup;
      await this.loadGroups();
      await this.loadSettings(true);
    },
    togglePriceSelected(price) {
      if (this.selectedPrices.find((x) => x === price.id) === undefined) {
        this.selectedPrices.push(price.id);
      } else {
        this.selectedPrices = this.selectedPrices.filter((x) => x !== price.id);
      }
    },
    scrollAnomalies({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (!this.filter && scrollTop + clientHeight >= scrollHeight) {
        this.page += this.page;
        this.loadSettings(false);
      }
    },
    showOverridePopup(e) {
      this.overridePopupPrice = e;
    },
    async loadCustomer() {
      this.customer = await this.customerGet(this.$route.params.id);
    },
    modifiedAnomalies() {
      return this.anomalies.filter(
        (anomaly) =>
          this.original_anomalies.findIndex(
            (originalAnomaly) =>
              originalAnomaly.id === anomaly.id &&
              originalAnomaly.overrideValue !== anomaly.overrideValue,
          ) !== -1,
      );
    },
    scrollToTop() {
      try {
        document.getElementById('customer_index_scrolling_outer_wrapper').scrollTop = 0;
        document.getElementById('scroll-to-wrapping-element').scrollTop = 0;
      } catch (e) {
        // element not found
      }
    },
    filterPVC(anomaly) {
      return !anomaly.description.includes('PVC');
    },
    filterAluminium(anomaly) {
      return !anomaly.description.includes('Aluminium');
    },
    filterTimber(anomaly) {
      return !anomaly.description.includes('Timber');
    },
    filterOther(anomaly) {
      return (
        !this.filterPVC(anomaly) || !this.filterAluminium(anomaly) || !this.filterTimber(anomaly)
      );
    },
    priceUpdated(price, value) {
      this.isEdited = true;
      const modifiedPrice = this.anomalies.find((anomaly) => anomaly.id === price.id);
      modifiedPrice.overrideValue = value;
    },
    async updateAnomalies() {
      await window.touch.staffUpdateBasePrices(
        this.datasetId,
        this.modifiedAnomalies().map((anomaly) => ({
          Id: anomaly.id,
          Value: anomaly.overrideValue === '' ? 0 : Number(anomaly.overrideValue),
          FOC: Number(anomaly.overrideValue) === 0,
        })),
      );
    },
    async updateSpecficCustomerOverrides(data) {
      const promiseArray = [];
      let message = '';
      for (let i = 0; i < data.length; i += 1) {
        const response = this.$store.dispatch('touch/staffUpdateAlternativePriceData', {
          Anomalies: [
            {
              Id: data[i].id,
              Value: data[i].newValue,
              FOC: Number(data[i].newValue) === 0,
            },
          ],
          CustomerId: data[i].customerId,
          customerName: data[i].customerName,
          DatasetId: this.datasetId,
        });
        promiseArray.push(response);
      }
      await Promise.all(promiseArray).then((response) => {
        if (response && response.some((x) => x && x.errors)) {
          for (let i = 0; i < response.length; i += 1) {
            const errorMessage = response[i].errors
              ? response[i].errors.data.message
              : 'Save Succesful';
            message += `${response[i].customerName} - ${errorMessage}<br>`;
          }
          this.alertBox().fire({
            title:
              response.filter((x) => x.errors).length === data.length
                ? 'Save Failed'
                : 'Partial Save.',
            html: message,
          });
        } else {
          this.alertBox().fire({
            title: 'Save Succesful',
          });
          this.overridePopupPrice = undefined;
        }
      });
    },
    async fullRefresh() {
      this.loading = true;
      await this.loadGroups();
      await this.loadSettings(true);
      this.loading = false;
    },
    async loadGroups() {
      this.groups = (
        await this.$store.dispatch('touch/staffGetFinancialGroups', {
          CustomerId: this.customerId,
          DatasetId: this.datasetId,
        })
      ).data;

      this.groups.sort((a, b) => 
        a.description.localeCompare(b.description, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      )

      if (typeof this.groups.find((x) => x.id === this.undefinedGroupId) === 'undefined') {
        this.groups.push({
          id: this.undefinedGroupId,
          description: 'Not Grouped',
        });
      }

      if (this.groupsForDisplay[0]) {
        this.active_section = this.groupsForDisplay[0].id;
      }
    },
    async loadSettings(categoryChange, newSearch) {
      if (newSearch) {
        this.selectedPrices = [];
        this.loading = true;
      }
      if (categoryChange || this.filter) {
        this.selectedPrices = [];
        this.noMoreResults = false;
        this.page = 0;
        this.scrollToTop();
      }

      // we don't need to reload settings if we are just changing categories when looking at search results
      // or if we are changing categories, not filtering, and there are no more results to load.
      if (
        (categoryChange && this.filter) ||
        (categoryChange && !this.filter && this.noMoreResults)
      ) {
        this.selectedPrices = [];
        this.loading = false;
        return;
      }

      const offset = newSearch ? 0 : this.page + (categoryChange ? 0 : this.anomalies.length);
      const limit = this.filter ? 9999 : this.entriesPerPage;
      if (!this.active_section && !this.filter) {
        return;
      }
      const anomalies = (
        await window.touch.staffGetBasePrices(
          this.datasetId,
          limit,
          offset,
          this.filter ? undefined : this.active_section,
          this.filter,
        )
      ).financials
        .map((anomaly) => ({
          ...anomaly,
        }))
        .sort((a, b) => 
          a.description.localeCompare(b.description, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        )
        .map((anomoly) => ({
          ...anomoly,
          // eslint-disable-next-line no-nested-ternary
          overrideValue: anomoly.foc ? 0 : anomoly.overrideValue ? anomoly.overrideValue : null,
          groupText: this.groups.find((group) => group.id === anomoly.groupId).description,
        }));

      if (categoryChange || newSearch) {
        this.selectedPrices = [];
        this.original_anomalies = JSON.parse(JSON.stringify(anomalies));
        this.anomalies = anomalies;
        if (this.page === 0 && this.anomalies.length < this.entriesPerPage) {
          this.noMoreResults = true;
        }
        if (newSearch && this.groups.find((x) => x.id === this.active_section) === undefined) {
          this.active_section = this.groups.filter((x) =>
            this.anomalies.find((y) => y.groupId === x.id),
          )[0].id;
        }
      } else {
        this.original_anomalies = [
          ...this.original_anomalies,
          ...JSON.parse(JSON.stringify(anomalies)),
        ];
        this.anomalies = [...this.anomalies, ...anomalies];
        if (anomalies.length === 0) {
          this.noMoreResults = true;
        }
      }
      this.loading = false;
    },
    async handleGroupAdd() {
      this.loading = true;
      await window.touch.staffUpdateFinancialGroups(this.datasetId, [
        {
          Description: this.newGroupName,
          ListIndex: this.groups.length,
        },
      ]);
      await this.loadGroups();
      this.newGroupName = '';
      this.loading = false;
    },
    async updateGroups() {
      const groupDetails = [];
      for (let i = 0; i < this.groups.length; i += 1) {
        if (this.groups[i].id === this.undefinedGroupId) {
          /* eslint-disable */
          continue;
          /* eslint-enable */
        }
        groupDetails.push({
          Id: this.groups[i].id,
          Description: this.groups[i].description,
        });
      }
      await window.touch.staffUpdateFinancialGroups(this.datasetId, groupDetails);
    },
    async saveSettings() {
      this.loading = true;

      if (this.validateInput()) {
        await this.updateAnomalies(this.modifiedAnomalies());
        await this.updateGroups();
        this.fullRefresh();
        this.isEdited = false;
        this.pending_section = undefined;
      }

      this.loading = false;
    },
    validateInput() {
      let isValid = true;

      this.anomalies.forEach((anomaly) => {
        if (!validate.isNumber(Number(anomaly.overrideValue))) {
          isValid = false;
        }
      });

      return isValid;
    },
  },
};
</script>
<style scoped>
.save-btn-container {
  position: fixed;
  bottom: 0px;
  right: 0px;
}
.table-container {
  margin-bottom: 75px;
}

.pricing-category-options-list-wrapper {
  min-height: 100%;
}
</style>
