<template>
  <div class="p-10 w-full h-full">
    <div
      v-if="isFilter"
      class="absolute top-0 left-0 w-full h-full z-40"
      @click="isFilter = false"
    ></div>
    <div v-if="racks.length > 0" class="relative w-full h-full">
      <GoogleMaps :markers="filteredRacks"></GoogleMaps>
      <div v-if="isFilter" class="absolute p-8 top-0 left-0 w-full h-full pointer-events-none z-50">
        <div
          class="relative bg-white h-full rounded p-5 pointer-events-auto shadow-lg"
          :style="{ 'min-width': '300px', 'max-width': '800px' }"
        >
          <div class="flex items-center px-2">
            <h2 class="mr-4"><strong>Filter</strong></h2>
            <div
              v-if="filteredLength > 0"
              class="text-center bg-red-400 py-1 px-4 rounded cursor-pointer"
              @click="(filterStatuses = []), (filterRackTypes = []), (filterLocationTypes = [])"
            >
              <strong class="text-white">Reset Filter</strong>
            </div>
            <div
              class="ml-auto p-1 bg-white cursor-pointer rounded bg-gray-300"
              @click="isFilter = false"
            >
              <i class="fa fa-times w-5 h-1 text-center"></i>
            </div>
          </div>
          <div class="grid grid-cols-2 auto-rows-min">
            <div class="mt-3 px-2">
              <h3><strong>Types</strong></h3>
              <div
                v-for="rackType in rackTypes"
                :key="rackType.id"
                class="flex bg-gray-300 rounded mb-2 px-2 py-1 cursor-pointer"
                :style="{ 'flex:': '1 1 80px' }"
                :class="{ 'bg-green-400': filterRackTypes.includes(rackType.id) }"
                @click="
                  filterRackTypes.includes(rackType.id)
                    ? filterRackTypes.splice(filterRackTypes.indexOf(rackType.id), 1)
                    : filterRackTypes.push(rackType.id)
                "
              >
                <div class="capitalize">
                  {{ rackType.name }}
                </div>
                <div class="ml-auto">
                  {{ racks.filter((x) => x.rackTypeId === rackType.id).length }}
                </div>
              </div>
            </div>
            <div class="mt-3 px-2">
              <h3><strong>Locations</strong></h3>
              <div
                v-for="locationType in locationTypes.filter((x) => x.locations.length > 0)"
                :key="locationType.id"
              >
                <div class="capitalize text-right">
                  {{ locationType[0].toLowerCase() }}
                </div>
                <div
                  v-for="location in locationType.locations"
                  :key="location.id"
                  class="flex bg-gray-300 rounded mb-2 px-2 py-1 cursor-pointer"
                  :class="{ 'bg-green-400 ': filterLocationTypes.includes(location.id) }"
                  @click="
                    filterLocationTypes.includes(location.id)
                      ? filterLocationTypes.splice(filterLocationTypes.indexOf(location.id), 1)
                      : filterLocationTypes.push(location.id)
                  "
                >
                  <div class="capitalize">
                    {{ location.name }}
                  </div>
                  <div class="ml-auto">
                    {{ racks.filter((x) => x.locationId === location.id).length }}
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 px-2">
              <h3><strong>Statuses</strong></h3>
              <div
                v-for="status in statuses"
                :key="status.id"
                class="flex bg-gray-300 rounded mb-2 px-2 py-1 cursor-pointer"
                :class="{ 'bg-green-400 ': filterStatuses.includes(status[1]) }"
                @click="
                  filterStatuses.includes(status[1])
                    ? filterStatuses.splice(filterStatuses.indexOf(status[1]), 1)
                    : filterStatuses.push(status[1])
                "
              >
                <div class="capitalize">
                  {{ status[0].toLowerCase() }}
                </div>
                <div class="ml-auto">
                  {{ racks.filter((x) => x.status === status[1]).length }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="absolute top-0 left-0 p-8">
        <div
          class="relative bg-white cursor-pointer rounded shadow-lg text-center"
          :style="{ height: '50px', width: '50px' }"
          @click="isFilter = true"
        >
          <i class="fa fa-filter w-6 h-6" :style="{ 'line-height': '50px' }"></i>
          <div
            class="absolute bg-red-400 text-center rounded"
            :style="{ height: '20px', width: '20px', top: '-5px', right: '-5px' }"
          >
            <span class="text-white" :style="{ 'line-height': '20px' }">{{ filteredLength }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GoogleMaps from '@/components/shared/googleMaps.vue';

export default {
  components: {
    GoogleMaps,
  },
  data() {
    return {
      racks: [],
      statuses: [],
      rackTypes: [],
      locations: [],
      filterStatuses: [],
      filterRackTypes: [],
      filterLocationTypes: [],
      isFilter: false,
      epwinHQ: {
        lat: 52.67803,
        lng: -2.41285,
      },
    };
  },
  computed: {
    filteredRacks() {
      return this.filteredLength > 0
        ? this.racks.filter(
            (x) =>
              (this.filterStatuses.includes(x.status) || this.filterStatuses.length === 0) &&
              (this.filterRackTypes.includes(x.rackTypeId) || this.filterRackTypes.length === 0) &&
              (this.filterLocationTypes.includes(x.locationId) ||
                this.filterLocationTypes.length === 0),
          )
        : this.racks;
    },
    filteredLength() {
      return this.filterStatuses.concat(this.filterRackTypes).concat(this.filterLocationTypes)
        .length;
    },
  },
  async mounted() {
    this.statuses = Object.entries(this.enums.RackStatus);
    this.locationTypes = Object.entries(this.enums.RackLocationTypes);
    this.locationTypes = this.locationTypes.map((x) => ({ ...x, locations: [] }));
    this.rackTypes = await window.touch.listRackTypes();

    const racks = await window.touch.getRackList();
    for (let i = 0; i < racks.length; i += 1) {
      this.racks.push({
        position:
          racks[i].latitude + racks[i].longitude === 0
            ? this.epwinHQ
            : { lat: racks[i].latitude, lng: racks[i].longitude },
        status: racks[i].status,
        rackTypeId: racks[i].rackTypeId,
        locationId: racks[i].locationId,
      });

      if (
        racks[i].location &&
        !this.locationTypes[racks[i].locationType].locations.some(
          (x) => x.id === racks[i].locationId,
        )
      ) {
        this.locationTypes[racks[i].locationType].locations.push({
          id: racks[i].locationId,
          name: racks[i].location,
        });
      }
    }
    if (racks.length === 0) {
      this.alertBox().fire({
        title: 'Empty Rack List',
        icon: 'warning',
      });
    }
  },
};
</script>
