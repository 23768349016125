<template>
  <div>
    <div class="xs:p-10 min-h-screen">
      <div class="bg-white p-3 xs:p-10 border">
        <div class="flex flex-col sm:flex-row relative border-b mb-10 pb-10">
          <div class="flex flex-col">
            <div class="flex flex-col justify-around text-base mb-3">
              <div>Meta title</div>
            </div>
            <div class="">
              <input v-model="siteTitle" type="text" class="form-control max-w-md" />
            </div>
            <div class="text-gray-600 text-base mt-3 max-w-3xl">
              Meta tags are used to provide additional information about a page to search engines and
              are displayed within the tab title.
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row relative border-b mb-10 pb-10">
          <div class="new-image-zone w-full">
            <div class="flex flex-col justify-around text-base">
              <div>Primary Login Image</div>
            </div>
            <div class="text-gray-600 text-base my-3">
              This is the primary image that is displayed on your TOUCH Portal login page where your
              customers will login.
            </div>
            <div>
              <div v-if="loginImage.src">
                <div class="btn-action" @click.prevent="resetImage(loginImage.imageType)">Reset To Default</div>
              </div>
              <vue-dropzone
                v-else
                id="dropzone"
                ref="fileUploadDropzone"
                :options="dropzoneOptions"
                :use-custom-slot="true"
                class="p-0 hover:bg-white"
                style="min-height: auto"
                @vdropzone-file-added="fileAddedToDropZone($event, loginImage.imageType)"
                @vdropzone-removed-file="fileRemovedFromDropZone(loginImage.imageType)"
              >
                <div class="max-w-sm text-center block border border-gray-200 p-6 flex flex-col justify-around text-black hover:bg-gray-200 cursor-pointer">
                  <div class="mb-1">Drag and drop image file</div>
                  <div class="mb-1">or</div>
                  <div>
                    <a class="btn-action w-32"> Select Image </a>
                  </div>
                </div>
              </vue-dropzone>
            </div>
            <div class="text-gray-500 py-3">
              <div>Required file type: JPG, PNG.</div>
              <div>Recommended size: 440px by 220px</div>
              <div>Max File Size: 1MB</div>
            </div>
          </div>
          <div class="flex flex-col sm:ml-auto">
            <div v-if="loginImage.src" class="flex flex-col justify-center border border-gray-300" style="min-height: 300px; width: 400px;">
              <img :src="loginImage.src" style="max-height: 200px" />
            </div>
            <div v-else class="border border-gray-300" style="min-height: 300px; width: 400px;">
              <img src="/images/login-image.png" />
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row relative border-b mb-10 pb-10">
          <div class="new-image-zone w-full">
            <div class="flex flex-col justify-around text-base">
              <div>Login Page Background Image</div>
            </div>
            <div class="text-gray-600 text-base my-3">
              This is the background image that is displayed on your TOUCH Portal login page where your
              customers will login.
            </div>
            <div>
              <div v-if="secondaryLoginImage.src">
                <div class="btn-action" @click.prevent="resetImage(secondaryLoginImage.imageType)">Reset To Default</div>
              </div>
              <vue-dropzone
                v-else
                id="dropzone"
                ref="fileUploadDropzone"
                :options="dropzoneOptions"
                :use-custom-slot="true"
                class="p-0 hover:bg-white"
                style="min-height: auto"
                @vdropzone-file-added="fileAddedToDropZone($event, secondaryLoginImage.imageType)"
                @vdropzone-removed-file="fileRemovedFromDropZone(secondaryLoginImage.imageType)"
              >
                <div class="max-w-sm text-center block border border-gray-200 p-6 flex flex-col justify-around text-black hover:bg-gray-200 cursor-pointer">
                  <div class="mb-1">Drag and drop image file</div>
                  <div class="mb-1">or</div>
                  <div>
                    <a class="btn-action w-32"> Select Image </a>
                  </div>
                </div>
              </vue-dropzone>
            </div>
            <div class="text-gray-500 py-3">
              <div>Required file type: JPG, PNG.</div>
              <div>Recommended size: 440px by 220px</div>
              <div>Max File Size: 1MB</div>
            </div>
          </div>
          <div class="flex flex-col sm:ml-auto">
            <div
              v-if="secondaryLoginImage.src"
              class="flex flex-col justify-center border border-gray-300"
              style="min-height: 300px; width: 400px;"
            >
              <img :src="secondaryLoginImage.src" style="max-height: 200px" />
            </div>
            <div v-else class="border border-gray-300" style="min-height: 300px; width: 400px;">
              <img src="/images/login-background.png" style="max-height: 200px" />
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-5 relative pb-10 border-b">
          <div class="flex flex-col justify-around text-base">
            <div>Portal Login Page Text</div>
          </div>
          <div class="text-gray-600 text-base">
            <strong>Location:</strong> This is the text displayed on your TOUCH Portal login page where your customers will login.
          </div>
          <div class="flex flex-col">
            <div class="flex flex-col justify-around text-base mb-3">
              <div>Header</div>
              <div class="text-gray-600 text-base">
                This will be displayed on the Portal login page as the heading text.
              </div>
            </div>
            <div class="">
              <input v-model="loginHeader" type="text" class="form-control max-w-md" />
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-col justify-around text-base mb-3">
              <div>Sub-Header</div>
              <div class="text-gray-600 text-base">
                This will be displayed on the Portal login page as the sub-heading text.
              </div>
            </div>
            <div class="">
              <input v-model="loginSubHeader" type="text" class="form-control max-w-md" />
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-col justify-around text-base mb-3">
              <div>Body Text</div>
              <div class="text-gray-600 text-base">
                This will be displayed on the Portal login page as welcome text underneath the headers.
              </div>
            </div>
            <div class="">
              <input v-model="loginWelcomeText" type="text" class="form-control max-w-md" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sticky bottom-0 bg-white p-3 xs:p-4 text-right border-gray-300 border-t">
      <div class="flex flex-col xs:flex-row justify-between">
        <div class="ml-auto mt-2 xs:mt-0">
          <button class="btn-action btn-lg w-full xs:w-auto" @click.prevent.stop="save()">
            {{ $t('Save Changes') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import imageHelper from '@/mixins/imageHelper';
import validate from 'validate.js';

const imageTypeEnum = {
  LOGIN: 1,
  SECONDARY_LOGIN: 2,
};

export default {
  components: {
    'vue-dropzone': vue2Dropzone,
  },
  mixins: [imageHelper],
  data() {
    return {
      images: [
        {
          imageType: 1,
          src: '',
          newImage: '',
          reset: false,
          default: '',
        },
        {
          imageType: 2,
          src: '',
          newImage: '',
          reset: false,
          default: '',
        },
      ],
      loginHeader: undefined,
      loginSubHeader: undefined,
      loginWelcomeText: undefined,
      siteTitle: undefined,
      imageTypeEnum,
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        addRemoveLinks: true,
      },
    };
  },
  computed: {
    loginImage() {
      return this.images.find((x) => x.imageType === imageTypeEnum.LOGIN);
    },
    secondaryLoginImage() {
      return this.images.find((x) => x.imageType === imageTypeEnum.SECONDARY_LOGIN);
    },
    newImages() {
      return typeof this.images.find((x) => x.newImage) !== 'undefined';
    },
    deletedImages() {
      return typeof this.images.find((x) => x.reset) !== 'undefined';
    },
  },
  async mounted() {
    await this.updateCurrentImages();
  },
  methods: {
    ...mapActions({
      updateStyles: 'style/updateCompanyStyles',
      getStyles: 'style/getCompanyStyles',
      deleteStyles: 'style/deleteCompanyStyling',
    }),
    resetImage(imageType) {
      this.fileRemovedFromDropZone(imageType);
      const image = this.getImageDetails(imageType);
      image.reset = true;
      image.src = '';
    },
    getImageDetails(imageType) {
      return this.images.find((x) => x.imageType === imageType);
    },
    async fileAddedToDropZone(uploadedFile, imageType) {
      this.errors = [];
      if (!uploadedFile) {
        return;
      }

      const extension = uploadedFile.name.replace(/^.*\./, '');
      const allowedExtensions = ['jpg', 'jpeg', 'png'];

      if (!allowedExtensions.includes(extension.toLowerCase())) {
        this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          text: 'File type not allowed, please ensure the file is one of the following formats: jpg, png',
        });
        return;
      }
      const details = this.getImageDetails(imageType);
      if (details.isPartOfCustomStyling) {
        details.newImage = await this.imageToBase64WithPrefix(uploadedFile);
        details.reset = false;
      } else {
        details.newImage = await this.imageToBase64(uploadedFile);
      }
    },
    fileRemovedFromDropZone(imageType) {
      this.getImageDetails(imageType).newImage = null;
    },
    async save() {
      const validationErrors = validate(
        {
          siteTitle: this.siteTitle,
        },
        {
          siteTitle: {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      if (this.deletedImages) {
        await this.deleteStyles({
          portalPageLoginImage: this.loginImage.reset,
          portalPageLoginSecondaryImage: this.secondaryLoginImage.reset,
        });
        this.images = this.images.map((x) => {
          x.reset = false;
          return x;
        });
      }
      try {
        await this.updateStyles({
          portalPageLoginImage: this.loginImage.newImage,
          portalPageLoginSecondaryImage: this.secondaryLoginImage.newImage,
          portalPageLoginHeader: this.loginHeader,
          portalPageSubHeader: this.loginSubHeader,
          portalPageLoginWelcomeText: this.loginWelcomeText,
          siteTitle: this.siteTitle,
        });
        await this.updateCurrentImages(); 
        this.alertBox().fire({
          title: 'Settings Updated',
          icon: 'success',
        });
      }
      catch (error) {
        this.alertBox().fire({
          title: 'There has been an issue updating your settings',
          icon: 'error',
        });
      }
    },
    resetSrcsToDefault() {
      this.images = this.images.map((x) => {
        x.src = '';
        return x;
      });
    },
    async updateCurrentImages() {
      const styles = await this.getStyles();
      this.resetSrcsToDefault();
      const indexLogin = this.images.findIndex((x) => x.imageType === imageTypeEnum.LOGIN)
      this.$set(this.images, indexLogin, {
        src: styles?.portalPageStyling?.loginImageUri,
        reset: false,
        imageType: imageTypeEnum.LOGIN,
      })
      const indexSecondaryLogin = this.images.findIndex((x) => x.imageType === imageTypeEnum.SECONDARY_LOGIN)
      this.$set(this.images, indexSecondaryLogin, {
        src: styles?.portalPageStyling?.loginSecondaryImageUri,
        reset: false,
        imageType: imageTypeEnum.SECONDARY_LOGIN,
      })

      this.loginHeader = styles?.portalPageStyling?.loginHeader;
      this.loginSubHeader = styles?.portalPageStyling?.loginSubHeader;
      this.loginWelcomeText = styles?.portalPageStyling?.loginWelcomeText;
      this.siteTitle = styles?.siteTitle;
    },
  },
};
</script>
<style scoped>
.input {
  display: flex;
  flex-direction: column;
}
.image {
  width: 200px;
}
.preview-section {
  width: 500px;
}
.new-image-zone {
  width: 100%;
}

@media (min-width: theme("screens.sm")) {
  .new-image-zone {
    width: 700px;
  }
}
</style>
