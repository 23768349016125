<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['Basket']]" />
    </template>
    <template #header_page_title> Basket </template>
    <template #header_page_actions>
      <div class="md:px-10 flex items-center flex items-center cursor-pointer" @click="isCostsHidden = !isCostsHidden">
        <div class="relative">
          <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
          <div class="switch-toggle" :class="{'_active': !isCostsHidden }"></div>
        </div>
        <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
          <span v-text="!isCostsHidden ? 'Costs On' : 'Costs Off'"></span>
        </div>
      </div>
    </template>
    <Basket
      :customer="customer"
      :can-skip-sop="canSkipSop"
      :show-place-order-button="showPlaceOrderButton"
      :show-save-product-button="showSaveProductButton"
      :is-costs-hidden="isCostsHidden"
      url=""
      breakdown="Cost"
      @placeOrderProcess="placeOrderProcess"
      @saveQuote="saveQuote"
    >
    </Basket>
  </touch-layout>
</template>

<script>
import { mapActions } from 'vuex';
import Basket from '@/components/shared/basket/Basket.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
    Basket,
  },
  data() {
    return {
      customer: undefined,
      isCostsHidden: true,
    };
  },
  computed: {
    canSkipSop() {
      return this.customer?.customer?.skipOrderAcceptance;
    },
    showPlaceOrderButton() {
      return this.customer?.customer?.skipOrderAcceptance;
    },
    showSaveProductButton() {
      return !this.customer?.customer?.skipOrderAcceptance;
    },
  },
  async mounted() {
    this.customer = await this.customerGet();
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
    }),
    async placeOrderProcess() {
      const portalBypassSopUrl = '/checkout';
      if (this.$store.state.basket.skipSop) {
        await this.routerPush(portalBypassSopUrl);
      } else {
        await this.routerPush('/orders');
      }
    },
    async saveQuote() {
      await this.routerPush('/quotes');
    },
  },
};
</script>
