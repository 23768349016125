<template>
  <div class="bg-gray-200 w-full h-full overflow-y-auto">
    <div
      v-if="demo_mode"
      v-show="$store.state.infoPopup.activePopup === 'vendor_settings:intro_popup'"
      class="absolute top-0 left-0 right-0 bottom-0 bg-black_80 flex flex-col justify-around z-10"
    >
      <div
        class="p-10 m-auto text-center text-white bg-touch-purple"
        style="background-color: #6f4a97; max-width: 500px"
      >
        <div>
          Welcome to Vendor setup, this will allow you to input your company information for your
          customer to see on each designer and paperwork. Manage your product range and prices and
          deploy your own designer on your website
        </div>
        <div class="pt-10">
          <div
            class="border border-white p-3 hover:bg-white hover:text-touch-purple rounded cursor-pointer"
            @click="$store.state.infoPopup.activePopup = 'vendor_settings:company_details'"
          >
            Continue <i class="fal fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <loading :loading="loading" class="w-full h-full scrolling-touch xs:p-10 pt-10">
      <div class="mx-auto" style="max-width: 1550px">
        <router-link
          v-if="step_1_complete && step_2_complete"
          :to="
            product_name === 'Branded Mode'
              ? '/setup/website/settings'
              : '/dealer-network-setup/website'
          "
          class="block bg-green-200 hover:bg-green-300 p-6 lg:p-10 mb-3 sm:mb-10 relative"
        >
          <div class="flex justify-between">
            <div
              v-if="product_name === 'Dealer Network Mode'"
              class="sm:text-xl flex flex-col justify-around pr-3"
            >
              You are now ready to deploy Dealer Network Mode
            </div>
            <div
              v-if="product_name === 'Branded Mode'"
              class="sm:text-xl flex flex-col justify-around pr-3"
            >
              You are now ready to deploy Branded Mode
            </div>
            <div v-if="step_1_complete && step_2_complete" class="flex flex-col justify-around">
              <a class="bg-blue-600 text-white rounded p-2 2xs:p-3 2xs:px-8 text-xs 2xs:text-sm">
                Deploy
              </a>
            </div>
          </div>
        </router-link>
        <div class="flex flex-col-reverse xs:flex-row">
          <div>
            <div class="bg-white p-6 lg:p-10 border mb-3 sm:mb-10 relative">
              <div class="flex flex-col md:flex-row">
                <div
                  class="md:w-1/3 flex flex-row md:flex-col justify-between md:pr-6 mb-6 md:mb-0"
                >
                  <div>
                    <div class="text-xl mr-20 pr-20 md:pr-0 md:mr-0">
                      <strong>Company Details & Settings</strong>
                      <info-popup
                        :id="'vendor_settings:company_details'"
                        class=""
                        :info_message="'To begin, we need you to enter your company contact information to be displayed to a customer.'"
                        :next_id="'vendor_settings:products'"
                      ></info-popup>
                    </div>
                    <div class="text-brand-primary mt-2">* Required Setup</div>
                  </div>
                  <div
                    v-if="step_1_complete"
                    class="absolute top-0 right-0 md:mt-auto md:relative m-6 md:m-0"
                  >
                    <a
                      class="inline-block border border-green-600 text-green-600 rounded p-2 2xs:p-3 whitespace-no-wrap text-xs 2xs:text-sm"
                    >
                      Setup Complete <i class="fal fa-check ml-2 2xs:ml-3"></i>
                    </a>
                  </div>
                </div>
                <div class="md:w-2/3">
                  <div class="sm:flex">
                    <div class="sm:w-1/2 md:border-l md:pl-6 pr-6">
                      <div class="flex mb-6">
                        <div class="pr-6">
                          <div
                            class="bg-black text-white rounded w-10 h-10 text-3xl flex flex-col justify-around text-center"
                          >
                            <i class="fal fa-envelope-open-text"></i>
                          </div>
                        </div>
                        <div>
                          <strong>Email Address</strong>
                        </div>
                      </div>
                      <div class="flex mb-6">
                        <div class="pr-6">
                          <div
                            class="bg-black text-white rounded w-10 h-10 text-3xl flex flex-col justify-around text-center"
                          >
                            <i class="fal fa-browser"></i>
                          </div>
                        </div>
                        <div>
                          <strong>Website Address</strong>
                        </div>
                      </div>
                      <div class="flex mb-6 sm:mb-0">
                        <div class="pr-6">
                          <div
                            class="bg-black text-white rounded w-10 h-10 text-3xl flex flex-col justify-around text-center"
                          >
                            <i class="fal fa-phone-rotary"></i>
                          </div>
                        </div>
                        <div>
                          <strong>Phone number</strong>
                        </div>
                      </div>
                    </div>
                    <div class="sm:w-1/2 sm:border-l sm:pl-6">
                      <div class="flex mb-6">
                        <div class="pr-6">
                          <div
                            class="bg-black text-white rounded w-10 h-10 text-3xl flex flex-col justify-around text-center"
                          >
                            <i class="fal fa-map-marker-times"></i>
                          </div>
                        </div>
                        <div>
                          <strong>Address</strong>
                        </div>
                      </div>
                      <div class="flex mb-6">
                        <div class="pr-6">
                          <div
                            class="bg-black text-white rounded w-10 h-10 text-3xl flex flex-col justify-around text-center"
                          >
                            <i class="fal fa-paperclip"></i>
                          </div>
                        </div>
                        <div>
                          <strong>Logo</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6">
                    <div>
                      <router-link
                        :to="company_setup_url"
                        class="btn-primary-outline btn-lg w-full"
                      >
                        Manage Details
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="bg-white p-6 lg:p-10 border mb-3 sm:mb-10 relative">
              <div class="flex flex-col md:flex-row">
                <div
                  class="md:w-1/3 flex flex-row md:flex-col justify-between md:pr-6 mb-6 md:mb-0"
                >
                  <div>
                    <div class="text-xl mr-20 pr-20 md:pr-0 md:mr-0">
                      <strong>Products</strong>
                      <info-popup
                        :id="'vendor_settings:products'"
                        :info_message="'Select which products you sell, these will then be shown on your sales and survey interface and your branded mode if selected.'"
                        :next_id="'vendor_settings:customer_items'"
                      ></info-popup>
                    </div>
                    <div class="text-brand-primary mt-2">* Required Setup</div>
                  </div>
                  <div
                    v-if="step_2_complete"
                    class="absolute top-0 right-0 md:mt-auto md:relative m-6 md:m-0"
                  >
                    <a
                      class="inline-block border border-green-600 text-green-600 rounded p-2 2xs:p-3 whitespace-no-wrap text-xs 2xs:text-sm"
                    >
                      Setup Complete <i class="fal fa-check ml-2 2xs:ml-3"></i>
                    </a>
                  </div>
                </div>
                <div class="md:w-2/3">
                  <div class="sm:flex">
                    <div class="sm:w-1/2 md:border-l md:pl-6 sm:pr-6">
                      <div
                        v-if="product_name === 'Vendor'"
                        class="flex flex col justify-around mb-6 sm:mb-0"
                      >
                        Activate products to populate your designers. These should be products you
                        want to quote and survey to your customers
                      </div>
                      <div
                        v-if="product_name === 'Dealer Network Mode'"
                        class="flex flex col justify-around mb-6 sm:mb-0"
                      >
                        Activate products for Dealer Network Mode. These should be products you want
                        homeowners to enquire about.
                      </div>
                      <div
                        v-if="product_name === 'Branded Mode'"
                        class="flex flex col justify-around mb-6 sm:mb-0"
                      >
                        Activate products for Branded Mode. These should be products you want to
                        allow your customers to enquire about.
                      </div>
                    </div>
                    <div class="sm:w-1/2 sm:border-l sm:pl-6">
                      <div class="flex">
                        <div class="pr-6">
                          <div
                            class="bg-black text-white rounded w-10 h-10 text-3xl flex flex-col justify-around text-center"
                          >
                            <i class="fal fa-window-frame"></i>
                          </div>
                        </div>
                        <div>
                          <strong>Activate your products</strong>
                          <div class="text-gray-600">
                            These products will match what is available to quote and order.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6">
                    <div>
                      <router-link
                        :to="product_setup_url"
                        class="btn-primary-outline btn-lg w-full"
                      >
                        Activate products
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="isCompanyStylingEnabled"
              class="bg-white p-6 lg:p-10 border mb-3 sm:mb-10 relative"
            >
              <div class="flex flex-col md:flex-row">
                <div
                  class="md:w-1/3 flex flex-row md:flex-col justify-between md:pr-6 mb-6 md:mb-0"
                >
                  <div>
                    <div class="text-xl mr-20 pr-20 md:pr-0 md:mr-0">
                      <strong>Custom Styles</strong>
                    </div>
                  </div>
                </div>
                <div class="md:w-2/3">
                  <div class="sm:flex">
                    <div class="md:border-l md:pl-6 sm:pr-6">
                      <div class="flex flex col justify-around mb-6 sm:mb-0">
                        Edit images and text to personalise TOUCH for your business. You can edit
                        various areas including; Dealer Network intro and enquiry screen,
                        VirtualHome, pdfs and email correspondence to your customers.
                      </div>
                    </div>
                  </div>
                  <div class="mt-6">
                    <div>
                      <router-link
                        :to="custom_styles_url"
                        class="btn-primary-outline btn-lg w-full"
                      >
                        Manage Styles
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="!step_1_complete || !step_2_complete"
              class="mb-3 sm:mb-10 text-center text-md"
            >
              <span>In order to setup your {{ setupMode }} designer you must complete the first 2
                steps.</span>
            </div>
            <div class="bg-white p-6 lg:p-10 border mb-3 sm:mb-10 relative">
              <div
                v-if="!step_1_complete || !step_2_complete"
                class="absolute top-0 left-0 bottom-0 right-0 bg-white_80"
              ></div>
              <div class="flex flex-col md:flex-row">
                <div
                  class="md:w-1/3 flex flex-row md:flex-col justify-between md:pr-6 mb-6 md:mb-0"
                >
                  <div>
                    <div class="text-xl mr-20 pr-20 md:pr-0 md:mr-0">
                      <strong>Add {{ product_name }} to your website</strong>
                      <info-popup
                        :id="'vendor_settings:deploy'"
                        :info_message="'Follow the instructions to deploy your designer to your website.'"
                        :next_id="'vendor_header:dashboard_button'"
                      ></info-popup>
                    </div>
                    <div class="text-brand-primary mt-2">* Required Setup</div>
                    <div class="mt-3">
                      If you wish to generate leads via your own website simply upload the required
                      business documents in line with GDPR Guidelines.
                    </div>
                  </div>
                  <div
                    v-if="step_4_complete"
                    class="absolute top-0 right-0 md:mt-auto md:relative m-6 md:m-0"
                  >
                    <a
                      class="inline-block border border-green-600 text-green-600 rounded p-2 2xs:p-3 whitespace-no-wrap text-xs 2xs:text-sm"
                    >
                      Setup Complete <i class="fal fa-check ml-2 2xs:ml-3"></i>
                    </a>
                  </div>
                </div>
                <div class="md:w-2/3">
                  <div class="sm:flex">
                    <div class="sm:w-1/2 md:border-l md:pl-6 sm:pr-6 mb-6 sm:mb-0">
                      <div class="flex flex col justify-around">
                        <img :src="$t('/images/branded-landing-page-setup-designer.png')" />
                      </div>
                    </div>
                    <div class="sm:w-1/2 sm:border-l sm:pl-6">
                      <div class="flex mb-6">
                        <div class="pr-6">
                          <div
                            class="bg-black text-white rounded w-10 h-10 text-3xl flex flex-col justify-around text-center"
                          >
                            <i class="fal fa-file-invoice"></i>
                          </div>
                        </div>
                        <div>
                          <strong>Business Documents</strong>
                          <div v-if="product_name === 'Vendor'" class="text-gray-600">
                            To generate enquiries online you must supply a terms and privacy policy.
                          </div>
                          <div v-if="product_name === 'Dealer Network Mode'" class="text-gray-600">
                            To generate enquiries online you must supply a terms and privacy policy.
                          </div>
                          <div v-if="product_name === 'Branded Mode'" class="text-gray-600">
                            To comply with GDPR you must supply a terms and privacy policy.
                          </div>
                        </div>
                      </div>
                      <div class="flex">
                        <div class="pr-6">
                          <div
                            class="bg-black text-white rounded w-10 h-10 text-3xl flex flex-col justify-around text-center"
                          >
                            <i class="fal fa-check-square"></i>
                          </div>
                        </div>
                        <div>
                          <strong>Deploy {{ product_name }}</strong>
                          <div class="text-gray-600">
                            Simply copy the link and sent it to whoever manages your website.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6">
                    <div>
                      <router-link
                        :to="!step_1_complete ? '#!' : website_settings_url"
                        class="btn-primary-outline btn-lg w-full"
                      >
                        How to deploy {{ product_name }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sm:ml-10 mb-3 sm:mb-0">
            <div class="bg-green-200 p-6 text-center vendor-setup-help-sidebar">
              <div class="mb-6 flex items-center">
                <div>
                  <img src="/images/knowledge-base-header-icon.png" style="width: 100px" />
                </div>
                <div class="pl-3 text-left">
                  <strong class="text-xl">Knowledge Base</strong>
                  <div>How to videos and guides.</div>
                </div>
              </div>
              <template v-if="product_name === 'Vendor'">
                <div>
                  <img src="/images/vendor-setup-guide.png" class="w-auto max-w-full mx-auto" />
                </div>
                <div>
                  <a
                    class="rounded-full bg-white border border-black text-center p-3 w-full block"
                    href="/documents/vendor_how_to_guide.pdf"
                    target="_blank"
                  >
                    <strong>Download Guide</strong>
                  </a>
                </div>
              </template>
              <template v-if="product_name === 'Dealer Network Mode'">
                <a
                  class="bg-white hover:bg-gray-100 rounded-lg flex items-center cursor-pointer"
                  @click="openWidget(101000455583)"
                >
                  <div>
                    <img
                      src="/images/generic-youtube-thumb.png"
                      class="rounded-tl-lg rounded-bl-lg"
                      style="width: 50px"
                    />
                  </div>
                  <div class="text-left px-4">
                    <div class="text-gray-600">youtube.com</div>
                    <div>How to Setup Dealer Network</div>
                  </div>
                </a>
              </template>
              <template v-if="product_name === 'Branded Mode'">
                <a
                  target="_blank"
                  class="cursor-pointer bg-white hover:bg-gray-100 rounded-lg flex items-center mt-3"
                  @click="viewUrlFile('https://youtu.be/AskH9DcZt8M')"
                >
                  <div>
                    <img
                      src="/images/generic-youtube-thumb.png"
                      class="rounded-tl-lg rounded-bl-lg"
                      style="width: 50px"
                    />
                  </div>
                  <div class="text-left px-4">
                    <div class="text-gray-600">youtube.com</div>
                    <div>How to Purchase Branded Mode</div>
                  </div>
                </a>
                <a
                  target="_blank"
                  class="cursor-pointer bg-white hover:bg-gray-100 rounded-lg flex items-center mt-3"
                  @click="viewUrlFile('https://youtu.be/wMd1fEpC--o')"
                >
                  <div>
                    <img
                      src="/images/generic-youtube-thumb.png"
                      class="rounded-tl-lg rounded-bl-lg"
                      style="width: 50px"
                    />
                  </div>
                  <div class="text-left px-4">
                    <div class="text-gray-600">youtube.com</div>
                    <div>How to use the Virtual Home Feature</div>
                  </div>
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>
    </loading>
  </div>
</template>

<script>
import validate from 'validate.js';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  props: {
    customer_items_setup_url: String,
    custom_styles_url: String,
    company_setup_url: String,
    product_setup_url: String,
    website_settings_url: String,
    setupMode: String,
  },
  data() {
    return {
      anomalies: [],
      privacyPolicyDocument: undefined,
      termsAndConditionsDocument: undefined,
      product_overrides: {},
      loading: true,
    };
  },
  computed: {
    product_name() {
      return this.setupMode;
    },
    ...mapState('auth', {
      wizardMode: 'wizardMode',
    }),
    ...mapGetters({
      isCompanyStylingEnabled: 'style/isCompanyStylingEnabled',
      customer: 'user/getCustomer',
    }),
    prices_url() {
      if (!this.step_1_complete) {
        return '#!';
      }
      return '/setup/prices';
    },
    active_products_count() {
      if (!this.loading) {
        return this.product_overrides.filter(({ enabled }) => enabled).length;
      }
      return 0;
    },
    step_1_complete() {
      let companyDetailsOk = false;
      const settingsToCheck = {};
      if (!this.loading && this.customer) {
        companyDetailsOk = true;
        settingsToCheck.email = this.customer.eCommerceIntegration.email;
        settingsToCheck.mobilePhone = this.customer.eCommerceIntegration.mobilePhone;
        settingsToCheck.webAddress = this.customer.eCommerceIntegration.webAddress;
        settingsToCheck.addressId = this.customer.eCommerceIntegration.addressId;
        Object.values(settingsToCheck).forEach((item) => {
          if (item === null || item === 0 || item === '' || item === undefined) {
            companyDetailsOk = false;
          }
        });
      }

      return companyDetailsOk;
    },
    step_2_complete() {
      let productsOk = false;
      if (!this.loading) {
        productsOk = this.active_products_count > 0;
      }

      return productsOk;
    },
    step_3_complete() {
      // check pricing
      let isValid = true;
      let total = 0;

      if (!this.loading) {
        this.anomalies.forEach((anomaly) => {
          if (!validate.isNumber(Number(anomaly.overrideValue))) {
            isValid = false;
          } else {
            total += Number(anomaly.overrideValue);
          }
        });
      }

      if (total <= 0) {
        isValid = false;
      }

      return isValid;
    },
    step_4_complete() {
      return (
        this.privacyPolicyDocument !== undefined && this.termsAndConditionsDocument !== undefined
      );
    },
  },
  async mounted() {
    this.privacyPolicyDocument = await this.getPrivacyPolicyDocument();
    this.termsAndConditionsDocument = await this.getTermsAndConditionsDocument();
    this.product_overrides = await this.customerGetAllProductsWithOverrides();
    this.loading = false;
    this.$store.state.infoPopup.activePopup = 'vendor_settings:intro_popup';
  },
  methods: {
    ...mapActions({
      getAnomalies: 'branded/getAnomalies',
      getTermsAndConditionsDocument: 'user/getTermsAndConditionsDocument',
      getPrivacyPolicyDocument: 'user/getPrivacyPolicyDocument',
      customerGetAllProductsWithOverrides: 'user/customerGetAllProductsWithOverrides',
    }),
    viewUrlFile(url) {
      this.alertBox()
        .fire({
          title: 'You are about to open an external file link',
          html: `<p>Would you like to proceed to open the following url address?</p><br>${url}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Proceed',
          cancelButtonText: 'Cancel',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            window.open(url);
          }
        });
    },
    openWidget(articleId) {
      window.openFreshdeskWidget(articleId);
    },
  },
};
</script>
