var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"flex bg-white px-5 border-t-4 border-white whitespace-no-wrap"},[(!_vm.touch_business_user)?_c('router-link',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black",class:{
        'border-black': _vm.active_section === 'details',
        'border-white': _vm.active_section !== 'details',
      },attrs:{"to":"/my-account/details"}},[_vm._v(" My Details ")]):_vm._e(),(_vm.touch_business_user)?_c('router-link',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black",class:{
        'border-black': _vm.active_section === 'details',
        'border-white': _vm.active_section !== 'details',
      },attrs:{"to":`/branch/${_vm.$route.params.id}/details`}},[_vm._v(" Branch Details ")]):_vm._e(),(_vm.touch_business_user)?_c('router-link',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black",class:{
        'border-black': _vm.active_section === 'address',
        'border-white': _vm.active_section !== 'address',
      },attrs:{"to":`/branch/${_vm.$route.params.id}/address`}},[_vm._v(" Address ")]):_vm._e(),(!_vm.touch_business_user)?_c('router-link',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black",class:{
        'border-black': _vm.active_section === 'staff',
        'border-white': _vm.active_section !== 'staff',
      },attrs:{"to":"/my-account/staff?statusFilter=1&licenseFilter=1"}},[_vm._v(" Staff & Licenses ")]):_vm._e(),(_vm.touch_business_user)?_c('router-link',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black",class:{
        'border-black': _vm.active_section === 'staff',
        'border-white': _vm.active_section !== 'staff',
      },attrs:{"to":`/branch/${_vm.$route.params.id}/staff`}},[_vm._v(" Staff & Licenses ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }