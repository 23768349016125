<template>
  <div class="h-screen flex print:h-auto" :class="page_override_class">
    <portal-target name="portal_popup">
      <!--
      This component can be located anywhere in your App.
      The slot content of the above portal component will be rendered here.
      -->
    </portal-target>
    <portal-target name="portal_pricing_info_popup"> </portal-target>
    <component :is="sidebar_component" v-if="show_sidebar"></component>
    <div class="flex flex-col h-screen w-full">
      <TopPanel
        v-if="['/portal', '/business'].includes($route.path)"
        class="hidden sm:flex border-b"
      />
      <site-header
        :sidebar-visible="show_sidebar"
        :visualiser_place_page="visualiser_place_page"
        :on-landing-page="onLandingPage"
      >
        <template #header_page_breadcrumb>
          <TopPanel
            v-if="!['/portal', '/business'].includes($route.path)"
            class="hidden xs:flex"
            :class="{ 'text-white': touch_portal_installation }"
          >
            <slot name="header_page_breadcrumb"></slot>
          </TopPanel>
        </template>
        <template #header_page_title>
          <slot name="header_page_title"></slot>
        </template>
        <template #header_page_actions>
          <slot name="header_page_actions"></slot>
        </template>
        <template #site_header_actions_wrapper>
          <slot name="site_header_actions_wrapper"></slot>
        </template>
      </site-header>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SiteHeader from '@/components/shared/nav/SiteHeader.vue';
import TopPanel from '@/components/shared/nav/TopPanel.vue';

export default {
  name: 'TouchLayout',
  components: {
    'site-header': SiteHeader,
    TopPanel,
  },
  props: {
    vendor_settings_layout: {},
    visualiser_place_page: {},
    show_site_header_actions_wrapper: {},
    page_override_class: {
      default: '',
    },
  },
  computed: {
    ...mapState('auth', {
      wizardMode: 'wizardMode',
    }),
    showSearch() {
      return this.$route.meta.showSearch === true;
    },
    searchPlaceholder() {
      return 'Enter Search';
    },
    sidebar_component() {
      return this.touch_portal_user ? 'portal-sidebar-menu' : 'business-sidebar-menu';
    },
    show_sidebar() {
      return (
        this.authenticated_user && !this.branded_mode_user && this.$route.meta.showSidebar !== false
      );
    },
    onLandingPage() {
      return this.$route.name === 'Setup';
    },
  },
};
</script>
