<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['My Account', '/manage-touch-account/company-details'],
          ['Company Details'],
        ]"
      />
    </template>
    <template #header_page_title> Company Details </template>
    <Menu></Menu>
    <loading
      :loading="false"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden"
    >
      <CompanyDetails></CompanyDetails>
    </loading>
  </touch-layout>
</template>

<script>
import Menu from '@/components/business/manageTouchAccount/Menu.vue';
import CompanyDetails from '@/components/business/manageTouchAccount/CompanyDetails.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Menu,
    CompanyDetails,
    Breadcrumbs,
  },
};
</script>
