<template>
  <div
    id="customer_index_scrolling_outer_wrapper"
    class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch"
  >
    <CreateRack
      v-if="createRackTypeModalOpen"
      :id="createRackTypeModalModel.id"
      :initial-name="createRackTypeModalModel.name"
      @close="createRackTypeModalOpen = false"
      @save="createRack"
    />
    <div class="m-3 xs:m-10 mb-0 flex flex-row items-center justify-between">
      <div class="text-2xl font-medium">Manage Rack Types</div>
      <button class="btn-action" @click="openModal">Add Rack Type</button>
    </div>
    <div class="bg-white border m-3 xs:m-10 p-3">
      <table v-if="rackTypes.length" class="table-lined xs:table-unstacked _with-hover w-full">
        <thead>
          <tr>
            <th>#</th>
            <th>Rack Type Name</th>
          </tr>
        </thead>
        <tr v-for="rackType in rackTypes" :key="rackType.id">
          <th>{{ rackType.id }}</th>
          <th>{{ rackType.name }}</th>
          <td class="td-action-buttons">
            <div class="table-edit-btn" @click="openModal(rackType.id)">
              <i class="fa fa-pencil"></i>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import CreateRack from '@/components/business/rackManager/CreateRack.vue';

export default {
  components: {
    CreateRack,
  },
  data() {
    return {
      rackTypes: [],
      createRackTypeModalOpen: false,
      createRackTypeModalModel: undefined,
    };
  },
  async created() {
    this.loadRackTypes();
  },
  methods: {
    async loadRackTypes() {
      this.rackTypes = await window.touch.listRackTypes();
    },
    async createRack(rackType) {
      this.createRackTypeModalOpen = false;

      if (rackType.id > 0) {
        await window.touch.updateRackType(rackType.id, rackType.name);
      } else {
        await window.touch.createRackType(rackType.name);
      }
      await this.loadRackTypes();
    },
    openModal(id) {
      this.createRackTypeModalModel = this.rackTypes.find((x) => x.id === id) ?? {};
      this.createRackTypeModalOpen = true;
    },
  },
};
</script>
