<template>
  <div>
    <transition v-if="loading" name="fade-in">
      <div
        class="z-50 bg-white absolute bottom-0 left-0 right-0 top-0 p-10 flex flex-col justify-around items-center"
      >
        <div>
          <div class="loading-spinner"></div>
          <div class="flex items-center tracking-widest mt-3">Loading, please wait</div>
        </div>
      </div>
    </transition>
    <transition name="fade-in">
      <div
        v-if="saving_quote"
        class="z-10 bg-white absolute bottom-0 left-0 right-0 top-0 p-10 flex flex-col justify-around items-center"
      >
        <div>
          <div class="loading-spinner"></div>
          <div class="flex items-center tracking-widest mt-3">Saving your {{ buttonName }}</div>
        </div>
      </div>
    </transition>
    <div class="flex h-screen overflow-hidden relative">
      <div
        v-if="basketItems.length === 0 && !loading"
        class="bg-gray-200 w-full flex-grow overflow-y-auto scrolling-touch text-center"
      >
        <div class="p-10">No basket items to display</div>
        <router-link
          v-if="$store.state.basket.salesSectorType === enums.salesSectorType.RETAIL"
          :to="url + '/choose-product'"
          class="btn-primary btn-lg"
        >
          Design a Product
        </router-link>
        <router-link v-else :to="url + '/new-quote'" class="btn-primary btn-lg">
          Design a Product
        </router-link>
      </div>
      <main
        v-if="basketItems.length > 0"
        class="basket-main bg-gray-200 w-full flex-grow sm:w-1/2 md:w-2/3 flex flex-col overflow-hidden"
        :class="{ '_can-order': !isEnquiry }"
      >
        <div class="px-5 md:px-10 mt-4">
          <div v-if="branded_mode_enabled" class="flex">
            <router-link
              key="add_another"
              to="/choose-product"
              tag="button"
              class="btn-action text-xs py-3 px-3 md:px-10 w-full xs:mr-3"
              data-index="0"
            >
              <span class="pr-3"><i class="fal fa-plus"></i></span> Add Another Product
            </router-link>
            <router-link
              v-if="basketMainItems.length > 0"
              key="open-visualiser"
              :to="{ name: 'visualiser-portal' }"
              tag="button"
              class="btn-action text-xs py-3 px-3 md:px-10 w-full ml-3 hidden xs:block"
              data-index="0"
            >
              <span class="pr-3"><i class="fal fa-house"></i></span> Open Virtual Home
            </router-link>
          </div>
          <router-link
            v-else
            key="add_another"
            :to="url + '/choose-product'"
            tag="button"
            class="btn-action text-xs py-3 px-3 md:px-10 w-full"
            data-index="0"
          >
            <span class="pr-3"><i class="fal fa-plus"></i></span> Add Another Product
          </router-link>
        </div>
        <div
          class="overflow-y-auto overflow-x-hidden scrolling-touch flex-grow"
          :class="{ 'md:overflow-y-hidden': showImage || showSummary || showExtras }"
        >
          <div class="px-5 md:px-10">
            <sizing-warning v-if="!contractHasSizing"></sizing-warning>
          </div>
          <animation-staggered-slide
            class="flex md:flex-col flex-wrap p-2 md:p-10 md:pt-0 relative"
          >
            <!--            <div class="text-lg m-2 md:m-0 md:mt-6" key="basket_products_title">-->
            <!--              Products-->
            <!--            </div>-->
            <basket-tile
              v-for="(item, index) in basketMainItems"
              :key="item.key"
              :item="item"
              :data-index="index"
              :style="getTileStyle(item.key)"
              :show-sizes="contractHasSizing"
              :is-costs-hidden="isCostsHidden"
              :extras="extrasForItem(item.key)"
              @show-summary="showSummaryPanel(item.key)"
              @add-extras="showExtrasPanel(item.key)"
              @show-image="showImagePanel(item.key)"
              @delete-line-item="deleteLineItem(item.key)"
              @duplicate-line-item="duplicateLineItem(item.key)"
            />
            <stock-parts-basket-list
              v-if="cartItems.length > 0"
              key="basket_stock_parts_title"
            ></stock-parts-basket-list>

            <router-link
              v-if="!hideVirtualHomeBanner"
              key="open-visualiser"
              tag="div"
              :to="{ name: 'visualiser-portal' }"
              class="rounded-lg cursor-pointer mt-6 sm:mt-10 hidden xs:flex"
              style="background-color: #242424"
            >
              <div>
                <img src="/images/visualiser-banner-1.png" class="rounded-lg" />
              </div>
              <div class="hidden md:block">
                <img :src="virtualHomeBannerUrl" class="rounded-lg" />
              </div>
              <HelpPoint
                key="help"
                class="absolute right-0 bottom-0 mr-2 mb-2 z-10"
                freshdesk-article-id="101000456233"
              />
            </router-link>
          </animation-staggered-slide>
        </div>
      </main>
      <transition name="fade-in" mode="out-in" @after-enter="afterFadeInBasketSidebar">
        <basket-image
          v-if="showImage && loadedItem"
          ref="basket-image"
          key="basket_image"
          :item="loadedItem"
          @close="hideImagePanel()"
        />
        <basket-summary
          v-else-if="showSummary && loadedItem"
          ref="basket-summary"
          key="basket_summary"
          :show-sizes="contractHasSizing"
          :item="loadedItem"
          :extras="extrasForItem(loadedItem.key)"
          @close="hideSummaryPanel()"
        />
        <basket-extras
          v-else-if="showExtras"
          ref="basket-extras"
          key="basket_extras"
          :item-id="loadedItemId"
          :extras="basketExtraItems"
          @delete-line-item="deleteLineItem($event)"
          @close="hideExtrasPanel()"
        />
        <BasketInvisionAppLinks
          v-else-if="isBasketInvisionAppLinks"
          @close="isBasketInvisionAppLinks = false"
        />
        <basket-pricing
          v-else-if="basketItems.length > 0"
          key="basket_pricing"
          :extras="basketExtraItems"
          :customer="customer"
          :show-save-product-button="showSaveProductButton"
          :show-place-order-button="showPlaceOrderButton"
          :breakdown="breakdown"
          :is-costs-hidden="isCostsHidden"
          @save-order="saveOrder($event)"
          @add-extras="addExtrasToQuote"
          @save-quote="saveQuote()"
          @showBasketInvisionAppLinks="isBasketInvisionAppLinks = true"
        >
          <template #extras>
            <slot name="extras"></slot>
          </template>
        </basket-pricing>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import StockPartsBasketList from '@/components/shared/basket/StockPartsBasketList.vue';
import HelpPoint from '@/components/shared/HelpPoint.vue';
import Tile from '@/components/shared/basket/Tile.vue';
import BasketPricing from '@/components/shared/basket/BasketPricing.vue';
import BasketSummary from '@/components/shared/basket/BasketSummary.vue';
import BasketInvisionAppLinks from '@/components/shared/basket/BasketInvisionAppLinks.vue';
import BasketImage from '@/components/shared/basket/BasketImage.vue';
import BasketExtras from '@/components/shared/basket/BasketExtras.vue';
import SizingWarning from '@/components/shared/basket/SizingWarning.vue';

export default {
  components: {
    'basket-tile': Tile,
    'basket-pricing': BasketPricing,
    'basket-summary': BasketSummary,
    'basket-image': BasketImage,
    'basket-extras': BasketExtras,
    'sizing-warning': SizingWarning,
    'stock-parts-basket-list': StockPartsBasketList,
    BasketInvisionAppLinks,
    HelpPoint,
  },
  props: {
    customer: Object,
    customerId: Number,
    canSkipSop: Boolean,
    showSaveProductButton: Boolean,
    showPlaceOrderButton: Boolean,
    url: String,
    breakdown: String,
    isCostsHidden: Boolean,
  },
  data() {
    return {
      showSummary: false,
      showImage: false,
      showExtras: false,
      itemIndex: undefined,
      loadedItem: undefined,
      saving_quote: false,
      isBasketInvisionAppLinks: false,
      loading: true,
      virtualHomeBannerUrl: '/images/visualiser-banner-2.png',
    };
  },
  computed: {
    ...mapGetters({
      cartItems: 'stockParts/cartItems',
    }),
    ...mapState('basket', {
      basketItems: 'basket',
      isEnquiry: 'isEnquiry',
      contractIdentifier: 'contractIdentifier',
      buttonName: 'buttonName',
      contractData: 'contractData',
    }),
    ...mapGetters({
      total: 'basket/total',
      contractHasSizing: 'basket/contractHasSizing',
    }),
    hideVirtualHomeBanner() {
      return (
        this.basketItems.filter((x) => x.omitFromVisualiser).length === this.basketItems.length ||
        !this.branded_mode_enabled ||
        this.basketMainItems.length === 0
      );
    },
    prohibitedIssues() {
      return this.basketItems.filter((item) =>
        item.issues
          ? item.issues.filter(
              (issue) =>
                issue.severityType === window.enum.IssueSeverityEnumLabel.PROHIBIT ||
                issue.severityType === window.enum.IssueSeverityEnumLabel.RESTRICT,
            ).length
          : 0,
      );
    },
    loadedItemId() {
      return this.loadedItem ? this.loadedItem.itemKey : 0;
    },
    basketMainItems() {
      return this.basketItems.filter(
        (item) => item.inputType === window.enum.inputType.FENESTRATION,
      );
    },
    basketExtraItems() {
      return this.basketItems.filter((item) => item.inputType === window.enum.inputType.CUSTOMER);
    },
  },
  async mounted() {
    this.loading = true;
    if (!this.$store.getters['basket/hasContract']) {
      this.routerPush(`${this.url}/new-quote`);
    } else {
      try {
        await this.$store.dispatch('basket/refresh', true);
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    }
    this.virtualHomeBannerUrl =
      (await this.getCompanyStyles())?.virtualHomePageStyling?.bannerImageUri ??
      this.virtualHomeBannerUrl;
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getCompanyStyles: 'style/getCompanyStyles',
    }),
    tableOfDefaults(item) {
      const prefix = '<ul class="pt-3">';
      const suffix = '</ul>';

      const rows = item.specification
        .filter((spec) => spec.mustBeSelected && !spec.hasBeenSelected)
        .map((spec) => `<li>${spec.name}</li>`)
        .join('');

      return `${prefix}${rows}${suffix}`;
    },
    extrasForItem(itemKey) {
      return this.basketExtraItems.filter((extra) => extra.parentKey === itemKey);
    },
    addExtrasToQuote() {
      this.itemIndex = undefined;
      this.loadedItem = undefined;
      this.showExtras = true;
    },
    readyToBeProcessed() {
      let foundItem = this.basketMainItems.find((item) => item.invalidSpec);
      if (foundItem) {
        this.routerPush(`${this.url}/design/${foundItem.key}?isInvalidSpec=true`);
        return false;
      }

      foundItem = this.basketMainItems.find((item) => item.invalidDimensions);
      if (foundItem) {
        this.routerPush(`${this.url}/design/${foundItem.key}?tab=measurements`, () => {
          this.alertBox().fire({
            title: 'You are required to add dimensions',
            didDestroy: () => {
              setTimeout(() => {
                this.$store.commit('designer/SET_OPEN_MENU_TYPE', 'specification');
                setTimeout(() => {
                  this.$store.commit('designer/SET_OPEN_MENU_TYPE', 'sizes');
                }, 5);
              }, 5);
            },
          });
        });
        return false;
      }

      return true;
    },
    async saveQuote() {
      if (!this.readyToBeProcessed()) {
        return;
      }
      if (this.prohibitedIssues.length) {
        this.showIssuesModal();
        return;
      }
      this.saving_quote = true;
      const { jobId, quoteId } = await this.$store.dispatch('basket/saveQuote');
      this.$emit('saveQuote', quoteId, jobId);
    },
    async placeOrderProcess(bypassSOP) {
      this.saving_quote = true;
      this.$store.state.basket.skipSop = this.canSkipSop && bypassSOP;
      const { orderId } = await this.$store.dispatch('basket/updateOrder');
      this.$emit('placeOrderProcess', orderId);
    },
    saveOrder(bypassSOP) {
      if (!this.readyToBeProcessed()) {
        return;
      }
      if (this.prohibitedIssues.length) {
        this.showIssuesModal();
        return;
      }
      if (bypassSOP && this.canSkipSop) {
        this.placeOrderProcess(bypassSOP);
      } else {
        this.alertBox()
          .fire({
            title: 'Order on hold',
            text:
              this.$store.state.user.customer.financial.paymentWithOrder &&
              this.contractData.paymentStatus === this.enums.PaymentStatus.AWAITING
                ? 'To formally place the order use the Confirm Customer Order button in the order page and pay for the order at checkout.'
                : 'To formally place the order use the Confirm Customer Order button in the order page.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Ok',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.placeOrderProcess();
            }
          });
      }
    },
    showIssuesModal() {
      this.alertBox().fire({
        title: 'There are issues with some of your items',
        text: 'Please review your items with issues before continuing.',
        icon: 'warning',
      });
    },
    async showSummaryPanel(index) {
      if (this.itemIndex !== index) {
        this.showImage = false;
        this.showSummary = false;
        this.showExtras = false;
        this.itemIndex = index;
        this.loadedItem = await this.$store.dispatch('basket/loadItem', {
          itemKey: index,
          customerID: this.customerId,
        });
      }
      this.showSummary = true;
      this.showExtras = false;
      this.showImage = false;
    },
    async showExtrasPanel(index) {
      if (this.itemIndex !== index) {
        this.showExtras = false;
        this.showSummary = false;
        this.showImage = false;
        this.itemIndex = index;
        this.loadedItem = await this.$store.dispatch('basket/loadItem', {
          itemKey: index,
          customerID: this.customerId,
        });
      }
      this.showExtras = true;
      this.showSummary = false;
      this.showImage = false;
    },
    hideSummaryPanel() {
      this.showSummary = false;
    },
    async showImagePanel(index) {
      if (this.itemIndex !== index) {
        this.showImage = false;
        this.showSummary = false;
        this.showExtras = false;
        this.itemIndex = index;
        this.loadedItem = await this.$store.dispatch('basket/loadItem', {
          itemKey: index,
          customerID: this.customerId,
        });
      }
      this.showImage = true;
      this.showExtras = false;
      this.showSummary = false;
    },
    hideImagePanel() {
      this.showImage = false;
    },
    hideExtrasPanel() {
      this.showExtras = false;
    },
    getTileStyle(itemKey) {
      if (
        this.loadedItem &&
        (this.showImage || this.showSummary) &&
        itemKey !== this.loadedItem.itemKey
      ) {
        return {
          opacity: 0.15,
          pointerEvents: 'none',
        };
      }
      if (
        this.loadedItem &&
        (this.showImage || this.showSummary) &&
        itemKey === this.loadedItem.itemKey
      ) {
        return {
          pointerEvents: 'none',
        };
      }
      return {};
    },
    afterFadeInBasketSidebar() {
      if (this.showImage) {
        this.$refs['basket-image'].$el.focus();
      }
      if (this.showSummary) {
        this.$refs['basket-summary'].$el.focus();
      }
      if (this.showExtras) {
        this.$refs['basket-extras'].$el.focus();
      }
    },
    deleteLineItem(lineItemId) {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to remove this item?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch('basket/deleteLineItem', {
                lineItemId,
              })
              .then(() => {
                this.alertBox().fire({
                  title: 'Item deleted',
                  icon: 'success',
                });
              });
          }
        });
    },
    duplicateLineItem(lineItemId) {
      this.$store.dispatch('basket/duplicateLineItem', {
        lineItemId,
      });
    },
  },
};
</script>
