<template>
  <Settings
    :customer-id="customerId"
    :bus="bus"
    :prop_settings="prop_settings"
    setup="Dealer Network Designer"
    @afterSaved="afterSaved"
  />
</template>

<script>
import Settings from '@/components/shared/setup/website/Settings.vue';

export default {
  components: {
    Settings,
  },
  props: ['bus', 'prop_settings'],
  data() {
    return {
      customerId: undefined,
    };
  },
  async mounted() {
    this.customerId = await window.touch.staffGetOrganisationCustomerId();
  },
  methods: {
    afterSaved() {
      this.$store.dispatch('auth/checkWizard', {
        customerId: this.customerId,
        isStaff: true,
      });
    },
  },
};
</script>
