<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['Touch Store']]" />
    </template>
    <template #header_page_title> Branded Mode </template>
    <template #header_page_actions>
      <LiveChatButton class="mr-4" />
    </template>
    <main class="p-5 overflow-x-scroll pb-10 bg-gray-200 h-full">
      <div class="text-center">
        <div class="mb-1">
          <i class="text-2xl fa-light fa-comments"></i>
        </div>
        <div class="my-1">
          <span class="text-2xl">Have a question about Branded Mode?</span>
        </div>
        <div class="my-1">
          <span class="text-lg text-green-500 font-thin">Chat with a Branded Mode specialist</span>
        </div>
      </div>
      <div class="border-solid border border-gray-200 mt-10 rounded-lg overflow-hidden bg-white">
        <div class="text-center bg-gradient-to-r from-blue-400 to-purple-500 py-3">
          <span class="text-lg text-white font-medium">Branded Mode - Free for 1 Month</span>
        </div>
        <div class="p-5">
          <div class="xs:flex py-3 items-center mb-5 text-center sm:text-start">
            <span class="text-xl font-medium ml-5">
              {{ $store.state.auth.companyName }} - Branded Mode
            </span>
            <div class="ml-auto text-center xs:text-right mt-2 xs:mt-0">
              <router-link
                to="/touch-store/more-benefits"
                class="btn btn-md mr-1 sm-mr-0 mb-1 2xs:mb-0"
              >
                More Benefits
              </router-link>
              <button
                class="btn-action btn-md ml-1 sm:ml-0 mt-1 sm:mt-0"
                @click="$refs.redirect.redirect()"
              >
                Buy Branded Mode
              </button>
            </div>
          </div>
          <div class="md:flex">
            <div class="xs:flex md:block lg:flex flex-1 px-5">
              <div class="flex-1">
                <img src="/images/branded mode.jpg" />
              </div>
              <div class="flex-1 py-3 xs:p-3 md:px-0 lg:ml-10">
                <div>
                  <span class="text-lg font-medium">Description</span>
                </div>
                <div class="mt-5">
                  <span>
                    Branded Mode is your own lead generation software for use online, in your showroom or a customers home.
                    Powered and linked to your Fabricators ordering Portal.
                  </span>
                </div>
              </div>
            </div>
            <div class="flex-1 px-5 lg:py-3 mt-5 md:mt-0">
              <div class="mb-5">
                <span class="text-lg font-medium">Features</span>
              </div>
              <div class="grid lg:grid-cols-2" :style="{ 'grid-auto-rows': '1fr' }">
                <div class="flex rounded-lg">
                  <i class="text-2xl fa-solid fa-square-check text-green-500  align-middle mr-2"></i>
                  <span class="text-sm">Lead Generation Software</span>
                </div>
                <div class="flex rounded-lg">
                  <i class="text-2xl fa-solid fa-square-check text-green-500  align-middle mr-2"></i>
                  <span class="text-sm">Use online, in showroom or customers home</span>
                </div>
                <div class="flex rounded-lg">
                  <i class="text-2xl fa-solid fa-square-check text-green-500  align-middle mr-2"></i>
                  <span class="text-sm">Add prices to quotes</span>
                </div>
                <div class="flex rounded-lg">
                  <i class="text-2xl fa-solid fa-square-check text-green-500  align-middle mr-2"></i>
                  <span class="text-sm">Quote management</span>
                </div>
                <div class="flex rounded-lg">
                  <i class="text-2xl fa-solid fa-square-check text-green-500  align-middle mr-2"></i>
                  <span class="text-sm">Home Visualiser</span>
                </div>
                <div class="flex rounded-lg">
                  <i class="text-2xl fa-solid fa-square-check text-green-500  align-middle mr-2"></i>
                  <span class="text-sm">Personalised Paperwork</span>
                </div>
                <div class="flex rounded-lg">
                  <i class="text-2xl fa-solid fa-square-check text-green-500  align-middle mr-2"></i>
                  <span class="text-sm">Accurate Product Imagery</span>
                </div>
                <div class="flex rounded-lg">
                  <i class="text-2xl fa-solid fa-square-check text-green-500 align-middle mr-2"></i>
                  <span class="text-sm">
                    Live Data - accurate and up to date with
                    {{ $store.state.auth.companyName }} Ordering
                  </span>
                </div>
                <div class="flex rounded-lg">
                  <i class="text-2xl fa-solid fa-square-check text-green-500  align-middle mr-2"></i>
                  <span class="text-sm">
                    Push orders directly to {{ $store.state.auth.companyName }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="border-solid border-t border-gray-200 py-5 px-8 cursor-pointer"
          @click="showSteps = !showSteps"
        >
          <div class="flex items-center">
            <span class="font-medium text-2xl">
              How to sign up to {{ $store.state.auth.companyName }} Branded Mode?
            </span>
            <i v-if="showSteps" class="ml-auto text-2xl fa fa-chevron-down text-black"></i>
            <i v-else class="ml-auto text-2xl fa fa-chevron-right text-black"></i>
          </div>
          <div v-if="showSteps" class="p-5">
            <div class="border-solid border-l-2 border-black my-5">
              <div class="flex pb-5" :style="{ 'margin-left': '-13px' }">
                <div
                  class="rounded-full border-solid border-2 border-black bg-white mr-5"
                  :style="{
                    'min-width': '25px',
                    'min-height': '25px',
                    'max-width': '25px',
                    'max-height': '25px',
                  }"
                ></div>
                <span>1. Select 'Buy Branded Mode'</span>
              </div>
              <div class="flex pb-5" :style="{ 'margin-left': '-13px' }">
                <div
                  class="rounded-full border-solid border-2 border-black bg-white mr-5"
                  :style="{
                    'min-width': '25px',
                    'min-height': '25px',
                    'max-width': '25px',
                    'max-height': '25px',
                  }"
                ></div>
                <div>
                  <span>
                    2. You will then be directed to the TOUCH store registration page - to register
                  </span>
                  <br />
                  <span>
                    (Your TOUCH Store login is where you manage your subscription and billing this is separate to your Portal login)
                  </span>
                </div>
              </div>
              <div class="flex pb-5" :style="{ 'margin-left': '-13px' }">
                <div
                  class="rounded-full border-solid border-2 border-black bg-white mr-5"
                  :style="{
                    'min-width': '25px',
                    'min-height': '25px',
                    'max-width': '25px',
                    'max-height': '25px',
                  }"
                ></div>
                <span>3. Once registered you will be redirected to the basket area.</span>
              </div>
              <div class="flex pb-5" :style="{ 'margin-left': '-13px' }">
                <div
                  class="rounded-full border-solid border-2 border-black bg-white mr-5"
                  :style="{
                    'min-width': '25px',
                    'min-height': '25px',
                    'max-width': '25px',
                    'max-height': '25px',
                  }"
                ></div>
                <span>
                  4. Add your Direct Debit details - you will not be charged for your first month.
                </span>
              </div>
              <div class="flex pt-5" :style="{ 'margin-left': '-13px' }">
                <div
                  class="rounded-full border-solid border-2 border-black bg-white mr-5"
                  :style="{
                    'min-width': '25px',
                    'min-height': '25px',
                    'max-width': '25px',
                    'max-height': '25px',
                  }"
                ></div>
                <span>
                  5. Once setup you will be able to setup your Branded Mode via your TOUCH Portal
                  account.
                </span>
              </div>
            </div>
            <div class="text-center mt-10">
              <button class="btn-action btn-md" @click.stop="$refs.redirect.redirect()">
                Buy Branded Mode
              </button>
            </div>
          </div>
        </div>
      </div>
      <Redirect ref="redirect" />
    </main>
  </touch-layout>
</template>

<script>
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Redirect from '@/components/branded/touchStore/Redirect.vue';
import LiveChatButton from '@/components/shared/nav/LiveChatButton.vue';

export default {
  components: {
    Breadcrumbs,
    Redirect,
    LiveChatButton,
  },
  data() {
    return {
      showSteps: false,
    };
  },
  destroyed() {
    window.LiveChatWidget.off('ready', this.readyCallback);
    window.LiveChatWidget.off('availability_changed', this.availabilityChangedCallback);
  },
};
</script>
