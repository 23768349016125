<template>
  <div class="flex-1 py-3 flex flex-col">
    <div class="bg-blue-100 border p-3 h-full">
      <div class="flex items-center border-b font-bold mb-3 pb-3">
        <div>
          Calculated {{ breakdown }}
        </div>
        <div class="ml-auto">
          <slot></slot>
        </div>
      </div>
      <table v-if="!isCostsHidden">
        <tbody>
          <tr>
            <td class="pb-1 w-32">Subtotal:</td>
            <td class="flex items-center gap-2 pb-1 text-brand-primary">
              <span v-html="formatCurrency(touchJob.currency(), touchJob.itemCost())"></span>
              <div
                v-if="
                  touchJob.deliveryCost() > 0 ||
                    touchJob.fittingPrice() > 0 ||
                    touchJob.surveyPrice() > 0
                "
                class="relative block"
              >
                <div
                  v-if="isPriceBreakdown"
                  class="info bg-black rounded absolute p-4 text-left w-64"
                  :style="{ bottom: 'calc(100% + 25px)', right: '-30px' }"
                >
                  <div class="flex flex-col gap-1 text-white">
                    <span class="opacity-50 mb-2">Subtotal includes:</span>
                    <div v-if="touchJob.deliveryCost() > 0">
                      <span><span
                        v-html="formatCurrency(touchJob.currency(), touchJob.deliveryCost())"
                      ></span>
                        delivery charge</span>
                    </div>
                    <div v-if="touchJob.fittingPrice() > 0">
                      <span><span
                        v-html="formatCurrency(touchJob.currency(), touchJob.fittingPrice())"
                      ></span>
                        fitting charge</span>
                    </div>
                    <div v-if="touchJob.surveyPrice() > 0">
                      <span><span
                        v-html="formatCurrency(touchJob.currency(), touchJob.surveyPrice())"
                      ></span>
                        survey charge</span>
                    </div>
                  </div>
                </div>
                <i
                  class="text-black cursor-pointer fa-regular fa-circle-info text-lg align-middle"
                  @mouseover="isPriceBreakdown = true"
                  @mouseleave="isPriceBreakdown = false"
                >
                </i>
              </div>
            </td>
          </tr>
          <tr>
            <td class="pb-1 w-32">VAT:</td>
            <td
              class="pb-1 text-brand-primary"
              v-html="formatCurrency(touchJob.currency(), touchJob.taxCost())"
            ></td>
          </tr>
          <tr>
            <td class="w-32">Total:</td>
            <td
              class="text-brand-primary"
              v-html="formatCurrency(touchJob.currency(), touchJob.totalCost())"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import currencyHelper from '@/mixins/currencyHelper';

export default {
  mixins: [currencyHelper],
  props: ['touchJob', 'breakdown', 'isCostsHidden',],
  data() {
    return {
      isPriceBreakdown: false,
    };
  },
};
</script>

<style scoped>
.info:before {
  content: "";
  position: absolute;
  top: 100%;
  right: 20px;
  width: 0;
  border-top: 20px solid black;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}
</style>
