<template>
  <div>
    <div class="xl:flex xl:items-center xl:mb-2">
      <button class="btn btn-action w-full m-4" @click="change">
        {{ spec.name }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['spec', 'loading', 'value', 'showAdvancedModeToggle'],
  data() {
    return {
      open_option_group: null,
      open_search: null,
      searchQuery: '',
    };
  },
  computed: {},
  methods: {
    change() {
      const heading = this.spec.optionHeadingId;
      const value = this.spec.instantSelectionOptionItemId;
      this.$emit('change', { heading, value });
    },
  },
};
</script>
