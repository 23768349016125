<template>
  <loading :loading="loading">
    <div>
      <div class="flex flex-col gap-2 lg:gap-4 lg:grid grid-cols-2">
        <div class="col-span-2">
          <div class="opacity-50">Name</div>
          <input v-model="name" type="text" class="w-full bg-gray-200 p-2 rounded-lg" />
        </div>
        <div class="col-span-2">
          <div class="opacity-50">Unit Of Measure</div>
          <div class="w-full bg-gray-200 p-2 rounded-lg">
            <select id="unit_of_measure" v-model="unit_of_measure" class="w-full bg-gray-200">
              <option v-if="unit_of_measure === null" :value="null">
                Please Select Unit Of Measure...
              </option>
              <option
                v-for="index in 7"
                :key="index"
                :value="index"
                v-text="$t(`unit_of_measure[${index}]`)"
              ></option>
            </select>
          </div>
        </div>
        <div class="col-span-2 mt-2">
          <div class="flex justify-between gap-2">
            <button
              class="btn flex-1"
              :class="{ 'btn-action': type === enums.fittingType.SUPPLY_ONLY }"
              @click.prevent="type = enums.fittingType.SUPPLY_ONLY"
            >
              Supply only
            </button>
            <button
              class="btn flex-1"
              :class="{ 'btn-action': type === enums.fittingType.SUPPLY_AND_FIT }"
              @click.prevent="type = enums.fittingType.SUPPLY_AND_FIT"
            >
              Supply &amp; Fit
            </button>
          </div>
        </div>
        <div>
          <div
            class="opacity-50"
            v-html="
              unit_of_measure
                ? 'Cost ' + $t(`unit_of_measure_cost_label[${unit_of_measure}]`)
                : 'Cost'
            "
          ></div>
          <div class="flex relative flex-grow w-full">
            <div class="bg-gray-200 rounded-l-lg pr-2 overflow-hidden">
              <div class="py-2 px-3 border-r bg-gray-200">
                {{ currencySymbol(currency) }}
              </div>
            </div>
            <input
              id="price"
              v-model.number="cost"
              type="text"
              class="flex-grow bg-gray-200 rounded-r-lg"
            />
          </div>
        </div>
        <div>
          <div
            class="opacity-50"
            v-html="
              unit_of_measure
                ? 'Price ' + $t(`unit_of_measure_price_label[${unit_of_measure}]`)
                : 'Price'
            "
          ></div>
          <div class="flex relative flex-grow w-full">
            <div class="bg-gray-200 rounded-l-lg pr-2 overflow-hidden">
              <div class="py-2 px-3 border-r bg-gray-200">
                {{ currencySymbol(currency) }}
              </div>
            </div>
            <input
              id="price"
              v-model.number="price"
              type="text"
              class="flex-grow bg-gray-200 rounded-r-lg"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2 lg:gap-4 mt-4">
        <div v-if="canUseProductMeasurements" class="flex flex-col p-4 bg-gray-200 rounded-lg">
          <label class="flex items-center mb-2 sm:mb-0">
            <span>Use Product Measurements</span>
            <input v-model="useProductMeasurements" type="checkbox" class="ml-2" />
            <div class="relative ml-auto">
              <div
                v-if="isProductMeasurementsInfo"
                class="info bg-black rounded absolute p-4 text-left w-64"
                :style="{ bottom: 'calc(100% + 20px)', right: '-20px' }"
              >
                <div class="flex flex-col gap-5 text-white">
                  <div>
                    Select this to price by product measurements, you will not be able to override
                    the sizing
                  </div>
                </div>
              </div>
              <i
                class="cursor-pointer fa-regular fa-circle-info text-lg"
                @mouseover="isProductMeasurementsInfo = true"
                @mouseleave="isProductMeasurementsInfo = false"
              >
              </i>
            </div>
          </label>
        </div>
        <div v-show="isValidImage" class="flex items-center gap-5 bg-gray-200 rounded-lg p-5">
          <img
            :src="imageURL"
            style="max-height: 50px; max-width: 150px"
            @load="isValidImage = true"
          />
          <div class="btn-action ml-auto" @click="(isValidImage = false), (image_data = undefined)">
            Reset To Default
          </div>
        </div>
        <vue-dropzone
          v-if="isValidImage === false"
          id="dropzone"
          ref="fileUploadDropzone"
          class="p-0 bg-gray-200 rounded-lg flex flex-col justify-center 2xs:mb-0 dz-dont-show-progress"
          :options="dropzoneOptions"
          :use-custom-slot="true"
          @vdropzone-file-added="fileAddedToDropZone"
        >
          <div
            class="flex flex-col gap-1 text-center block border border-gray-200 p-3 flex flex-col justify-center text-black bg-gray-200 cursor-pointer"
          >
            <div>Drag and drop logo image file</div>
            <div>or</div>
            <div><a class="btn-action w-32">Select Image</a></div>
          </div>
        </vue-dropzone>
      </div>
      <template v-if="canHaveOptions">
        <div v-for="(option, i) in options" :key="i" class="my-4">
          <div v-if="option.delete !== true" class="flex">
            <div class="flex-grow">
              <div class="flex flex-col lg:flex-row lg:items-center mb-2">
                <label class="w-32" for="price">Option Name</label>
                <input
                  v-model="option.description"
                  type="text"
                  class="flex-1 bg-gray-200 p-2 rounded-lg"
                />
              </div>
              <div class="flex flex-col lg:flex-row lg:items-center mb-2">
                <label class="w-32" for="price">
                  Price (<span
                    v-html="
                      unit_of_measure
                        ? $t(`unit_of_measure_price_label[${unit_of_measure}]`)
                        : 'Price'
                    "
                  ></span>)
                </label>
                <div class="flex relative flex-grow">
                  <div class="bg-gray-200 rounded-l-lg pr-2 overflow-hidden">
                    <div class="py-2 px-3 border-r bg-gray-200">
                      {{ currencySymbol(currency) }}
                    </div>
                  </div>
                  <input
                    id="price"
                    v-model.number="option.price"
                    type="text"
                    class="flex-grow bg-gray-200 rounded-r-lg"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-around">
              <button class="ml-2 p-2 cursor-pointer hover:text-gray-600" @click="deleteOption(i)">
                <i class="fal fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="flex justify-end mt-10">
      <button v-if="canHaveOptions" class="btn" @click="addOption">Add Options</button>
      <button class="btn ml-2 btn-action" @click="save">
        {{ extra ? "Update" : "Add" }} Extra
      </button>
    </div>
  </loading>
</template>

<script>
import validate from 'validate.js';
import imageHelper from '@/mixins/imageHelper';
import vue2Dropzone from 'vue2-dropzone';
import currencyHelper from '@/mixins/currencyHelper';
import extrasHelper from '@/mixins/extrasHelper';

export default {
  components: {
    'vue-dropzone': vue2Dropzone,
  },
  mixins: [imageHelper, currencyHelper, extrasHelper],
  props: ['groupId', 'currency', 'extra'],
  data() {
    return {
      fittingType: window.enum.unitOfMeasure.FITTING_DAYS,
      name: '',
      category: null,
      unit_of_measure: null,
      price: 0,
      cost: 0,
      type: 2,
      useProductMeasurements: false,
      options: [],
      image_data: null,
      imageURL: undefined,
      loading: false,
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        addRemoveLinks: true,
      },
      isProductMeasurementsInfo: false,
      isValidImage: false,
    };
  },
  computed: {
    canHaveOptions() {
      return this.unit_of_measure !== window.enum.unitOfMeasure.FITTING_DAYS;
    },
    activeOptions() {
      return this.options.filter((option) => option.deleted !== true);
    },
  },
  async created() {
    this.category = this.groupId;
    if (this.extra) {
      this.id = this.extra.id;
      this.name = this.extra.description;
      this.unit_of_measure = this.extra.unitOfMeasure;
      this.price = this.extra.price;
      this.cost = this.extra.cost;
      this.type = this.extra.type;
      this.useProductMeasurements = this.extra.useSizesFromProduct;
      this.imageURL = `${window.touch.imageBaseURL + this.extra.image}?${new Date().getTime()}`;
      this.options = (await window.touch.customerGetExtraItemOptions(this.extra.id)).options;
      this.originalExtra = JSON.parse(JSON.stringify(this.mapExtras()));
    }
  },
  methods: {
    mapExtras() {
      return {
        id: this.id,
        name: this.name,
        unit_of_measure: this.unit_of_measure,
        price: this.price,
        cost: this.cost,
        type: this.type,
        useProductMeasurements: this.useProductMeasurements,
        options: this.options,
      };
    },
    deleteOption(index) {
      this.$set(this.options[index], 'delete', true);
    },
    addOption() {
      this.options.push({
        itemId: this.id,
        description: '',
        value: 0,
        deleted: false,
      });
    },
    inputIsValid() {
      return validate(
        {
          name: this.name,
          category: this.category,
          unitOfMeasure: this.unit_of_measure,
          price: this.price,
          cost: this.cost,
        },
        {
          name: {
            presence: { allowEmpty: false },
          },
          category: {
            presence: { allowEmpty: false },
          },
          unitOfMeasure: {
            presence: { allowEmpty: false },
          },
          price: {
            presence: { allowEmpty: false },
          },
          cost: {
            presence: { allowEmpty: false },
          },
        },
      );
    },
    async save() {
      if (this.extra) {
        this.loading = true;
      }
      const validationErrors = this.inputIsValid();

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        this.loading = false;
        return;
      }
      const { newIds, updatedIds } = await window.touch.customerUpdateExtraItems([
        {
          id: this.id,
          GroupId: this.category,
          Description: this.name,
          UnitOfMeasure: this.unit_of_measure,
          Price: this.price,
          Cost: this.cost,
          Type: this.type,
          UseSizesFromProduct: this.canUseProductMeasurements && this.useProductMeasurements,
          ImageData: this.image_data,
        },
      ]);
      const savedId = newIds.length > 0 ? newIds[0] : updatedIds[0];
      if (this.options.length > 0) {
        await this.$store.dispatch(
          'touch/UpdateExtraItemOptions',
          this.options.map((x) => ({ ...x, itemid: savedId })),
        );
      }
      this.loading = false;
      this.originalExtra = JSON.parse(JSON.stringify(this.mapExtras()));
      this.$emit('close', savedId);
    },
    async fileAddedToDropZone(uploadedFile) {
      if (!uploadedFile) {
        return;
      }

      const extension = uploadedFile.name.replace(/^.*\./, '');
      const allowedExtensions = ['jpg', 'jpeg', 'png'];

      if (!allowedExtensions.includes(extension.toLowerCase())) {
        this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          text: 'File type not allowed, please ensure the file is one of the following formats: jpg, png',
        });
        return;
      }
      this.image_data = await this.imageToBase64WithPrefix(uploadedFile);
      this.imageURL = this.image_data;
      this.isValidImage = true;
    },
    fileRemovedFromDropZone() {
      this.image_data = null;
    },
  },
};
</script>

<style scoped>
.info:before {
  content: "";
  position: absolute;
  top: 100%;
  right: 20px;
  width: 0;
  border-top: 10px solid black;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
</style>
