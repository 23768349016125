<template>
  <div class="py-3">
    <div class="p-2 h-10 w-full border flex flex-wrap items-center bg-white">
      <div class="pr-2">
        <span> {{ placeholder }}:</span>
      </div>
      <select
        :ref="'select-' + placeholder"
        v-model="staffId"
        class="flex-auto font-bold"
        required
        @change="addStaffToContract(staffId)"
        @click="isActive = !isActive"
      >
        <option :value="0">None</option>
        <option v-for="contact in contacts" :key="contact.id" class="font-bold" :value="contact.id">
          {{ contact.firstName + " " + contact.lastName }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    role: Number,
    placeholder: String,
    contractId: String,
    jobKey: Number,
    currentStaffId: Number,
  },
  data() {
    return {
      staffId: 0,
      contacts: [],
      isActive: false,
    };
  },
  async mounted() {
    if (this.currentStaffId) {
      this.staffId = this.currentStaffId;
    }
    this.contacts = await window.touch.ListOrganisationContacts(this.role);
    if (this.currentStaffId !== 0 && !this.contacts.some((x) => x.id === this.currentStaffId)) {
      const customer = await window.touch.customerGetContact(this.currentStaffId);
      this.contacts.push(customer);
    }
  },
  methods: {
    async addStaffToContract(staffId) {
      await window.touch.contractAddStaffToContract(
        this.contractId,
        this.jobKey,
        this.role,
        staffId,
      );
    },
  },
};
</script>
