import store from '../../store';

export default async (to, from, next) => {
  store.state.user.freshdeskWidgetEnabled = false;
  const isLoginValid = await store.dispatch('auth/validateLogin', 'public');
  if (isLoginValid) {
    if (from.name === null) {
      await store.dispatch(
        'touch/setBrandedContract',
        store.state.basket.contractIdentifier.contractId,
      );
    }
    next();
  } else {
    next({
      path: `/launch/${to.params.slug}`,
    });
  }
};
