export default {
  namespaced: true,
  state: {
    articles: [
      {
        duration: undefined,
        title: 'How to Buy Branded Mode',
        id: '1',
        imageURL: 'How to Buy Branded Mode.png',
        url:'https://store.bm-touch.co.uk/branded-mode/how-to-purchase'
      },
      {
        duration: undefined,
        title: 'Branded Mode Setup Guide',
        id: '2',
        imageURL: 'Branded Mode Setup Guide.png',
        url:'https://store.bm-touch.co.uk/branded-mode/how-to-set-up'
      },
      {
        duration: undefined,
        title: 'Deploying Branded Mode',
        id: '3',
        imageURL: 'Deploying Branded Mode.png',
        url:'https://store.bm-touch.co.uk/branded-mode/deployment'
      },
      {
        duration: undefined,
        title: 'Increase Sales with Branded Mode',
        id: '4',
        imageURL: 'Increase Sales with Branded Mode.png',
        url:'https://store.bm-touch.co.uk/branded-mode/benefits'
      },
    ],
    hiddenArticlesIDs: [],
  },
  mutations: {
    addHiddenArticlesIDs(state, Id) {
      state.hiddenArticlesIDs.push(Id);
    },
    removeHiddenArticlesIDs(state, Id) {
      const index = state.hiddenArticlesIDs.indexOf(Id);
      state.hiddenArticlesIDs.splice(index, 1);
    },
  },
};
