import Touch from './touch';

Touch.prototype.authAuthorisedProducts = async function authAuthorisedProducts() {
  return this.parseResponse(await this.authenticatedPost('auth/AuthorisedProducts'));
};

Touch.prototype.loginstaff = async function loginstaff(username, password) {
  const response = await this.post('auth/loginstaff', {
    Email: username,
    Password: password,
  });

  if (response.data.loginStatus === 0) {
    const userInfo = {
      id: response.data.contactId,
      companyName: response.data.companyName,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      image: response.data.image,
      username,
      email: username,
      staffLogin: true,
      admin: response.data.admin,
      token: response.data.token,
      tokenExpiry: response.data.expiresAt,
    };

    return userInfo;
  }

  return false;
};

Touch.prototype.logincustomer = async function logincustomer(username, password) {
  const response = await this.post('auth/logincustomer', {
    Email: username,
    Password: password,
  });

  if (response.data.loginStatus === 0) {
    const userInfo = {
      companyName: response.data.companyName,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      image: response.data.image,
      username,
      email: username,
      admin: response.data.admin,
      id: response.data.contactId,
      isDemo: response.data.isDemo,
      token: response.data.token,
      tokenExpiry: response.data.expiresAt,
    };

    return userInfo;
  }

  return false;
};

Touch.prototype.authAuthenticateConnectCustomer = async function authAuthenticateConnectCustomer(
  username,
  password,
  accountId,
) {
  return this.parseResponse(
    await this.authenticatedPost('auth/AuthenticateConnectCustomer', {
      Email: username,
      Password: password,
      SourceAccountId: accountId,
    }),
  );
};

Touch.prototype.authVendorGuest = async function authVendorGuest(alias, returnUrl) {
  return this.post('auth/VendorGuest', {
    Alias: alias,
    ReturnUrl: returnUrl,
  });
};

Touch.prototype.authGetJWT = function authGetJWT() {
  return this.authenticatedPost('auth/GetJWT');
};

Touch.prototype.setLogin = function setLogin(authToken, authTokenExpiry, installation) {
  this.installation = installation;
  this.token = authToken;
  this.tokenExpiry = authTokenExpiry;
};

Touch.prototype.authGetMyStoreLinkToken = async function authGetMyStoreLinkToken() {
  return (await this.authenticatedPost('auth/GetMyStoreLinkToken')).data;
};

Touch.prototype.requestPasswordReset = async function requestPasswordReset(username) {
  return this.post('auth/RequestPasswordReset', {
    Email: username,
  });
};

Touch.prototype.authGetCookie = async function authGetCookie() {
  return this.authenticatedPost({
    endpoint: '/api/',
    method: 'auth/GetCookie',
  });
};

Touch.prototype.resetPassword = async function resetPassword(token, password) {
  return this.parseResponse(
    await this.post('auth/ResetPassword', {
      Token: token,
      Password: password,
    }),
    (error) => {
      throw error;
    },
  );
};

Touch.prototype.checkPasswordResetToken = async function checkPasswordResetToken(token) {
  return this.post('auth/CheckPasswordResetToken', {
    Token: token,
  });
};

Touch.prototype.isDesignerAvailable = async function isDesignerAvailable(ecommerceAccountAlias) {
  let productId = window.enum.productType.BRANDED;
  if (window.VUE_APP_OVERRIDE === 'vendor') {
    productId = window.enum.productType.VENDOR;
  }
  return this.parseResponse(
    await this.authenticatedPost('auth/isDesignerAvailable', {
      ParentAccountAlias: process.env.VUE_APP_FABRICATOR_ALIAS,
      EcommerceAccountAlias: ecommerceAccountAlias,
      Product: Number(productId),
    }),
    () => ['unknown error'],
    (response) => response.data.issues,
  );
};

Touch.prototype.authUpdateCompanyWebSettings = async function authUpdateCompanyWebSettings(
  key,
  value,
) {
  return (
    await this.authenticatedPost('auth/UpdateCompanyWebSettings', {
      key,
      value,
    })
  ).data;
};

Touch.prototype.authGetCompanyWebSettings = async function authGetCompanyWebSettings() {
  return this.authenticatedPost('auth/GetCompanyWebSettings');
};

Touch.prototype.authGetOrganisationWebSetting = async function authGetOrganisationWebSetting(
  searchKey,
) {
  return (
    await this.authenticatedPost('auth/GetOrganisationWebSetting', {
      SearchKey: searchKey,
    })
  ).data;
};

Touch.prototype.authLoginSuperUser = async function authLoginSuperUser(username, password, email) {
  const response = await this.post('auth/LoginSuperUser', {
    Username: username,
    Password: password,
    ContactEmail: email,
  });

  if (response.data.loginStatus === 0) {
    const userInfo = {
      companyName: response.data.companyName,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      image: response.data.image,
      username,
      email: username,
      admin: response.data.admin,
      id: response.data.contactId,
      superuser: true,
      token: response.data.token,
      tokenExpiry: response.data.expiresAt,
    };

    return userInfo;
  }

  return false;
};

Touch.prototype.isProductAvailable = async function isProductAvailable(product, companyId) {
  return this.authenticatedPost('auth/IsProductAvailable', {
    Product: product,
    CompanyId: companyId,
  });
};
