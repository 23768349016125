var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"bg-white overflow-x-auto pl-3 md:pl-10"},[_c('div',{staticClass:"flex bg-white gap-10 border-t-4 border-white"},[_c('router-link',{staticClass:"flex-shrink-0",attrs:{"to":"/manage-touch-account/company-details"}},[_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm",class:{
            'border-black': _vm.$route.name === 'company-details',
            'border-white': _vm.$route.name !== 'company-details',
          },domProps:{"textContent":_vm._s('Company Details')}})]),_c('router-link',{attrs:{"to":"/manage-touch-account/documents-and-agreements"}},[_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
            'border-black': _vm.$route.name === 'documents-and-agreements',
            'border-white': _vm.$route.name !== 'documents-and-agreements',
          },domProps:{"textContent":_vm._s('Documents & Agreements')}})]),_c('router-link',{attrs:{"to":"/manage-touch-account/custom-styles"}},[(_vm.isCompanyStylingEnabled)?_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm",class:{
            'border-black': _vm.$route.name === 'custom-styles',
            'border-white': _vm.$route.name !== 'custom-styles',
          },domProps:{"textContent":_vm._s('Custom Styles')}}):_vm._e()]),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0",attrs:{"to":"/manage-touch-account/delivery-options"}},[_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm",class:{
            'border-black': _vm.$route.name === 'delivery-options',
            'border-white': _vm.$route.name !== 'delivery-options',
          },domProps:{"textContent":_vm._s('Delivery Options')}})]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0",attrs:{"to":"/manage-touch-account/quote-stages"}},[_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm",class:{
            'border-black': _vm.$route.name === 'quote-stages',
            'border-white': _vm.$route.name !== 'quote-stages',
          },domProps:{"textContent":_vm._s('Quote Stages')}})]):_vm._e(),(_vm.$store.state.basket.isPaymentGateway)?_c('router-link',{staticClass:"flex-shrink-0",attrs:{"to":"/manage-touch-account/payment-gateway"}},[_c('div',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm",class:{
            'border-black': _vm.$route.name === 'payment-gateway',
            'border-white': _vm.$route.name !== 'payment-gateway',
          },domProps:{"textContent":_vm._s('Payment Gateway')}})]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }