var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.single_target_question)?[(
        _vm.single_spec.instantSelectionOptionItemId &&
          _vm.single_spec.instantSelectionOptionItemId !== '00000000-0000-0000-0000-000000000000'
      )?_c('single-target-instant-selection-question',{attrs:{"loading":_vm.loading,"spec":_vm.single_spec},on:{"change":function($event){return _vm.singleChange($event)}}}):_c('single-target-question',{attrs:{"loading":_vm.loading,"spec":_vm.single_spec,"show-advanced-mode-toggle":false},on:{"change":function($event){return _vm.singleChange($event)},"update-parameter":function($event){return _vm.updateParameter($event)},"set-text":function($event){return _vm.singleSetText($event)},"scroll-to":function($event){return _vm.$emit('scroll-to', $event)}},model:{value:(_vm.advancedMode),callback:function ($$v) {_vm.advancedMode=$$v},expression:"advancedMode"}})]:_vm._e(),(!_vm.single_target_question && _vm.instantSelectionQuestion)?[(
        _vm.spec[0].instantSelectionOptionItemId &&
          _vm.spec[0].instantSelectionOptionItemId !== '00000000-0000-0000-0000-000000000000'
      )?_c('single-target-instant-selection-question',{attrs:{"loading":_vm.loading,"spec":_vm.spec[0]},on:{"change":function($event){return _vm.multiInstantChange($event)}}}):_vm._e()]:_vm._e(),(!_vm.single_target_question && !_vm.instantSelectionQuestion)?[(_vm.apply_to_all_mode)?_c('single-target-question',{attrs:{"loading":_vm.loading,"spec":_vm.multi_as_single_spec,"show-advanced-mode-toggle":true},on:{"set-text":function($event){return _vm.singleSetText($event)},"change":function($event){return _vm.singleChange($event)},"update-parameter":function($event){return _vm.updateParameter($event)},"scroll-to":function($event){return _vm.$emit('scroll-to', $event)}},model:{value:(_vm.advancedMode),callback:function ($$v) {_vm.advancedMode=$$v},expression:"advancedMode"}}):_c('multi-target-question',{attrs:{"loading":_vm.loading,"specs":_vm.spec},on:{"change":function($event){return _vm.multiChange($event)},"component-highlight":function($event){return _vm.$emit('component-highlight', $event)},"scroll-to":function($event){return _vm.$emit('scroll-to', $event)}},model:{value:(_vm.advancedMode),callback:function ($$v) {_vm.advancedMode=$$v},expression:"advancedMode"}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }