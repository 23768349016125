<template>
  <div class="h-full">
    <loading
      id="customer_index_scrolling_outer_wrapper"
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10 min-h-full"
    >
      <div class="flex flex-row gap-5">
        <div class="w-64 bg-white border p-3">
          <div v-for="category in categoriesForDisplay" :key="category.id" class="mb-5">
            <div class="mb-1">{{ category.description }}</div>
            <div
              v-for="subCategory in stockPartSubcategoriesWithParts.filter(
                (x) => x.partCategoryId === category.id && x.description,
              )"
              :key="subCategory.id"
              class="cursor-pointer mb-2 px-5 py-1 rounded bg-gray-200"
              :class="{
                'bg-green-300':
                  openStockPartSubcategory && openStockPartSubcategory.id === subCategory.id,
              }"
              @click="openStockPartSubcategory = subCategory"
            >
              {{ subCategory.description }}
            </div>
          </div>
        </div>
        <div id="item-table" class="flex-grow bg-white border p-3">
          <table class="table-lined xs:table-unstacked _with-hover w-full">
            <thead class="sticky top-0 bg-white z-10">
              <tr>
                <th>Colour</th>
                <th>Code</th>
                <th v-if="!isInCustomerMode">Cost</th>
                <th>Price</th>
                <th v-if="isInCustomerMode">FOC</th>
                <th v-if="!isInCustomerMode">Weight</th>
                <th v-if="!isInCustomerMode">Per</th>
                <th v-if="!isInCustomerMode">Customer Prices</th>
              </tr>
            </thead>
            <tbody v-for="part in organizedPartItems" :key="part.id">
              <tr class="part-header bg-green-600 text-white text-center">
                <td class="w-full" colspan="7">{{ part[0].part }}</td>
              </tr>
              <tr v-for="(item, index) in part" :key="item.id + '-' + index">
                <td v-if="item.colour">{{ item.colour }}</td>
                <td v-else class="text-center">-</td>
                <td v-if="item.code">{{ item.code }}</td>
                <td v-else class="text-center">-</td>
                <td v-if="!isInCustomerMode" class="flex relative py-1 px-2">
                  <div class="absolute py-2 px-3 border rounded-l-lg">£</div>
                  <input
                    v-model="item.cost"
                    type="number"
                    class="flex-grow bg-white p-2 rounded-lg pl-16 w-32 border border-l-0"
                    @input="hasChangedItems = true"
                  />
                </td>
                <td class="flex flex-row relative py-1 px-2">
                  <div v-if="isInCustomerMode" class="inline-block mr-2 italic text-xs">
                    (Global Price: {{ item.price }})
                  </div>
                  <div class="inline-block">
                    <div class="absolute py-2 px-3 border rounded-l-lg">£</div>
                    <input
                      v-if="isInCustomerMode"
                      v-model="item.customerPrices.nettPrice"
                      :class="{ 'cursor-not-allowed text-gray-400': item.customerPrices.foc }"
                      :disabled="item.customerPrices.foc"
                      type="number"
                      class="flex-grow bg-white p-2 rounded-lg pl-16 w-32 border border-l-0"
                      @input="hasChangedItems = true"
                    />
                    <input
                      v-else
                      v-model="item.price"
                      type="number"
                      class="flex-grow bg-white p-2 rounded-lg pl-16 w-32 border border-l-0"
                      @input="hasChangedItems = true"
                    />
                  </div>
                </td>
                <td v-if="isInCustomerMode">
                  <input
                    v-model="item.customerPrices.foc"
                    type="checkbox"
                    @input="hasChangedItems = true"
                  />
                </td>
                <td v-if="!isInCustomerMode" class="flex flex-row relative py-1 px-2">
                  <div class="absolute py-2 px-3 border rounded-r-lg kg-marker">kg</div>
                  <input
                    v-model="item.weight"
                    type="number"
                    class="flex-grow bg-white p-2 rounded-lg pr-10 w-32 border border-r-0"
                    @input="hasChangedItems = true"
                  />
                </td>
                <td v-if="!isInCustomerMode && item.per">{{ item.per }}</td>
                <td v-else-if="!isInCustomerMode" class="text-center">-</td>
                <td v-if="item.hasCustomerPrices && !isInCustomerMode">
                  <div
                    class="text-center cursoir-pointer"
                    @click="itemIdForModalEditing = item.id"
                  >
                    <i class="fa fa-person"></i>
                  </div>
                </td>
                <td v-else-if="!isInCustomerMode" class="text-center">-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </loading>
    <div v-if="!loading" class="text-right w-full p-3 fixed bottom-0 right-0">
      <button class="btn-action btn-lg" @click="saveSettings">Save Changes</button>
    </div>
    <PriceOverrides
      v-if="itemIdForModalEditing !== -1"
      :item-id="itemIdForModalEditing"
      :global-price="getItemPrice(itemIdForModalEditing)"
      :item-code="getItemCode(itemIdForModalEditing)"
      :colour="getItemColour(itemIdForModalEditing)"
      :part-description="getPartDescription(itemIdForModalEditing)"
      @close="itemIdForModalEditing = -1"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PriceOverrides from '@/components/shared/StockPartsPricingOverrides.vue';

export default {
  components: {
    PriceOverrides,
  },
  props: {
    query: String,
    minimumQueryLength: Number,
  },
  data() {
    return {
      loading: true,
      customer: {},
      items: [],
      hasChangedItems: false,
      selectedCategory: null,
      selectedSubCategory: null,
      searchTerm: '',
      categoryExpanded: true,
      isInCustomerMode: false,
      modalOpen: false,
      itemIdForModalEditing: -1,
      newFilter: '',
      filter: '',
    };
  },
  computed: {
    ...mapGetters('stockParts', {
      categoriesForDisplay: 'stockPartCategoriesWithSubcategories',
      subCategories: 'stockPartSubcategoriesForOpenCategory',
      stockPartSubcategoriesWithParts: 'stockPartSubcategoriesWithParts',
    }),
    ...mapState('stockParts', {
      results: 'queriedStockParts',
      searchQuery: 'searchQuery',
    }),
    openStockPartSubcategory: {
      get() {
        return this.$store.state.stockParts.openStockPartSubcategory;
      },
      set(subcategory) {
        if (!this.hasChangedItems) {
          this.$store.commit('stockParts/setOpenStockPartSubcategory', subcategory);
          return;
        }

        this.alertBox()
          .fire({
            title: `You have unsaved changes in this "${this.openStockPartSubcategory.description}" category`,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save These Prices',
            denyButtonText: 'Discard Changes',
            cancelButtonText: `Back To ${this.openStockPartSubcategory.description}`,
          })
          .then(async (res) => {
            if (res.isDismissed) {
              return;
            }

            if (res.isConfirmed) {
              await this.saveSettings();
            }

            this.$store.commit('stockParts/setOpenStockPartSubcategory', subcategory);
          });
      },
    },
    openSubcategoryId() {
      if (!this.openStockPartSubcategory) {
        return undefined;
      }

      return this.openStockPartSubcategory.id;
    },
    subCategoriesForDisplay() {
      return this.subCategories;
    },
    organizedPartItems() {
      return Object.values(window._.groupBy(this.items, (item) => item.partId));
    },
  },
  watch: {
    subCategories: {
      handler() {
        this.$store.dispatch('stockParts/selectValidSubcategory');
      },
      deep: true,
    },
    openSubcategoryId: {
      async handler() {
        if (this.openSubcategoryId) {
          this.hasChangedItems = false;
          this.items = await this.$store.dispatch('stockParts/loadItemsForSubcategory', {
            subCategoryId: this.openSubcategoryId,
            customerId: this.$route.params.id,
          });
        }
      },
    },
    async searchQuery(to) {
      if (to === '') {
        this.items = await this.$store.dispatch('stockParts/loadItemsForSubcategory', {
          subCategoryId: this.openSubcategoryId,
          customerId: this.$route.params.id,
        });
      }
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.loadCustomer();
      this.isInCustomerMode = true;
    }
    this.$store.commit('stockParts/initialize');

    await Promise.all([
      this.$store.dispatch('stockParts/loadStockPartCategories'),
      this.$store.dispatch('stockParts/loadStockPartSubcategories'),
    ]);

    this.$store.dispatch('stockParts/selectValidCategory');
    this.loading = false;
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
    }),
    getItemPrice(id) {
      return this.items.find((x) => x.id === id)?.price;
    },
    getItemCode(id) {
      return this.items.find((x) => x.id === id)?.code;
    },
    getItemColour(id) {
      return this.items.find((x) => x.id === id)?.colour;
    },
    getPartDescription(itemId) {
      return this.items.find((x) => x.id === itemId)?.part;
    },
    async loadCustomer() {
      this.customer = await this.customerGet(this.$route.params.id);
    },
    async saveSettings() {
      if (this.hasChangedItems) {
        if (this.isInCustomerMode) {
          await window.touch.stockUpdateStockCustomerPrice(
            this.items
              .filter(
                (x) =>
                  x.customerPrices.nettPrice !== undefined || x.customerPrices.foc !== undefined,
              )
              .map((x) => ({
                foc: x.customerPrices.foc,
                nettPrice: x.customerPrices.nettPrice,
                customerId: this.$route.params.id,
                stockId: x.id,
                id: x.customerPrices.id,
              })),
          );
        } else {
          await window.touch.stockUpdateStockItems(this.items);
        }
      }
    },
  },
};
</script>
<style scoped>
.kg-marker {
  right: 14px;
}
@media (min-width: 768px) {
  .xs\:table-unstacked td {
    padding: 0.3rem;
  }
}
</style>
