<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['My Account', '/my-account'], ['Custom Styles']]"
      />
    </template>
    <template #header_page_title> Custom Styles</template>
    <Menu />
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <div class="xs:p-10 min-h-screen">
        <div class="bg-white p-3 xs:p-10 border">
          <div class="flex flex-col sm:flex-row gap-5 border-b pb-10">
            <div class="flex-1 flex flex-col gap-5 max-w-3xl">
              <div>
                <div class="text-base">Requote Email Address</div>
                <div class="text-gray-600 text-base mb-3">This is the email address where your re-quote will be sent from.</div>
                <input
                  v-if="$store.state.auth.admin"
                  v-model="customer.customer.requoteEmail"
                  class="form-control max-w-sm"
                />
                <input
                  v-if="!$store.state.auth.admin"
                  v-model="customer.customer.requoteEmail"
                  class="form-control bg-gray-200 text-gray-500 max-w-sm"
                  readonly
                />
              </div>
              <div>
                <div class="text-base">Requote Email Subject</div>
                <div class="text-gray-600 text-base mb-3">Personalise the subject line for re-quote emails that are sent to consumers.</div>
                <input
                  v-if="$store.state.auth.admin"
                  v-model="customer.customer.requoteEmailSubject"
                  class="form-control max-w-sm"
                />
                <input
                  v-if="!$store.state.auth.admin"
                  v-model="customer.customer.requoteEmailSubject"
                  class="form-control bg-gray-200 text-gray-500 max-w-sm"
                  readonly
                />
              </div>
              <div>
                <div class="text-base">Requote Email Body Text</div>
                <div class="text-gray-600 text-base mb-3">Personalise the message in your email, this could be a nice welcoming message or terms and conditions of the re-quote.</div>
                <textarea
                  v-if="$store.state.auth.admin"
                  v-model="customer.customer.requoteEmailBodyText"
                  class="form-control max-w-sm"
                ></textarea>
                <textarea
                  v-if="!$store.state.auth.admin"
                  v-model="customer.customer.requoteEmailBodyText"
                  class="form-control bg-gray-200 text-gray-500 max-w-sm"
                  readonly
                ></textarea>
              </div>
            </div>
            <div class="sm:ml-auto border border-gray-300" style="min-height: 300px; width: 400px;">
              <img src="/images/guides/requote-email.jpg" />
            </div>
          </div>
          <div>
            <div v-if="isCompanyStylingEnabled" class="flex-1 flex flex-col gap-5 sm:flex-row mt-10 border-b pb-10">
              <div>
                <strong class="text-base">Quote PDF First Page Image.</strong>
                <div class="text-gray-600 text-base my-3">
                  This will be displayed on the first page of the quote PDF.
                </div>
                <div class="">
                  <div v-if="quotePdfFirstImage && quotePdfFirstImage.src" class="btn-action mt-2" @click.prevent="resetImage(enums.ImageTypeEnum.quotePDFFirstImage)">
                    Reset To Default
                  </div>
                  <vue-dropzone
                    v-else
                    id="dropzone"
                    ref="fileUploadDropzone"
                    :options="dropzoneOptions"
                    :use-custom-slot="true"
                    class="p-0 hover:bg-white"
                    style="min-height: auto"
                    @vdropzone-file-added="fileAddedToDropZone($event, enums.ImageTypeEnum.quotePDFFirstImage)"
                    @vdropzone-removed-file="fileRemovedFromDropZone(enums.ImageTypeEnum.quotePDFFirstImage)"
                  >
                    <div class="max-w-sm text-center block border border-gray-200 p-6 flex flex-col justify-around text-black hover:bg-gray-200 cursor-pointer">
                      <div class="mb-1">Drag and drop image file</div>
                      <div class="mb-1">or</div>
                      <div>
                        <a class="btn-action w-32"> Select Image </a>
                      </div>
                    </div>
                  </vue-dropzone>
                </div>
                <div class="text-gray-500 py-3">
                  <div>Required file type: JPG, PNG.</div>
                  <div>Recommended size: 1157 x 760px.</div>
                  <div>Max File Size: 1MB</div>
                </div>
              </div>
              <div
                v-if="quotePdfFirstImage && quotePdfFirstImage.src"
                class="sm:ml-auto flex flex-col justify-center border border-gray-300"
                style="min-height: 300px; width: 400px;"
              >
                <img :src="quotePdfFirstImage.src" />
              </div>
              <img
                v-else
                class="sm:ml-auto border border-gray-300"
                style="min-height: 300px; width: 400px;"
                src="/images/quotePdfFrontPageImageTemplate.png"
              />
            </div>
            <div v-if="isCompanyStylingEnabled" class="flex-1 flex flex-col gap-5 sm:flex-row mt-10 pb-10 border-b">
              <div>
                <strong class="text-base">Quote PDF Last Page Image.</strong>
                <div class="text-gray-600 text-base my-3">
                  This will be displayed on the last page of the quote PDF.
                </div>
                <div class="">
                  <div v-if="quotePDFLastImage && quotePDFLastImage.src" class="btn-action mt-2" @click.prevent="resetImage(enums.ImageTypeEnum.quotePDFLastImage)">
                    Reset To Default
                  </div>
                  <vue-dropzone
                    v-else
                    id="dropzone"
                    ref="fileUploadDropzone"
                    :options="dropzoneOptions"
                    :use-custom-slot="true"
                    class="p-0 hover:bg-white"
                    style="min-height: auto"
                    @vdropzone-file-added="fileAddedToDropZone($event, enums.ImageTypeEnum.quotePDFLastImage)"
                    @vdropzone-removed-file="fileRemovedFromDropZone(enums.ImageTypeEnum.quotePDFLastImage)"
                  >
                    <div class="max-w-sm text-center block border border-gray-200 p-6 flex flex-col justify-around text-black hover:bg-gray-200 cursor-pointer">
                      <div class="mb-1">Drag and drop image file</div>
                      <div class="mb-1">or</div>
                      <div>
                        <a class="btn-action w-32"> Select Image </a>
                      </div>
                    </div>
                  </vue-dropzone>
                </div>
                <div class="text-gray-500 py-3">
                  <div>Required file type: JPG, PNG.</div>
                  <div>Recommended size: 1157 x 760px.</div>
                  <div>Max File Size: 1MB</div>
                </div>
              </div>
              <div
                v-if="quotePDFLastImage && quotePDFLastImage.src"
                class="sm:ml-auto flex flex-col justify-center border border-gray-300"
                style="min-height: 300px; width: 400px;"
              >
                <img :src="quotePDFLastImage.src" />
              </div>
              <img
                v-else
                class="sm:ml-auto border border-gray-300"
                style="min-height: 300px; width: 400px;"
                src="/images/quotePdfBackPageImageTemplate.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="is_admin_user"
        class="sticky bottom-0 bg-white p-3 xs:p-4 text-right border-gray-300 border-t"
      >
        <div class="flex flex-col xs:flex-row justify-between">
          <div class="ml-auto mt-2 xs:mt-0">
            <button class="btn-action btn-lg w-full xs:w-auto" @click.prevent.stop="save()">
              {{ $t('Save Changes') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import Menu from '@/components/portal/myAccount/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapGetters, mapActions } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import imageHelper from '@/mixins/imageHelper';
import validate from 'validate.js';

export default {
  components: {
    Menu,
    Breadcrumbs,
    'vue-dropzone': vue2Dropzone,
  },
  mixins: [imageHelper],
  data() {
    return {
      newImages: [],
      customer:undefined,
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        addRemoveLinks: true,
      },
      requoteImages: [
        {
          imageType: 1,
          src: '',
        },
        {
          imageType: 2,
          src: '',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isCompanyStylingEnabled: 'style/isCompanyStylingEnabled',
    }),
    quotePdfFirstImage() {
      return this.requoteImages.find(
        (x) => x.imageType === this.enums.ImageTypeEnum.quotePDFFirstImage && !x.reset,
      );
    },
    quotePDFLastImage() {
      return this.requoteImages.find(
        (x) => x.imageType === this.enums.ImageTypeEnum.quotePDFLastImage && !x.reset,
      );
    },
    resetImages() {
      return this.requoteImages.filter((x) => x.reset);
    },
  },
  created(){
    this.customer = JSON.parse(JSON.stringify(this.$store.state.user.customer))
  },
  async mounted() {
    await this.updateImageSrcs();
  },
  methods: {
    ...mapActions({
      updateCustomerRequoteDetails: 'user/updateCustomerRequoteDetails',
      updateStyles: 'style/updateCompanyStyles',
      deleteStyles: 'style/deleteCompanyStyling',
      getStyles: 'style/getCompanyStyles',
      setCustomer: 'user/setCustomer',
    }),
    resetImage(imageType) {
      const images = JSON.parse(JSON.stringify(this.requoteImages));
      images.find((x) => x.imageType === imageType).reset = true;
      this.requoteImages = images;
    },
    async fileAddedToDropZone(uploadedFile, imageType) {
      this.errors = [];
      if (!uploadedFile) {
        return;
      }

      const extension = uploadedFile.name.replace(/^.*\./, '');
      const allowedExtensions = ['jpg', 'jpeg', 'png'];

      if (!allowedExtensions.includes(extension.toLowerCase())) {
        this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          text: 'File type not allowed, please ensure the file is one of the following formats: jpg, png',
        });
        return;
      }

      if (uploadedFile.size > 1000000) {
        this.$refs[refs].dropzone.removeAllFiles();
        this.alertBox().fire({
          title: 'File Size Limit Exceeded',
          text: 'The maximum file size is 1MB.',
          icon: 'error',
        });
        return;
      }

      const existingNewImage = this.newImages.find((x) => x.imageType === imageType);
      const uploadedFileBase64 = await this.imageToBase64WithPrefix(uploadedFile);
      if (typeof existingNewImage === 'undefined') {
        this.newImages.push({
          imageType,
          newImage: uploadedFileBase64,
        });
      } else {
        existingNewImage.newImage = uploadedFileBase64;
      }
    },
    fileRemovedFromDropZone(imageType) {
      const existingNewImage = this.newImages.find((x) => x.imageType === imageType);
      if (typeof existingNewImage === 'undefined') {
        return;
      }
      this.newImages = this.newImages.filter((x) => x.imageType !== imageType);
    },
    async save() {
      this.loading = true;
      const validationErrors = validate(
        {
          email: this.customer.customer.requoteEmail,
        },
        {
          email: {
            presence: { allowEmpty: false },
            length: {
              maximum: 255,
            },
            email: true,
          },
        },
      );
      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        this.loading = false;
        return;
      }
      if (this.resetImages?.length) {
        await this.deleteStyles({
          quotePDFFirstImage:
            this.resetImages.find(
              (x) => x.imageType === this.enums.ImageTypeEnum.quotePDFFirstImage,
            )?.reset &&
            this.newImages.find((x) => x.imageType === this.enums.ImageTypeEnum.quotePDFFirstImage)
              ?.newImage === undefined,
          quotePDFLastImage:
            this.resetImages.find((x) => x.imageType === this.enums.ImageTypeEnum.quotePDFLastImage)
              ?.reset &&
            this.newImages.find((x) => x.imageType === this.enums.ImageTypeEnum.quotePDFLastImage)
              ?.newImage === undefined,
        });
      }

      try {
        if (this.newImages.length) {
          await this.updateStyles({
            quotePDFFirstImage: this.newImages.find(
              (x) => x.imageType === this.enums.ImageTypeEnum.quotePDFFirstImage,
            )?.newImage,
            quotePDFLastImage: this.newImages.find(
              (x) => x.imageType === this.enums.ImageTypeEnum.quotePDFLastImage,
            )?.newImage,
          });
          await this.updateImageSrcs();
        }
        await this.updateCustomerRequoteDetails({
          customerId: this.customer.customerId,
          requoteEmail: this.customer.customer.requoteEmail,
          requoteEmailSubject: this.customer.customer.requoteEmailSubject,
          requoteEmailBodyText: this.customer.customer.requoteEmailBodyText,
        });
        await this.setCustomer(this.customer.customerId);
        this.alertBox().fire({
          title: 'Settings Updated',
          icon: 'success',
        });
      }
      catch (error) {
        this.alertBox().fire({
          title: 'There has been an issue updating your settings',
          icon: 'error',
        });
      }
    },
    async updateImageSrcs() {
      const styles = await this.getStyles();
      const indexQuotePDFFirstImage = this.requoteImages.findIndex((x) => x.imageType === this.enums.ImageTypeEnum.quotePDFFirstImage)
      this.$set(this.requoteImages, indexQuotePDFFirstImage, {
        src: styles.quoteReportStyling.firstPageImageUri,
        imageType: this.enums.ImageTypeEnum.quotePDFFirstImage,
        reset: false
      })
      const indexQuotePDFLastImage = this.requoteImages.findIndex((x) => x.imageType === this.enums.ImageTypeEnum.quotePDFLastImage)
      this.$set(this.requoteImages, indexQuotePDFLastImage, {
        src: styles.quoteReportStyling.lastPageImageUri,
        imageType: this.enums.ImageTypeEnum.quotePDFLastImage,
        reset: false
      })
    },
  },
};
</script>
