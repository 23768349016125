<template>
  <portal v-if="!isAdmin" to="portal_popup">
    <modal-window
      class="text-center"
      :modal_open="true"
      :is-form="false"
      title="Branded Mode Free Trial"
      @close="(isAdmin = true), (isRedirecting = false)"
    >
      <div class="p-5 text-center max-w-md mx-auto">
        <div class="mb-5">
          <strong>{{ $store.state.auth.companyName }} Branded Mode Free Trial can only be activated via
            your company's Admin Account</strong>
        </div>
        <div class="mb-5">
          <span>To subscribe to {{ $store.state.auth.companyName }} Branded Mode, please login to your
            Admin account with the email(s) below:</span>
        </div>
        <div class="mb-5">
          <div v-for="adminUser in adminUsers" :key="adminUser.id">
            <strong>{{ adminUser }}</strong>
          </div>
        </div>
        <button to="/" class="btn btn-md" @click="(isAdmin = true), (isRedirecting = false)">
          Close
        </button>
      </div>
    </modal-window>
  </portal>
  <div
    v-else-if="isRedirecting"
    class="w-full h-full fixed bg-black bg-opacity-75 top-0 left-0 z-50"
  >
    <div
      class="rounded-lg p-10 bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-md text-center"
    >
      <div class="mb-10">
        <img class="w-full px-10" src="/images/touch-logo.svg" />
      </div>
      <div class="mb-10">
        <span>You are now being redirected to <strong>store.bm-touch.co.uk</strong> to register for
          your free trial.</span>
      </div>
      <LoadingIcon />
    </div>
  </div>
</template>

<script>
import LoadingIcon from '@/components/shared/LoadingIcon.vue';

export default {
  components: {
    LoadingIcon,
  },
  data() {
    return {
      isRedirecting: false,
      isAdmin: true,
    };
  },
  computed: {
    adminUsers() {
      return this.$store.state.user.customer.users.filter((x) => x.isAdmin).map((x) => x.email);
    },
  },
  methods: {
    redirect() {
      this.isRedirecting = true;
      setTimeout(async () => {
        if (this.$store.state.auth.admin) {
          const url = new URL(`${process.env.VUE_APP_STORE_PREFIX}connect/touch/connect-branded`);
          url.searchParams.set(
            'store-link-token',
            (await window.touch.authGetMyStoreLinkToken()).token,
          );
          url.searchParams.set('fabricator', this.$store.getters['style/fabricator_name']);
          window.location.href = url.toString();
          this.isAdmin = true;
          this.isRedirecting = false;
        } else {
          this.isAdmin = false;
        }
      }, 3000);
    },
  },
};
</script>
