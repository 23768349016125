<template>
  <router-view v-if="customer" :customer="customer"></router-view>
</template>

<script>

export default {
  async beforeRouteEnter(to, from, next) {
    const customer = await window.touch.customerGet(to.params.customerId || to.params.id, true)
    next((vm) => {
      vm.customer = customer;
    });
  },
  data () {
    return {
      customer: undefined
    }
  },
};
</script>
