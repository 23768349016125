<template>
  <div class="bg-white p-3 xs:p-10 border mt-5 sm:mt-0">
    <slot name="enquiries-overdue-limit"></slot>
    <div v-if="companySettings" class="pb-10 border-b">
      <div class="mb-3 text-base max-w-3xl">
        Request Measurements <span class="text-red-600 ml-1">*</span>
      </div>
      <div class="flex flex-col justify-around text-gray-600 text-base max-w-3xl">
        Would you like to request measurements from your customers via the {{ setup }} Designer? If
        Yes, this question will appear on the intro screen.
      </div>
      <div class="flex flex-col md:flex-row -mx-5">
        <div class="p-5">
          <label class="flex flex-col">
            <div>
              <div class="inline-flex border border-gray-600 rounded-lg">
                <div
                  class="text-white border border-white rounded-l-lg px-3"
                  :class="{
                    'bg-green-600': companySettings.showConsumerSizes,
                    'bg-gray-600': !companySettings.showConsumerSizes,
                  }"
                >
                  Yes
                </div>
                <div
                  class="bg-gray-600 text-white border border-white rounded-r-lg px-3"
                  :class="{
                    'bg-gray-600': companySettings.showConsumerSizes,
                    'bg-green-600': !companySettings.showConsumerSizes,
                  }"
                >
                  No
                </div>
              </div>
              <input v-model="companySettings.showConsumerSizes" type="checkbox" class="hidden" />
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row gap-5 mt-10 border-b pb-10">
      <div class="flex-1 flex flex-col">
        <div class="flex flex-col justify-around text-base">
          <strong>Show Design Names</strong>
        </div>
        <div class="text-gray-600 text-base my-3 max-w-md">
          <div>
            Choose between Design A or Design B when a customer chooses a product type.
          </div>
        </div>
        <div class="flex flex-col md:flex-row -mx-5">
          <div class="p-5">
            <label class="flex flex-col">
              <div>
                <div class="inline-flex border border-gray-600 rounded-lg cursor-pointer">
                  <div
                    class="text-white border border-white rounded-l-lg px-3"
                    :class="{
                      'bg-green-600': companySettings.showDesignNames,
                      'bg-gray-600': !companySettings.showDesignNames,
                    }"
                  >
                    Design A
                  </div>
                  <div
                    class="bg-gray-600 text-white border border-white rounded-r-lg px-3"
                    :class="{
                      'bg-gray-600': companySettings.showDesignNames,
                      'bg-green-600': !companySettings.showDesignNames,
                    }"
                  >
                    Design B
                  </div>
                </div>
                <input v-model="companySettings.showDesignNames" type="checkbox" class="hidden" />
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="sm:ml-auto border border-gray-300" style="min-height: 300px; width: 400px;">
        <img src="/images/guides/show-design-names.jpg" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    companySettings: Object,
    setup: String,
  }
}

</script>