export default {
  namespaced: true,
  state: {
    searchQuery: '',
    minimumQueryLength: 2,
    openStockPartSubcategory: undefined,
    openStockPartCategory: undefined,
    allStockParts: [],
    queriedStockParts: [],
    stockPartCategories: [],
    stockPartSubcategories: [],
  },
  mutations: {
    initialize(state) {
      state.openStockPartSubcategory = undefined;
      state.searchQuery = '';
      state.openStockPartCategory = undefined;
      state.allStockParts = [];
      state.queriedStockParts = [];
      state.stockPartCategories = [];
      state.stockPartSubcategories = [];
    },
    setSearchQuery(state, query) {
      state.searchQuery = query;
    },
    setOpenStockPartSubcategory(state, subcategory) {
      state.openStockPartSubcategory = subcategory;
    },
    setOpenStockPartCategory(state, category) {
      state.openStockPartCategory = category;
    },
    setStockPartSubcategories(state, list) {
      state.stockPartSubcategories = list;
    },
    setStockParts(state, list) {
      state.allStockParts = list;
    },
    setQueriedStockParts(state, { query, results }) {
      if (state.searchQuery === query) {
        state.queriedStockParts = results;
      }
    },
    setStockPartCategories(state, list) {
      state.stockPartCategories = list;
    },
  },
  actions: {
    updateItem(context, { itemId, quantity }) {
      return context.dispatch(
        'basket/updateStockItemQty',
        {
          itemId,
          quantity,
        },
        { root: true },
      );
    },
    deleteItem(context, lineItemId) {
      window.alertBox
        .fire({
          title: 'Are you sure?',
          text: 'Delete this stock item?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Continue',
        })
        .then((result) => {
          if (result.isConfirmed) {
            context.dispatch(
              'basket/deleteLineItem',
              {
                lineItemId,
              },
              { root: true },
            );
          }
        });
    },
    updateQtyForItem(context, { itemId, quantity }) {
      return context.dispatch(
        'basket/updateStockItemQty',
        {
          itemId,
          quantity,
        },
        { root: true },
      );
    },
    async selectValidSubcategory(context) {
      const { stockPartSubcategoriesForOpenCategory } = context.getters;

      if (
        !stockPartSubcategoriesForOpenCategory.some(
          (subcategory) => subcategory.id === context.getters.openStockPartSubcategoryId,
        )
      ) {
        context.commit('setOpenStockPartSubcategory', stockPartSubcategoriesForOpenCategory[0]);
      }
    },
    async selectValidCategory(context) {
      const { stockPartCategoriesWithSubcategories } = context.getters;

      if (
        !stockPartCategoriesWithSubcategories.some(
          (category) => category.id === context.getters.openStockPartCategoryId,
        )
      ) {
        context.commit('setOpenStockPartCategory', stockPartCategoriesWithSubcategories[0]);
      }
    },
    async loadItemsForSubcategory(context, { subCategoryId, customerId }) {
      let customerPrices;
      if (customerId) {
        customerPrices = await window.touch.stockListStockCustomerPrice(customerId);
      }
      let res = [];
      if (context.state.searchQuery) {
        res = context.getters.activeStockParts.filter(
          (part) => part.subCategoryId === context.getters.openStockPartSubcategoryId,
        );
      } else {
        res = await window.touch.stockListStockItems(subCategoryId);
      }
      res.forEach((r) => {
        const customerPricesForItem = customerPrices?.find((p) => p.stockItemId === r.id);
        if (customerPricesForItem) {
          // eslint-disable-next-line
          r.customerPrices = customerPricesForItem;
        } else {
          // eslint-disable-next-line
          r.customerPrices = {
            foc: undefined,
            nettPrice: undefined,
            customerId,
            id: undefined,
            stockItemId: r.id,
          };
        }
      });
      return res;
    },
    async loadStockPartSubcategories(context) {
      context.commit(
        'setStockPartSubcategories',
        await window.touch.stockListStockPartSubCategories(),
      );
    },
    async loadStockParts(context) {
      context.commit('setStockParts', await window.touch.stockListStockParts());
    },
    async loadStockPartCategories(context) {
      context.commit('setStockPartCategories', await window.touch.stockListStockPartCategories());
    },
    async runItemQuery(context, search) {
      context.commit('setSearchQuery', search);
      if (
        context.state.searchQuery &&
        context.state.searchQuery.length >= context.state.minimumQueryLength
      ) {
        const stockListStockItems = await window.touch.stockListStockItems(undefined, search);
        context.commit('setQueriedStockParts', {
          query: context.state.searchQuery,
          results: stockListStockItems,
        });
      }
    },
  },
  getters: {
    activeStockParts(state) {
      if (!state.searchQuery || state.searchQuery.length < state.minimumQueryLength) {
        return state.allStockParts;
      }
      return state.queriedStockParts;
    },
    stockPartCategoriesWithSubcategories(state, getters) {
      if (!state.searchQuery || state.searchQuery.length < state.minimumQueryLength) {
        return state.stockPartCategories;
      }
      return state.stockPartCategories.filter((category) =>
        getters.stockPartSubcategoriesWithParts.some(
          (subcategory) => subcategory.partCategoryId === category.id,
        ),
      );
    },
    stockPartSubcategoriesWithParts(state, getters) {
      if (!state.searchQuery || state.searchQuery.length < state.minimumQueryLength) {
        return state.stockPartSubcategories;
      }
      return state.stockPartSubcategories.filter((subcategory) =>
        getters.activeStockParts.some((part) => part.subCategoryId === subcategory.id),
      );
    },
    stockPartSubcategoriesForOpenCategory(state, getters) {
      try {
        return getters.stockPartSubcategoriesWithParts.filter(
          (subcategory) => subcategory.partCategoryId === state.openStockPartCategory.id,
        );
      } catch (e) {
        return [];
      }
    },
    filteredStockParts(state, getters) {
      try {
        return getters.activeStockParts.filter(
          (part) => part.subCategoryId === state.openStockPartSubcategory.id,
        );
      } catch (e) {
        return [];
      }
    },
    openStockPartSubcategoryId(state) {
      if (!state.openStockPartSubcategory) {
        return null;
      }

      return state.openStockPartSubcategory.id;
    },
    openStockPartCategoryId(state) {
      if (!state.openStockPartCategory) {
        return null;
      }

      return state.openStockPartCategory.id;
    },
    cartItems(state, getters, rootState, rootGetters) {
      return rootGetters['basket/stockItems'];
    },
  },
};
