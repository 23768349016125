<template>
  <div class="flex flex-col justify-around w-full p-2">
    <label class="bg-white rounded-full text-xs flex border border-white w-full">
      <div class="bg-brand-primary rounded-full p-2 border-white" style="width: 32px; height: 32px">
        <i class="fal fa-search text-white fa-fw"></i>
      </div>
      <div class="flex flex-col justify-around uppercase">
        <input
          ref="searchInput"
          type="text"
          class="bg-transparent outline-none pl-2 h-full"
          :value="value"
          @click.stop
          @input="$emit('input', $event.target.value)"
        />
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: ['value'],
  mounted() {
    if (window.innerWidth > 1024) {
      this.$refs.searchInput.focus();
    }
  },
};
</script>
