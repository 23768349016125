<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Quote Stages', '/manage-touch-account/quote-stages'],
          ['Quote Stages'],
        ]"
      />
    </template>
    <template #header_page_title> Quote Stages </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <a class="btn-action" @click.stop.prevent="isUpdateQuoteStage = true"> Add Quote Stage </a>
      </div>
    </template>
    <Menu></Menu>
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <loading :loading="loading" class="min-h-full">
        <div class="flex flex-row flex-wrap bg-white border m-3 xs:m-10 mt-4 relative p-3">
          <table v-if="stages.length" class="table-lined xs:table-unstacked _with-hover w-full">
            <thead>
              <tr>
                <th>Name</th>
                <th class="w-32"></th>
              </tr>
            </thead>
            <tr v-for="stage in stages" :key="stage.id">
              <td>{{ stage.description }}</td>
              <td class="td-action-buttons">
                <div
                  class="table-edit-btn mr-2"
                  @click.stop.prevent="
                    (description = stage.description), (id = stage.id), (isUpdateQuoteStage = true)
                  "
                >
                  <i class="cursor-pointer fa fa-pencil"></i>
                </div>
                <div
                  class="table-edit-btn"
                  @click.stop.prevent="deleteQuoteStage(stage.id, stage.description)"
                >
                  <i class="cursor-pointer text-red-600 fa fa-trash-can"></i>
                </div>
              </td>
            </tr>
          </table>
          <div v-else>No Quote Stages</div>
        </div>
      </loading>
    </div>
    <modal-window
      v-if="isUpdateQuoteStage"
      ref="modal"
      :modal_open="true"
      title="Add Quote Stage"
      @close="isUpdateQuoteStage = false"
    >
      <div>
        <input v-model="description" type="text" class="form-control" />
      </div>
      <div class="text-right mt-5">
        <button class="btn-action btn-lg rounded-sm" @click.prevent="updateQuoteStage(id)">
          Save
        </button>
      </div>
    </modal-window>
  </touch-layout>
</template>

<script>
import Menu from '@/components/business/manageTouchAccount/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
    Menu,
  },
  data() {
    return {
      loading: true,
      isUpdateQuoteStage: false,
      id: undefined,
      description: '',
      stages: [],
    };
  },
  async mounted() {
    await this.getCustomQuoteStatuses();
    this.loading = false;
  },
  methods: {
    async getCustomQuoteStatuses() {
      this.stages = await window.touch.GetCustomQuoteStatuses(
        this.$store.state.user.customer.companyId,
      );
    },
    async updateQuoteStage(id) {
      await window.touch.UpdateCustomQuoteStage([{ id, description: this.description }]);
      this.isUpdateQuoteStage = false;
      this.description = '';
      this.id = undefined;
      await this.getCustomQuoteStatuses();
    },
    async deleteQuoteStage(id, name) {
      this.alertBox()
        .fire({
          title: `Are you sure you want to delete ${name} ?`,
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await window.touch.UpdateCustomQuoteStage([{ id, delete: true }]);
            await this.getCustomQuoteStatuses();
          }
        });
    },
  },
};
</script>
