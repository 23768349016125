<template>
  <portal v-if="modal_open" to="portal_popup">
    <modal-window :modal_open="modal_open" title="Add Delivery Option" @close="$emit('close')">
      <div class="flex flex-col container" @click="search = ''">
        <div class="grid grid-cols-2 flex-wrap gap-5">
          <div class="col-span-2">
            <div class="font-medium">Name <span class="text-green-600">*</span></div>
            <input v-model="name" class="form-control" />
          </div>
          <div class="flex flex-col gap-5">
            <div>
              <div class="font-medium">Delivery Type</div>
              <div class="form-control multiple">
                <div
                  v-for="deliveryTypeEnum in deliveryTypeEnums.slice(1, deliveryTypeEnums.length)"
                  :key="deliveryTypeEnum.id"
                  class="flex gap-4 cursor-pointer"
                  @click="setDeliveryType(deliveryTypeEnum[1])"
                >
                  <input
                    type="checkbox"
                    :disabled="
                      deliveryTypeEnum[1] === enums.DeliveryType.Direct &&
                        deliveryType === enums.DeliveryType.Direct
                    "
                    :checked="deliveryTypeEnum[1] === deliveryType"
                  />
                  <div>
                    {{ deliveryTypeEnum[0] }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="[enums.DeliveryType.ViaBranch].includes(deliveryType)">
              <div class="font-medium">Company <span class="text-green-600">*</span></div>
              <select v-model="companyId" class="form-control">
                <option :value="undefined" default disabled>Select a company...</option>
                <option v-for="(company, index) in companies" :key="index" :value="company.id">
                  {{ company.name }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <div class="font-medium">Delivery Days</div>
            <div class="form-control multiple">
              <div
                v-for="deliveryDaysEnum in deliveryDaysEnums"
                :key="deliveryDaysEnum.id"
                class="flex gap-4 cursor-pointer"
                @click="setDeliveryDays(deliveryDaysEnum[1])"
              >
                <input
                  type="checkbox"
                  :checked="
                    bitsInRoles(deliveryDays).includes(deliveryDaysEnum[1]) ||
                      (deliveryDaysEnum[1] === 0 && bitsInRoles(deliveryDays).length === 0)
                  "
                />
                <div>
                  {{ deliveryDaysEnum[0] }}
                </div>
              </div>
            </div>
          </div>
          <div>
            Minimum Order Amount
            <div class="font-medium">
              <input v-model.number="minimumOrderValue" type="number" class="form-control" />
            </div>
          </div>
          <div>
            Minimum Order Charge
            <div class="font-medium">
              <input v-model.number="deliveryCharge" type="number" class="form-control" />
            </div>
          </div>
          <div>
            <div class="font-medium">Lead Time Addition (Days)</div>
            <input v-model.number="leadTimeAddition" type="number" class="form-control" />
          </div>
          <div class="flex flex-col">
            <div class="flex gap-2">
              <div class="font-medium">Post Code Areas</div>
              <div>
                <strong
                  class="cursor-pointer underline text-blue-600"
                  @click="isSubmitList = true"
                >Submit List</strong>
                <modal-window
                  :modal_open="isSubmitList"
                  title="Submit Post Code Area List"
                  :is-form="false"
                  @close="isSubmitList = false"
                >
                  <textarea
                    v-model="postCodeAreaList"
                    placeholder="Enter comma seperated list of postcode areas"
                    class="form-control"
                  ></textarea>
                  <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
                    <button
                      class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
                      @click.prevent="isSubmitList = false"
                    >
                      Cancel
                    </button>
                    <button class="btn-action btn-lg" @click="addPostCodes()">Save</button>
                  </div>
                </modal-window>
              </div>
            </div>
            <div>
              <div class="flex items-center gap-2" @click.stop>
                <div class="flex-auto font-medium">
                  <input
                    ref="postcodeInput"
                    v-model="search"
                    placeholder="Add post code prefix..."
                    class="form-control"
                    maxlength="4"
                    @keyup.enter="
                      matchingPostCodes.push({
                        PostcodePrefix: search.toUpperCase(),
                        DeliveryOptionId: id,
                      }),
                      (search = ''),
                      $refs.postcodeInput.focus()
                    "
                  />
                </div>
                <div class="flex items-center ml-auto gap-2">
                  <div
                    class="bg-gray-300 rounded-lg cursor-pointer p-2 px-4"
                    :class="{
                      'opacity-50 pointer-events-none':
                        search.length === 0 ||
                        filteredPostCodes.length > 0 ||
                        matchingPostCodes.some(
                          (x) => x.PostcodePrefix.toLowerCase() === search.toLowerCase(),
                        ),
                    }"
                    @click="
                      matchingPostCodes.push({
                        PostcodePrefix: search.toUpperCase(),
                        DeliveryOptionId: id,
                      }),
                      (search = ''),
                      $refs.postcodeInput.focus()
                    "
                  >
                    <span>ADD</span>
                  </div>
                </div>
              </div>
              <span
                v-if="search.length === 4"
                class="font-light text-xs text-red-500"
              >Max 4 letters.</span>
              <div class="relative">
                <div
                  v-if="search.length > 0"
                  class="absolute top-0 bg-gray-300 flex flex-col gap-2 w-full"
                >
                  <div
                    v-for="postCode in filteredPostCodes"
                    :key="postCode.id"
                    class="mr-2 cursor-pointer hover:bg-gray-400 p-2 w-full"
                    @click="
                      matchingPostCodes.push({ ...postCode, DeliveryOptionId: id }),
                      (search = ''),
                      $refs.postcodeInput.focus()
                    "
                  >
                    <span>{{ postCode.PostcodePrefix }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-2 mt-2 flex flex-wrap gap-2">
            <div
              v-for="postCode in matchingPostCodes.filter((x) => !x.delete)"
              :key="postCode.id"
              class="flex items-center gap-2 px-4 py-2 bg-gray-200 rounded-lg cursor-pointer"
              @click="removePostCode(postCode)"
            >
              <span>{{ postCode.PostcodePrefix }}</span>
              <i class="fa fa-times"></i>
            </div>
          </div>
        </div>
        <div class="text-right mt-auto flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="saveDeliveryOption">
            Save Delivery Option
          </button>
        </div>
      </div>
    </modal-window>
  </portal>
</template>

<script>
import validate from 'validate.js';

export default {
  props: {
    deliveryOption: Object,
    deliveryDaysEnums: Array,
    deliveryTypeEnums: Array,
    bitsInRoles: Function,
    postCodes: Array,
  },
  data() {
    return {
      id: undefined,
      deliveryType: 1,
      deliveryDays: 0,
      name: '',
      minimumOrderValue: null,
      deliveryCharge: null,
      companyId: undefined,
      companies: [],
      leadTimeAddition: 0,
      postCode: '',
      search: '',
      matchingPostCodes: [],
      originalPostCodes: [],
      modal_open: false,
      isSubmitList: false,
      postCodeAreaList: '',
    };
  },
  computed: {
    mappedPostCodes() {
      return this.postCodes
        .map((y) => ({
          id: y.id,
          delete: y.delete,
          PostcodePrefix: y.postcodePrefix,
          DeliveryOptionId: y.deliveryOptionId,
        }))
        .sort((a, b) =>
          a.PostcodePrefix.localeCompare(b.PostcodePrefix, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        );
    },
    filteredPostCodes() {
      return this.mappedPostCodes
        .filter(
          (x) =>
            x.PostcodePrefix.toLowerCase().startsWith(this.search.toLowerCase()) &&
            !this.matchingPostCodes.some((y) => y.PostcodePrefix === x.PostcodePrefix),
        )
        .slice(0, 5);
    },
  },
  async created() {
    if (this.deliveryOption) {
      this.id = this.deliveryOption.id;
      await this.setDeliveryType(this.deliveryOption.deliveryType, true);
      this.deliveryDays = this.deliveryOption.deliveryDays;
      this.name = this.deliveryOption.name;
      this.minimumOrderValue = this.deliveryOption.minimumOrderValue;
      this.deliveryCharge = this.deliveryOption.deliveryCharge;
      this.companyId = this.deliveryOption.companyId;
      this.leadTimeAddition = this.deliveryOption.leadTimeAddition;
    }
    this.matchingPostCodes = this.mappedPostCodes.filter((x) => x.DeliveryOptionId === this.id);
    this.originalPostCodes = JSON.parse(JSON.stringify(this.matchingPostCodes));
    this.modal_open = true;
  },
  methods: {
    async saveDeliveryOption() {
      const validationErrors = validate(
        {
          name: this.name,
          companies: this.companyId,
        },
        {
          name: {
            presence: { allowEmpty: false },
          },
          companies: [this.enums.DeliveryType.ViaBranch].includes(this.deliveryType)
            ? {
                presence: {
                  allowEmpty: false,
                },
              }
            : undefined,
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      const deliveryOption = [
        {
          id: this.id,
          Name: this.name,
          DeliveryType: this.deliveryType,
          DeliveryDays: this.deliveryDays,
          MinimumOrderAmount: this.minimumOrderValue,
          MinimumOrderCharge: this.deliveryCharge,
          CompanyId: this.companyId,
          LeadTimeAddition: this.leadTimeAddition,
          delete: false,
        },
      ];

      try {
        const { newIds } = await window.touch.staffUpdateDeliveryOptions(deliveryOption);
        const postCodes = this.matchingPostCodes.map((matchingPostCode) => ({
          ...matchingPostCode,
          DeliveryOptionId: newIds.length > 0 ? newIds[0] : matchingPostCode.DeliveryOptionId,
        }));
        await window.touch.staffUpdatePostcodes(postCodes);
        this.alertBox().fire({
          title: 'Save Successful',
          icon: 'success',
        });
      } catch (error) {
        this.alertBox().fire({
          title: error,
          icon: 'error',
        });
      }

      this.$emit('loadItems');
      this.$emit('close');
    },
    async setDeliveryType(value, isStart) {
      if (isStart) {
        this.deliveryType = value;
      } else {
        this.deliveryType = this.deliveryType === value ? this.enums.DeliveryType.Direct : value;
      }
      this.companyId = undefined;
      if (this.deliveryType === this.enums.DeliveryType.ViaBranch) {
        this.companies = await window.touch.getListCompanies(this.enums.CompanyType.Branch);
      } else if (this.deliveryType === this.enums.DeliveryType.ByCarrier) {
        this.companies = await window.touch.getListCompanies(this.enums.CompanyType.Carrier);
      } else {
        this.companies = [];
      }
    },
    setDeliveryDays(value) {
      if (value === 0) {
        this.deliveryDays = 0;
      } else if (this.bitsInRoles(this.deliveryDays).includes(value)) {
        this.deliveryDays -= value;
      } else {
        this.deliveryDays += value;
      }
    },
    addPostCodes() {
      const validationErrors = validate(
        {
          postCodeAreaList: this.postCodeAreaList,
        },
        {
          postCodeAreaList: {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }
      const postCodeAreas = this.postCodeAreaList.replaceAll(' ', '').split(',');

      if (postCodeAreas.filter((x) => x.length > 4).length > 0) {
        this.alertBox().fire({
          title: 'Validation Errors',
          text: 'Max 4 Letters Per Post Code Prefix',
        });
        return;
      }

      for (let i = 0; i < postCodeAreas.length; i += 1) {
        if (
          !this.matchingPostCodes.some((x) => x.PostcodePrefix === postCodeAreas[i].toUpperCase())
        ) {
          this.matchingPostCodes.push({
            PostcodePrefix: postCodeAreas[i].toUpperCase(),
            DeliveryOptionId: this.id,
            id: this.mappedPostCodes.some(
              (x) => x.PostcodePrefix === postCodeAreas[i].toUpperCase(),
            )
              ? this.mappedPostCodes.filter(
                  (x) => x.PostcodePrefix === postCodeAreas[i].toUpperCase(),
                )[0].id
              : undefined,
          });
        }
      }
      this.postCodeAreaList = '';
      this.isSubmitList = false;
    },
    removePostCode(postCode) {
      const index = this.matchingPostCodes.findIndex(
        (x) => x.PostcodePrefix === postCode.PostcodePrefix,
      );
      if (postCode.id && this.originalPostCodes.some((x) => x.id === postCode.id)) {
        this.$set(this.matchingPostCodes[index], 'delete', true);
      } else {
        this.matchingPostCodes.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 650px;
}
</style>
