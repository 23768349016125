<template>
  <loading :loading="loading">
    <div class="flex flex-col h-screen w-full">
      <div
        v-if="demo_mode"
        ref="intro_popup"
        class="absolute top-0 left-0 right-0 bottom-0 bg-black_80 flex flex-col justify-around z-10"
      >
        <div
          class="p-10 m-auto text-center text-white rounded-lg bg-touch-purple"
          style="max-width: 500px"
        >
          <div>
            Welcome to the quote and survey designer, this will allow you to design a product with
            full technical and survey information if needed.
          </div>
          <div class="mt-6">
            <div class="mb-3">1. Choose a product from the menu on the left.</div>
            <div>2. Choose a design</div>
          </div>
          <div class="pt-10">
            <div
              class="border border-white p-3 hover:bg-white hover:text-touch-purple rounded cursor-pointer"
              @click="$refs.intro_popup.remove()"
            >
              Continue <i class="fal fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col flex-grow overflow-hidden">
        <template v-if="!stock_parts_mode">
          <div class="flex flex-grow overflow-y-auto">
            <div
              class="trade-designer-choose-products-sidebar xs:block"
              :class="{ hidden: !products_for_group_mobile_dropdown_open }"
            >
              <div class="trade-designer-choose-products-sidebar-header">
                <div class="flex items-center">
                  <span class="p-4 xs:p-0 xs:px-6"> Choose a product </span>
                </div>
                <div class="flex flex-col justify-around xs:hidden p-3">
                  <i class="fa fa-caret-down"></i>
                </div>
              </div>
              <div class="trade-designer-choose-products-sidebar-body">
                <products-for-group
                  :type-id="filter.typeId"
                  :group-id="filter.groupId"
                  :selected-product-id="selectedProductId"
                  @select-product="selectProduct"
                ></products-for-group>
              </div>
            </div>
            <div v-if="selected_product" class="flex flex-col flex-grow w-full">
              <div class="flex-grow overflow-y-auto scrolling-touch">
                <div class="flex xs:hidden">
                  <div
                    class="flex-1 p-3 border-b border-r text-center"
                    @click="
                      products_for_group_mobile_dropdown_open =
                        !products_for_group_mobile_dropdown_open
                    "
                  >
                    Choose Product
                    <i class="fa fa-caret-down ml-3"></i>
                  </div>
                  <div
                    class="flex-1 p-3 border-b text-center"
                    @click="filters_mobile_dropdown_open = !filters_mobile_dropdown_open"
                  >
                    <i class="fal fa-sliders-h mr-3"></i>
                    Filter
                  </div>
                </div>
                <div
                  class="flex w-full border-b xs:flex items-center"
                  :class="{ hidden: !filters_mobile_dropdown_open }"
                >
                  <div class="text-base px-3 md:px-6 hidden sm:block border-t-4 border-white">
                    Filter By:
                  </div>
                  <div class="xs:flex flex-wrap flex-grow">
                    <group-and-type-filter
                      v-model="filter"
                      :number_of_squares_dropdown_open="number_of_squares_dropdown_open"
                      @close-number-of-squares-dropdown="number_of_squares_dropdown_open = false"
                    >
                    </group-and-type-filter>

                    <div class="xs:flex">
                      <div
                        class="relative m-3"
                        tabindex="3"
                        @focusout="number_of_squares_dropdown_open = false"
                      >
                        <div
                          class="border border-t-4 border-l-0 border-r-0 border-b-0 border-white w-full"
                          :class="{ 'border-green-600': qty_of_squares_filter !== undefined }"
                        ></div>
                        <div
                          class="border flex justify-between cursor-pointer"
                          @click="number_of_squares_dropdown_open = !number_of_squares_dropdown_open"
                        >
                          <div class="p-3 whitespace-no-wrap text-gray-600">Squares Quantity</div>
                          <div class="p-3">
                            <i v-if="number_of_squares_dropdown_open" class="fa fa-caret-up"></i>
                            <i v-else class="fa fa-caret-down"></i>
                          </div>
                        </div>
                        <div
                          v-if="number_of_squares_dropdown_open"
                          class="absolute bg-gray-200 w-full md:w-64 border border-t-0 z-10 text-xs sm:text-sm"
                        >
                          <div
                            class="flex justify-between cursor-pointer bg-white m-2 rounded-lg cursor-pointer hover:bg-brand-primary hover:text-white"
                            :class="{
                              'bg-brand-primary text-white': qty_of_squares_filter === undefined,
                            }"
                            @click="qty_of_squares_filter = undefined"
                          >
                            <div class="p-3">Show All</div>
                            <div v-if="qty_of_squares_filter === undefined" class="p-3">
                              <i class="fal fa-check"></i>
                            </div>
                          </div>
                          <div
                            v-for="(squaresQty, index) in quantityOfSquaresFilterList"
                            :key="index"
                            class="flex cursor-pointer bg-white m-2 rounded-lg cursor-pointer hover:bg-brand-primary hover:text-white"
                            :class="{
                              'bg-brand-primary text-white': qty_of_squares_filter === squaresQty,
                            }"
                            @click="
                              (qty_of_squares_filter = squaresQty),
                              (number_of_squares_dropdown_open = false)
                            "
                          >
                            <div v-if="qty_of_squares_filter === squaresQty" class="p-3">
                              <i class="fal fa-check"></i>
                            </div>
                            <div class="p-3">
                              <label :title="squaresQty">
                                {{ squaresQty }} Square<template v-if="squaresQty > 1">s</template></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="overflow-x-auto bg-white">
                  <div class="flex bg-white px-5 border-t-4 border-white whitespace-no-wrap">
                    <div
                      v-if="filter.selectedGroupName !== null"
                      class="px-1 xs:px-3 py-3 flex items-center text-gray-600"
                    >
                      {{ filter.selectedGroupName }} <i class="fal fa-chevron-right ml-1 xs:ml-3"></i>
                    </div>
                    <div
                      v-if="filter.selectedTypeName !== null"
                      class="px-1 xs:px-3 py-3 flex items-center text-gray-600"
                    >
                      {{ filter.selectedTypeName }} <i class="fal fa-chevron-right ml-1 xs:ml-3"></i>
                    </div>
                    <div class="px-1 xs:px-3 py-3 font-medium">{{ selected_product.name }}</div>
                    <div
                      v-if="qty_of_squares_filter !== undefined"
                      class="px-1 xs:px-3 py-3 flex items-center text-gray-600"
                    >
                      <i class="fal fa-chevron-right mr-1 xs:mr-3"></i>
                      {{ qty_of_squares_filter }} Square<template v-if="qty_of_squares_filter > 1">
                        s
                      </template>
                    </div>
                  </div>
                </div>
                <animation-staggered-fade
                  v-if="filtered_product_designs.length > 0"
                  :class="{
                    'filtered-product-designs-margin': choose_product_sidebar_closed,
                    'opacity-25': loading,
                  }"
                >
                  <div key="designs-holder" class="flex flex-wrap w-full justify-center">
                    <button
                      v-for="(product_design, index) in filtered_product_designs"
                      :key="product_design.designId"
                      class="flex flex-col w-full xs:w-1/2 sm:w-1/4 lg:w-1/5 p-3 lg:p-5 cursor-pointer"
                      :data-index="index"
                      @click.prevent="selectDesign(product_design.designId)"
                    >
                      <div class="flex flex-col h-full bg-gray-200 rounded-lg w-full">
                        <div>
                          <div class="flex flex-col justify-around p-3 pb-0 md:pb-3">
                            <div v-if="product_design.imageUrl" class="mx-auto">
                              <img
                                class="flex flex-col justify-around p-3"
                                style="max-height: 150px"
                                data-svgImage
                                :src="product_design.imageUrl"
                              />
                            </div>
                            <span
                              v-else
                              class="flex flex-col justify-around p-3"
                              style="max-height: 150px"
                              data-svgImage
                              v-html="product_design.imageSvg"
                            ></span>
                          </div>
                        </div>
                        <div class="mt-auto p-3 pt-0">
                          <div class="btn-action w-full" v-text="product_design.name"></div>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    v-if="qty_of_squares_filter === undefined"
                    key="pagination"
                    class="text-center mx-6 mb-6"
                  >
                    <div class="flex justify-end">
                      <div class="hidden xs:flex flex-col justify-around p-1 mr-2">
                        View more results
                      </div>
                      <div
                        class="flex flex-col justify-around cursor-pointer hover:text-gray-600 mr-1 border bg-white p-1 w-6 text-center"
                        :class="{ 'text-gray-400 pointer-events-none': pagination.currentPage === 1 }"
                        @click="showPage(1)"
                      >
                        <div class="flex justify-center">
                          <i class="fa fa-caret-left"></i>
                          <i class="fa fa-caret-left"></i>
                        </div>
                      </div>
                      <div
                        class="flex flex-col justify-around cursor-pointer hover:text-gray-600 border bg-white p-1 w-6 text-center"
                        :class="{ 'text-gray-400 pointer-events-none': pagination.currentPage === 1 }"
                        @click="showPage(pagination.currentPage - 1)"
                      >
                        <i class="fa fa-caret-left"></i>
                      </div>
                      <div class="flex flex-col justify-around">
                        <select
                          v-model="pagination.currentPage"
                          class="h-7 w-18 bg-white mx-1 border p-1 text-xs"
                          @change="showPage(pagination.currentPage)"
                        >
                          <option
                            v-for="page in pagination.maxPage"
                            :key="page"
                            :selected="pagination.currentPage === page"
                            :value="page"
                          >
                            {{ page }} of {{ pagination.maxPage }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="flex flex-col justify-around cursor-pointer hover:text-gray-600 border bg-white p-1 w-6 text-center"
                        :class="{
                          'text-gray-400 pointer-events-none':
                            pagination.currentPage === pagination.maxPage,
                        }"
                        @click="showPage(pagination.currentPage + 1)"
                      >
                        <i class="fa fa-caret-right"></i>
                      </div>
                      <div
                        class="flex flex-col justify-around cursor-pointer hover:text-gray-600 ml-1 border bg-white p-1 w-6"
                        :class="{
                          'text-gray-400 pointer-events-none':
                            pagination.currentPage === pagination.maxPage,
                        }"
                        @click="showPage(pagination.maxPage)"
                      >
                        <div class="flex justify-center">
                          <i class="fa fa-caret-right"></i>
                          <i class="fa fa-caret-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </animation-staggered-fade>
                <div v-else key="none-found">
                  <div v-if="product_design_search" class="flex justify-between p-3">
                    <div class="flex flex-col justify-around">
                      No {{ selected_product.name }} Designs match "{{ product_design_search }}"
                    </div>
                    <div>
                      <a class="btn-action" @click.stop="product_design_search = ''">
                        Clear Search
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <stock-parts-designer :url="url" @set-mode="setMode" />
        </template>
      </div>
    </div>
  </loading>
</template>

<script>
import GroupAndTypeFilter from '@/components/shared/chooseProduct/GroupAndTypeFilter.vue';
import ProductsForGroup from '@/components/shared/chooseProduct/ProductsForGroup.vue';
import StockPartsDesigner from '@/components/shared/chooseProduct/StockPartsDesigner.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    'group-and-type-filter': GroupAndTypeFilter,
    'products-for-group': ProductsForGroup,
    'stock-parts-designer': StockPartsDesigner,
  },
  props: {
    customer: {
      default: null,
      type: Object,
    },
    url: String,
  },
  data() {
    return {
      pagination: {
        currentPage: 1,
        perPage: 50,
      },
      filter: {
        groupId: undefined,
        typeId: undefined,
        selectedGroupName: undefined,
        selectedTypeName: undefined,
      },
      selectedProductId: undefined,
      product_search: '',
      product_design_search: '',
      qty_of_squares_filter: undefined,
      selected_product: undefined,
      products: [],
      product_designs: [],
      sq_filtered_product_designs: undefined,
      choose_product_dropdown_open: true,
      loading: true,
      choose_product_sidebar_closed: false,
      number_of_squares_dropdown_open: false,
      products_for_group_mobile_dropdown_open: false,
      filters_mobile_dropdown_open: false,
      stock_parts_mode: false,
      hasStockParts: false,
    };
  },
  computed: {
    customerName() {
      try {
        return this.customer.company.name;
      } catch (e) {
        return 'Loading';
      }
    },
    customerId() {
      try {
        return this.customer.customerId;
      } catch (e) {
        return 'Loading';
      }
    },
    quantityOfSquaresFilterList() {
      return [...Array(12).keys()].map((i) => i + 1);
    },
    filtered_product_designs() {
      if (this.qty_of_squares_filter === undefined && this.product_design_search === '') {
        return this.product_designs.slice().sort((a, b) => a.listIndex - b.listIndex);
      }

      let filteredProductDesigns = this.sq_filtered_product_designs
        ? this.sq_filtered_product_designs
        : this.product_designs;

      if (this.product_design_search !== '') {
        filteredProductDesigns = filteredProductDesigns.filter((productDesign) => {
          if (productDesign.name.toLowerCase().includes(this.product_design_search.toLowerCase())) {
            return productDesign;
          }
          return false;
        });
      }

      return filteredProductDesigns.sort((a, b) => a.listIndex - b.listIndex);
    },
  },
  watch: {
    async selected_product(selectedProduct) {
      this.product_designs = [];
      this.qty_of_squares_filter = undefined;
      this.product_design_search = '';

      this.getProductStoredDesignsForPage({
        customerId: this.$store.state.basket.customerId,
        processingLevel: this.$store.state.auth.processingLevel,
        productId: this.selected_product.productId,
        page: this.pagination.currentPage,
        perPage: this.pagination.perPage,
        squaresQty: this.qty_of_squares_filter,
      }).then((response) => {
        if (selectedProduct.productId === this.selected_product.productId) {
          this.product_designs = response.filteredList;

          this.pagination.currentPage = 1;
          this.pagination.totalDesigns = response.total;
          this.pagination.maxPage = Math.ceil(
            this.pagination.totalDesigns / this.pagination.perPage,
          );

          this.addHeightToSVGs();
        }
      });
    },
    product_design_search(newQuery) {
      if (newQuery.length > 0) {
        this.qty_of_squares_filter = undefined;
      }
    },
    qty_of_squares_filter(numberOfSquares) {
      if (numberOfSquares >= 1) {
        this.product_design_search = '';
        this.getProductStoredDesignsForPage({
          customerId: this.$store.state.basket.customerId,
          processingLevel: this.$store.state.auth.processingLevel,
          productId: this.selected_product.productId,
          page: 1,
          perPage: 9000,
          squaresQty: this.qty_of_squares_filter,
        }).then((response) => {
          this.sq_filtered_product_designs = response.filteredList;
        });
      } else {
        this.sq_filtered_product_designs = undefined;
      }
    },
  },
  async mounted() {
    this.loading = true;

    if (!this.$store.getters['basket/hasContract']) {
      this.routerPush('/new-quote');
      return;
    }
    this.hasStockParts = true;
    this.loading = false;
  },
  methods: {
    ...mapActions({ getProductStoredDesignsForPage: 'touch/getProductStoredDesignsForPage' }),
    async hasModule(prooduct, module) {
      const result = await this.$store.dispatch('auth/hasModule', {
        productName: prooduct,
        moduleName: module,
      });

      return result;
    },
    addHeightToSVGs() {
      this.$nextTick(() => {
        const svgImages = document.querySelectorAll('[data-svgImage] svg');
        svgImages.forEach((image) => {
          image.setAttribute('height', '140px');
        });
      });
    },
    async showPage(page) {
      this.pagination.currentPage = page;
      this.product_designs = (
        await this.getProductStoredDesignsForPage({
          customerId: this.$store.state.basket.customerId,
          processingLevel: this.$store.state.auth.processingLevel,
          productId: this.selected_product.productId,
          page: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          squaresQty: this.qty_of_squares_filter,
        })
      ).filteredList;
      this.addHeightToSVGs();
    },
    setMode(mode) {
      switch (mode) {
        case 'products':
          this.stock_parts_mode = false;
          this.$emit('setStockParts', this.stock_parts_mode);
          break;
        case 'stock-parts':
          this.stock_parts_mode = true;
          this.$emit('setStockParts', this.stock_parts_mode);
          break;
        default:
          throw new Error(`Unknown Mode: ${mode}`);
      }
    },
    selectProduct(product) {
      this.selectedProductId = product.productId;
      this.selected_product = product;
      this.$emit('selectedProductName', this.selected_product.name);
      this.products_for_group_mobile_dropdown_open = false;
    },
    async selectDesign(designId) {
      if (this.loading) {
        return;
      }

      this.loading = true;
      await this.$store
        .dispatch('basket/addDesign', {
          designId,
          productId: this.selected_product.productId,
        })
        .then((itemKey) => {
          this.routerPush(`${this.url}/design/${itemKey}`);
        });
    },
    toggleChooseProductDropdownOpen() {
      this.choose_product_dropdown_open = !this.choose_product_dropdown_open;
    },
  },
};
</script>
