import moment from 'moment';

export default {
  computed: {
    todaysDate() {
      return this.moment().format('ddd, LL');
    },
  },
  methods: {
    moment(date) {
      return moment(date).locale(navigator.language);
    },
    formatDate(date, format = 'L') {
      return this.moment(date).format(format);
    },
    formatHumanDate(date) {
      return this.moment(date).fromNow();
    },
  },
};
