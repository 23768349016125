var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white p-3 xs:p-10 border mt-5 sm:mt-0"},[_vm._t("enquiries-overdue-limit"),(_vm.companySettings)?_c('div',{staticClass:"pb-10 border-b"},[_vm._m(0),_c('div',{staticClass:"flex flex-col justify-around text-gray-600 text-base max-w-3xl"},[_vm._v(" Would you like to request measurements from your customers via the "+_vm._s(_vm.setup)+" Designer? If Yes, this question will appear on the intro screen. ")]),_c('div',{staticClass:"flex flex-col md:flex-row -mx-5"},[_c('div',{staticClass:"p-5"},[_c('label',{staticClass:"flex flex-col"},[_c('div',[_c('div',{staticClass:"inline-flex border border-gray-600 rounded-lg"},[_c('div',{staticClass:"text-white border border-white rounded-l-lg px-3",class:{
                  'bg-green-600': _vm.companySettings.showConsumerSizes,
                  'bg-gray-600': !_vm.companySettings.showConsumerSizes,
                }},[_vm._v(" Yes ")]),_c('div',{staticClass:"bg-gray-600 text-white border border-white rounded-r-lg px-3",class:{
                  'bg-gray-600': _vm.companySettings.showConsumerSizes,
                  'bg-green-600': !_vm.companySettings.showConsumerSizes,
                }},[_vm._v(" No ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companySettings.showConsumerSizes),expression:"companySettings.showConsumerSizes"}],staticClass:"hidden",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.companySettings.showConsumerSizes)?_vm._i(_vm.companySettings.showConsumerSizes,null)>-1:(_vm.companySettings.showConsumerSizes)},on:{"change":function($event){var $$a=_vm.companySettings.showConsumerSizes,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.companySettings, "showConsumerSizes", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.companySettings, "showConsumerSizes", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.companySettings, "showConsumerSizes", $$c)}}}})])])])])]):_vm._e(),_c('div',{staticClass:"flex flex-col sm:flex-row gap-5 mt-10 border-b pb-10"},[_c('div',{staticClass:"flex-1 flex flex-col"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"flex flex-col md:flex-row -mx-5"},[_c('div',{staticClass:"p-5"},[_c('label',{staticClass:"flex flex-col"},[_c('div',[_c('div',{staticClass:"inline-flex border border-gray-600 rounded-lg cursor-pointer"},[_c('div',{staticClass:"text-white border border-white rounded-l-lg px-3",class:{
                    'bg-green-600': _vm.companySettings.showDesignNames,
                    'bg-gray-600': !_vm.companySettings.showDesignNames,
                  }},[_vm._v(" Design A ")]),_c('div',{staticClass:"bg-gray-600 text-white border border-white rounded-r-lg px-3",class:{
                    'bg-gray-600': _vm.companySettings.showDesignNames,
                    'bg-green-600': !_vm.companySettings.showDesignNames,
                  }},[_vm._v(" Design B ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companySettings.showDesignNames),expression:"companySettings.showDesignNames"}],staticClass:"hidden",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.companySettings.showDesignNames)?_vm._i(_vm.companySettings.showDesignNames,null)>-1:(_vm.companySettings.showDesignNames)},on:{"change":function($event){var $$a=_vm.companySettings.showDesignNames,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.companySettings, "showDesignNames", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.companySettings, "showDesignNames", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.companySettings, "showDesignNames", $$c)}}}})])])])])]),_vm._m(3)])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 text-base max-w-3xl"},[_vm._v(" Request Measurements "),_c('span',{staticClass:"text-red-600 ml-1"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col justify-around text-base"},[_c('strong',[_vm._v("Show Design Names")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-gray-600 text-base my-3 max-w-md"},[_c('div',[_vm._v(" Choose between Design A or Design B when a customer chooses a product type. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sm:ml-auto border border-gray-300",staticStyle:{"min-height":"300px","width":"400px"}},[_c('img',{attrs:{"src":"/images/guides/show-design-names.jpg"}})])
}]

export { render, staticRenderFns }