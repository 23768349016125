<template>
  <Settings
    :bus="bus"
    :prop_settings="prop_settings"
    setup="Branded Mode"
    @afterSaved="afterSaved"
  />
</template>

<script>
import Settings from '@/components/shared/setup/website/Settings.vue';

export default {
  components: {
    Settings,
  },
  props: ['bus', 'prop_settings'],
  methods: {
    afterSaved() {
      this.$store.dispatch('auth/checkWizard');
    },
  },
};
</script>
