<template>
  <div class="w-full">
    <div class="bg-white overflow-x-auto pl-3 md:pl-10">
      <div class="flex bg-white gap-10 border-t-4 border-white">
        <router-link class="flex-shrink-0" to="/manage-touch-account/company-details">
          <div
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm"
            :class="{
              'border-black': $route.name === 'company-details',
              'border-white': $route.name !== 'company-details',
            }"
            v-text="'Company Details'"
          ></div>
        </router-link>
        <router-link to="/manage-touch-account/documents-and-agreements">
          <div
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
            :class="{
              'border-black': $route.name === 'documents-and-agreements',
              'border-white': $route.name !== 'documents-and-agreements',
            }"
            v-text="'Documents & Agreements'"
          ></div>
        </router-link>
        <router-link to="/manage-touch-account/custom-styles">
          <div
            v-if="isCompanyStylingEnabled"
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm"
            :class="{
              'border-black': $route.name === 'custom-styles',
              'border-white': $route.name !== 'custom-styles',
            }"
            v-text="'Custom Styles'"
          ></div>
        </router-link>
        <router-link
          v-if="!isLeadGen"
          class="flex-shrink-0"
          to="/manage-touch-account/delivery-options"
        >
          <div
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm"
            :class="{
              'border-black': $route.name === 'delivery-options',
              'border-white': $route.name !== 'delivery-options',
            }"
            v-text="'Delivery Options'"
          ></div>
        </router-link>
        <router-link v-if="!isLeadGen" class="flex-shrink-0" to="/manage-touch-account/quote-stages">
          <div
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm"
            :class="{
              'border-black': $route.name === 'quote-stages',
              'border-white': $route.name !== 'quote-stages',
            }"
            v-text="'Quote Stages'"
          ></div>
        </router-link>
        <router-link v-if="$store.state.basket.isPaymentGateway" class="flex-shrink-0" to="/manage-touch-account/payment-gateway">
          <div
            class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm"
            :class="{
              'border-black': $route.name === 'payment-gateway',
              'border-white': $route.name !== 'payment-gateway',
            }"
            v-text="'Payment Gateway'"
          ></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  computed: {
    isLeadGen() {
      return (
        this.$store.state.style?.fabricator?.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
    ...mapGetters({
      isCompanyStylingEnabled: 'style/isCompanyStylingEnabled',
    }),
  },
};
</script>
